import { SET_USER } from "../../constant/constant"


export const initialState = {
    user: null
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.user
            }
        default:
            return state
    }
}

export default stateReducer