import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { ActiveNavigationofTimeline, AuthJobPostingFront, CompanyMobileTopNav, UserMobileTopNav } from '../../../component';
import { useStateValue } from '../../../contexts/StateContextProvider';
import { Loader } from '../../../elements';
import db from '../../../firebase';
import { useDocumentTitle, useScrollTop } from '../../../hooks';
import '../index.css';
import * as ROUTER from '../../../constant/router';

const AuthJobTimeLine = () => {
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
       if(!posts.length){
         let mounted = true
         setLoading(true)
         db.collection('jobs')
         .where("typePost", '==', 'jobPosting')
         .orderBy('timestamp', 'desc')
         .onSnapshot(snapshot=>{
            if(mounted){
               if(snapshot.empty){
                  setLoading(false)
                  return
               }
                  setPosts(snapshot.docs.map(doc=> ({id:doc.id, ...doc.data()})))
                  setLoading(false)
            }
         }) 
      return () => mounted = false
       }
    }, [])

    const onRefreshBtn = () => {
      window.location.reload();
   }

   const [{user}] = useStateValue();
   
   useScrollTop();
   useDocumentTitle("仕事 / コピペル");

    return (
        <div className='feed'>
            {user.role === "USER" ? <UserMobileTopNav/> : <CompanyMobileTopNav/>}
           <ActiveNavigationofTimeline/>
           <div className="feed__header">
              <h2>求人投稿</h2>
              <button onClick={onRefreshBtn} className='timeLine__load__btn'><i className='fas fa-redo-alt refresh__btn'/></button>    
           </div>
           <div className='jobs_timeLine-btn'>
            <Link to={ROUTER.JOB__EMPLOYEE__TIMELINE}><button>正社員</button></Link>
            <Link to={ROUTER.JOB__PARTTIME__TIMELINE}><button>アルバイト</button></Link>
            <Link to={ROUTER.JOB__OUTSOURSING__TIMELINE}><button>業務委託</button></Link>
            <Link to={ROUTER.JOB__NEWGRADUATION__TIMELINE}><button>新卒</button></Link>
            <Link to={ROUTER.JOB__CONTRACT__EMPLOYEE__TIMELINE}><button>契約社員</button></Link>
            <Link to={ROUTER.JOB__FREELANCE__TIMELINE}><button>フリーランス</button></Link>
            <Link to={ROUTER.JOB__INTERN__TIMELINE}><button>インターン</button></Link>
           </div>
           {loading && <div className="feed__loader"><Loader/></div>}

            <AuthJobPostingFront posts={posts} />

        </div>
    )
}

export default AuthJobTimeLine;