import React, {useState, useEffect} from 'react';
import { ActiveNavigationofTimeline, UnAuthJobPostingFront } from '../../../component';
import { Loader } from '../../../elements';
import db from '../../../firebase';
import { useDocumentTitle, useScrollTop } from '../../../hooks';
import '../index.css';

const UnAuthEventsTimeLine = () => {
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
       if(!posts.length){
         let mounted = true
         setLoading(true)
         db.collection('jobs')
         .orderBy('timestamp', 'desc')
         .where('typePost', '==', 'eventPosting')
         .onSnapshot(snapshot=>{
            if(mounted){
               if(snapshot.empty){
                  setLoading(false)
                  return
               }
                  setPosts(snapshot.docs.map(doc=> ({id:doc.id, ...doc.data()})))
                  setLoading(false)
            }
         }) 
      return () => mounted = false
       }
    }, [])

    const onRefreshBtn = () => {
      window.location.reload();
   }

   useScrollTop();
   useDocumentTitle("イベント / コピペル");
   
    return (
        <div className='feed time__margin'>
           <ActiveNavigationofTimeline/>
           <div className="feed__header">
              <h2>イベント</h2>
              <button onClick={onRefreshBtn} className='timeLine__load__btn'><i className='fas fa-redo-alt refresh__btn'/></button>        
           </div>

           { loading && <div className="feed__loader"><Loader/></div> }

            <UnAuthJobPostingFront posts={posts} />

        </div>
    )
}

export default UnAuthEventsTimeLine;