import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DELETE__ACCOUNT, HOME } from "../../constant/router";
import { useStateValue } from '../../contexts/StateContextProvider';
import db from '../../firebase';
import './index.css';

const SidebarAccoutModal = () => {
    const [namePublish, setNamePublish] = useState(false);
    const [accountPublish, setAccountPublish] = useState(false);
    const [email, setEmail] = useState(false);
    const [age, setAge] = useState(false);
    const [users, setUsers] = useState([])
    const [{user}] = useStateValue();
    const history = useHistory();

    useEffect(() => {
        if(!users.length){
            const getUser = db.collection('users').doc(user.id).onSnapshot(res=>{
                setUsers(res.data())
            })
    
            return () => getUser();
        }
    }, [])

    const onLogout = () => {
        localStorage.clear()
        history.push(HOME)
        window.location.reload()
    }

    const onDelete = () => {
        history.push(DELETE__ACCOUNT);
        window.location.reload();
    }
    const [changeEmail, setChangeEmail] = useState('');
    const onUpdateEmail = () => {
        db.collection('users').doc(`${user.id}`).update({
            email: changeEmail
        })
    }

    const [changeAge, setChangeAge] = useState('');
    const onUpdateAge = () => {
        db.collection('users').doc(user.id).update({
            age: changeAge
        })

        setTimeout(() => {
            window.location.reload()
        }, 100)
    }

    const onUpdateNamePublishTrue = () => {
        db.collection('users').doc(user.id).update({
            anonymous: true
        })
    }
    const onUpdateNamePublishFalse = () => {
        db.collection('users').doc(user.id).update({
            anonymous: false
        })
    }

    
    const onUpdateAccountPublishTrue = () => {
        db.collection('users').doc(user.id).update({
            published: true
        })
    }
    const onUpdateAccountPublishFalse = () => {
        db.collection('users').doc(user.id).update({
            published: false
        })
    }
    return(
        <div className="sidebar__account__modal">
            <h3>アカウント情報の変更</h3>
            <div className="sidebar__account__modal-edit">
                <div className="sidebar__account__modal-edit-each">
                    {!namePublish ? 
                    <div className="sidebar__account__modal-edit-each-btn cursor" onClick={() => setNamePublish(true)}>お名前 / 表示・非表示<span>{users.anonymous === true ? '非表示になっています' : '表示しています'}<i className="fas fa-plus-circle setting-circle"/></span></div> 
                    : 
                    <div className="sidebar__account__modal-edit-each-btn cursor" onClick={() => setNamePublish(false)}>お名前 / 表示・非表示<span>{users.anonymous === true ? '非表示になっています' : '表示しています'}<i className="fas fa-minus-circle setting-circle"/></span></div>}
                    {!namePublish ? <></> : 
                    <>
                    {users.anonymous === true ? <button className="sidebar__account-btn" onClick={() => onUpdateNamePublishFalse()}>表示する</button> : <button className="sidebar__account-btn" onClick={() => onUpdateNamePublishTrue()}>非表示にする</button>}
                    </>
                    }
                </div>
            </div>
            <div className="sidebar__account__modal-edit">
                <div className="sidebar__account__modal-edit-each">
                    {!email ? <div className="sidebar__account__modal-edit-each-btn cursor" onClick={() => setEmail(true)}>メールアドレス<span>{user.email}<i className="fas fa-plus-circle setting-circle"/></span></div> : <div className="sidebar__account__modal-edit-each-btn cursor" onClick={() => setEmail(false)}>メールアドレス<span>{user.email}<i className="fas fa-minus-circle setting-circle"/></span></div>}
                    {!email ? <></> : <div className="d-flex"><input type='text' placeholder={`${users.email}`} value={changeEmail} onChange={e => setChangeEmail(e.target.value)} /><button onClick={onUpdateEmail}>変更する</button></div>}
                </div>
            </div>
            <div className="sidebar__account__modal-edit">
                <div className="sidebar__account__modal-edit-each">
                    {!age ? <div className="sidebar__account__modal-edit-each-btn cursor" onClick={() => setAge(true)}>年齢<span>{user.age}<i className="fas fa-plus-circle setting-circle"/></span></div> : <div className="sidebar__account__modal-edit-each-btn cursor" onClick={() => setAge(false)}>年齢<span>{user.age}<i className="fas fa-minus-circle setting-circle"/></span></div>}
                    {!age ? <></> : <div className="d-flex"><input type='number' placeholder={`${users.age}`} value={changeAge} setChangeAge={e => setChangeAge(e.target.value)}/><button onClick={onUpdateAge}>変更する</button></div>}
                </div>
            </div>
            <div className="sidebar__account__modal-edit">
                <div className="sidebar__account__modal-edit-each">
                    {!accountPublish ? 
                    <div className="sidebar__account__modal-edit-each-btn cursor" onClick={() => setAccountPublish(true)}>アカウント / 表示・非表示<span>{users.published === false ? '非表示になっています' : '表示しています'}<i className="fas fa-plus-circle setting-circle"/></span></div> 
                    : 
                    <div className="sidebar__account__modal-edit-each-btn cursor" onClick={() => setAccountPublish(false)}>アカウント / 表示・非表示<span>{users.published === false ? '非表示になっています' : '表示しています'}<i className="fas fa-minus-circle setting-circle"/></span></div>}
                    {!accountPublish ? <></> : 
                    <>
                    {users.published === true ? <button className="sidebar__account-btn" onClick={() => onUpdateAccountPublishFalse()}>非表示する</button> : <button className="sidebar__account-btn" onClick={() => onUpdateAccountPublishTrue()}>表示にする</button>}
                    </>
                    }
                </div>
            </div>
            <h3>アカウント</h3>
            <div className="unAuth__topBar__content">
                <button onClick={onLogout}>ログアウト</button>
                <button onClick={onDelete}>アカウント削除</button>
            </div>
        </div>
    )
}

export default SidebarAccoutModal;