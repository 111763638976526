import React from "react";
import { Link, useHistory } from "react-router-dom";
import { logo2 } from "../../assets";
import { useDocumentTitle, useScrollTop } from "../../hooks";
import './index.css';

const NotFound = () => {
    useDocumentTitle("Copipel / 440");
    useScrollTop();
    const history = useHistory();

    return(
        <div className="not__foundPage">
            <h1>お探しのページが見つかりませんでした</h1>
            <img src={logo2} alt='' className="not__foundPage__logo"/>
            <section className="error-container">
                <span className="four"><span className="screen-reader-text">4</span></span>
                <span className="zero"><span className="screen-reader-text">0</span></span>
                <span className="four"><span className="screen-reader-text">4</span></span>
            </section>
            <div className="link-container">
                <button onClick={() => history.goback()} className="more-link">もどる</button>
                <Link to='/'><button className="more-link">ホーム</button></Link>
            </div>
        </div>
    );
};

export default NotFound;