import React, {useState, useEffect} from 'react';
import firebase from 'firebase';
import { Button } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CropIcon from '@material-ui/icons/Crop';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import { useHistory } from 'react-router-dom';

import db from '../../firebase';
import postToCloudinary from '../../helpers/postToCloudinary';
import { getInfo } from '../../helpers/getImageDimension';
import { generateAltText } from '../../helpers/generateAltText';
import YoputubeClickInfo from './YoutubeClickInfo';
import { useStateValue } from '../../contexts/StateContextProvider';
import { useDocumentTitle, useScrollTop } from '../../hooks';
import { AddALT, CropPhotoB, StatusInput } from '../../component';
import { MessageModal, Modal, Spinner, TabbarMenu } from '../../elements';
import './index.css';
import { HOME } from '../../constant/router';


const JobPostingBox = () => {
    const history = useHistory();
    const [{user}] = useStateValue()
    const [altText, setAltText] = useState(generateAltText(user.displayName))
    const [src, setSrc] = useState(null)
    const [imageToSend, setImageToSend] = useState(null)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isOpenYoutubeModal, setIsOpenYoutubeModal] = useState(false)
    const [initialImageSize, setinitialImageSize] = useState({width: 0, height: 0})
    const [initialAspectRatio, setinitialAspectRatio] = useState(null)
    const [croppedImageResult, setCroppedImageResult ] = useState(null)
    const [isLoading, setIsloading] = useState(false);
    const initInput = {
        briefIntro: "",
        kindJob: "",
        wanted: "",
        hireSection: "",
        welfare: "",
        timeWork: "",
        otherAddress: "",
        offDay: "",
        jobTitle: "",
        salary: "",
        salary2: "",
        salaryType: '',
        prefecture: "",
        website: "",
        reqSkills1: "",
        reqSkills2: "",
        reqSkills3: "",
        reqSkills4: "",
        opSkills1: "",
        opSkills2: "",
        opSkills3: "",
        opSkills4: "",
        youtubeLink: "",
        keywords1: "",
        keywords2: "",
        keywords3: "",
        keywords4: "",
        access: '',
        qualification: '',
        qualificationShort: ''
    }
    const [inputStatus, setInputStatus] = useState(initInput);
    useScrollTop();
    useDocumentTitle("仕事投稿 / コピペル");

    const sendPost = (e) => {
        e.preventDefault()
        setIsloading(true)
        const {briefIntro, kindJob, wanted, hireSection, welfare, timeWork, otherAddress, offDay, jobTitle, salary, prefecture, website, reqSkills1, reqSkills2, reqSkills3, reqSkills4,
        opSkills1, opSkills2, opSkills3, opSkills4, youtubeLink, keywords1, keywords2, keywords3, keywords4, access, salaryType, salary2, qualification, qualificationShort } = inputStatus
        if(imageToSend){
            postToCloudinary(imageToSend)
            .then( res=>{
                const name = user.displayName;
                db.collection('jobs').add({
                    access: access,
                    displayName: user.displayName,
                    companyName: user.displayName,
                    qualification: qualification,
                    qualificationShort: qualificationShort,
                    salary: salary,
                    salary2: salary2,
                    salaryType: salaryType,
                    prefecture: prefecture,
                    otherAddress: otherAddress,
                    youtubeLink: youtubeLink,
                    altText: altText,
                    briefIntro: briefIntro,
                    kindJob: kindJob,
                    wanted: wanted,
                    reqSkill: [reqSkills1, reqSkills2, reqSkills3, reqSkills4],
                    opSkill: [opSkills1, opSkills2, opSkills3, opSkills4],
                    hireSection: hireSection,
                    timeWork: timeWork,
                    welfare: welfare,
                    offDay: offDay,
                    jobTitle: jobTitle,
                    website: website,
                    nameKeywords: [name.substring(0, 1), name.substring(0, 2), name.substring(0, 3), name.substring(0, 4), name.substring(0, 5), name.substring(0, 6), name.substring(0, 7), name.substring(0, 8), name.substring(0, 9), name.substring(0, 10),
                        name.substring(0, 11), name.substring(0, 12), name.substring(0, 13), name.substring(0, 14), name.substring(0, 15), name.substring(0, 16), name.substring(0, 17), name.substring(0, 18), name.substring(0, 19), name.substring(0, 20), name],
                    image: res,
                    keywords: [name.substring(0, 1), name.substring(0, 2), name.substring(0, 3), name.substring(0, 4), name.substring(0, 5), name.substring(0, 6), name.substring(0, 7), name.substring(0, 8), name.substring(0, 9), name.substring(0, 10),
                        name.substring(0, 11), name.substring(0, 12), name.substring(0, 13), name.substring(0, 14), name.substring(0, 15), name.substring(0, 16), name.substring(0, 17), name.substring(0, 18), name.substring(0, 19), name.substring(0, 20),
                        reqSkills1, reqSkills2, reqSkills3, reqSkills4,
                        keywords1.substring(0, 1), keywords1.substring(0, 2), keywords1.substring(0, 3), keywords1.substring(0, 4), keywords1.substring(0, 5),
                        keywords1.substring(0, 6), keywords1.substring(0, 7), keywords1.substring(0, 8), keywords1.substring(0, 9), keywords1.substring(0, 10),
                        keywords2.substring(0, 1), keywords2.substring(0, 2), keywords2.substring(0, 3), keywords2.substring(0, 4), keywords2.substring(0, 5),
                        keywords2.substring(0, 6), keywords2.substring(0, 7), keywords2.substring(0, 8), keywords2.substring(0, 9), keywords2.substring(0, 10),
                        keywords3.substring(0, 1), keywords3.substring(0, 2), keywords3.substring(0, 3), keywords3.substring(0, 4), keywords3.substring(0, 5),
                        keywords3.substring(0, 6), keywords3.substring(0, 7), keywords3.substring(0, 8), keywords3.substring(0, 9), keywords3.substring(0, 10),
                        keywords4.substring(0, 1), keywords4.substring(0, 2), keywords4.substring(0, 3), keywords4.substring(0, 4), keywords4.substring(0, 5),
                        keywords4.substring(0, 6), keywords4.substring(0, 7), keywords4.substring(0, 8), keywords4.substring(0, 9), keywords4.substring(0, 10),
                    ],
                    keywordsWord: [keywords1, keywords2, keywords3, keywords4],
                    eventDate: '',
                    eventPlace: '',
                    eventWhy: '',
                    eventDetails: '',
                    eventTime: '',
                    likes: [],
                    bookMark: [],
                    underRecrute: true,
                    typePost: "jobPosting",
                    senderId: user.id,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp()
                })
                setInputStatus(initInput);
                setSrc(null);
                setIsloading(false);
                history.push(HOME)

            })
            .catch( err=> {
                setIsloading(false)
                return
            })

        } else {
            const name = user.displayName;
        db.collection('jobs').add({
            access: access,
            displayName: user.displayName,
            image: '',
            companyName: user.displayName,
            qualification: qualification,
            qualificationShort: qualificationShort,
            salary: salary,
            salary2: salary2,
            salaryType: salaryType,
            youtubeLink: youtubeLink,
            prefecture: prefecture,
            altText: altText,
            briefIntro: briefIntro,
            kindJob: kindJob,
            wanted: wanted,
            reqSkill: [reqSkills1, reqSkills2, reqSkills3, reqSkills4],
            opSkill: [opSkills1, opSkills2, opSkills3, opSkills4],
            hireSection: hireSection,
            website: website,
            timeWork: timeWork,
            welfare: welfare,
            otherAddress: otherAddress,
            offDay: offDay,
            jobTitle: jobTitle,
            nameKeywords: [name.substring(0, 1), name.substring(0, 2), name.substring(0, 3), name.substring(0, 4), name.substring(0, 5), name.substring(0, 6), name.substring(0, 7), name.substring(0, 8), name.substring(0, 9), name.substring(0, 10),
                name.substring(0, 11), name.substring(0, 12), name.substring(0, 13), name.substring(0, 14), name.substring(0, 15), name.substring(0, 16), name.substring(0, 17), name.substring(0, 18), name.substring(0, 19), name.substring(0, 20), name],
            eventDate: '',
            keywords: [name.substring(0, 1), name.substring(0, 2), name.substring(0, 3), name.substring(0, 4), name.substring(0, 5), name.substring(0, 6), name.substring(0, 7), name.substring(0, 8), name.substring(0, 9), name.substring(0, 10),
                name.substring(0, 11), name.substring(0, 12), name.substring(0, 13), name.substring(0, 14), name.substring(0, 15), name.substring(0, 16), name.substring(0, 17), name.substring(0, 18), name.substring(0, 19), name.substring(0, 20),
                reqSkills1, reqSkills2, reqSkills3, reqSkills4,
                keywords1.substring(0, 1), keywords1.substring(0, 2), keywords1.substring(0, 3), keywords1.substring(0, 4), keywords1.substring(0, 5),
                keywords1.substring(0, 6), keywords1.substring(0, 7), keywords1.substring(0, 8), keywords1.substring(0, 9), keywords1.substring(0, 10),
                keywords2.substring(0, 1), keywords2.substring(0, 2), keywords2.substring(0, 3), keywords2.substring(0, 4), keywords2.substring(0, 5),
                keywords2.substring(0, 6), keywords2.substring(0, 7), keywords2.substring(0, 8), keywords2.substring(0, 9), keywords2.substring(0, 10),
                keywords3.substring(0, 1), keywords3.substring(0, 2), keywords3.substring(0, 3), keywords3.substring(0, 4), keywords3.substring(0, 5),
                keywords3.substring(0, 6), keywords3.substring(0, 7), keywords3.substring(0, 8), keywords3.substring(0, 9), keywords3.substring(0, 10),
                keywords4.substring(0, 1), keywords4.substring(0, 2), keywords4.substring(0, 3), keywords4.substring(0, 4), keywords4.substring(0, 5),
                keywords4.substring(0, 6), keywords4.substring(0, 7), keywords4.substring(0, 8), keywords4.substring(0, 9), keywords4.substring(0, 10),
            ],
            keywordsWord: [keywords1, keywords2, keywords3, keywords4],
            eventPlace: '',
            eventWhy: '',
            eventDetails: '',
            eventTime: '',
            typePost: "jobPosting",
            likes: [],
            bookMark: [],
            underRecrute: true,
            senderId: user.id,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
        })
        setInputStatus(initInput);
        setSrc(null);
        setIsloading(false);
        history.push(HOME)
        }
    }

    const onSelectFile = e => {
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            setSrc(fileReader.result)
            setImageToSend(fileReader.result)
        }   
        fileReader.readAsDataURL(e.target.files[0])

        getInfo(e).then(res=> {
            setinitialImageSize({width: res.width, height: res.height})
        })
    }

    useEffect(() => {
        setinitialAspectRatio(initialImageSize.width/initialImageSize.height)
    }, [initialImageSize])

    const changeSrc = () => {
        setSrc(URL.createObjectURL(croppedImageResult))
        setImageToSend(croppedImageResult)
    }

    const callbackforModal = () =>{
        changeSrc()
        if (altText.length === 0){
            setAltText(generateAltText(user.displayName))
        }
    }

    const items = [
        {
            id: 0,
            title:'',
            icon: <CropIcon />,
            item: <CropPhotoB
                    image={src} 
                    setCroppedImageResult ={setCroppedImageResult} 
                    initialAspectRatio    = {initialAspectRatio}
            />
        },
        {
            id: 1,
            title: 'ALT',
            icon: '',
            item: <AddALT image={croppedImageResult} altText={altText} setAltText={setAltText}/>
        }
    ]

    return (
        <>
            <MessageModal
            open={isOpenYoutubeModal} 
            onClose={()=>setIsOpenYoutubeModal(false)}
            Icon = {ArrowBackOutlinedIcon}
            title="Youtubeのリンクの貼り方"
            >
                <YoputubeClickInfo/>
            </MessageModal>

            <Modal  open={isOpenModal} 
                    onClose={()=>setIsOpenModal(false)}
                    title="画像を編集"
                    callback = {callbackforModal}
                    Icon = {ArrowBackOutlinedIcon}
                    ButtonText='保存する'
                    >
                    <TabbarMenu items={items}/>
            </Modal>

            <div className='jobBox'>
                <div className='youtube__info__div'>
                    <button className='youtube__info' onClick={()=>setIsOpenYoutubeModal(true)}><i className='fas fa-info-circle youtube__click__info'/></button>
                </div>
                <form onSubmit={sendPost}>
                    {inputStatus.hireSection === "正社員" || inputStatus.hireSection === "アルバイト" || inputStatus.hireSection === "新卒" || inputStatus.hireSection === "契約社員" || inputStatus.hireSection === "インターン" || inputStatus.hireSection === "業務委託" || inputStatus.hireSection === "" ? 
                        <div className='jobBox__wrapperInput' >
                            <div className='jobBox__input'>
                                <select className='jobPost__select' onChange={e => setInputStatus({...inputStatus, hireSection: e.target.value})} required>
                                    <option disabled selected>雇用区分を選択する</option>
                                    <option value='正社員'>正社員</option>
                                    <option value='アルバイト'>アルバイト</option>
                                    <option value='業務委託'>業務委託</option>
                                    <option value='フリーランス'>フリーランス</option>
                                    <option value='新卒'>新卒</option>
                                    <option value='契約社員'>契約社員</option>
                                    <option value='インターン'>インターン</option>
                                </select>
                                <input type='text' placeholder='タイトル' value={inputStatus.jobTitle} onChange={e => setInputStatus({...inputStatus, jobTitle: e.target.value})} required maxLength='20'/>
                                <input type='text' placeholder='ウェブサイトURL(申し込み用のURLなど)' value={inputStatus.website} onChange={e => setInputStatus({...inputStatus, website: e.target.value})}/>
                                <input type='text' placeholder='Youtube URL(会社の紹介動画など)、リンクの貼り方は上記のボタンより確認できます' value={inputStatus.youtubeLink} onChange={e => setInputStatus({...inputStatus, youtubeLink: e.target.value})}/>
                                <textarea rows='1' 
                                            placeholder="事業内容"
                                            type='text' 
                                            value={inputStatus.briefIntro}
                                            onChange={e => setInputStatus({...inputStatus, briefIntro: e.target.value})}
                                            required                     
                                >
                                </textarea>

                                <select className="jobPost__select" onChange={e => setInputStatus({...inputStatus, kindJob: e.target.value})} required>
                                    <option selected disabled>職種を選択する</option>
                                    {/*営業*/}
                                    <option disabled>営業</option>
                                    <option value='法人営業'>法人営業</option>
                                    <option value='個人営業'>個人営業</option>
                                    <option value='内勤営業・カウンターセールス'>内勤営業・カウンターセールス</option>
                                    <option value='ルートセールス・代理店営業'>ルートセールス・代理店営業</option>
                                    <option value='IT営業'>IT営業</option>
                                    <option value='海外営業'>海外営業</option>
                                    <option value='不動産営業'>不動産営業</option>
                                    <option value='金融営業'>金融営業</option>
                                    <option value='テレマーケティング'>テレマーケティング</option>

                                    {/*事務・アシスタント*/}
                                    <option disabled>事務・アシスタント</option>
                                    <option value='一般事務'>一般事務</option>
                                    <option value='営業事務'>営業事務</option>
                                    <option value='カスタマーサポート'>カスタマーサポート</option>
                                    <option value='受付'>受付</option>
                                    <option value='秘書'>秘書</option>

                                    {/*財務・会計・経理*/}
                                    <option value='経理'>経理</option>
                                    <option value='財務'>財務</option>
                                    <option value='管理会計'>管理会計</option>

                                    {/*総務・人事・法務・知財・広報・IR*/}
                                    <option disabled>総務・人事・法務・知財・広報・IR</option>
                                    <option value='人事（採用）'>人事（採用）</option>
                                    <option value='人事（労務）'>人事（労務）</option>
                                    <option value='総務'>総務</option>
                                    <option value='広報'>広報</option>
                                    <option value='法務'>法務</option>

                                    {/*商品企画・営業企画・マーケティング・宣伝*/}
                                    <option disabled>商品企画・営業企画・マーケティング・宣伝</option>
                                    <option value='商品企画、サービス企画'>商品企画、サービス企画</option>
                                    <option value='販売促進・PR・広告宣伝'>販売促進・PR・広告宣伝</option>
                                    <option value='Webマーケティング'>Webマーケティング</option>

                                    {/*経営企画・事業統括・新規事業開発*/}
                                    <option disabled>経営企画・事業統括・新規事業開発</option>
                                    <option value='経営者・経営幹部・役員(CEO、COO、CFO、CIO、CTO)'>経営者・経営幹部・役員（CEO、COO、CFO、CIO、CTO）</option>

                                    {/*管理職・エグゼクティブ*/}
                                    <option disabled>管理職・エグゼクティブ</option>
                                    <option value='経営企画・営業企画'>経営企画・営業企画</option>
                                    <option value='事業企画・新規事業開発'>事業企画・新規事業開発</option>
                                    <option value='事業統括マネージャー職（営業部門・管理部門）'>事業統括マネージャー職（営業部門・管理部門）</option>

                                    {/*Webエンジニア*/}
                                    <option disabled>エンジニア</option>
                                    <option value='システムエンジニア（Web・オープン系・パッケージ開発）'>システムエンジニア（Web・オープン系・パッケージ開発）</option>
                                    <option value='システムエンジニア（汎用機系）'>システムエンジニア（汎用機系）</option>
                                    <option value='プロジェクトマネジャー（Web・オープン系・パッケージ開発）'>プロジェクトマネジャー（Web・オープン系・パッケージ開発）</option>
                                    <option value='モバイルアプリ'>モバイルアプリ</option>
                                    <option value='フロントエンド'>フロントエンド</option>
                                    <option value='バックエンド'>バックエンド</option>
                                    <option value='フルスタック'>フルスタック</option>

                                    {/*インフラエンジニア*/}
                                    <option disabled>インフラエンジニア</option>
                                    <option value='インフラエンジニア'>インフラエンジニア</option>
                                    <option value='サーバーエンジニア（設計構築）'>サーバーエンジニア（設計構築）</option>
                                    <option value='ネットワークエンジニア（設計構築）'>ネットワークエンジニア（設計構築）</option>
                                    <option value='データベースエンジニア'>データベースエンジニア</option>
                                    <option value='プロジェクトマネージャー（インフラ）'>プロジェクトマネージャー（インフラ）</option>
                                    <option value='Webサービスエンジニア（ネットワーク・サーバー・データベース）'>Webサービスエンジニア（ネットワーク・サーバー・データベース）</option>
                                    <option value='運用・監視・保守（インフラ）'>運用・監視・保守（インフラ）</option>

                                    {/*サポート・ヘルプデスク*/}
                                    <option disabled>サポート・ヘルプデスク</option>
                                    <option value='サポート・ヘルプデスク'>サポート・ヘルプデスク</option>

                                    {/*社内SE（社内情報システム）*/}
                                    <option disabled>社内SE（社内情報システム）</option>
                                    <option value='社内SE（社内情報システム）'>社内SE（社内情報システム）</option>

                                    {/* Creative */}
                                    {/*Web・モバイル（制作・開発）*/}
                                    <option disabled>Web・モバイル</option>
                                    <option value='Webプロデューサー・Webディレクター・Webプランナー'>Webプロデューサー・Webディレクター・Webプランナー</option>
                                    <option value='Webデザイナー'>Webデザイナー</option>
                                    <option value='Webライター・Web編集（コンテンツ制作）'>Webライター・Web編集（コンテンツ制作）</option>
                                    <option value='UI・UXデザイナー（Web・モバイル）'>UI・UXデザイナー（Web・モバイル）</option>
                                    <option value='アシスタントディレクター・制作進行管理'>アシスタントディレクター・制作進行管理</option>

                                    {/*広告・グラフィック*/}
                                    <option disabled>広告・グラフィック</option>
                                    <option value='フォトグラファー・カメラマン'>フォトグラファー・カメラマン</option>

                                    {/*出版・広告・販促・印刷*/}
                                    <option disabled>出版・広告・販促・印刷</option>
                                    <option value='イラストレーター【広告・グラフィック関連】'>イラストレーター【広告・グラフィック関連】</option>
                                    <option value='DTPオペレーター'>DTPオペレーター</option>
                                    <option value='その他広告・グラフィック関連職'>その他広告・グラフィック関連職</option>
                                    <option value='編集・記者・ライター'>編集、記者、ライター</option>
                                    <option value='デザイナー（グラフィック・その他）'>デザイナー（グラフィック・その他）</option>

                                    {/*店舗・販売*/}
                                    <option disabled>店舗・販売</option>
                                    <option value='店長'>店長</option>
                                    <option value='ホールスタッフ'>ホールスタッフ</option>
                                    <option value='調理スタッフ'>調理スタッフ</option>

                                    {/*美容関連*/}
                                    <option disabled>美容関連</option>
                                    <option value='美容師・理容師・その他美容関連'>美容師・理容師・その他美容関連</option>
                                    <option value='美容部員・エステティシャン・マッサージ・ビューティーアドバイザー'>美容部員・エステティシャン・マッサージ・ビューティーアドバイザー</option>

                                    {/*ブライダル・葬祭*/}
                                    <option disabled>ブライダル・葬祭</option>
                                    <option value='ウェディングプランナー'>ウェディングプランナー</option>
                                    <option value='葬祭ディレクター・プランナー'>葬祭ディレクター・プランナー</option>
                                    <option value='その他ブライダル・葬祭関連'>その他ブライダル・葬祭関連</option>

                                    {/*旅行関連*/}
                                    <option disabled>旅行関連</option>
                                    <option value='旅行手配員・添乗員・ツアーコンダクター'>旅行手配員・添乗員・ツアーコンダクター</option>

                                    {/*宿泊施設・ホテル*/}
                                    <option disabled>宿泊施設・ホテル</option>
                                    <option value='施設管理・マネジメント'>施設管理・マネジメント</option>
                                    <option value='フロント業務・予約受付'>フロント業務・予約受付</option>
                                    <option value='その他宿泊施設・ホテル関連'>その他宿泊施設・ホテル関連</option>

                                    {/*教育／スクール／研修／塾講師／専門学校／英会話学校*/}
                                    <option disabled>教育／スクール／研修／塾講師／専門学校／英会話学校</option>
                                    <option value='スクール長・マネジャー'>スクール長・マネジャー</option>
                                    <option value='講師・指導員・インストラクター'>講師・指導員・インストラクター</option>

                                    {/*建築設計・積算*/}
                                    <option disabled>建築設計・積算</option>
                                    <option value='意匠設計・構造設計'>意匠設計・構造設計</option>
                                    <option value='製図・CADオペレーター'>製図・CADオペレーター</option>
                                    <option value='内装設計・インテリア・空間デザイン'>内装設計・インテリア・空間デザイン</option>
                                    <option value='積算（建築設計）'>積算（建築設計）</option>
                                    <option value='内装設計（店舗・オフィス）'>内装設計（店舗・オフィス）</option>
                                    <option value='内装設計・リフォーム・インテリア（住宅）'>内装設計・リフォーム・インテリア（住宅）</option>

                                    {/*施工管理・職人・現場作業員*/}
                                    <option disabled>施工管理・職人・現場作業員</option>
                                    <option value='建築施工管理（RC造・S造・SRC造・木造）'>建築施工管理（RC造・S造・SRC造・木造）</option>
                                    <option value='建築施工管理（店舗内装・オフィス内装）'>建築施工管理（店舗内装・オフィス内装） </option>
                                    <option value='建築施工管理（住宅内装・リフォーム・インテリア'>建築施工管理（住宅内装・リフォーム・インテリア</option>
                                    <option value='設備施工管理（電気・空調・衛生設備）'>設備施工管理（電気・空調・衛生設備） </option>
                                    <option value='土木施工管理（トンネル・道路・造成・ダム・河川・港湾・造園など）'>土木施工管理（トンネル・道路・造成・ダム・河川・港湾・造園など）</option>

                                    {/*その他建設・建築・不動産・プラント・工場関連職*/}
                                    <option disabled>その他建設・建築・不動産・プラント・工場関連職</option>
                                    <option value='その他建設・建築・不動産・プラント・工場関連職'>その他建設・建築・不動産・プラント・工場関連職</option>

                                    {/*医療・看護*/}
                                    <option disabled>医療・看護</option>
                                    <option value='病院長・事務長'>病院長・事務長</option>
                                    <option value='医師・看護師'>医師・看護師</option>
                                    <option value='歯科医師・歯科衛生士・歯科技工士'>歯科医師・歯科衛生士・歯科技工士</option>
                                    <option value='臨床心理士・カウンセラー'>臨床心理士・カウンセラー</option>
                                    <option value='栄養士・管理栄養士'>栄養士・管理栄養士</option>
                                    <option value='その他医療・看護'>その他医療・看護</option>

                                    {/*福祉*/}
                                    <option disabled>福祉</option>
                                    <option value='院長・福祉施設長'>院長・福祉施設長</option>
                                    <option value='介護福祉士・ケアマネジャー'>介護福祉士・ケアマネジャー</option>
                                    <option value='ホームヘルパー・ケアワーカー'>ホームヘルパー・ケアワーカー</option>

                                    {/*その他・専門職*/}
                                    <option disabled>その他・専門職</option>
                                    <option value='その他・専門職'>その他・専門職</option>
                                </select>

                                <textarea rows='1' 
                                            placeholder="仕事内容"
                                            type='text' 
                                            value={inputStatus.wanted}
                                            onChange={e => setInputStatus({...inputStatus, wanted: e.target.value})}
                                            required                           
                                >
                                </textarea>
                                <textarea rows='1' 
                                            placeholder="応募資格・条件"
                                            type='text' 
                                            value={inputStatus.qualification}
                                            onChange={e => setInputStatus({...inputStatus, qualification: e.target.value})} required              
                                >
                                </textarea>
                                <input type='text' maxLength='20' value={inputStatus.qualificationShort} onChange={e => setInputStatus({...inputStatus, qualificationShort: e.target.value})} required placeholder='20字以内で簡単に応募資格・条件を説明ください'/>
                                {/* <div className='userBox__input__content'>
                                    <input  type='text' placeholder='必須スキル(1)' onChange={e => setInputStatus({...inputStatus, reqSkills1: e.target.value})} value={inputStatus.reqSkills1} maxLength='10'/>
                                    <input  type='text' placeholder='必須スキル(2)' onChange={e => setInputStatus({...inputStatus, reqSkills2: e.target.value})} value={inputStatus.reqSkills2} maxLength='10'/>
                                    <input  type='text' placeholder='必須スキル(3)' onChange={e => setInputStatus({...inputStatus, reqSkills3: e.target.value})} value={inputStatus.reqSkills3} maxLength='10'/>
                                    <input  type='text' placeholder='必須スキル(4)' onChange={e => setInputStatus({...inputStatus, reqSkills4: e.target.value})} value={inputStatus.reqSkills4} maxLength='10'/>
                                </div>
                                <div className='userBox__input__content'>
                                    <input  type='text' placeholder='望ましいスキル(1)' onChange={e => setInputStatus({...inputStatus, opSkills1: e.target.value})} value={inputStatus.opSkills1} maxLength='10'/>
                                    <input  type='text' placeholder='望ましいスキル(2)' onChange={e => setInputStatus({...inputStatus, opSkills2: e.target.value})} maxLength='10'/>
                                    <input  type='text' placeholder='望ましいスキル(3)' onChange={e => setInputStatus({...inputStatus, opSkills3: e.target.value})} maxLength='10'/>
                                    <input  type='text' placeholder='望ましいスキル(4)' onChange={e => setInputStatus({...inputStatus, opSkills4: e.target.value})} maxLength='10'/>
                                </div> */}
                                <select className='jobPost__select' onChange={e => setInputStatus({...inputStatus, prefecture: e.target.value})} required>
                                    <option disabled selected>県を選択する</option>
                                    <option value="リモート">リモート</option>
                                    <option value="北海道">北海道</option>
                                    <option value="青森">青森</option>
                                    <option value="岩手" >岩手</option>
                                    <option value="宮城" >宮城</option>
                                    <option value="秋田" >秋田</option>
                                    <option value="山形" >山形</option>
                                    <option value="福島" >福島</option>
                                    <option value="茨城" >茨城</option>
                                    <option value="栃木" >栃木</option>
                                    <option value="群馬" >群馬</option>
                                    <option value="埼玉" >埼玉</option>
                                    <option value="千葉" >千葉</option>
                                    <option value="東京" >東京</option>
                                    <option value="神奈川" >神奈川</option>
                                    <option value="新潟" >新潟</option>
                                    <option value="富山" >富山</option>
                                    <option value="石川" >石川</option>
                                    <option value="福井" >福井</option>
                                    <option value="山梨" >山梨</option>
                                    <option value="長野" >長野</option>
                                    <option value="岐阜" >岐阜</option>
                                    <option value="静岡" >静岡</option>
                                    <option value="愛知" >愛知</option>
                                    <option value="三重" >三重</option>
                                    <option value="滋賀" >滋賀</option>
                                    <option value="京都" >京都</option>
                                    <option value="大阪" >大阪</option>
                                    <option value="兵庫" >兵庫</option>
                                    <option value="奈良" >奈良</option>
                                    <option value="和歌山" >和歌山</option>
                                    <option value="鳥取" >鳥取</option>
                                    <option value="島根" >島根</option>
                                    <option value="岡山" >岡山</option>
                                    <option value="広島" >広島</option>
                                    <option value="山口" >山口</option>
                                    <option value="徳島" >徳島</option>
                                    <option value="香川" >香川</option>
                                    <option value="愛媛" >愛媛</option>
                                    <option value="高知" >高知</option>
                                    <option value="福岡" >福岡</option>
                                    <option value="佐賀" >佐賀</option>
                                    <option value="長崎" >長崎</option>
                                    <option value="熊本" >熊本</option>
                                    <option value="大分" >大分</option>
                                    <option value="宮崎" >宮崎</option>
                                    <option value="鹿児島" >鹿児島</option>
                                    <option value="沖縄" >沖縄</option>
                                </select>
                                <input type='text' placeholder='勤務地の場所(残りの住所)' value={inputStatus.otherAddress} onChange={e => setInputStatus({...inputStatus, otherAddress: e.target.value})} required/>
                                <input type='text' placeholder='アクセス' value={inputStatus.access} onChange={e => setInputStatus({...inputStatus, access: e.target.value})} required/>
                                <select className='jobPost__select' onChange={e => setInputStatus({...inputStatus, salaryType: e.target.value})} required>
                                    <option disabled selected>給与形態を選択する</option>
                                    <option value="年俸">年俸</option>
                                    <option value="月給">月給</option>
                                    <option value="日給">日給</option>
                                    <option value="時給">時給</option>
                                    <option value="成果報酬">成果報酬</option>
                                </select>
                                <div className='d-flex salary-input'>
                                    <input type='text' placeholder='〇〇〇' value={inputStatus.salary} onChange={e => setInputStatus({...inputStatus, salary: e.target.value})} required/><p>円～</p>
                                    <input type='text' placeholder='〇〇〇' value={inputStatus.salary2} onChange={e => setInputStatus({...inputStatus, salary2: e.target.value})} required/><p>円</p>
                                </div>
                                <textarea rows='1' 
                                            placeholder="勤務時間"
                                            type='text' 
                                            value={inputStatus.timeWork}
                                            onChange={e => setInputStatus({...inputStatus, timeWork: e.target.value})} required             
                                >
                                </textarea>
                                <textarea rows='1' 
                                            placeholder="待遇・福利厚生"
                                            type='text' 
                                            value={inputStatus.welfare}
                                            onChange={e => setInputStatus({...inputStatus, welfare: e.target.value})} required           
                                >
                                </textarea>
                                <textarea rows='1' 
                                            placeholder="休日・休暇"
                                            type='text' 
                                            value={inputStatus.offDay}
                                            onChange={e => setInputStatus({...inputStatus, offDay: e.target.value})} required              
                                >
                                </textarea>
                                <div className='d-flex'>
                                    <div>
                                        <input type='text' list='keyword' maxLength='10' placeholder='キーワード1' value={inputStatus.keywords1} onChange={e => setInputStatus({...inputStatus, keywords1: e.target.value})}/>
                                        <datalist id='keyword'>
                                            <option value='駅近5分以内'>駅近5分以内</option>
                                            <option value='交通費支給'>交通費支給</option>
                                            <option value='リモート'>リモート</option>
                                            <option value='一部リモート'>一部リモート</option>
                                            <option value='週休2日'>週休2日</option>
                                            <option value='週2・3からOK'>週2・3からOK</option>
                                            <option value='大学生OK'>大学生OK</option>
                                            <option value='高校生OK'>高校生OK</option>
                                            <option value='副業・WワークOK'>副業・WワークOK</option>
                                            <option value='未経験OK'>未経験OK</option>
                                            <option value='経験者歓迎'>経験者歓迎</option>
                                            <option value='学歴不問'>学歴不問</option>
                                            <option value='産休・育休'>産休・育休</option>
                                            <option value='研修あり'>研修あり</option>
                                            <option value='資格手当'>資格手当</option>
                                            <option value='家族手当'>家族手当</option>
                                            <option value='インセンティブ'>インセンティブ</option>
                                            <option value='社保完備'>社保完備</option>
                                            <option value='育児支援'>育児支援</option>
                                            <option value='急募'>急募</option>
                                            <option value='Web面接OK'>Web面接OK</option>
                                            <option value='福利厚生充実'>福利厚生充実</option>
                                            <option value='服装自由'>服装自由</option>
                                            <option value='日払い・週払い・即日払いOK'>日払い・週払い・即日払いOK</option>
                                            <option value='時短勤務制度あり'>時短勤務制度あり</option>
                                            <option value='寮・社宅・住宅手当あり'>寮・社宅・住宅手当あり</option>
                                            <option value='社員登用あり'>社員登用あり</option>
                                        </datalist>
                                    </div>
                                    <div>
                                        <input type='text' list='keyword' maxLength='10' placeholder='キーワード2' value={inputStatus.keywords2} onChange={e => setInputStatus({...inputStatus, keywords2: e.target.value})}/>
                                        <datalist id='keyword'>
                                            <option value='駅近5分以内'>駅近5分以内</option>
                                            <option value='交通費支給'>交通費支給</option>
                                            <option value='リモート'>リモート</option>
                                            <option value='一部リモート'>一部リモート</option>
                                            <option value='週休2日'>週休2日</option>
                                            <option value='週2・3からOK'>週2・3からOK</option>
                                            <option value='大学生OK'>大学生OK</option>
                                            <option value='高校生OK'>高校生OK</option>
                                            <option value='副業・WワークOK'>副業・WワークOK</option>
                                            <option value='未経験OK'>未経験OK</option>
                                            <option value='経験者歓迎'>経験者歓迎</option>
                                            <option value='学歴不問'>学歴不問</option>
                                            <option value='産休・育休'>産休・育休</option>
                                            <option value='研修あり'>研修あり</option>
                                            <option value='資格手当'>資格手当</option>
                                            <option value='家族手当'>家族手当</option>
                                            <option value='インセンティブ'>インセンティブ</option>
                                            <option value='社保完備'>社保完備</option>
                                            <option value='育児支援'>育児支援</option>
                                            <option value='急募'>急募</option>
                                            <option value='Web面接OK'>Web面接OK</option>
                                            <option value='福利厚生充実'>福利厚生充実</option>
                                            <option value='服装自由'>服装自由</option>
                                            <option value='日払い・週払い・即日払いOK'>日払い・週払い・即日払いOK</option>
                                            <option value='時短勤務制度あり'>時短勤務制度あり</option>
                                            <option value='寮・社宅・住宅手当あり'>寮・社宅・住宅手当あり</option>
                                            <option value='社員登用あり'>社員登用あり</option>
                                        </datalist>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div>
                                        <input type='text' list='keyword' maxLength='10' placeholder='キーワード3' value={inputStatus.keywords3} onChange={e => setInputStatus({...inputStatus, keywords3: e.target.value})}/>
                                        <datalist id='keyword'>
                                        <option value='駅近5分以内'>駅近5分以内</option>
                                            <option value='交通費支給'>交通費支給</option>
                                            <option value='リモート'>リモート</option>
                                            <option value='一部リモート'>一部リモート</option>
                                            <option value='週休2日'>週休2日</option>
                                            <option value='週2・3からOK'>週2・3からOK</option>
                                            <option value='大学生OK'>大学生OK</option>
                                            <option value='高校生OK'>高校生OK</option>
                                            <option value='副業・WワークOK'>副業・WワークOK</option>
                                            <option value='未経験OK'>未経験OK</option>
                                            <option value='経験者歓迎'>経験者歓迎</option>
                                            <option value='学歴不問'>学歴不問</option>
                                            <option value='産休・育休'>産休・育休</option>
                                            <option value='研修あり'>研修あり</option>
                                            <option value='資格手当'>資格手当</option>
                                            <option value='家族手当'>家族手当</option>
                                            <option value='インセンティブ'>インセンティブ</option>
                                            <option value='社保完備'>社保完備</option>
                                            <option value='育児支援'>育児支援</option>
                                            <option value='急募'>急募</option>
                                            <option value='Web面接OK'>Web面接OK</option>
                                            <option value='福利厚生充実'>福利厚生充実</option>
                                            <option value='服装自由'>服装自由</option>
                                            <option value='日払い・週払い・即日払いOK'>日払い・週払い・即日払いOK</option>
                                            <option value='時短勤務制度あり'>時短勤務制度あり</option>
                                            <option value='寮・社宅・住宅手当あり'>寮・社宅・住宅手当あり</option>
                                            <option value='社員登用あり'>社員登用あり</option>
                                        </datalist>
                                    </div>
                                    <div>
                                        <input type='text' list='keyword' maxLength='10' placeholder='キーワード4' value={inputStatus.keywords4} onChange={e => setInputStatus({...inputStatus, keywords4: e.target.value})}/>
                                        <datalist id='keyword'>
                                        <option value='駅近5分以内'>駅近5分以内</option>
                                            <option value='交通費支給'>交通費支給</option>
                                            <option value='リモート'>リモート</option>
                                            <option value='一部リモート'>一部リモート</option>
                                            <option value='週休2日'>週休2日</option>
                                            <option value='週2・3からOK'>週2・3からOK</option>
                                            <option value='大学生OK'>大学生OK</option>
                                            <option value='高校生OK'>高校生OK</option>
                                            <option value='副業・WワークOK'>副業・WワークOK</option>
                                            <option value='未経験OK'>未経験OK</option>
                                            <option value='経験者歓迎'>経験者歓迎</option>
                                            <option value='学歴不問'>学歴不問</option>
                                            <option value='産休・育休'>産休・育休</option>
                                            <option value='研修あり'>研修あり</option>
                                            <option value='資格手当'>資格手当</option>
                                            <option value='家族手当'>家族手当</option>
                                            <option value='インセンティブ'>インセンティブ</option>
                                            <option value='社保完備'>社保完備</option>
                                            <option value='育児支援'>育児支援</option>
                                            <option value='急募'>急募</option>
                                            <option value='Web面接OK'>Web面接OK</option>
                                            <option value='福利厚生充実'>福利厚生充実</option>
                                            <option value='服装自由'>服装自由</option>
                                            <option value='日払い・週払い・即日払いOK'>日払い・週払い・即日払いOK</option>
                                            <option value='時短勤務制度あり'>時短勤務制度あり</option>
                                            <option value='寮・社宅・住宅手当あり'>寮・社宅・住宅手当あり</option>
                                            <option value='社員登用あり'>社員登用あり</option>
                                        </datalist>
                                    </div>
                                </div>
                                {
                                    src &&
                                        <div className='jobBox__input-image'>
                                            <CancelIcon className='cancelIcon' onClick={()=>setSrc(null)}/>
                                            <img src={src} alt="new test"/>               
                                            <Button className='editImage' onClick={()=>setIsOpenModal(true)}>編集する</Button>
                                        </div>                        
                                }

                                <div className='jobBox__input-actions'>
                                    <div className='jobBox__input-icons'>
                                        <StatusInput Icon='fas fa-image'
                                                    type="file" 
                                                    accept="image/*"
                                                    name="image-upload"
                                                    id="input-image"
                                                    onChange={onSelectFile}
                                        />

                                    </div>
                        
                                    {
                                        isLoading ?
                                        <Button className='jobBox__button'><Spinner /></Button>
                                        :
                                        <Button type='submit'className='jobBox__button'>投稿する</Button>
                                    }

                            </div>
                        </div>    
                    </div>
                    :
                    <></>
                    }
                    {inputStatus.hireSection === "フリーランス" ? 
                    <div className='jobBox__wrapperInput' >
                        <div className='jobBox__input'>
                            <select className='jobPost__select' onChange={e => setInputStatus({...inputStatus, hireSection: e.target.value})}>
                                <option disabled selected>雇用区分を選択する</option>
                                <option value='正社員'>正社員</option>
                                <option value='アルバイト'>アルバイト</option>
                                <option value='業務委託'>業務委託</option>
                                <option value='フリーランス'>フリーランス</option>
                                <option value='インターン'>インターン</option>
                            </select>
                            <input type='text' placeholder='タイトル' value={inputStatus.jobTitle} onChange={e => setInputStatus({...inputStatus, jobTitle: e.target.value})} required/>
                            <textarea rows='1' 
                                        placeholder="簡単な仕事内容の説明"
                                        type='text' 
                                        value={inputStatus.briefIntro}
                                        onChange={e => setInputStatus({...inputStatus, briefIntro: e.target.value})}
                                        required                     
                            >
                            </textarea>

                            <select className="jobPost__select" onChange={e => setInputStatus({...inputStatus, kindJob: e.target.value})}>
                                <option disabled selected>職種の選択</option>
                                {/*営業*/}
                                <option disabled>営業</option>
                                <option value='法人営業'>法人営業</option>
                                <option value='個人営業'>個人営業</option>
                                <option value='内勤営業・カウンターセールス'>内勤営業・カウンターセールス</option>
                                <option value='ルートセールス・代理店営業'>ルートセールス・代理店営業</option>
                                <option value='IT営業'>IT営業</option>
                                <option value='海外営業'>海外営業</option>
                                <option value='不動産営業'>不動産営業</option>
                                <option value='金融営業'>金融営業</option>
                                <option value='テレマーケティング'>テレマーケティング</option>

                                {/*事務・アシスタント*/}
                                <option disabled>事務・アシスタント</option>
                                <option value='一般事務'>一般事務</option>
                                <option value='営業事務'>営業事務</option>
                                <option value='カスタマーサポート'>カスタマーサポート</option>
                                <option value='受付'>受付</option>
                                <option value='秘書'>秘書</option>

                                {/*財務・会計・経理*/}
                                <option value='経理'>経理</option>
                                <option value='財務'>財務</option>
                                <option value='管理会計'>管理会計</option>

                                {/*総務・人事・法務・知財・広報・IR*/}
                                <option disabled>総務・人事・法務・知財・広報・IR</option>
                                <option value='人事（採用）'>人事（採用）</option>
                                <option value='人事（労務）'>人事（労務）</option>
                                <option value='総務'>総務</option>
                                <option value='広報'>広報</option>
                                <option value='法務'>法務</option>

                                {/*商品企画・営業企画・マーケティング・宣伝*/}
                                <option disabled>商品企画・営業企画・マーケティング・宣伝</option>
                                <option value='商品企画、サービス企画'>商品企画、サービス企画</option>
                                <option value='販売促進・PR・広告宣伝'>販売促進・PR・広告宣伝</option>
                                <option value='Webマーケティング'>Webマーケティング</option>

                                {/*経営企画・事業統括・新規事業開発*/}
                                <option disabled>経営企画・事業統括・新規事業開発</option>
                                <option value='経営者・経営幹部・役員(CEO、COO、CFO、CIO、CTO)'>経営者・経営幹部・役員（CEO、COO、CFO、CIO、CTO）</option>

                                {/*管理職・エグゼクティブ*/}
                                <option disabled>管理職・エグゼクティブ</option>
                                <option value='経営企画・営業企画'>経営企画・営業企画</option>
                                <option value='事業企画・新規事業開発'>事業企画・新規事業開発</option>
                                <option value='事業統括マネージャー職（営業部門・管理部門）'>事業統括マネージャー職（営業部門・管理部門）</option>

                                {/*Webエンジニア*/}
                                <option disabled>Webエンジニア</option>
                                <option value='システムエンジニア（Web・オープン系・パッケージ開発）'>システムエンジニア（Web・オープン系・パッケージ開発）</option>
                                <option value='システムエンジニア（汎用機系）'>システムエンジニア（汎用機系）</option>
                                <option value='プロジェクトマネジャー（Web・オープン系・パッケージ開発）'>プロジェクトマネジャー（Web・オープン系・パッケージ開発）</option>
                                <option value='フロントエンド'>フロントエンド</option>
                                <option value='バックエンド'>バックエンド</option>
                                <option value='フルスタック'>フルスタック</option>

                                {/*インフラエンジニア*/}
                                <option disabled>インフラエンジニア</option>
                                <option value='サーバーエンジニア（設計構築）'>サーバーエンジニア（設計構築）</option>
                                <option value='ネットワークエンジニア（設計構築）'>ネットワークエンジニア（設計構築）</option>
                                <option value='データベースエンジニア'>データベースエンジニア</option>
                                <option value='プロジェクトマネージャー（インフラ）'>プロジェクトマネージャー（インフラ）</option>
                                <option value='Webサービスエンジニア（ネットワーク・サーバー・データベース）'>Webサービスエンジニア（ネットワーク・サーバー・データベース）</option>
                                <option value='運用・監視・保守（インフラ）'>運用・監視・保守（インフラ）</option>

                                {/*サポート・ヘルプデスク*/}
                                <option disabled>サポート・ヘルプデスク</option>
                                <option value='テクニカルサポート・カスタマーサポート'>テクニカルサポート・カスタマーサポート</option>
                                <option value='ヘルプデスク'>ヘルプデスク</option>

                                {/*社内SE（社内情報システム）*/}
                                <option disabled>社内SE（社内情報システム）</option>
                                <option value='システム開発・運用（アプリ担当）'>システム開発・運用（アプリ担当）</option>
                                <option value='システム構築・運用（インフラ担当）'>システム構築・運用（インフラ担当）</option>
                                <option value='IT戦略・システム企画担当'>IT戦略・システム企画担当</option>

                                {/*Web・モバイル（制作・開発）*/}
                                <option disabled>Web・モバイル（制作・開発）</option>
                                <option value='Webプロデューサー・Webディレクター・Webプランナー'>Webプロデューサー・Webディレクター・Webプランナー</option>
                                <option value='Webデザイナー'>Webデザイナー</option>
                                <option value='マークアップエンジニア・コーダー・フロントエンドエンジニア'>マークアップエンジニア・コーダー・フロントエンドエンジニア</option>
                                <option value='Webライター・Web編集（コンテンツ制作）'>Webライター・Web編集（コンテンツ制作）</option>
                                <option value='UI・UXデザイナー（Web・モバイル）'>UI・UXデザイナー（Web・モバイル）</option>
                                <option value='アシスタントディレクター・制作進行管理'>アシスタントディレクター・制作進行管理</option>

                                {/*広告・グラフィック*/}
                                <option disabled>広告・グラフィック</option>
                                <option value='フォトグラファー、カメラマン'>フォトグラファー、カメラマン</option>

                                {/*出版・広告・販促・印刷*/}
                                <option disabled>出版・広告・販促・印刷</option>
                                <option value='イラストレーター【広告・グラフィック関連】'>イラストレーター【広告・グラフィック関連】</option>
                                <option value='DTPオペレーター'>DTPオペレーター</option>
                                <option value='その他広告・グラフィック関連職'>その他広告・グラフィック関連職</option>
                                <option value='編集、記者、ライター'>編集、記者、ライター</option>
                                <option value='デザイナー（グラフィック・その他）'>デザイナー（グラフィック・その他）</option>

                                {/*店舗・販売*/}
                                <option disabled>店舗・販売</option>
                                <option value='店長'>店長</option>
                                <option value='ホールスタッフ'>ホールスタッフ</option>
                                <option value='調理スタッフ'>調理スタッフ</option>

                                {/*美容関連*/}
                                <option disabled>美容関連</option>
                                <option value='美容師・理容師・その他美容関連'>美容師・理容師・その他美容関連</option>
                                <option value='美容部員・エステティシャン・マッサージ・ビューティーアドバイザー'>美容部員・エステティシャン・マッサージ・ビューティーアドバイザー</option>

                                {/*ブライダル・葬祭*/}
                                <option disabled>ブライダル・葬祭</option>
                                <option value='ウェディングプランナー'>ウェディングプランナー</option>
                                <option value='葬祭ディレクター・プランナー'>葬祭ディレクター・プランナー</option>
                                <option value='その他ブライダル・葬祭関連'>その他ブライダル・葬祭関連</option>

                                {/*旅行関連*/}
                                <option disabled>旅行関連</option>
                                <option value='旅行手配員・添乗員・ツアーコンダクター'>旅行手配員・添乗員・ツアーコンダクター</option>

                                {/*宿泊施設・ホテル*/}
                                <option disabled>宿泊施設・ホテル</option>
                                <option value='施設管理・マネジメント'>施設管理・マネジメント</option>
                                <option value='フロント業務・予約受付'>フロント業務・予約受付</option>
                                <option value='その他宿泊施設・ホテル関連'>その他宿泊施設・ホテル関連</option>

                                {/*教育／スクール／研修／塾講師／専門学校／英会話学校*/}
                                <option disabled>教育／スクール／研修／塾講師／専門学校／英会話学校</option>
                                <option value='スクール長・マネジャー'>スクール長・マネジャー</option>
                                <option value='講師・指導員・インストラクター'>講師・指導員・インストラクター</option>

                                {/*建築設計・積算*/}
                                <option disabled>建築設計・積算</option>
                                <option value='意匠設計・構造設計'>意匠設計・構造設計</option>
                                <option value='製図・CADオペレーター'>製図・CADオペレーター</option>
                                <option value='内装設計・インテリア・空間デザイン'>内装設計・インテリア・空間デザイン</option>
                                <option value='積算（建築設計）'>積算（建築設計）</option>
                                <option value='内装設計（店舗・オフィス）'>内装設計（店舗・オフィス）</option>
                                <option value='内装設計・リフォーム・インテリア（住宅）'>内装設計・リフォーム・インテリア（住宅）</option>

                                {/*施工管理・職人・現場作業員*/}
                                <option disabled>施工管理・職人・現場作業員</option>
                                <option value='建築施工管理（RC造・S造・SRC造・木造）'>建築施工管理（RC造・S造・SRC造・木造）</option>
                                <option value='建築施工管理（店舗内装・オフィス内装）'>建築施工管理（店舗内装・オフィス内装） </option>
                                <option value='建築施工管理（住宅内装・リフォーム・インテリア'>建築施工管理（住宅内装・リフォーム・インテリア</option>
                                <option value='設備施工管理（電気・空調・衛生設備）'>設備施工管理（電気・空調・衛生設備） </option>
                                <option value='土木施工管理（トンネル・道路・造成・ダム・河川・港湾・造園など）'>土木施工管理（トンネル・道路・造成・ダム・河川・港湾・造園など）</option>

                                {/*その他建設・建築・不動産・プラント・工場関連職*/}
                                <option disabled>その他建設・建築・不動産・プラント・工場関連職</option>
                                <option value='その他建設・建築・不動産・プラント・工場関連職'>その他建設・建築・不動産・プラント・工場関連職</option>

                                {/*医療・看護*/}
                                <option disabled>医療・看護</option>
                                <option value='病院長・事務長'>病院長・事務長</option>
                                <option value='医師・看護師'>医師・看護師</option>
                                <option value='歯科医師・歯科衛生士・歯科技工士'>歯科医師・歯科衛生士・歯科技工士</option>
                                <option value='臨床心理士・カウンセラー'>臨床心理士・カウンセラー</option>
                                <option value='栄養士・管理栄養士'>栄養士・管理栄養士</option>
                                <option value='その他医療・看護'>その他医療・看護</option>

                                {/*福祉*/}
                                <option disabled>福祉</option>
                                <option value='院長・福祉施設長'>院長・福祉施設長</option>
                                <option value='介護福祉士・ケアマネジャー'>介護福祉士・ケアマネジャー</option>
                                <option value='ホームヘルパー・ケアワーカー'>ホームヘルパー・ケアワーカー</option>

                                {/*その他・専門職*/}
                                <option disabled>その他・専門職</option>
                                <option value='その他・専門職'>その他・専門職</option>
                            </select>

                            <textarea rows='1' 
                                        placeholder="任せたい仕事内容..."
                                        type='text' 
                                        value={inputStatus.wanted}
                                        onChange={e => setInputStatus({...inputStatus, wanted: e.target.value})}
                                        required                           
                            >
                            </textarea>
                            <div className='userBox__input__content'>
                                <input  type='text' placeholder='必須スキル(1)' onChange={e => setInputStatus({...inputStatus, reqSkills1: e.target.value})} value={inputStatus.reqSkills1} maxLength='10'/>
                                <input  type='text' placeholder='必須スキル(2)' onChange={e => setInputStatus({...inputStatus, reqSkills2: e.target.value})} value={inputStatus.reqSkills2} maxLength='10'/>
                                <input  type='text' placeholder='必須スキル(3)' onChange={e => setInputStatus({...inputStatus, reqSkills3: e.target.value})} value={inputStatus.reqSkills3} maxLength='10'/>
                                <input  type='text' placeholder='必須スキル(4)' onChange={e => setInputStatus({...inputStatus, reqSkills4: e.target.value})} value={inputStatus.reqSkills4} maxLength='10'/>
                            </div>
                            <input type='number' placeholder='予算(￥)' value={inputStatus.salary} onChange={e => setInputStatus({...inputStatus, salary: e.target.value})} required/>
                            {
                                src &&
                                    <div className='jobBox__input-image'>
                                        <CancelIcon className='cancelIcon' onClick={()=>setSrc(null)}/>
                                        <img src={src} alt="new test"/>               
                                        <Button className='editImage' onClick={()=>setIsOpenModal(true)}>編集する</Button>
                                    </div>                        
                            }

                            <div className='jobBox__input-actions'>
                                <div className='jobBox__input-icons'>
                                    <StatusInput Icon='fas fa-image'
                                                type="file" 
                                                accept="image/*"
                                                name="image-upload"
                                                id="input-image"
                                                onChange={onSelectFile}
                                    />

                                </div>
                    
                                {
                                    isLoading ?
                                    <Button className='jobBox__button'><Spinner /></Button>
                                    :
                                    <Button type='submit'className='jobBox__button'>投稿する</Button>
                                }

                            </div>
                        </div>    
                    </div>
                    :<></>
                    }
                </form>
            </div>
        </>
    )
}

export default JobPostingBox;
