import firebase from "firebase";
import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { logo2 } from "../../../assets";
import { HOME } from "../../../constant/router";
import { MessageModal } from "../../../elements";
import db from "../../../firebase";
import { useDocumentTitle, useScrollTop } from "../../../hooks";


const UnAuthDeleteAccount = () => {
    const [email, setEmail] = useState();
    const history = useHistory();
    const [isOpenModal, setIsOpenModal] = useState(false)
    const onSignout = () => {
        localStorage.clear()
        history.push(HOME)
    }

    const onDeleteAccount = () => {
        db.collection('deleteAccount').add({
            email: email,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        onSignout();
    }

    useScrollTop();
    useDocumentTitle("アカウント削除 / コピペル");

    return(
        <>
        <MessageModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        title='アカウント削除確認'
        >
            <div className="delete__account-modal">
                <ul>
                    <li>・24時間以内にアカウントは完全削除されます(24時間以内であれば、ログインすることが可能です)</li>
                    <li>・アカウント削除後にデータの復旧することはできません</li>
                    <li className="delete__account-input"><input type="checkbox" id='agree' required/><label htmlFor="agree">上記の内容について理解しました</label></li>
                </ul>
                <button onClick={onDeleteAccount}>削除する</button>
            </div>
        </MessageModal>
        <div className="forget__password">
            <div className="card login-form">
                <div className="card-body">
                    <img src={logo2} alt="" className="logo__form__page"/>
                    <h3 className="card-title">アカウント削除</h3>
                    <div>
                        <div className="form-group">
                            <label for="exampleInputEmail1">アカウント削除のために登録しているメールアドレスを入力してください</label>
                            <input type="email" className="form-control form-control-sm" value={email} placeholder="メールアドレス" onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <button onClick={() => setIsOpenModal(true)} className="btn btn-primary btn-block">削除する</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default UnAuthDeleteAccount;
