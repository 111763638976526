import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { logo2 } from "../../../assets";
import { LOGIN } from "../../../constant/router";
import { useStateValue } from "../../../contexts/StateContextProvider";
import db from "../../../firebase";
import passwordsValidate from "../../../helpers/passwordInpurValidate";

const ChangePassword = () => {
    const [error, setError] = useState('');
    const initValues = {
        password: '',
        confirmPassword: ''
    }

    const [passwordState, setPasswordState] = useState(initValues);

    const history = useHistory();
    const [message, setMessage] = useState(false);
    const [{user}] = useStateValue();
    const onSignoutBtn = () => {
        localStorage.clear()
        history.push(LOGIN)
        window.location.reload()
    }


    const onChangePassword = (e) => {
        const {password, confirmPassword} = passwordState
        e.preventDefault();
        if(!error.length){
            if(password === confirmPassword){
                db.collection('users').doc(user.id).update({
                    password: password
                })
                setMessage(true)
                setTimeout(() => {
                    onSignoutBtn()
                }, 5000)
            } else {
                setError(passwordsValidate(passwordState))
            }
        }
    }

    return(
        <div className="forget__password">
            <div className="card login-form">
                <div className="card-body">
                    <img src={logo2} alt="" className="logo__form__page"/>
                    <h3 className="card-title">パスワード変更</h3>
                    <div>
                        <div className="form-group">
                            <label>新しいパスワードを入力してください</label>
                            <input type="password" className="form-control form-control-sm" value={passwordState.password} placeholder="パスワード" onChange={(e) => setPasswordState({...passwordState, password: e.target.value})}/>
                            <input type="password" className="form-control form-control-sm" value={passwordState.confirmPassword} placeholder="もう一度パスワードを入力してください" onChange={(e) => setPasswordState({...passwordState, confirmPassword: e.target.value})}/>
                            {error.length>0 && <div className='error'>{error}</div>}
                            {message ? <p>パスワードを変更しました。ログアウトします...</p> : <></>}
                        </div>
                        <button className="btn btn-primary btn-block" onClick={onChangePassword}>パスワード変更する</button>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;