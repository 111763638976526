import React from 'react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import Search from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import './index.css';
import { HELP__CENTER, HOME, LOGIN, SEARCH__WORDS } from '../../constant/router';

const UnAuthBottomNav = () => {
  return (
    <div className="bottomNav">
       <nav>
          <NavLink exact to={HOME} activeClassName='bottomNav__active' replace><HomeIcon /></NavLink>
          <NavLink to={SEARCH__WORDS} activeClassName='bottomNav__active' replace><Search /></NavLink>
          <NavLink to={LOGIN} activeClassName='bottomNav__active' replace><PersonIcon /></NavLink>
          <NavLink to={HELP__CENTER} activeClassName='bottomNav__active' replace><HelpOutlineIcon /></NavLink>
       </nav>
    </div>
  );
};

export default UnAuthBottomNav;
