import React from "react";
import { useHistory } from "react-router-dom";
import { PRIVACY__POLICY, USE__AND__TERMS } from "../../constant/router";


const SidebarTerms = () => {

    const history = useHistory();
    const onUse = () => {
        history.push(USE__AND__TERMS)
        window.location.reload()
    }

    const onPrivacy = () => {
        history.push(PRIVACY__POLICY)
        window.location.reload()
    }

    return(
        <div className="sidebar__terms">
            <div className="unAuth__topBar__content">
                <button onClick={onUse}>利用規約</button>
                <button onClick={onPrivacy}>プライバシーポリシー</button>
            </div>
        </div>
    )
}

export default SidebarTerms;