import React from "react";
import { useStateValue } from "../../contexts/StateContextProvider";
import './index.css';
import { EventPostingBox, JobPostingBox } from "..";
import { TabbarMenu } from "../../elements";
import { useDocumentTitle, useScrollTop } from "../../hooks";
import NotFound from "../error/NotFound";
import { CompanyDashboardMobileNav } from "../../component";

const CompanyDashboardPost = () => {
    const [{user}] = useStateValue();
    const items = [
        {
            id:0,
            title: "求人投稿",
            item: <JobPostingBox/>
        },
        {
            id:1,
            title: "イベント投稿",
            item: <EventPostingBox/>
        }
    ];


    useScrollTop();
    useDocumentTitle("投稿する / Copipel")
    return(
        <>
            <CompanyDashboardMobileNav/>
            <div className="company__dashboard-post">
            {user.role === 'COMPANY' ? 
                <div className="company__dashboard-post-tabs">
                    <TabbarMenu items={items}/>
                </div>
            : <NotFound/>
            }
            </div>
        </>
    )
}


export default CompanyDashboardPost;