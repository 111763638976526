import React, { useState, useEffect } from "react";
import { logo2 } from "../../../assets";
import db from "../../../firebase";
import '../index.css';

const ConfirmEmail = () => {
    const [code, setCode] = useState('');
    const [getCode, setGetCode] = useState([]);
    const [email, setEmail] = useState('');
    const [profile, setProfile] = useState([]);
    const [openCode, setOpenCode] = useState(false);

    useEffect(() => {
            const getUser = db.collection('users').where('email', '==', `${email}`).onSnapshot(snapshot=>{
                setProfile(snapshot.docs.map(doc=>({
                  id: doc.id,
                  ...doc.data()
                }))[0])
              })
    
            return () => getUser()
      })

      useEffect(() => {
            const getCode = db.collection('confirmEmail').where('email', '==', `${email}`).onSnapshot(snapshot=>{
                setGetCode(snapshot.docs.map(doc=>({
                  id: doc.id,
                  ...doc.data()
                })))
              })
            return () => getCode();
      })

    const onSubmitCode = () => {
        if(`${getCode.code}` === code){
            db.collection('users').doc(`${profile.id}`).update({
                emailConformation: true
            })
        }
    }

    let min = 11111111
    let max = 99999999
    const randomNum = Math.floor(Math.random() * (max-min)) + min;

    const onResubmitCode = () => {
        db.collection('confirmEmail').doc(`${getCode.id}`).delete();
        db.collection('confirmEmail').add({
            email: email,
            code: randomNum
        })
    }

    const onSendCode = () => {
        db.collection('confirmEmail').add({
            email: email,
            code: randomNum
        })
        setOpenCode(true)
    }

    return(
        <>
            <div className="forget__password">
            <div className="card login-form">
                <div className="card-body width-50rem">
                    <img src={logo2} alt="" className="logo__form__page logo__form__code"/>
                    <h3 className="card-title text-center">メールアドレス認証</h3>
                    <div className="card-text">
                        <div className="form-group">
                            {openCode ? 
                                <div>
                                    <label>認証コードを入力してください</label>
                                    <input type="text" maxLength='8' name="code" className="form-control form-control-sm" value={code} placeholder="例)12345678" onChange={(e) => setCode(e.target.value)}/>
                                </div>
                                :
                                <div>
                                    <input type="email" className="form-control form-control-sm" value={email} placeholder="メールアドレスを入力してください" onChange={(e) => setEmail(e.target.value)}/>
                            </div>
                            }
                        </div>
                        {openCode ? <button className="btn btn-primary btn-block code-btn" onClick={onSubmitCode}><span>コードを認証する</span></button> : <button className="btn btn-primary btn-block code-btn" onClick={onSendCode}><span>コードを送信する</span></button>}
                    </div>    
                    <div className="resend__code opacity-hover">
                        <button onClick={onResubmitCode}>コードを再送信する</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}


export default ConfirmEmail;