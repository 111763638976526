import React from 'react';
import Modal from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
import { Button } from '@material-ui/core';

import './index.css';

const CommonModal = ({ open, 
                       onClose,
                       title,
                       callback,
                       children,
                       Icon,
                       ButtonText
}) => {

    const onClickSave = () => {
        callback()
        onClose()
    }
    return (
        <div style={{ overflowY: 'hidden'}}>
            <Modal open={open}>
                <i className='fas fa-times modal__close__btn' onClick={onClose}/>
                <div className="modal__header">
                {ButtonText.length>0 && <Button onClick={onClickSave}>{ButtonText}</Button> }  
                    <h2>{title}</h2>
                            
                </div>
                {children}
            </Modal>
        </div>
    )
}

export default CommonModal
