import React from 'react';
import { Link } from 'react-router-dom';
import { LOGIN } from '../../constant/router';

const HomeBack = () => {
    return(
        <div className='home__back'>
            <div className='home__back__image'>
                <div className='home__back-header'>
                    <h2><span>キャリア形成に、最適な選択肢を。</span></h2>
                </div>
            </div>
            <div className='home__back__btn'>
                <Link to={LOGIN}><button>アカウント作成する</button></Link>
            </div>
        </div>
    )
}

export default HomeBack;