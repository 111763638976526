import { ADD_ROOM, EMPTY_ROOM, REMOVE_ROOM } from "../../constant/constant"

export const chatReducer = (state, action) => {
    switch (action.type) {
        case ADD_ROOM: {
            return [...state, action.room]
        }

        case REMOVE_ROOM: {
            return state.filter(user => user.id !== action.id)
        }

        case EMPTY_ROOM:{
            return []
        }

        default:
            return state
    }
} 