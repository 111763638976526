import React, { useState } from "react";
import logo2 from '../../../assets/logo2.png';
import db from "../../../firebase";
import './index.css';
import { useDocumentTitle, useScrollTop } from "../../../hooks";
import ForgetPasswordContent from "./ForgetPasswordContent";

const ForgetPassword = () => {
    const [isOpenCode, setIsOpenCode] = useState(false);

    const [email, setEmail] = useState("");


    let min = 11111111
    let max = 99999999
    const randomNum = Math.floor(Math.random() * (max-min)) + min;

    const onSendCode = () => {
        db.collection('confirmEmail').add({
            email: email,
            code: randomNum
        })
        setIsOpenCode(true)
    }
    useScrollTop();
    useDocumentTitle("パスワード変更 / コピペル")

    return(
        <>
        {isOpenCode === false ? 
            <div className="forget__password">
            <div className="card login-form">
                <div className="card-body">
                    <img src={logo2} alt="" className="logo__form__page"/>
                    <h3 className="card-title">パスワード変更</h3>
                    <div className="card-text">
                        <div className="form-group">
                            <label>パスワード変更のために登録したメールアドレスを入力してください</label>
                            <input type="email" className="form-control form-control-sm" value={email} placeholder="メールアドレス" onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <button className="btn btn-primary btn-block" onClick={onSendCode}>送信する</button>
                    </div>    
                </div>
            </div>
        </div>
        :
        <>
        <ForgetPasswordContent email={email}/>
        </>    
        }
        </>
    );
};

export default ForgetPassword;
