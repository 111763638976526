import React from "react";
import { useStateValue } from "../../contexts/StateContextProvider";
import './index.css';
import { TabbarMenu } from "../../elements";
import CompanyDashboardJobPost from "./post/CompanyDashboardJobPost";
import CompanyDashboardEventPost from "./post/CompanyDashboardEvent";
import { useDocumentTitle, useScrollTop } from "../../hooks";
import CompanyDashboardNav from "../../component/mobileNav/CompanyDashboardNav";
import NotFound from "../error/NotFound";


const CompanyDashboardAllPost = () => {
    const [{user}] = useStateValue();
    const items = [
        {
            id:0,
            title: "求人投稿",
            item: <CompanyDashboardJobPost/>
        },
        {
            id:1,
            title: "イベント投稿",
            item: <CompanyDashboardEventPost/>
        }
    ];

    useScrollTop();
    useDocumentTitle("投稿一覧 / Copipel")
    return(
        <>
            <CompanyDashboardNav/>
            <div className="company__dashboard-post">
            {user.role === 'COMPANY' ? 
                <div className="company__dashboard-post-tabs">
                    <TabbarMenu items={items}/>
                </div>
            : <NotFound/>
            }
            </div>
        </>
    )
}


export default CompanyDashboardAllPost;