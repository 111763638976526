import React, { useState } from "react";
import CompanyDashboardNav from "../../component/mobileNav/CompanyDashboardNav";
import { useStateValue } from "../../contexts/StateContextProvider";
import db from "../../firebase";
import { useDocumentTitle, useScrollTop } from "../../hooks";
import NotFound from "../error/NotFound";
import './index.css';
import UserSearchList from "./user-search/UserSearchList";

const CompanyDashboardSearch = () => {
    const [search, setSearch] = useState("");
    const [users, setUsers] = useState([]);
    useScrollTop();
    useDocumentTitle("さがす / Copipel");

    const onClickSearchUser = () => {
        db.collection('users').where('role', '==', 'USER').onSnapshot(snapshot => {
            if(snapshot.empty){
                return
            }
            setUsers(snapshot.docs.map(doc => ({id:doc.id, ...doc.data()})))
        })
       }
    const [{user}] = useStateValue();
    return(
        <>
        <CompanyDashboardNav/>
        {user.role === "COMPANY" ? 
            <div className="company__dashboard-search">
                <div className="d-flex">
                    <input type="text" value={search} onChange={e => setSearch(e.target.value)} placeholder='求めているスキルを入力してください'/>
                    <button className="search-btn" onClick={() => onClickSearchUser()}><i className="fas fa-search search-btn-icon"/></button>
                </div>
                <ul className='widgets__trendsContainer search__user-result'>
                    {search ? 
                        <>
                            {
                            users && users.filter(u=> u.skillsKeyword[0] === search || u.skillsKeyword[1] === search || u.skillsKeyword[2] === search || u.skillsKeyword[3] === search || u.skillsKeyword[4] === search ||
                                u.skillsKeyword[5] === search || u.skillsKeyword[6] === search || u.skillsKeyword[7] === search || u.skillsKeyword[8] === search || u.skillsKeyword[9] === search ||
                                u.skillsKeyword[10] === search || u.skillsKeyword[11] === search || u.skillsKeyword[12] === search || u.skillsKeyword[13] === search || u.skillsKeyword[14] === search ||
                                u.skillsKeyword[15] === search || u.skillsKeyword[16] === search || u.skillsKeyword[17] === search || u.skillsKeyword[18] === search || u.skillsKeyword[19] === search ||
                                u.skillsKeyword[20] === search || u.skillsKeyword[21] === search || u.skillsKeyword[22] === search || u.skillsKeyword[23] === search || u.skillsKeyword[24] === search ||
                                u.skillsKeyword[25] === search || u.skillsKeyword[26] === search || u.skillsKeyword[27] === search || u.skillsKeyword[28] === search || u.skillsKeyword[29] === search ||
                                u.skillsKeyword[30] === search || u.skillsKeyword[31] === search || u.skillsKeyword[32] === search || u.skillsKeyword[33] === search || u.skillsKeyword[34] === search ||
                                u.skillsKeyword[35] === search || u.skillsKeyword[36] === search || u.skillsKeyword[37] === search || u.skillsKeyword[38] === search || u.skillsKeyword[39] === search ||
                                u.skillsKeyword[40] === search || u.skillsKeyword[41] === search || u.skillsKeyword[42] === search || u.skillsKeyword[43] === search || u.skillsKeyword[44] === search ||
                                u.skillsKeyword[45] === search || u.skillsKeyword[46] === search || u.skillsKeyword[47] === search || u.skillsKeyword[48] === search || u.skillsKeyword[49] === search ||
                                u.skillsKeyword[50] === search || u.skillsKeyword[51] === search || u.skillsKeyword[52] === search || u.skillsKeyword[53] === search || u.skillsKeyword[54] === search ||
                                u.skillsKeyword[55] === search || u.skillsKeyword[56] === search || u.skillsKeyword[57] === search || u.skillsKeyword[58] === search || u.skillsKeyword[59] === search || u.skillsKeyword[60] === search
                                ).map(user=> {
                                return <li key={user.id}><UserSearchList display={user}/></li>
                            })
                            }
                        </>
                    : <></>}
                </ul>
            </div>
        : <NotFound/>    
        }
        </>
    )
}


export default CompanyDashboardSearch;