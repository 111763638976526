import React from 'react';
import Modal from 'react-responsive-modal';
import "react-responsive-modal/styles.css";

import './index.css';

const MessageModal = ({ open, 
                       onClose,
                       title,
                       callback,
                       children,
                       Icon,

}) => {

    return (
        <Modal open={open} onClose={() => onClose}>
            <div className="modal__header">
                <div className='backIcon'>
                    <i className='fas fa-times modal__close__btn cursor' onClick={onClose}/>
                </div>
                <h2>{title}</h2>
            </div>
            {children}
        </Modal>
    )
}

export default MessageModal;
