import React, { useState } from "react";
import db from "../../../firebase";
import { AuthJobPost, CompanyMobileTopNav, SearchTabbar, UserMobileTopNav } from "../../../component";
import { useStateValue } from "../../../contexts/StateContextProvider";

const AuthUserPostSearchHome = () => {
    const [searchSkills, setSearchSkills] = useState('');
    const [areaUser, setAreaUser] = useState('');
    const [kindJob, setKindJob] = useState('');
    const [posts, setPosts] = useState([])

   const onClickSearchUser = () => {
    db.collection('jobs').where('typePost', '==', 'userPosting')
    .orderBy('timestamp', 'desc')
    .onSnapshot(snapshot=>{
        if(snapshot.empty){
            return
         }
            setPosts(snapshot.docs.map(doc=> ({id:doc.id, ...doc.data()})))
    })
   }
   const [{user}] = useStateValue();
    return(
        <div className="search__box">
            {user.role === "USER" ? <UserMobileTopNav/> : <CompanyMobileTopNav/>}
            <SearchTabbar/>
            <div className="search__box-content">
                <div className="mobile-flex">
                    <div className="search__box-content1">
                        <select onChange={(e) => setAreaUser(e.target.value)} value={areaUser}>
                            <option>都道府県</option>
                            <option value="リモート">リモート</option>
                            <option value="北海道">北海道</option>
                            <option value="青森">青森</option>
                            <option value="岩手">岩手</option>
                            <option value="宮城">宮城</option>
                            <option value="秋田">秋田</option>
                            <option value="山形">山形</option>
                            <option value="福島">福島</option>
                            <option value="茨城">茨城</option>
                            <option value="栃木">栃木</option>
                            <option value="群馬">群馬</option>
                            <option value="埼玉">埼玉</option>
                            <option value="千葉">千葉</option>
                            <option value="東京">東京</option>
                            <option value="神奈川">神奈川</option>
                            <option value="新潟">新潟</option>
                            <option value="富山">富山</option>
                            <option value="石川">石川</option>
                            <option value="福井">福井</option>
                            <option value="山梨">山梨</option>
                            <option value="長野">長野</option>
                            <option value="岐阜">岐阜</option>
                            <option value="静岡">静岡</option>
                            <option value="愛知">愛知</option>
                            <option value="三重">三重</option>
                            <option value="滋賀">滋賀</option>
                            <option value="京都">京都</option>
                            <option value="大阪">大阪</option>
                            <option value="兵庫">兵庫</option>
                            <option value="奈良">奈良</option>
                            <option value="和歌山">和歌山</option>
                            <option value="鳥取">鳥取</option>
                            <option value="島根">島根</option>
                            <option value="岡山">岡山</option>
                            <option value="広島">広島</option>
                            <option value="山口">山口</option>
                            <option value="徳島">徳島</option>
                            <option value="香川">香川</option>
                            <option value="愛媛">愛媛</option>
                            <option value="高知">高知</option>
                            <option value="福岡">福岡</option>
                            <option value="佐賀">佐賀</option>
                            <option value="長崎">長崎</option>
                            <option value="熊本">熊本</option>
                            <option value="大分">大分</option>
                            <option value="宮崎">宮崎</option>
                            <option value="鹿児島">鹿児島</option>
                            <option value="沖縄">沖縄</option>
                        </select>
                        <select onChange={e => setKindJob(e.target.value)} value={kindJob}>
                            <option>職種</option>
                            {/*営業*/}
                            <option disabled>営業</option>
                            <option value='法人営業'>法人営業</option>
                            <option value='個人営業'>個人営業</option>
                            <option value='内勤営業・カウンターセールス'>内勤営業・カウンターセールス</option>
                            <option value='ルートセールス・代理店営業'>ルートセールス・代理店営業</option>
                            <option value='IT営業'>IT営業</option>
                            <option value='海外営業'>海外営業</option>
                            <option value='不動産営業'>不動産営業</option>
                            <option value='金融営業'>金融営業</option>
                            <option value='テレマーケティング'>テレマーケティング</option>

                            {/*事務・アシスタント*/}
                            <option disabled>事務・アシスタント</option>
                            <option value='一般事務'>一般事務</option>
                            <option value='営業事務'>営業事務</option>
                            <option value='カスタマーサポート'>カスタマーサポート</option>
                            <option value='受付'>受付</option>
                            <option value='秘書'>秘書</option>

                            {/*財務・会計・経理*/}
                            <option value='経理'>経理</option>
                            <option value='財務'>財務</option>
                            <option value='管理会計'>管理会計</option>

                            {/*総務・人事・法務・知財・広報・IR*/}
                            <option disabled>総務・人事・法務・知財・広報・IR</option>
                            <option value='人事（採用）'>人事（採用）</option>
                            <option value='人事（労務）'>人事（労務）</option>
                            <option value='総務'>総務</option>
                            <option value='広報'>広報</option>
                            <option value='法務'>法務</option>

                            {/*商品企画・営業企画・マーケティング・宣伝*/}
                            <option disabled>商品企画・営業企画・マーケティング・宣伝</option>
                            <option value='商品企画、サービス企画'>商品企画、サービス企画</option>
                            <option value='販売促進・PR・広告宣伝'>販売促進・PR・広告宣伝</option>
                            <option value='Webマーケティング'>Webマーケティング</option>

                            {/*経営企画・事業統括・新規事業開発*/}
                            <option disabled>経営企画・事業統括・新規事業開発</option>
                            <option value='経営者・経営幹部・役員(CEO、COO、CFO、CIO、CTO)'>経営者・経営幹部・役員（CEO、COO、CFO、CIO、CTO）</option>

                            {/*管理職・エグゼクティブ*/}
                            <option disabled>管理職・エグゼクティブ</option>
                            <option value='経営企画・営業企画'>経営企画・営業企画</option>
                            <option value='事業企画・新規事業開発'>事業企画・新規事業開発</option>
                            <option value='事業統括マネージャー職（営業部門・管理部門）'>事業統括マネージャー職（営業部門・管理部門）</option>

                            {/*Webエンジニア*/}
                            <option disabled>エンジニア</option>
                            <option value='システムエンジニア（Web・オープン系・パッケージ開発）'>システムエンジニア（Web・オープン系・パッケージ開発）</option>
                            <option value='システムエンジニア（汎用機系）'>システムエンジニア（汎用機系）</option>
                            <option value='プロジェクトマネジャー（Web・オープン系・パッケージ開発）'>プロジェクトマネジャー（Web・オープン系・パッケージ開発）</option>
                            <option value='モバイルアプリ'>モバイルアプリ</option>
                            <option value='フロントエンド'>フロントエンド</option>
                            <option value='バックエンド'>バックエンド</option>
                            <option value='フルスタック'>フルスタック</option>

                            {/*インフラエンジニア*/}
                            <option disabled>インフラエンジニア</option>
                            <option value='インフラエンジニア'>インフラエンジニア</option>
                            <option value='サーバーエンジニア（設計構築）'>サーバーエンジニア（設計構築）</option>
                            <option value='ネットワークエンジニア（設計構築）'>ネットワークエンジニア（設計構築）</option>
                            <option value='データベースエンジニア'>データベースエンジニア</option>
                            <option value='プロジェクトマネージャー（インフラ）'>プロジェクトマネージャー（インフラ）</option>
                            <option value='Webサービスエンジニア（ネットワーク・サーバー・データベース）'>Webサービスエンジニア（ネットワーク・サーバー・データベース）</option>
                            <option value='運用・監視・保守（インフラ）'>運用・監視・保守（インフラ）</option>

                            {/*サポート・ヘルプデスク*/}
                            <option disabled>サポート・ヘルプデスク</option>
                            <option value='サポート・ヘルプデスク'>サポート・ヘルプデスク</option>

                            {/*社内SE（社内情報システム）*/}
                            <option disabled>社内SE（社内情報システム）</option>
                            <option value='社内SE（社内情報システム）'>社内SE（社内情報システム）</option>

                            {/* Creative */}
                            {/*Web・モバイル（制作・開発）*/}
                            <option disabled>Web・モバイル</option>
                            <option value='Webプロデューサー・Webディレクター・Webプランナー'>Webプロデューサー・Webディレクター・Webプランナー</option>
                            <option value='Webデザイナー'>Webデザイナー</option>
                            <option value='Webライター・Web編集（コンテンツ制作）'>Webライター・Web編集（コンテンツ制作）</option>
                            <option value='UI・UXデザイナー（Web・モバイル）'>UI・UXデザイナー（Web・モバイル）</option>
                            <option value='アシスタントディレクター・制作進行管理'>アシスタントディレクター・制作進行管理</option>

                            {/*広告・グラフィック*/}
                            <option disabled>広告・グラフィック</option>
                            <option value='フォトグラファー・カメラマン'>フォトグラファー・カメラマン</option>

                            {/*出版・広告・販促・印刷*/}
                            <option disabled>出版・広告・販促・印刷</option>
                            <option value='イラストレーター【広告・グラフィック関連】'>イラストレーター【広告・グラフィック関連】</option>
                            <option value='DTPオペレーター'>DTPオペレーター</option>
                            <option value='その他広告・グラフィック関連職'>その他広告・グラフィック関連職</option>
                            <option value='編集・記者・ライター'>編集、記者、ライター</option>
                            <option value='デザイナー（グラフィック・その他）'>デザイナー（グラフィック・その他）</option>

                            {/*店舗・販売*/}
                            <option disabled>店舗・販売</option>
                            <option value='店長'>店長</option>
                            <option value='ホールスタッフ'>ホールスタッフ</option>
                            <option value='調理スタッフ'>調理スタッフ</option>

                            {/*美容関連*/}
                            <option disabled>美容関連</option>
                            <option value='美容師・理容師・その他美容関連'>美容師・理容師・その他美容関連</option>
                            <option value='美容部員・エステティシャン・マッサージ・ビューティーアドバイザー'>美容部員・エステティシャン・マッサージ・ビューティーアドバイザー</option>

                            {/*ブライダル・葬祭*/}
                            <option disabled>ブライダル・葬祭</option>
                            <option value='ウェディングプランナー'>ウェディングプランナー</option>
                            <option value='葬祭ディレクター・プランナー'>葬祭ディレクター・プランナー</option>
                            <option value='その他ブライダル・葬祭関連'>その他ブライダル・葬祭関連</option>

                            {/*旅行関連*/}
                            <option disabled>旅行関連</option>
                            <option value='旅行手配員・添乗員・ツアーコンダクター'>旅行手配員・添乗員・ツアーコンダクター</option>

                            {/*宿泊施設・ホテル*/}
                            <option disabled>宿泊施設・ホテル</option>
                            <option value='施設管理・マネジメント'>施設管理・マネジメント</option>
                            <option value='フロント業務・予約受付'>フロント業務・予約受付</option>
                            <option value='その他宿泊施設・ホテル関連'>その他宿泊施設・ホテル関連</option>

                            {/*教育／スクール／研修／塾講師／専門学校／英会話学校*/}
                            <option disabled>教育／スクール／研修／塾講師／専門学校／英会話学校</option>
                            <option value='スクール長・マネジャー'>スクール長・マネジャー</option>
                            <option value='講師・指導員・インストラクター'>講師・指導員・インストラクター</option>

                            {/*建築設計・積算*/}
                            <option disabled>建築設計・積算</option>
                            <option value='意匠設計・構造設計'>意匠設計・構造設計</option>
                            <option value='製図・CADオペレーター'>製図・CADオペレーター</option>
                            <option value='内装設計・インテリア・空間デザイン'>内装設計・インテリア・空間デザイン</option>
                            <option value='積算（建築設計）'>積算（建築設計）</option>
                            <option value='内装設計（店舗・オフィス）'>内装設計（店舗・オフィス）</option>
                            <option value='内装設計・リフォーム・インテリア（住宅）'>内装設計・リフォーム・インテリア（住宅）</option>

                            {/*施工管理・職人・現場作業員*/}
                            <option disabled>施工管理・職人・現場作業員</option>
                            <option value='建築施工管理（RC造・S造・SRC造・木造）'>建築施工管理（RC造・S造・SRC造・木造）</option>
                            <option value='建築施工管理（店舗内装・オフィス内装）'>建築施工管理（店舗内装・オフィス内装） </option>
                            <option value='建築施工管理（住宅内装・リフォーム・インテリア'>建築施工管理（住宅内装・リフォーム・インテリア</option>
                            <option value='設備施工管理（電気・空調・衛生設備）'>設備施工管理（電気・空調・衛生設備） </option>
                            <option value='土木施工管理（トンネル・道路・造成・ダム・河川・港湾・造園など）'>土木施工管理（トンネル・道路・造成・ダム・河川・港湾・造園など）</option>

                            {/*その他建設・建築・不動産・プラント・工場関連職*/}
                            <option disabled>その他建設・建築・不動産・プラント・工場関連職</option>
                            <option value='その他建設・建築・不動産・プラント・工場関連職'>その他建設・建築・不動産・プラント・工場関連職</option>

                            {/*医療・看護*/}
                            <option disabled>医療・看護</option>
                            <option value='病院長・事務長'>病院長・事務長</option>
                            <option value='医師・看護師'>医師・看護師</option>
                            <option value='歯科医師・歯科衛生士・歯科技工士'>歯科医師・歯科衛生士・歯科技工士</option>
                            <option value='臨床心理士・カウンセラー'>臨床心理士・カウンセラー</option>
                            <option value='栄養士・管理栄養士'>栄養士・管理栄養士</option>
                            <option value='その他医療・看護'>その他医療・看護</option>

                            {/*福祉*/}
                            <option disabled>福祉</option>
                            <option value='院長・福祉施設長'>院長・福祉施設長</option>
                            <option value='介護福祉士・ケアマネジャー'>介護福祉士・ケアマネジャー</option>
                            <option value='ホームヘルパー・ケアワーカー'>ホームヘルパー・ケアワーカー</option>

                            {/*その他・専門職*/}
                            <option disabled>その他・専門職</option>
                            <option value='その他・専門職'>その他・専門職</option>
                        </select>
                    </div>
                    <div className="search__box-content2 search__box-content2-event">
                        <input type="text" placeholder="スキル..." value={searchSkills} onChange={(e) => setSearchSkills(e.target.value)} />
                    </div>
                    <div className="search__box-content-btn mobile-btn-margin">
                        <button onClick={() => onClickSearchUser()}>さがす</button>
                    </div>
                </div>
            </div>
            {posts && posts.filter(i => i.prefecture === areaUser || i.skills[0] === searchSkills || i.skills[1] === searchSkills || i.skills[2] === searchSkills || i.skills[3] === searchSkills).map(post => (
                <AuthJobPost
                key={post.id}
                    postId = {post.id}
                    altText = {post.altText}
                    senderId = {post.senderId}
                    username = {post.username}
                    kindJob = {post.kindJob}
                    prefecture = {post.prefecture}
                    jobTitle = {post.jobTitle}
                    avatar = {post.avatar}
                    image = {post.image}
                    timestamp = {post.timestamp}
                    likes = {post.likes}
                    bookMark = {post.bookMark}
                    styleWork = {post.styleWork}
                    salary = {post.salary}
                    reqSkill = {post.reqSkill}
                    website = {post.website}
                    eventPlace = {post.eventPlace}
                    eventDate = {post.eventDate}
                    eventTime = {post.eventTime}
                    skills = {post.skills}
                    wantedJob = {post.wantedJob}
                    canWorkPlace = {post.canWorkPlace}
                    keywordsWord = {post.keywordsWord}
                    />
            ))}
        </div>
    )
}

export default AuthUserPostSearchHome;