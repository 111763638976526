import React from "react";
import '../index.css';
import * as ROUTE from '../../../constant/router';
import { useDocumentTitle, useScrollTop } from "../../../hooks";
import { Link } from "react-router-dom";
import { SearchTabbar } from "../../../component";

const WordsSearch = () => {
    useScrollTop();
    useDocumentTitle('ワード / Copipel');

    return(
        <div className="search__box">
        <SearchTabbar/>
        <div className="words__search">
            <div className="words__search-content-1 words__search-content">
                <span className="words__search-content-title">都道府県</span>
                <div className="words__search-flex">
                    <Link to={ROUTE.HOKKAIDO__PREFECTURE__TIMELINE}><button>北海道</button></Link>
                </div>
                <div className="words__search-flex">
                    <Link to={ROUTE.AOMORI__PREFECTURE__TIMELINE}><button>青森県</button></Link>
                    <Link to={ROUTE.IWATE__PREFECTURE__TIMELINE}><button>岩手県</button></Link>
                    <Link to={ROUTE.MIYAGI__PREFECTURE__TIMELINE}><button>宮城県</button></Link>
                    <Link to={ROUTE.AKITA__PREFECTURE__TIMELINE}><button>秋田県</button></Link>
                    <Link to={ROUTE.YAMAGATA__PREFECTURE__TIMELINE}><button>山形県</button></Link>
                    <Link to={ROUTE.HUKUSIMA__PREFECTURE__TIMELINE}><button>福島県</button></Link>
                </div>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.IBARAKI__PREFECTURE__TIMELINE}><button>茨城県</button></Link>
                            <Link to={ROUTE.TOTIGI__PREFECTURE__TIMELINE}><button>栃木県</button></Link>
                            <Link to={ROUTE.GUNMA__PREFECTURE__TIMELINE}><button>群馬県</button></Link>
                            <Link to={ROUTE.SAITAMA__PREFECTURE__TIMELINE}><button>埼玉県</button></Link>
                        </div>
                        <div>
                            <Link to={ROUTE.TIBA__PREFECTURE__TIMELINE}><button>千葉県</button></Link>
                            <Link to={ROUTE.TOKYO__PREFECTURE__TIMELINE}><button>東京都</button></Link>
                            <Link to={ROUTE.KANAGAWA__PREFECTURE__TIMELINE}><button>神奈川県</button></Link>
                        </div>
                    </div>
                </div>
                <div className="words__search-flex">
                    <Link to={ROUTE.NIGATA__PREFECTURE__TIMELINE}><button>新潟県</button></Link>
                    <Link to={ROUTE.TOYAMA__PREFECTURE__TIMELINE}><button>富山県</button></Link>
                    <Link to={ROUTE.ISHIKAWA__PREFECTURE__TIMELINE}><button>石川県</button></Link>
                    <Link to={ROUTE.HUKUI__PREFECTURE__TIMELINE}><button>福井県</button></Link>
                    <Link to={ROUTE.YAMANASHI__PREFECTURE__TIMELINE}><button>山梨県</button></Link>
                </div>
                <div className="words__search-flex">
                    <Link to={ROUTE.NAGANO__PREFECTURE__TIMELINE}><button>長野県</button></Link>
                    <Link to={ROUTE.GIHU__PREFECTURE__TIMELINE}><button>岐阜県</button></Link>
                    <Link to={ROUTE.SHIZUOKA__PREFECTURE__TIMELINE}><button>静岡県</button></Link>
                    <Link to={ROUTE.AITI__PREFECTURE__TIMELINE}><button>愛知県</button></Link>
                </div>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.MIE__PREFECTURE__TIMELINE}><button>三重県</button></Link>
                            <Link to={ROUTE.SHIGA__PREFECTURE__TIMELINE}><button>滋賀県</button></Link>
                            <Link to={ROUTE.KYOTO__PREFECTURE__TIMELINE}><button>京都府</button></Link>
                            <Link to={ROUTE.OSAKA__PREFECTURE__TIMELINE}><button>大阪府</button></Link>
                        </div>
                        <div>
                            <Link to={ROUTE.HYOGO__PREFECTURE__TIMELINE}><button>兵庫県</button></Link>
                            <Link to={ROUTE.NARA__PREFECTURE__TIMELINE}><button>奈良県</button></Link>
                            <Link to={ROUTE.WAKAYAMA__PREFECTURE__TIMELINE}><button>和歌山県</button></Link>
                        </div>
                    </div>
                </div>
                <div className="words__search-flex">
                    <Link to={ROUTE.TOTTORI__PREFECTURE__TIMELINE}><button>鳥取県</button></Link>
                    <Link to={ROUTE.SHIMANE__PREFECTURE__TIMELINE}><button>島根県</button></Link>
                    <Link to={ROUTE.OKAYAMA__PREFECTURE__TIMELINE}><button>岡山県</button></Link>
                    <Link to={ROUTE.HIROSHIMA__PREFECTURE__TIMELINE}><button>広島県</button></Link>
                    <Link to={ROUTE.YAMAGUTI__PREFECTURE__TIMELINE}><button>山口県</button></Link>
                </div>
                <div className="words__search-flex">
                    <Link to={ROUTE.TOKUSHIMA__PREFECTURE__TIMELINE}><button>徳島県</button></Link>
                    <Link to={ROUTE.KAGAWA__PREFECTURE__TIMELINE}><button>香川県</button></Link>
                    <Link to={ROUTE.EHIME__PREFECTURE__TIMELINE}><button>愛媛県</button></Link>
                    <Link to={ROUTE.KOUTI__PREFECTURE__TIMELINE}><button>高知県</button></Link>
                </div>
                <div className="words__search-flex">
                    <Link to={ROUTE.HUKUOKA__PREFECTURE__TIMELINE}><button>福岡県</button></Link>
                    <Link to={ROUTE.SAGA__PREFECTURE__TIMELINE}><button>佐賀県</button></Link>
                    <Link to={ROUTE.NAGASAKI__PREFECTURE__TIMELINE}><button>長崎県</button></Link>
                    <Link to={ROUTE.KUMAMOTO__PREFECTURE__TIMELINE}><button>熊本県</button></Link>
                </div>
                <div className="words__search-flex">
                    <Link to={ROUTE.OITA__PREFECTURE__TIMELINE}><button>大分県</button></Link>
                    <Link to={ROUTE.MIYAZAKI__PREFECTURE__TIMELINE}><button>宮崎県</button></Link>
                    <Link to={ROUTE.KAGOSHIMA__PREFECTURE__TIMELINE}><button>鹿児島県</button></Link>
                    <Link to={ROUTE.OKINAWA__PREFECTURE__TIMELINE}><button>沖縄県</button></Link>
                </div>
            </div>
            <div className="words__search-content-2 words__search-content">
                <span className="words__search-content-title">キーワード</span>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.NEAR__STATION__TIMELINE}><button>駅近</button></Link>
                            <Link to={ROUTE.TRANSPORTATION__PAID__TIMELINE}><button>交通費支給</button></Link>
                            <Link to={ROUTE.REMOTE__TIMELINE}><button>リモート</button></Link>   
                        </div>
                        <div>
                            <Link to={ROUTE.TWO__DAYS__OFF__TIMELINE}><button>週休2日</button></Link>
                            <Link to={ROUTE.TWO__THREE__OK__TIMELINE}><button>週2・3からOK</button></Link>
                        </div>
                    </div>

                </div>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.UNIVERSITY__STUDENT__OK}><button>大学生OK</button></Link>
                            <Link to={ROUTE.HIGHSCHOOL__STUDENT__OK}><button>高校生OK</button></Link>
                            <Link to={ROUTE.NO__EXPERIENCE__TIMELINE}><button>未経験OK</button></Link> 
                        </div>
                        <div>
                            <Link to={ROUTE.SIDE__BUSINESS__TIMELINE}><button>副業・WワークOK</button></Link>
                            <Link to={ROUTE.WELCOME__EXPERIENCE__TIMELINE}><button>経験者歓迎</button></Link>
                        </div>
                    </div>
                </div>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.EDUCATIONAL__BACKGOUND__NOT__TIMELINE}><button>学歴不問</button></Link>
                            <Link to={ROUTE.MATERNITY__CHILDCARE__LEAVE__TIMELINE}><button>産休・育休</button></Link>
                            <Link to={ROUTE.WITH__TRAINING__TIMELINE}><button>研修あり</button></Link>
                        </div>
                        <div>
                            <Link to={ROUTE.QUALIFICATION__ALLOWANCE__TIMELINE}><button>資格手当</button></Link>
                            <Link to={ROUTE.FAMILY__ALLOWANCE__TIMELINE}><button>家族手当</button></Link>
                        </div>
                    </div>
                </div>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.INSENTIVE__TIMELINE}><button>インセンティブ</button></Link>
                            <Link to={ROUTE.SOCIAL__INSURANCE__TIMELINE}><button>社保完備</button></Link>
                            <Link to={ROUTE.CHILDCARE__SUPPORT__TIMELINE}><button>育児支援</button></Link>
                        </div>
                        <div>
                            <Link to={ROUTE.URGENT__RECRUITMENT__TIMELINE}><button>急募</button></Link>
                            <Link to={ROUTE.WEB__INTERVIEW__OK__TIMELINE}><button>Web面接OK</button></Link>
                            <Link to={ROUTE.BENEFITS__TIMELINE}><button>福利厚生充実</button></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="words__search-content-3 words__search-content">
                <span className="words__search-content-title">職種</span>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.JOB__CORPORATE__SALES__TIMELINE}><button>法人営業</button></Link>
                            <Link to={ROUTE.JOB__PERSONAL__SALES__TIMELINE}><button>個人営業</button></Link>
                            <Link to={ROUTE.JOB__IT__SALES__TIMELINE}><button>IT営業</button></Link>
                        </div>
                        <div>
                            <Link to={ROUTE.JOB__AGENCY__SALES__TIMELINE}><button>ルートセールス・代理店営業</button></Link>
                            <Link to={ROUTE.JOB__INHOUSE__SALES__TIMELINE}><button>内勤営業・カウンターセールス</button></Link>
                        </div>
                    </div>
                </div>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.JOB__FOREIGN__SALES__TIMELINE}><button>海外営業</button></Link>
                            <Link to={ROUTE.JOB__REALSTATE__SALES__TIMELINE}><button>不動産営業</button></Link>
                        </div>
                        <div>
                            <Link to={ROUTE.JOB__FINANCE__SALES__TIMELINE}><button>金融営業</button></Link>
                            <Link to={ROUTE.JOB__TELEPHONE__SALES__TIMELINE}><button>テレマーケティング</button></Link>
                        </div>
                    </div>
                </div>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.JOB__GENERAL__OFFICE__TIMELINE}><button>一般事務</button></Link>
                            <Link to={ROUTE.JOB__SALES__OFFICE__TIMELINE}><button>営業事務</button></Link>
                            <Link to={ROUTE.JOB__RECEIPT__OFFICE__TIMELINE}><button>受付</button></Link>
                        </div>
                        <div>
                            <Link to={ROUTE.JOB__CUSTOMER__OFFICE__TIMELINE}><button>カスタマーサポート</button></Link>
                            <Link to={ROUTE.JOB__SECRETARY__OFFICE__TIMELINE}><button>秘書</button></Link>
                        </div>
                    </div>
                </div>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.JOB__MOBILE__APP__ENGINEER__TIMELINE}><button>モバイルアプリ</button></Link>
                            <Link to={ROUTE.JOB__WEBFRONTEND__ENGINEER__TIMELINE}><button>フロントエンド</button></Link>
                            <Link to={ROUTE.JOB__WEBBACKEND__ENGINEER__TIMELINE}><button>バックエンド</button></Link>
                        </div>
                        <div>
                            <Link to={ROUTE.JOB__WEBFULLSTACK__ENGINEER__TIMELINE}><button>フルスタック</button></Link>
                            <Link to={ROUTE.JOB__INFRA__ENGINEER__TIMELINE}><button>インフラエンジニア</button></Link>
                        </div>
                    </div>
                </div>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.JOB__INFRA__SERVER__ENGINEER__TIMELINE}><button>サーバーエンジニア（設計構築）</button></Link>
                            <Link to={ROUTE.JOB__INFRA__NETWORK__TIMELINE}><button>ネットワークエンジニア（設計構築）</button></Link>
                        </div>
                        <div>
                            <Link to={ROUTE.JOB__INFRA__DATABASE__ENGINEER__TIMELINE}><button>データベースエンジニア</button></Link>
                        </div>
                    </div>
                </div>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.JOB__SUPPORT__ENGINEER__TIMELINE}><button>サポート・ヘルプデスク</button></Link>
                            <Link to={ROUTE.JOB__INFRA__PM__ENGINEER__TIMELINE}><button>プロジェクトマネージャー（インフラ）</button></Link>   
                        </div>
                        <div>
                            <Link to={ROUTE.JOB__INFRA__MAINTAIN__ENGINEER__TIMELINE}><button>運用・監視・保守（インフラ）</button></Link>
                        </div>
                    </div>
                </div>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.JOB__INFRA__WEBSERVICE__ENGINEER__TIMELINE}><button>Webサービスエンジニア</button></Link>
                        </div>
                        <div>
                            <Link to={ROUTE.JOB__INCOMPANY__SE__ENGINEER__TIMELINE}><button>社内SE（社内情報システム）</button></Link>
                        </div>
                    </div>
                </div>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.JOB__WEBDIRECTOR__CREATIVE__TIMELINE}><button>Webプロデューサー/ディレクター/Webプランナー</button></Link> 
                        </div>
                        <div>
                            <Link to={ROUTE.JOB__WEBDESIGNER__CREATIVE__TIMELINE}><button>Webデザイナー</button></Link>
                        </div>
                    </div>
                </div>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.JOB__WEBWRITER__CREATIVE__TIMELINE}><button>Webライター・Web編集（コンテンツ制作）</button></Link>
                        </div>
                        <div>
                            <Link to={ROUTE.JOB__ASSISTDIRECTOR__CREATIVE__TIMELINE}><button>アシスタントディレクター・制作進行管理</button></Link>
                        </div>
                    </div>
                </div>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.JOB__UIUX__CREATIVE__TIMELINE}><button>UI・UXデザイナー（Web・モバイル）</button></Link>
                        </div>
                        <div>
                            <Link to={ROUTE.JOB__PHOTOGRAPHER__CREATIVE__TIMELINE}><button>フォトグラファー・カメラマン</button></Link>
                        </div>
                    </div>
                </div>
                <div className="words__search-flex">
                    <div className="mobile-flex">
                        <div>
                            <Link to={ROUTE.JOB__ILLUSTRATOR__CREATIVE__TIMELINE}><button>イラストレーター【広告・グラフィック関連</button></Link>
                            <Link to={ROUTE.JOB__DESIGNER__CREATIVE__TIMELINE}><button>デザイナー（グラフィック・その他）</button></Link>
                        </div>
                        <div>
                            <Link to={ROUTE.JOB__WRITER__CREATIVE__TIMELINE}><button>編集・記者・ライター</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default WordsSearch;