
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DELETE__ACCOUNT, HOME } from "../../constant/router";
import { useStateValue } from '../../contexts/StateContextProvider';
import db from '../../firebase';
import './index.css';

const SidebarAccoutCompanyModal = () => {
    const [displayName, setDisplayName] = useState(false);
    const [email, setEmail] = useState(false);
    const [prefecture, setPrefecture] = useState(false);
    const [users, setUsers] = useState([])
    const [{user}] = useStateValue();
    const history = useHistory();

    useEffect(() => {
        if(!users.length){
            const getUser = db.collection('users').doc(user.id).onSnapshot(res=>{
                setUsers(res.data())
            })
    
            return () => getUser();
        }
    }, [])

    const onLogout = () => {
        localStorage.clear()
        history.push(HOME)
        window.location.reload()
    }

    const onDelete = () => {
        history.push(DELETE__ACCOUNT);
        window.location.reload();
    }

    const [changeName, setChangeName] = useState('');
    const onUpdateName = () => {
        db.collection('users').doc(user.id).update({
            displayName: changeEmail
        })
    }

    const [changeEmail, setChangeEmail] = useState('');
    const onUpdateEmail = () => {
        db.collection('users').doc(user.id).update({
            email: changeEmail
        })
    }

    const [changePrefecture, setChangePrefecture] = useState('');
    const onUpdatePrefecture = () => {
        db.collection('users').doc(user.id).update({
            prefecture: changePrefecture
        })
    }

    return(
        <div className="sidebar__account__modal">
            <h3>アカウント情報の変更</h3>
            <div className="sidebar__account__modal-edit">
                <div className="sidebar__account__modal-edit-each">
                    {!displayName ? <div className="sidebar__account__modal-edit-each-btn cursor" onClick={() => setDisplayName(true)}>お名前<span>{user.displayName}<i className="fas fa-plus-circle setting-circle"/></span></div> : <div className="sidebar__account__modal-edit-each-btn cursor" onClick={() => setDisplayName(false)}>お名前<span>{user.displayName}<i className="fas fa-minus-circle setting-circle"/></span></div>}
                    {!displayName ? <></> : <div className="d-flex"><input type='text' placeholder={`${users.displayName}`} value={changeName} onChange={e => setChangeName(e.target.value)} /><button onClick={onUpdateName}>変更する</button></div>}
                </div>
            </div>
            <div className="sidebar__account__modal-edit">
                <div className="sidebar__account__modal-edit-each">
                    {!email ? <div className="sidebar__account__modal-edit-each-btn cursor" onClick={() => setEmail(true)}>メールアドレス<span>{user.email}<i className="fas fa-plus-circle setting-circle"/></span></div> : <div className="sidebar__account__modal-edit-each-btn cursor" onClick={() => setEmail(false)}>メールアドレス<span>{user.email}<i className="fas fa-minus-circle setting-circle"/></span></div>}
                    {!email ? <></> : <div className="d-flex"><input type='text' placeholder={`${users.email}`} value={changeEmail} onChange={setChangeEmail} /><button onClick={onUpdateEmail}>変更する</button></div>}
                </div>
            </div>
            <div className="sidebar__account__modal-edit">
                <div className="sidebar__account__modal-edit-each">
                    {!prefecture ? <div className="sidebar__account__modal-edit-each-btn cursor" onClick={() => setPrefecture(true)}>所在地<span>{user.prefecture === '' ? '登録されていません' : user.prefecture}<i className="fas fa-plus-circle setting-circle"/></span></div> : <div className="sidebar__account__modal-edit-each-btn cursor" onClick={() => setPrefecture(false)}>所在地<span>{user.prefecture}<i className="fas fa-minus-circle setting-circle"/></span></div>}
                    {!prefecture ? <></> : <div className="d-flex"><input type='text' placeholder={`${user.prefecture === '' ? '登録されていません' : user.prefecture}`} value={changePrefecture} onChange={e => setChangePrefecture(e.target.value)}/><button onClick={onUpdatePrefecture}>変更する</button></div>}
                </div>
            </div>
            <h3>アカウント</h3>
            <div className="unAuth__topBar__content">
                <button onClick={onLogout}>ログアウト</button>
                <button onClick={onDelete}>アカウント削除</button>
            </div>
        </div>
    )
}

export default SidebarAccoutCompanyModal;