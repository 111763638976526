import React  from 'react';
import FlipMove from 'react-flip-move';
import UnAuthJobPost from './UnAuthJobPost';


const UnAuthJobPostingFront = ({posts}) => {

    return (
        <>
        <FlipMove>
        {
            posts.map(post => (
                <UnAuthJobPost 
                key={post.id}
                postId = {post.id}
                altText = {post.altText}
                senderId = {post.senderId}
                username = {post.username}
                kindJob = {post.kindJob}
                prefecture = {post.prefecture}
                jobTitle = {post.jobTitle}
                avatar = {post.avatar}
                image = {post.image}
                timestamp = {post.timestamp}
                salary = {post.salary}
                website = {post.website}
                eventPlace = {post.eventPlace}
                eventDate = {post.eventDate}
                eventTime = {post.eventTime}
                skills = {post.skills}
                wantedJob = {post.wantedJob}
                canWorkPlace = {post.canWorkPlace}
                hireSection = {post.hireSection}
                keywordsWord = {post.keywordsWord}
                salary2 = {post.salary2}
                salaryType = {post.salaryType}
                qualificationShort = {post.qualificationShort}
            />
            ))
        }           
        </FlipMove>
        </>
    )
}

export default UnAuthJobPostingFront