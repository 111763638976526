import React from 'react';
import Modal from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
import './index.css';

const MessageModal = ({ open, 
                       onClose,
                       title,
                       children,
                       onBack,

}) => {

    return (
        <Modal open={open} onClose={onClose} id="overFlowBlock" showCloseIcon={false}>
            <div className="modal__header">
                <div className='backIcon'>
                    <i className='fas fa-arrow-left modal__back__btn cursor' onClick={onBack}/>
                    <i className='fas fa-times modal__close__btn cursor' onClick={onClose}/>
                </div>
                <h2>{title}</h2>
            </div>
            {children}
        </Modal>
    )
}

export default MessageModal;
