import React, { useState, useEffect } from 'react'
import db from '../../../../firebase'
import { Loader } from '../../../../elements'
import { ActiveNavigationofTimeline, AuthJobPostingFront, CompanyMobileTopNav, UserMobileTopNav } from '../../../..//component';
import { useStateValue } from '../../../../contexts/StateContextProvider';
import { useDocumentTitle, useScrollTop } from '../../../../hooks';
import '../../index.css';

const AuthTimeLine= () => {
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(false)
    const [{user}] = useStateValue();

    useEffect(() => {
       if(!posts.length){
         let mounted = true;
         setLoading(true)
         db.collection('jobs')
         .orderBy('timestamp', 'desc').where('kindJob', '==', 'WEB制作')
         .onSnapshot(snapshot=>{
            if(mounted){
               if(snapshot.empty){
                  setLoading(false)
                  return
               }
                  setPosts(snapshot.docs.map(doc=> ({id:doc.id, ...doc.data()})))
                  setLoading(false)
            }
         }) 
      return () => mounted = false;
       }

   }, []);

    const onRefreshBtn = () => {
       window.location.reload();
    }
    useScrollTop();
    useDocumentTitle("WEB制作 / Copipel");

    return (
       <>
            <div className='feed'>
               {user.role === "USER" ? <UserMobileTopNav/> : <CompanyMobileTopNav/>}
               <ActiveNavigationofTimeline/>
               <div className="feed__header">
                  <h2>WEB制作</h2>
                  <button onClick={onRefreshBtn} className='timeLine__load__btn'><i className='fas fa-redo-alt refresh__btn'/></button>
               </div>
               { loading && <div className="feed__loader"><Loader/></div> }
               <AuthJobPostingFront posts={posts} />
           </div>
       </>
    )
}

export default AuthTimeLine;