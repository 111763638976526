import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";

import logo from '../../assets/logo.png';
import { useRoomState } from '../../contexts/IsRoomOpenedContextProvider';
import { CLOSING_ROOM } from '../../constant/constant';
import SidebarOptions from '../sidebarOption/SidebarOption';
import { useStateValue } from '../../contexts/StateContextProvider';
import SidebarOptionNotification from '../sidebarOption/SidebarOptionNotification';
import './index.css';
import SidebarAccoutCompanyModal from '../modalComponent/SidebarAccountCompanyModal';
import SidebarAccountModal from '../modalComponent/SidebarAccoutModal';
import MessageModal from '../../elements/modal/MessageModal';
import UserPostingBox from '../../pages/postingBox/UserPostingBox';
import SidebarNotificationModal from '../modalComponent/SidebarNotificationModal';
import SidebarTerms from '../modalComponent/SidebarTerms';
import SidebarCopipel from '../modalComponent/SidebarCopipel';
import { ADMIN__DASHBOARD, COMPANY__DASHBOARD, FORGET_PASSWORD, HELP__CENTER, HOME, MESSAGE, NOTIFICATION, SEARCH__WORDS } from '../../constant/router';
import { BackModal } from '../../elements';


const AuthSidebar = () => {
    const [{isRoomOpened}, dispatch] = useRoomState();
    const [{user}] = useStateValue();

    const closeAllRooms= () => {
        dispatch({
            type: CLOSING_ROOM,  
        })
        if(isRoomOpened){}        
    }
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);
    const [isOpenAccountModal, setIsOpenAccountModal] = useState(false);
    const [isOpenNotificationModal, setIsOpenNotificationModal] = useState(false);
    const [isOpenTermsModal, setIsOpenTermsModal] = useState(false);
    const [isOpenCopipelModal, setIsOpenCopipelModal] = useState(false);


    const history = useHistory();

    const onHelpCenter = () => {
        setIsOpenInfoModal(false);
        history.push(HELP__CENTER);
    }
    const onPasswordChange = () => {
        setIsOpenInfoModal(false);
        history.push(FORGET_PASSWORD);
    }

    const onAccountInfo = () => {
        setIsOpenInfoModal(false);
        setIsOpenAccountModal(true);
    }

    const onNotification = () => {
        setIsOpenInfoModal(false);
        setIsOpenNotificationModal(true);
    }

    const onTerms = () => {
        setIsOpenInfoModal(false);
        setIsOpenTermsModal(true);
    }

    const onCopipel = () => {
        setIsOpenInfoModal(false);
        setIsOpenCopipelModal(true);
    }

    const onBackAccount = () => {
        setIsOpenAccountModal(false);
        setIsOpenInfoModal(true);
    }

    const onBackNotification = () => {
        setIsOpenNotificationModal(false);
        setIsOpenInfoModal(true);
    }

    const onBackTerms = () => {
        setIsOpenTermsModal(false);
        setIsOpenInfoModal(true);
    }

    const onBackCopipel = () => {
        setIsOpenCopipelModal(false);
        setIsOpenInfoModal(true);
    }


    return (
        <>
            <MessageModal
            open={isOpenModal}
            onClose={() => setIsOpenModal(false)}>
                <UserPostingBox/>
            </MessageModal>

            <MessageModal
               open={isOpenInfoModal} 
               onClose={()=>setIsOpenInfoModal(false)}
               >
                <div className="unAuth__topMenu">
                    <div className="unAuth__topBar__content">
                        <button onClick={onAccountInfo}><i className='far fa-user setting-icon'/>個人情報・アカウント設定</button>
                        <button onClick={onPasswordChange}><i className='fas fa-lock setting-icon'/>パスワード変更</button>
                        <button onClick={onNotification}><i className='far fa-bell setting-icon'/>通知設定</button>
                        <button onClick={onTerms}><i className='far fa-sticky-note setting-icon'/>プライバシーポリシー・利用規約</button>
                        <button onClick={onCopipel}><i className='fas fa-info setting-icon'/>コピペル情報</button>
                        <button onClick={onHelpCenter}><i className='fas fa-question setting-icon'/>ヘルプセンター</button>
                    </div>
                </div>
            </MessageModal>

            <BackModal
            open={isOpenAccountModal}
            onClose={() => setIsOpenAccountModal(false)}
            onBack={onBackAccount}
            >
                {user.role === 'USER' ? <SidebarAccountModal/> : <SidebarAccoutCompanyModal/>}
            </BackModal>

            <BackModal
            open={isOpenNotificationModal}
            onClose={() => setIsOpenNotificationModal(false)}
            onBack={onBackNotification}
            >
                <SidebarNotificationModal/>
            </BackModal>

            <BackModal
            open={isOpenTermsModal}
            onClose={() => setIsOpenTermsModal(false)}
            onBack={onBackTerms}
            >
                <SidebarTerms/>
            </BackModal>

            <BackModal
            open={isOpenCopipelModal}
            onClose={() => setIsOpenCopipelModal(false)}
            onBack={onBackCopipel}
            >
                <SidebarCopipel/>
            </BackModal>

        <div className='sidebar'>
           <Link to='/' replace><img src={logo} alt='' className='sidebar__icon'/></Link>
           <div className='margin-top-4rem'></div>
           <SidebarOptions text='ホーム' Icon='fas fa-home' url={HOME}/>
           <SidebarOptions text='プロフィール' Icon='fas fa-user' url={`/profile/${user.username}`}/>
           <SidebarOptions text='さがす' Icon='fas fa-search' url={SEARCH__WORDS}/>
           <SidebarOptionNotification text='通知' Icon='far fa-bell' url={NOTIFICATION}/>
           {user.role !== 'USER' ? <></> : <SidebarOptions text='ブックマーク' Icon='far fa-bookmark' url={`/profile/${user.username}/bookmark`}/>} 
           {user.role !== "USER" ? <SidebarOptions text="ダッシュボード" Icon='far fa-clipboard' url={COMPANY__DASHBOARD} /> : <></>}     
           <SidebarOptions text='メッセージ' Icon='far fa-envelope' onClick={closeAllRooms} url={MESSAGE}/>

           {user.role === 'USER' ?             
            <div className='sidebarOption'  onClick={() => setIsOpenModal(true)}>
                <i className='far fa-comment sidebarOptionIcon' />
                <h2>投稿する</h2>
            </div>
            :<></>
            }
            <div className='sidebarOption'  onClick={() => setIsOpenInfoModal(true)}>
                <i className='fas fa-cog sidebarOptionIcon' />
                <h2>設定</h2>
            </div>
            {user.role === "ADMIN" ? <SidebarOptions text='ADMIN' Icon='fas fa-user-shield' url={ADMIN__DASHBOARD}/> : <></>}
        </div>
        </>
    )
}

export default AuthSidebar