import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import db from '../../../firebase';
import AuthJobStatusPost from './AuthJobStatusPost';
import Loader from '../../../elements/loader/index';

const AuthJobStatus = () => {
    const [status, setStatus] = useState(null)
    const loading= false
    const history = useHistory()
    const {postId} = useParams()
    useEffect(() => {
       if(!status){
         let mounted = true
         if(postId){
         db.collection('jobs').doc(postId)
            .onSnapshot(snapshot=>{
               if(mounted){
                  setStatus({...snapshot.data()})
               }
         })
         return () => mounted = false
         }
       }
    }, [postId])

    return (
        <div className='feed'>
           <div className="status__header">
              <div className="status__backArrow" onClick={()=>history.goBack()}>
                 <ArrowBackOutlinedIcon />
              </div> 
           </div>
           {status && <AuthJobStatusPost status={status}/>} 
           { loading && <div className="feed__loader"><Loader/></div> }
        </div>
    );
};

export default AuthJobStatus;