export default function passwordsValidate(values) {
    let errors = { }
  
    const { password, confirmPassword } = values                                                                              
  
    if (!password) {
      errors.erPassword = 'パスワードを入力してください'
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d@$.!%*#?&]/.test(password)) {
      errors.erPassword = 'パスワードは８文字以上、１文字以上大文字、小文字、記号、数字が必要です'
    }
  
  
    if(password !== confirmPassword){
        errors.erPasswords = 'パスワードがマッチしていません'
    }
  
    let {erPassword, erPasswords} = errors
  
        if (erPassword){
            return erPassword
        } else {
        if (erPasswords){
            return erPasswords
        } else {
            return ''
            }
        }
    }