import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import db from "../../firebase";
import { CompanyProfileTopBar, UnAuthCompanyProfile, UnAuthUserProfile, UserProfileTopBar } from "../../component";
import NonUser from "../error/NonUser";


const UnAuthUserProfilePage = () => {
    const {username} = useParams();
    const history = useHistory(); 
    const [profile, setProfile] = useState([])

    useEffect(() => {
        if(!profile.length){
            const getData = db.collection('users').where('username', '==', username).onSnapshot(snapshot=>{
                setProfile(snapshot.docs.map(doc=>({
                  id: doc.id,
                  ...doc.data()
                }))[0])
              })
    
              return () => getData();
        }
      }, [])


      const [copySuccess, setCopySuccess] = useState('');
      const onCopyBtn = (e) => {
        navigator.clipboard.writeText(window.location.href)
        setCopySuccess('copied');
      }
  
  
    return(
        <>
        {profile ? 
            <div className='feed'>
                <div className="profile__header d-flex">
                <div className="profile__backArrow" onClick={()=>history.goBack()}>
                    <ArrowBackOutlinedIcon />
                </div>
                <div className='profile__title'>
                    <div className='profile__title_title'><h2>{profile && profile.displayName}</h2>{profile.role === "COMPANY" ? <VerifiedUserIcon className='post__badge'/>: <></>}</div>      
                </div>
                <div className="profile__top__link">
                        <button className="profile__top__link-pc" onClick={onCopyBtn}>{copySuccess !== 'copied' ? <p>URLをコピーする<i className="fas fa-check-circle check-icon"/></p> : <p>URLをコピーしました<i className="fas fa-check-circle check-icon check-icon-success"/></p>}</button>
                    </div>   
                </div>
                {profile.role === "USER" ? <UserProfileTopBar/> : <CompanyProfileTopBar/>}
                {profile && profile.published ?
                 <>
                 {profile && profile.role === "USER" ? <UnAuthUserProfile/> : <UnAuthCompanyProfile/>}
                 </>
                :
                <>
                <h4>現在、このアカウントは非公開になっています</h4>
                </>
                }
            </div>
        : <NonUser/>
        }
        </>
    )
}

export default UnAuthUserProfilePage;