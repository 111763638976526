import React, {useState} from 'react';
import { TextField } from '@material-ui/core';
import './index.css';

const EditQualiProfile = ({profile}) => {
    const {skills1, skills2, skills3, skills4, skills5, skills6, qualiYear1, qualiYear2, qualiYear3, qualiYear4,
        qualiYear5, qualiYear6, qualiYear7, qualiYear8, qualiMonth1, qualiMonth2, qualiMonth3, qualiMonth4, qualiMonth5, qualiMonth6, qualiMonth7, qualiMonth8, qualiContent1, qualiContent2, qualiContent3, qualiContent4, qualiContent5, qualiContent6, qualiContent7, qualiContent8} = profile
    const [profileState, setProfileState] = useState({
        skills1: skills1?skills1: '',
        skills2: skills2?skills2: '',
        skills3: skills3?skills3: '',
        skills4: skills4?skills4: '',
        skills5: skills5?skills5: '',
        skills6: skills6?skills6: '',
        qualiYear1: qualiYear1?qualiYear1: '',
        qualiYear2: qualiYear2?qualiYear2: '',
        qualiYear3: qualiYear3?qualiYear3: '',
        qualiYear4: qualiYear4?qualiYear4: '',
        qualiYear5: qualiYear5?qualiYear5: '',
        qualiYear6: qualiYear6?qualiYear6: '',
        qualiYear7: qualiYear7?qualiYear7: '',
        qualiYear8: qualiYear8?qualiYear8: '',
        qualiMonth1: qualiMonth1?qualiMonth1: '',
        qualiMonth2: qualiMonth2?qualiMonth2: '',
        qualiMonth3: qualiMonth3?qualiMonth3: '',
        qualiMonth4: qualiMonth4?qualiMonth4: '',
        qualiMonth5: qualiMonth5?qualiMonth5: '',
        qualiMonth6: qualiMonth6?qualiMonth6: '',
        qualiMonth7: qualiMonth7?qualiMonth7: '',
        qualiMonth8: qualiMonth8?qualiMonth8: '',
        qualiContent1: qualiContent1?qualiContent1: '',
        qualiContent2: qualiContent2?qualiContent2: '',
        qualiContent3: qualiContent3?qualiContent3: '',
        qualiContent4: qualiContent4?qualiContent4: '',
        qualiContent5: qualiContent5?qualiContent5: '',
        qualiContent6: qualiContent6?qualiContent6: '',
        qualiContent7: qualiContent7?qualiContent7: '',
        qualiContent8: qualiContent8?qualiContent8: '',
    })


    return (
        <>      
            <div className='editProfile'>
                <form noValidate autoComplete="off">
                    <div className='d-flex'>
                        <TextField id="skills1" label="スキル(1)" variant="filled" value={profileState.skills1} onChange={e=>setProfileState({...profileState, skills1:e.target.value})} inputProps={{ maxLength: 10 }}/>
                        <TextField id="skills2" label="スキル(2)" variant="filled" value={profileState.skills2} onChange={e=>setProfileState({...profileState, skills2:e.target.value})} inputProps={{ maxLength: 10 }}/>
                    </div>
                    <div className='d-flex'>
                        <TextField id="skills3" label="スキル(3)" variant="filled" value={profileState.skills3} onChange={e=>setProfileState({...profileState, skills3:e.target.value})} inputProps={{ maxLength: 10 }}/>
                        <TextField id="skills4" label="スキル(4)" variant="filled" value={profileState.skills4} onChange={e=>setProfileState({...profileState, skills4:e.target.value})} inputProps={{ maxLength: 10 }}/>
                    </div>
                    <div className='d-flex'>
                        <TextField id="skills5" label="スキル(5)" variant="filled" value={profileState.skills5} onChange={e=>setProfileState({...profileState, skills5:e.target.value})} inputProps={{ maxLength: 10 }}/>
                        <TextField id="skills6" label="スキル(6)" variant="filled" value={profileState.skills6} onChange={e=>setProfileState({...profileState, skills6:e.target.value})} inputProps={{ maxLength: 10 }}/>
                    </div>
                    <div className='career__date d-flex'>
                        <TextField id="qualiYear1" label="年(1)" variant="filled" value={profileState.qualiYear1} onChange={e=>setProfileState({...profileState, qualiYear1:e.target.value})}/>
                        <TextField id="qualiMonth1" label="月(1)" variant="filled" value={profileState.qualiMonth1} onChange={e=>setProfileState({...profileState, qualiMonth1:e.target.value})}/>
                        <TextField id="qualiContent1" label="内容(1)" variant="filled" value={profileState.qualiContent1} onChange={e=>setProfileState({...profileState, qualiContent1:e.target.value})}/>
                    </div>
                    <div className='career__date d-flex'>
                        <TextField id="qualiYear2" label="年(2)" variant="filled" value={profileState.qualiYear2} onChange={e=>setProfileState({...profileState, qualiYear2:e.target.value})}/>
                        <TextField id="qualiMonth2" label="月(2)" variant="filled" value={profileState.qualiMonth2} onChange={e=>setProfileState({...profileState, qualiMonth2:e.target.value})}/>
                        <TextField id="qualiContent2" label="内容(2)" variant="filled" value={profileState.qualiContent2} onChange={e=>setProfileState({...profileState, qualiContent2:e.target.value})}/>
                    </div>
                    <div className='career__date d-flex'>
                        <TextField id="qualiYear3" label="年(3)" variant="filled" value={profileState.qualiYear3} onChange={e=>setProfileState({...profileState, qualiYear3:e.target.value})}/>
                        <TextField id="qualiMonth3" label="月(3)" variant="filled" value={profileState.qualiMonth3} onChange={e=>setProfileState({...profileState, qualiMonth3:e.target.value})}/>
                        <TextField id="qualiContent3" label="内容(3)" variant="filled" value={profileState.qualiContent3} onChange={e=>setProfileState({...profileState, qualiContent3:e.target.value})}/>
                    </div>
                    <div className='career__date d-flex'>
                        <TextField id="qualiYear4" label="年(4)" variant="filled" value={profileState.qualiYear4} onChange={e=>setProfileState({...profileState, qualiYear4:e.target.value})}/>
                        <TextField id="qualiMonth4" label="月(4)" variant="filled" value={profileState.qualiMonth4} onChange={e=>setProfileState({...profileState, qualiMonth4:e.target.value})}/>
                        <TextField id="qualiContent4" label="内容(4)" variant="filled" value={profileState.qualiContent4} onChange={e=>setProfileState({...profileState, qualiContent4:e.target.value})}/>
                    </div>
                    <div className='career__date d-flex'>
                        <TextField id="qualiYear5" label="年(5)" variant="filled" value={profileState.qualiYear5} onChange={e=>setProfileState({...profileState, qualiYear5:e.target.value})}/>
                        <TextField id="qualiMonth5" label="月(5)" variant="filled" value={profileState.qualiMonth5} onChange={e=>setProfileState({...profileState, qualiMonth5:e.target.value})}/>
                        <TextField id="qualiContent5" label="内容(5)" variant="filled" value={profileState.qualiContent5} onChange={e=>setProfileState({...profileState, qualiContent5:e.target.value})}/>
                    </div>
                    <div className='career__date d-flex'>
                        <TextField id="qualiYear6" label="年(6)" variant="filled" value={profileState.qualiYear6} onChange={e=>setProfileState({...profileState, qualiYear6:e.target.value})}/>
                        <TextField id="qualiMonth6" label="月(6)" variant="filled" value={profileState.qualiMonth6} onChange={e=>setProfileState({...profileState, qualiMonth6:e.target.value})}/>
                        <TextField id="qualiContent6" label="内容(6)" variant="filled" value={profileState.qualiContent6} onChange={e=>setProfileState({...profileState, qualiContent6:e.target.value})}/>
                    </div>
                    <div className='career__date d-flex'>
                        <TextField id="qualiYear7" label="年(7)" variant="filled" value={profileState.qualiYear7} onChange={e=>setProfileState({...profileState, qualiYear7:e.target.value})}/>
                        <TextField id="qualiMonth7" label="月(7)" variant="filled" value={profileState.qualiMonth7} onChange={e=>setProfileState({...profileState, qualiMonth7:e.target.value})}/>
                        <TextField id="qualiContent7" label="内容(7)" variant="filled" value={profileState.qualiContent7} onChange={e=>setProfileState({...profileState, qualiContent7:e.target.value})}/>
                    </div>
                    <div className='career__date d-flex'>
                        <TextField id="qualiYear8" label="年(8)" variant="filled" value={profileState.qualiYear8} onChange={e=>setProfileState({...profileState, qualiYear8:e.target.value})}/>
                        <TextField id="qualiMonth8" label="月(8)" variant="filled" value={profileState.qualiMonth8} onChange={e=>setProfileState({...profileState, qualiMonth8:e.target.value})}/>
                        <TextField id="qualiContent8" label="内容(8)" variant="filled" value={profileState.qualiContent8} onChange={e=>setProfileState({...profileState, qualiContent8:e.target.value})}/>
                    </div>
                </form>
            </div>
        </>
    )
}

export default EditQualiProfile;

