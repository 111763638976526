import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/logo.png';
import { ABOUT__US, HELP__CENTER, HOME, SEARCH__WORDS } from '../../constant/router';
import SidebarOptions from '../sidebarOption/SidebarOption';
import './index.css';

const UnAuthSidebar = () => {
    return (
        <div className='sidebar'>
           <Link to={HOME}><img src={logo} alt='' className='sidebar__icon'/></Link>
           <div className='margin-top-4rem'></div>
           <SidebarOptions text='ホーム' Icon='fas fa-home' url={HOME}/>
           <SidebarOptions text='さがす' Icon='fas fa-search' url={SEARCH__WORDS}/>
           <SidebarOptions text='Copipelとは' Icon='fas fa-paperclip' url={ABOUT__US}/>
           {/* <SidebarOptions text='Copipelの使い方' Icon='fas fa-list-ol' url={HOW__TO__USE} /> */}
           <SidebarOptions text='ヘルプセンター' Icon='far fa-question-circle' url={HELP__CENTER} />
        </div>
    )
}

export default UnAuthSidebar