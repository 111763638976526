
import React from "react";
import { Link } from "react-router-dom";
import { onDeleteAccount, onDeleteAllPost } from "../../../redux/actions/serverActions";
import './index.css';

const GetUser = ({users}) => {

    const onDeleteBtn = (userId) => {
      const confirm = window.confirm('本当に削除しますか？')
      if (confirm){
         onDeleteAllPost(userId);
         onDeleteAccount(userId);
      }
      else{
         return
      }
   }


    return(
       <>
       <div className="get__user">
            <table id="userTable">
            <tr>
               <th>名前</th>
               <th>Email</th>
               <th>年齢</th>
               <th>プロフィール</th>
               <th>アカウント削除</th>
            </tr>
            {users.map(user => (
                  <tr>
                     <td>{user.displayName}</td>
                     <td><a href={`mailto: ${user.email}`} className="mailTo__link">{user.email}</a></td>
                     <td>{user.age}</td>
                     <td><Link to={user ? `/profile/${user.username}` : `/notfound`}><button>みる</button></Link></td>
                     <td>
                        <button onClick={() => onDeleteBtn(user.id)}>削除する</button>
                     </td>
                  </tr>
               ))}
              </table>
          </div>
       </>
    )
}

export default GetUser;