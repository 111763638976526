import React, { useState, useEffect } from "react";
import CloseIcon from '@material-ui/icons/Close';

import { useStateValue } from "../../contexts/StateContextProvider";
import db from "../../firebase";
import './index.css';
import MessageModal from "../../elements/modal/MessageModal";
import UserItemFollowing from "../userItemFollow/UserItemFollowing";


const ShowWhoLikesPost = ({senderId, postId}) => {
    const [likePost, setLikePost] = useState([]);
    const [users, setUsers] = useState([]);
    const [{user}] = useStateValue();


    useEffect(() => {
        const getData = db.collection('users').onSnapshot((snapshot) => {
            const userData = [];
            snapshot.forEach((doc) => userData.push({ ...doc.data(), id: doc.id }));
            setUsers(userData);
          });

        return () => getData();
    }, []);

    let list1 = []

    useEffect(() => {
        const getData = db.collection('jobs').doc(postId).onSnapshot((snapshot) => {
            list1.push(Array(snapshot.data().likes))
            setLikePost(snapshot.data().likes)
        })

        return () => getData();
    }, [])
    const [isOpenModal, setIsOpenModal] = useState(false)    
    return(
        <>
        <MessageModal
        open={isOpenModal} 
        onClose={() => setIsOpenModal(false)}
        title="気になるを押した人"
        Icon = {CloseIcon}
        >
                <div>
                    {users && users.filter(u => 
                    likePost[0] === u.id || likePost[1] === u.id || likePost[2] === u.id || likePost[3] === u.id || likePost[4] === u.id || likePost[5] === u.id || likePost[6] === u.id || likePost[7] === u.id || likePost[8] === u.id || likePost[9] === u.id || likePost[10] === u.id ||
                    likePost[11] === u.id || likePost[12] === u.id || likePost[13] === u.id || likePost[14] === u.id || likePost[15] === u.id || likePost[16] === u.id || likePost[17] === u.id || likePost[18] === u.id || likePost[19] === u.id || likePost[20] === u.id || likePost[21] === u.id
                    ).map(i => {
                        return <UserItemFollowing display={i} key={i.id}/>
                    })}
                </div>
        </MessageModal>
        <div className="whoLikes__show">
            {senderId === user.id ? <button onClick={() => setIsOpenModal(true)}>誰が申し込んだか見る</button> : <div></div>}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
        </div>
        </>
    )
}

export default ShowWhoLikesPost;