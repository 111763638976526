import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import UserProfileTopbar from "../UserProfileTopbar";
import db from "../../../../firebase";
import '../../index.css';
import UnAuthShowTabJobPost from "../../../tagPost/UnAuthShowTabJobPost";

const UnAuthUserBookMarkPage = () => {
    const {username} = useParams();
    const history = useHistory(); 
    const [profile, setProfile] = useState([])
    useEffect(() => {
      if(!profile.length){
        db.collection('users').where('username', '==', username).onSnapshot(snapshot=>{
          setProfile(snapshot.docs.map(doc=>({
            id: doc.id,
            ...doc.data()
          }))[0])
        })
      }
      }, [])

      const [copySuccess, setCopySuccess] = useState('');
      const onCopyBtn = (e) => {
        navigator.clipboard.writeText(window.location.href)
        setCopySuccess('copied');
      }
  
  
    return(
        <div className='feed'>
           <div className="profile__header">
              <div className="profile__backArrow" onClick={()=>history.goBack()}>
                 <ArrowBackOutlinedIcon />
              </div>
              <div className='profile__title'>
                <div className='profile__title_title'><h2>{profile.anonymous === true ? <>{"匿名"}</> : <>{profile.displayName}</>}</h2>{profile && profile.role === "COMPANY" ? <VerifiedUserIcon className='post__badge'/>: <></>}</div>
              </div>
              <div className="profile__top__link">
                  <button className="profile__top__link-pc" onClick={onCopyBtn}>{copySuccess !== 'copied' ? <p>URLをコピーする<i className="fas fa-check-circle check-icon"/></p> : <p>URLをコピーしました<i className="fas fa-check-circle check-icon check-icon-success"/></p>}</button>
              </div>   
           </div>
           <UserProfileTopbar/>
           {profile && profile.published ? <UnAuthShowTabJobPost/> : <h4>現在、このアカウントは非公開になっています</h4>}
        </div>
    )
}

export default UnAuthUserBookMarkPage;