import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from "react-router-dom";
import db from "../../firebase";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import UserItemFollowing from '../userItemFollow/UserItemFollowing';
import TabbarMenu from '../../elements/tabbarMenu/TabbarMenu';

const ProfileFollowing = () => {
   const history = useHistory()
   const {username} = useParams()
   const [following, setFollowing] = useState([])
   const [profile, setProfile] = useState({})

   useEffect(() => {
      if(!profile.length){
         if(username){
            db.collection('users').where('username', '==', username).onSnapshot(snapshot=>{
            setProfile(snapshot.docs.map(doc=>({
               id: doc.id,
               ...doc.data()
            }))[0])
            })         
         }
      }
   }, [])

   useEffect(() => {
      if(!following.length){
         if(profile.id){
            db.collection('users').where('followers', 'array-contains', profile.id)
            .onSnapshot(snapshot=> {
            setFollowing(snapshot.docs.map(doc => ({
               id: doc.id,
               ...doc.data()})))
            }) 
         }
      }
   }, [profile])

    const items = [
        {
            id: 0,
            title:'フォロー',
            item: <ul>
                     {following && following.map(user=>{
                        if(user.length === 0){
                           return(
                              <p>現在、フォローはいません</p>
                           )
                        } else {
                           return  <li key={user.id}> <UserItemFollowing display={user}/></li>
                        }
                     })}
                  </ul>
        },
    ]

    return (
        <div className='feed'>
           <div className="profile__header forFollow">
              <div className="profile__backArrow" onClick={()=>history.goBack()}>
                 <ArrowBackOutlinedIcon />
              </div>
              <div className='profile__title'>
                <div className='profile__title_title'><h2>{profile.displayName}</h2>{profile.role !== "USER" ? <CheckCircleIcon />: <></>}</div>        
              </div>
           </div>
           <TabbarMenu items={items}/>

        </div>
    );
};

export default ProfileFollowing;