import React from "react";
import { Link, NavLink } from "react-router-dom";
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentIcon from '@material-ui/icons/Assignment';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import HomeIcon from '@material-ui/icons/Home';
import { ADMIN__CHART, ADMIN__DASHBOARD, ADMIN__GET__COMPANY, ADMIN__GET__POST, ADMIN__GET__USER, HOME } from "../../../constant/router";
import './index.css';

const AdminSidebar = () => {
    return(
        <aside className="sidenavigation">
            <h3>ADMIN</h3>
            <div className="sidenavigation-wrapper">
                <div className="sidenavigation-item">
                    <NavLink
                    activeClassName="sidenavigation-menu-active"
                    className="sidenavigation-menu"
                    to={ADMIN__DASHBOARD}
                    >
                    <div className="d-flex">
                        <DashboardIcon fontSize='large' className="admin__sidebar-icon"/>
                        <h4>ダッシュボード</h4>
                    </div> 
                    </NavLink>
                </div>
                <div className="sidenavigation-item">
                    <NavLink
                    activeClassName="sidenavigation-menu-active"
                    className="sidenavigation-menu"
                    to={ADMIN__CHART}
                    >
                    <div className="d-flex">
                        <InsertChartIcon fontSize='large' className="admin__sidebar-icon"/>
                        <h4>チャート</h4>
                    </div> 
                    </NavLink>
                </div>
                <div className="sidenavigation-item">
                    <NavLink
                    activeClassName="sidenavigation-menu-active"
                    className="sidenavigation-menu"
                    to={ADMIN__GET__USER}
                    >
                    <div className="d-flex">
                        <PeopleIcon fontSize='large' className="admin__sidebar-icon"/>
                        <h4>ユーザー</h4>
                    </div> 
                    </NavLink>
                </div>
                <div className="sidenavigation-item">
                    <NavLink
                    activeClassName="sidenavigation-menu-active"
                    className="sidenavigation-menu"
                    to={ADMIN__GET__COMPANY}
                    >
                    <div className="d-flex">
                        <BusinessIcon fontSize='large' className="admin__sidebar-icon"/>
                        <h4>企業</h4>
                    </div> 
                    </NavLink>
                </div>
                <div className="sidenavigation-item">
                    <NavLink
                    activeClassName="sidenavigation-menu-active"
                    className="sidenavigation-menu"
                    to={ADMIN__GET__POST}
                    >
                    <div className="d-flex">
                        <AssignmentIcon fontSize='large' className="admin__sidebar-icon"/>
                        <h4>投稿一覧</h4>
                    </div> 
                    </NavLink>
                </div>
                <div className="sidenavigation-item">
                    <Link
                    className="sidenavigation-menu"
                    to={HOME}
                    >
                    <div className="d-flex">
                        <HomeIcon fontSize='large' className="admin__sidebar-icon"/>
                        <h4>ホーム</h4>
                    </div> 
                    </Link>
                </div>
            </div>
      </aside>
    )
}


export default AdminSidebar;