import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import db from "../../firebase";

import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import { useStateValue } from "../../contexts/StateContextProvider";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Avatar } from "@material-ui/core";
import { useDocumentTitle, useScrollTop } from "../../hooks";
import './index.css';

const Notification = () => {

    useScrollTop();
    useDocumentTitle("通知 / コピペル");
    const history = useHistory();
    const [{user}] = useStateValue();
    const [notification, setNotification] = useState([]);
    useEffect(() => {
         let mounted = true
         if(mounted){
           db.collection('notification').orderBy('timestamp','desc').limit(15)
           .onSnapshot(snapshot => setNotification(snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))))
         }
  
         return () => mounted = false
    }, [])

    const onRead = (info) => {
      db.collection('notification').doc(info).update({
          read: true
      })
   };

    return(
      <div className='feed notification__box'>
         <div className="profile__header forFollow">
            <div className="profile__backArrow" onClick={()=>history.goBack()}>
               <ArrowBackOutlinedIcon />
            </div>
            <div className='profile__title'>
            <div className='profile__title_title'><h2>{user.displayName}</h2>{user.role !== "USER" ? <CheckCircleIcon />: <></>}</div>        
            </div>
         </div>
         {notification.length > 0 ? 
         <div className="notification__content">
          <ul>
             {notification && notification.filter(i => i.userId === user.id).map((info, i) => {
                return   <>
                {info.read === true ?
                <div className="notification__bar">
                      <a href={`${info.url}`}><Avatar src={info.photoURL}/><p><strong>{info.details}</strong>さん{info.content}</p></a>
                </div>
                :
                <div className="notification__bar unread__notification" onClick={() => onRead(info.id)}>
                   <a href={`${info.url}`}><Avatar src={info.photoURL}/><p><strong>{info.details}</strong>{info.content}</p></a>
                </div>
                }
                </>
             })}
          </ul>
       </div>  
       :
       <div className='noNotification__content'>
          <p>通知はまだありません</p>
       </div>
       }
   </div>
    );
};

export default Notification;