import React from "react";
import { NavLink } from "react-router-dom";
import { EVENT__TIMELINE, HOME, JOB__TIMELINE, USER__TIMELINE } from "../../constant/router";
import './index.css';

const ActiveNavigationofTimeline = () => {
    return(
        <div className="active__navigation">
            <ul>
                <li><NavLink exact to={HOME} className="gray" activeclassname="active" replace ><span>すべて</span></NavLink></li>
                <li><NavLink to={JOB__TIMELINE} className="gray" activeclassname="active" replace><span>求人投稿</span></NavLink></li>
                <li><NavLink to={EVENT__TIMELINE} className="gray" activeclassname="active" replace><span>イベント</span></NavLink></li>
                <li><NavLink to={USER__TIMELINE} className="gray" activeclassname="active" replace><span>ユーザー</span></NavLink></li>
            </ul>
        </div>
    )
}

export default ActiveNavigationofTimeline;