import React, {useEffect, useState} from 'react';
import db from '../../firebase';
import { authImg } from '../../assets';
import { MessageModal, Spinner, TabbarMenu } from '../../elements';
import { useHistory, Link, Redirect } from 'react-router-dom';
import { useDocumentTitle, useScrollTop } from '../../hooks';
import { useStateValue } from '../../contexts/StateContextProvider';
import './index.css';
import { SET_USER } from '../../constant/constant';
import { FORGET_PASSWORD, HOME, LOGIN__EMAIL } from '../../constant/router';
import UseAndTerms from '../info/UseAndTerms';
import PrivacyPolicy from '../info/PrivacyPolicy';
import firebase from 'firebase';
import authValidate from '../../helpers/authInputValidator';
import SignupConfirmEmail from './code/SignupConfirmEmail';

const Login = () => {

    const init = {
        name : '',
        username : '',
        email : '',
        password : '',
        confirmPassword : '',
        useAndTerms: true,
    }
    const [dispatch] = useStateValue()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory();
    const [changeAuth, setChangeAuth] = useState(false);

    useScrollTop();
    useDocumentTitle("アカウント作成・ログイン / コピペル");

    const auths = (user) => {
        try {
            user.password = undefined
            localStorage.setItem('copipel_user', JSON.stringify(user))
            
            dispatch({
                type: SET_USER,
                user : JSON.parse(localStorage.getItem('copipel_user'))
            })
            
            setLoading(false)          
        } catch (error) {
            setLoading(false)
            return
        }
    }

    const [profile, setProfile] = useState({});
    const onSubmitLogin = (e) => {  
        const {email, password} = signupState

        db.collection('users').where('email', '==', email).onSnapshot(snapshot=>{
            setProfile(snapshot.docs.map(doc=>({
                id: doc.id,
                ...doc.data()
            }))[0])
        })
        e.preventDefault()
        setLoading(true)
        db.collection('users')
            .where('email', '==', email)
            .where('password','==', password)
            .where('emailConformation', '==', true)
            .limit(1)
            .get()
            .then(snapshot=>{
                if(!snapshot.docs.length){
                    setError('Invalid credential')
                return
                } else {
                    return {id: snapshot.docs[0].id, ...snapshot.docs[0].data()}
                }
            })
            .then(res=>{
                if(res){
                    auths(res)
                    history.replace(HOME);
                    window.location.reload();
                } else {
                    if(!profile.emailConformation){
                        setError('メールアドレスの確認を行ってください。自動的に確認コードページにリダイレクトします...')
                        setTimeout(() => {
                            return <Redirect to={LOGIN__EMAIL}/>
                        }, 4000)
                    } else {
                        setError('メールアドレス、パスワードをもう一度確かめてください')
                    }
                    setLoading(false)
                }
            })
            .catch(error=>{
                setLoading(false)
                setError('メールアドレス、パスワードをもう一度確かめてください')
                return
            }
        )
    }
    const [onOpenCode, setOnOpenCode] = useState(false);
    const [signupState, setSignupState] = useState(init);
    const [isOpenUseModal, setIsOpenUseModal] = useState(false);
    const [isOpenPrivacyModal, setIsOpenPrivacyModal] = useState(false);
    let min = 11111111
    let max = 99999999
    const randomNum = Math.floor(Math.random() * (max-min)) + min;
    const onSendCode = () => {
        db.collection('confirmEmail').add({
            email: signupState.email,
            code: randomNum
        })
    }
    const onSubmitUser = () => {
        const {name, username, email, password, useAndTerms } = signupState;
        db.collection('users').add({
            displayName: name,
            email,
            age: '',
            followers: [],
            following: [],
            joined: firebase.firestore.FieldValue.serverTimestamp(),
            password,
            photoURL: '',
            rooms: [],
            username,
            nameKeywords: [name.substring(0, 1), name.substring(0, 2), name.substring(0, 3), name.substring(0, 4), name.substring(0, 5), name.substring(0, 6), name.substring(0, 7), name.substring(0, 8), name.substring(0, 9), name.substring(0, 10),
                name.substring(0, 11), name.substring(0, 12), name.substring(0, 13), name.substring(0, 14), name.substring(0, 15), name.substring(0, 16), name.substring(0, 17), name.substring(0, 18), name.substring(0, 19), name.substring(0, 20), name],
            role: "USER",
            careerYear1: '', careerMonth1: '', careerContent1: '', careerTitle1: '',
            careerYear2: '', careerMonth2: '',careerContent2: '', careerTitle2: '',
            careerYear3: '', careerMonth3: '', careerContent3: '', careerTitle3: '',
            careerYear4: '', careerMonth4: '', careerContent4: '', careerTitle4: '',
            careerYear5: '', careerMonth5: '', careerContent5: '', careerTitle5: '',
            careerYear6: '', careerMonth6: '', careerContent6: '', careerTitle6: '',
            careerYear7: '', careerMonth7: '', careerContent7: '', careerTitle7: '',
            careerYear8: '', careerMonth8: '', careerContent8: '', careerTitle8: '',
            careerYear9: '', careerMonth9: '', careerContent9: '', careerTitle9: '',
            careerYear10: '', careerMonth10: '', careerContent10: '', careerTitle10: '',
            qualiYear1: '', qualiMonth1: '', qualiContent1: '', 
            qualiYear2: '', qualiMonth2: '', qualiContent2: '',
            qualiYear3: '', qualiMonth3: '', qualiContent3: '',
            qualiYear4: '', qualiMonth4: '', qualiContent4: '',
            qualiYear5: '', qualiMonth5: '', qualiContent5: '',
            qualiYear6: '', qualiMonth6: '', qualiContent6: '',
            twitterURL:'', instagramURL: '', lineURL: '', facebookURL: '',
            websiteURL1: '', websiteURL2: '', websiteURL3: '', prefecture: '', 
            skills1: '', skills2: '', skills3: '', skills4: '', skills5: '', skills6: '', 
            emailNotification: true,
            useAndTerms: useAndTerms,
            published: true,
            emailConformation: false,
            anonymous: false,
            firstTime: true
        })
        onSendCode();
        setOnOpenCode(true);
    }

    const onSubmitCompany = () => {
        const { name, username, email, password, useAndTerms } = signupState
        db.collection('users').add({
            displayName: name,
            email,
            followers: [],
            following: [],
            joined: firebase.firestore.FieldValue.serverTimestamp(),
            password,
            photoURL: '',
            rooms: [],
            username,
            nameKeywords: [name.substring(0, 1), name.substring(0, 2), name.substring(0, 3), name.substring(0, 4), name.substring(0, 5), name.substring(0, 6), name.substring(0, 7), name.substring(0, 8), name.substring(0, 9), name.substring(0, 10),
                    name.substring(0, 11), name.substring(0, 12), name.substring(0, 13), name.substring(0, 14), name.substring(0, 15), name.substring(0, 16), name.substring(0, 17), name.substring(0, 18), name.substring(0, 19), name.substring(0, 20),
                    name.replace("株式会社", "").substring(0, 1), name.replace("株式会社", "").substring(0, 2), name.replace("株式会社", "").substring(0, 3), name.replace("株式会社", "").substring(0, 4), name.replace("株式会社", "").substring(0, 5),
                    name.replace("株式会社", "").substring(0, 6), name.replace("株式会社", "").substring(0, 7), name.replace("株式会社", "").substring(0, 8), name.replace("株式会社", "").substring(0, 9), name.replace("株式会社", "").substring(0, 10),
                    name.replace("株式会社", "").substring(0, 11), name.replace("株式会社", "").substring(0, 12), name.replace("株式会社", "").substring(0, 13), name.replace("株式会社", "").substring(0, 14), name.replace("株式会社", "").substring(0, 15),
                    name.replace("株式会社", "").substring(0, 16), name.replace("株式会社", "").substring(0, 17), name.replace("株式会社", "").substring(0, 18), name.replace("株式会社", "").substring(0, 19), name.replace("株式会社", "").substring(0, 20),
                ],
            wallpaper: '',
            role: "COMPANY",
            doing: '',
            facebookURL: '',
            features: '',
            founder: '',
            instagramURL: '',
            lineURL: '',
            message: '',
            otherAddress: '',
            peopleWorking: '',
            prefecture: '',
            twitterURL: '',
            websiteURL1: '',
            websiteURL2: '',
            websiteURL3: '',
            whenFound: '',
            anonymous: false,
            emailNotification: true,
            useAndTerms: useAndTerms,
            published: true,
            emailConformation: false,
            firstTime: true
        })
        onSendCode();
        setOnOpenCode(true);
    }
    const [users, setUsers] = useState([]);
    useEffect(() => {
        if(!profile.length){
            const getUser = db.collection('users').onSnapshot(snapshot=>{
                setUsers(snapshot.docs.map(doc=>({
                  id: doc.id,
                  ...doc.data()
                })))
              })
        return () => getUser();
        }
      }, [])

    const preSignupUser = (e) => {
        e.preventDefault()
        onSubmitUser()
        setError(authValidate(signupState, users))
    }

    const preSignupCompany = (e) => {
        e.preventDefault()
        onSubmitCompany()
        setError(authValidate(signupState, users))
    }
    const items = [
        {
            id:0,
            title: "個人",
            item: <>
                <MessageModal
                open={isOpenUseModal}
                onClose={() => setIsOpenUseModal(false)}
                >
                    <UseAndTerms/>
                </MessageModal>
                <MessageModal
                open={isOpenPrivacyModal}
                onClose={() => setIsOpenPrivacyModal(false)}
                >
                    <PrivacyPolicy/>
                </MessageModal>
                    <div className="auth__form-content-signup">
                        <form onSubmit={preSignupUser}>
                            <div className="auth__form-content-input auth__form-content-margin">
                                <input type="text" name="name" className="auth-input" placeholder='お名前' value={signupState.name} onChange={(e)=>setSignupState({...signupState, name: e.target.value})}/>
                                <input type="text" name="username" className="auth-input" placeholder='ユーザー名' value={signupState.username} onChange={(e)=>setSignupState({...signupState, username: e.target.value})}/>
                                <input type="text" name="email" className="auth-input"  placeholder='メールアドレス' value={signupState.email} onChange={(e)=>setSignupState({...signupState, email: e.target.value})}/>
                                <input type="password" name="password" className="auth-input" placeholder='パスワード' value={signupState.password} onChange={(e)=>setSignupState({...signupState, password: e.target.value})}/>
                                <input type="password" name="confirmPassword" className="auth-input" placeholder='パスワードをもう一度入力してください' value={signupState.confirmPassword} onChange={(e)=>setSignupState({...signupState, confirmPassword: e.target.value})}/>
                            </div>
                            <div className="auth__form-use">
                                <div>
                                    <input type="checkbox" name="useAndTerms" className="auth-input-agree" value={true} onChange={(e) => setSignupState({...signupState, useAnsTerms: e.target.value})} required/>
                                </div>
                                <p onClick={() => setIsOpenUseModal(true)} className='underline cursor'>利用規約</p>と<p onClick={() => setIsOpenUseModal(true)} className='underline cursor'>プライバシーポリシー</p>に同意します
                            </div>
                            <div className='auth__form-content-signup-btn auth__form-content-btn'>
                                {error.length>0 && <div className='error'>{error}</div>}
                                <button type='submit' className='button'>{loading? <Spinner /> : '作成'}</button>
                            </div>
                        </form>
                        <div className='auth__form-info-flex auth__form-use auth__form-content-signup-center auth__form-info'>
                            <p>アカウントを持っていますか？<span onClick={() => setChangeAuth(false)} className='color-black cursor hover__underline hover__button'>ログイン</span></p>
                        </div>
                    </div>
                  </>

        },
        {
            id: 1,
            title: "法人",
            item: <>
                <MessageModal
                open={isOpenUseModal}
                onClose={() => setIsOpenUseModal(false)}
                >
                    <UseAndTerms/>
                </MessageModal>
                <MessageModal
                open={isOpenPrivacyModal}
                onClose={() => setIsOpenPrivacyModal(false)}
                >
                    <PrivacyPolicy/>
                </MessageModal>
                    <div className="auth__form-content-signup">
                        <form onSubmit={preSignupCompany}>
                            <div className="auth__form-content-input auth__form-content-margin">
                                <input type="text" name="name" className="auth-input" placeholder='会社名' value={signupState.name} onChange={(e)=>setSignupState({...signupState, name: e.target.value})}/>
                                <input type="text" name="username" className="auth-input" placeholder='ユーザー名' value={signupState.username} onChange={(e)=>setSignupState({...signupState, username: e.target.value})}/>
                                <input type="text" name="email" className="auth-input"  placeholder='メールアドレス' value={signupState.email} onChange={(e)=>setSignupState({...signupState, email: e.target.value})}/>
                                <input type="password" name="password" className="auth-input" placeholder='パスワード' value={signupState.password} onChange={(e)=>setSignupState({...signupState, password: e.target.value})}/>
                                <input type="password" name="confirmPassword" className="auth-input" placeholder='パスワードをもう一度入力してください' value={signupState.confirmPassword} onChange={(e)=>setSignupState({...signupState, confirmPassword: e.target.value})}/>
                            </div>
                            <div className="auth__form-use">
                                <div>
                                    <input type="checkbox" name="useAndTerms" className="auth-input-agree" value={true} onChange={(e) => setSignupState({...signupState, useAnsTerms: e.target.value})} required/>
                                </div>
                                <p onClick={() => setIsOpenUseModal(true)} className='underline cursor'>利用規約</p>と<p onClick={() => setIsOpenUseModal(true)} className='underline cursor'>プライバシーポリシー</p>に同意します
                            </div>
                            <div className='auth__form-content-signup-btn auth__form-content-btn'>
                                {error.length>0 && <div className='error'>{error}</div>}
                                <button type='submit' className='button'>{loading? <Spinner /> : '作成'}</button>
                            </div>
                        </form>
                        <div className='auth__form-info-flex auth__form-use auth__form-content-signup-center auth__form-info'>
                            <p>アカウントを持っていますか？<span onClick={() => setChangeAuth(false)} className='color-black cursor hover__underline hover__button'>ログイン</span></p>
                        </div>
                    </div>
                  </>

        }
    ];

    return (
        <>
        {onOpenCode? <SignupConfirmEmail email={signupState.email}/> : 
        <>
            {changeAuth ? 
            <>
            <div className='auth_form-signup'>
                <div className='auth__form-flex'>
                    <div className='auth__form-signup-content'>
                        <div className='auth__form-signup-content-logo'>
                            {/* <img src={logo2} alt=""/> */}
                        </div>
                        <h2>アカウント作成</h2>
                        <TabbarMenu items={items}/>
                    </div>
                    <div className='auth__form-signup-img'>
                        <img src={authImg} alt=""/>
                    </div>
                </div>
            </div>
            </>
            :
            <>
            <div className='auth_form'>
                <div className='auth__form-flex'>
                    <div className='auth__form-img'>
                        <img src={authImg} alt=""/>
                    </div>
                    <div className='auth__form-content'>
                        <div className='auth__form-content-logo'>
                            {/* <img src={logo2} alt=""/> */}
                        </div>
                        <h2>ログイン</h2>
                        <form onSubmit={onSubmitLogin}>
                            <div className='auth__form-content-input'>
                                <input type="email" name='email' className="auth-input" placeholder='メールアドレス'  value={signupState.email} onChange={e=>setSignupState({...signupState, email:e.target.value})} />
                                <input type="password" name='password' className="auth-input" placeholder='パスワード' value={signupState.password} onChange={e=>setSignupState({...signupState, password:e.target.value})} required/>
                            </div>
                            <div className='auth__form-content-btn'>
                                <button type='submit' className='button'>{loading? <Spinner /> : 'ログイン'}</button>
                            </div>
                        </form>
                        {error.length>0 && <div className='error'>{error}</div>}
                        <div className='auth__form-info'>
                            <div className='auth__form-info-center'>
                                <div className='auth__form-change1'>
                                    <p>パスワードを忘れましたか？<Link to={FORGET_PASSWORD}><span className='hover__underline hover__button cursor'>変更する</span></Link></p>
                                </div>
                            </div>
                            <div className='auth__form-border'></div>
                            <div className='auth__form-info-center'>
                                <div className='auth__form-change2'>
                                    <p>アカウントを持っていませんか？<span onClick={() => setChangeAuth(true)} className='color-black cursor hover__underline hover__button'>アカウント作成する</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            }
        </>
        }
        </>
    )
}

export default Login
