import React from 'react';
import ReactDOM from 'react-dom';
import StateContextProvider from './contexts/StateContextProvider';
import IsRoomOpenedContextProvider from './contexts/IsRoomOpenedContextProvider';
import App from './App';
import './styles/base.css';
import './styles/color.css';
import './styles/margin.css';
import './styles/size.css';

ReactDOM.render(
    <StateContextProvider>
        <IsRoomOpenedContextProvider>
            <App/>
        </IsRoomOpenedContextProvider>
    </StateContextProvider>, 
document.querySelector('#root'))