import React, {useState, useEffect} from 'react';
import { Button } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CropIcon from '@material-ui/icons/Crop';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import firebase from 'firebase';

import {useStateValue} from '../../contexts/StateContextProvider';
import db from '../../firebase';
import './index.css';
import postToCloudinary from '../../helpers/postToCloudinary';
import {getInfo} from '../../helpers/getImageDimension';
import {generateAltText} from '../../helpers/generateAltText';
import { useDocumentTitle, useScrollTop } from '../../hooks';
import { AddALT, CropPhotoB, StatusInput } from '../../component';
import { Modal, Spinner, TabbarMenu } from '../../elements';
import { HOME } from '../../constant/router';
import { useHistory } from 'react-router-dom';


const UserPostingBox = () => {
    const [{user}] = useStateValue()
    const [altText, setAltText] = useState(generateAltText(user.displayName))
    const [src, setSrc] = useState(null)
    const [imageToSend, setImageToSend] = useState(null)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [initialImageSize, setinitialImageSize] = useState({width: 0, height: 0})
    const [initialAspectRatio, setinitialAspectRatio] = useState(null)
    const [croppedImageResult, setCroppedImageResult ] = useState(null)
    const [isLoading, setIsloading] = useState(false)
    const history = useHistory();
    const initInput = {
        skills1: "",
        skills2: "",
        skills3: "",
        skills4: "",
        career: "",
        briefIntro: "",
        wantedJob: "",
        canWorkPlace: "",
        kindJob: ""
    }
    const [statusInput, setStatusInput] = useState(initInput);
    useScrollTop();
    useDocumentTitle("ユーザー投稿 / コピペル");

    const sendPost = (e) => {
        e.preventDefault()
        setIsloading(true)
        const {skills1, skills2, skills3, skills4, career, briefIntro, wantedJob, canWorkPlace, kindJob} = statusInput
        if(imageToSend){
            postToCloudinary(imageToSend)
            .then( res=>{
                db.collection('jobs').add({
                    displayName: user.displayName,
                    skills: [skills1, skills2, skills3, skills4],
                    typePost: "userPosting",
                    career: career,
                    briefIntro: '',
                    wantedJob: wantedJob,
                    briefIntroYour: briefIntro,
                    companyName: '',
                    canWorkPlace: canWorkPlace,
                    salary:'',
                    keywordsWord: ["", "", "", ""],
                    altText: altText,
                    jobTitle: '',
                    idDone: false,
                    kindJob: kindJob,
                    workDetails: '',
                    styleWork: '',
                    prefecture: '',
                    otherAddress: '',
                    wanted: '',
                    reqSkill: '',
                    opSkill: '',
                    hireSection: '',
                    grade: '',
                    timeWork: '',
                    welfare: '',
                    different: '',
                    testPeriod: '',
                    offDay: '',
                    bookMark: [],
                    likes: [],
                    image: res,
                    senderId: user.id,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp()
                })
                setStatusInput(initInput);
                setAltText('');
                setSrc(null);
                setIsloading(false);
                history.push(HOME)
                setIsOpenModal(false)
                setTimeout(() => {
                    window.location.reload();
                }, 100)
            })
            .catch( err=> {
                setIsloading(false)
                return
            })

        } else {

        db.collection('jobs').add({
            displayName: user.displayName,
            skills: [skills1, skills2, skills3, skills4],
            typePost: "userPosting",
            searchSkills: [skills1, skills2, skills3, skills4, skills1.toLowerCase(), skills2.toLowerCase(), skills3.toLowerCase(), skills4.toLowerCase(), skills1.substring(0, 1), skills1.substring(0, 2), skills1.substring(0, 3), skills1.substring(0, 4), skills1.substring(0, 5), skills1.substring(0, 6),
                skills2.substring(0, 1), skills2.substring(0, 2), skills2.substring(0, 3), skills2.substring(0, 4), skills2.substring(0, 5), skills2.substring(0, 6),skills3.substring(0, 1), skills3.substring(0, 2), skills3.substring(0, 3), skills3.substring(0, 4), skills3.substring(0, 5), skills3.substring(0, 6),
                skills4.substring(0, 1), skills4.substring(0, 2), skills4.substring(0, 3), skills4.substring(0, 4), skills4.substring(0, 5), skills4.substring(0, 6),
                ],
            career: career,
            briefIntro: '',
            wantedJob: wantedJob,
            briefIntroYour: briefIntro,
            companyName: '',
            salary:'',
            image: '',
            altText: altText,
            canWorkPlace: canWorkPlace,
            jobTitle: '',
            idDone: false,
            kindJob: kindJob,
            keywordsWord: ["", "", "", ""],
            workDetails: '',
            styleWork: '',
            prefecture: '',
            otherAddress: '',
            wanted: '',
            reqSkill: '',
            opSkill: '',
            hireSection: '',
            grade: '',
            timeWork: '',
            welfare: '',
            different: '',
            testPeriod: '',
            offDay: '',
            bookMark: [],
            likes: [],
            senderId: user.id,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
        })
        setStatusInput(initInput);
        setAltText('');
        setSrc(null);
        setIsloading(false);
        history.push(HOME)
        setIsOpenModal(false)
        setTimeout(() => {
            window.location.reload();
        }, 100)
        }
    }

    const onSelectFile = e => {
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            setSrc(fileReader.result)
            setImageToSend(fileReader.result)
        }   
        fileReader.readAsDataURL(e.target.files[0])

        getInfo(e).then(res=> {
            setinitialImageSize({width: res.width, height: res.height})
        })
    }

    useEffect(() => {
        setinitialAspectRatio(initialImageSize.width/initialImageSize.height)
    }, [initialImageSize])

    const changeSrc = () => {
        setSrc(URL.createObjectURL(croppedImageResult))
        setImageToSend(croppedImageResult)
    }

    const callbackforModal = () =>{
        changeSrc()
        if (altText.length === 0){
            setAltText(generateAltText(user.displayName))
        }
    }

    const items = [
        {
            id: 0,
            title:'',
            icon: <CropIcon />,
            item: <CropPhotoB
                    image={src} 
                    setCroppedImageResult ={setCroppedImageResult} 
                    initialAspectRatio    = {initialAspectRatio}
            />
        },
        {
            id: 1,
            title: 'ALT',
            icon: '',
            item: <AddALT image={croppedImageResult} altText={altText} setAltText={setAltText}/>
        }
    ]



    useEffect(() => {
        var textarea = document.querySelector('textarea')
        textarea.addEventListener('keydown', autosize)
                    
        function autosize(){
        var el = this
        setTimeout(function(){
            el.style.cssText = 'height:auto padding:0'
            el.style.cssText = 'height:' + el.scrollHeight + 'px'
        },0)
        }
    }, [])


    return (
        <>

            <Modal  open={isOpenModal} 
                    onClose={()=>setIsOpenModal(false)}
                    title="画像を編集"
                    callback = {callbackforModal}
                    Icon = {ArrowBackOutlinedIcon}
                    ButtonText='保存する'
                    >
                    <TabbarMenu items={items}/>
            </Modal>

            <div className='jobBox'>
                <form onSubmit={sendPost}>
                    <div className='jobBox__wrapperInput' >

                        <div className='jobBox__input'>
                            <div className='userBox__input__content'>
                                <input  type='text' placeholder='スキル(1)' onChange={e => setStatusInput({...statusInput, skills1: e.target.value})} value={statusInput.skills1} maxLength='10'/>
                                <input  type='text' placeholder='スキル(2)' onChange={e => setStatusInput({...statusInput, skills2: e.target.value})} value={statusInput.skills2} maxLength='10'/>
                                <input  type='text' placeholder='スキル(3)' onChange={e => setStatusInput({...statusInput, skills3: e.target.value})} value={statusInput.skills3} maxLength='10'/>
                                <input  type='text' placeholder='スキル(4)' onChange={e => setStatusInput({...statusInput, skills4: e.target.value})}  value={statusInput.skills4} maxLength='10'/>
                            </div>
                            <select className="jobPost__select" onChange={e => setStatusInput({...statusInput, kindJob: e.target.value})} required value={statusInput.kindJob}>
                            <option>選択する</option>
                                {/*営業*/}
                                <option disabled>営業</option>
                                <option value='法人営業'>法人営業</option>
                                <option value='個人営業'>個人営業</option>
                                <option value='内勤営業・カウンターセールス'>内勤営業・カウンターセールス</option>
                                <option value='ルートセールス・代理店営業'>ルートセールス・代理店営業</option>
                                <option value='IT営業'>IT営業</option>
                                <option value='海外営業'>海外営業</option>
                                <option value='不動産営業'>不動産営業</option>
                                <option value='金融営業'>金融営業</option>
                                <option value='テレマーケティング'>テレマーケティング</option>

                                {/*事務・アシスタント*/}
                                <option disabled>事務・アシスタント</option>
                                <option value='一般事務'>一般事務</option>
                                <option value='営業事務'>営業事務</option>
                                <option value='カスタマーサポート'>カスタマーサポート</option>
                                <option value='受付'>受付</option>
                                <option value='秘書'>秘書</option>

                                {/*財務・会計・経理*/}
                                <option value='経理'>経理</option>
                                <option value='財務'>財務</option>
                                <option value='管理会計'>管理会計</option>

                                {/*総務・人事・法務・知財・広報・IR*/}
                                <option disabled>総務・人事・法務・知財・広報・IR</option>
                                <option value='人事（採用）'>人事（採用）</option>
                                <option value='人事（労務）'>人事（労務）</option>
                                <option value='総務'>総務</option>
                                <option value='広報'>広報</option>
                                <option value='法務'>法務</option>

                                {/*商品企画・営業企画・マーケティング・宣伝*/}
                                <option disabled>商品企画・営業企画・マーケティング・宣伝</option>
                                <option value='商品企画、サービス企画'>商品企画、サービス企画</option>
                                <option value='販売促進・PR・広告宣伝'>販売促進・PR・広告宣伝</option>
                                <option value='Webマーケティング'>Webマーケティング</option>

                                {/*経営企画・事業統括・新規事業開発*/}
                                <option disabled>経営企画・事業統括・新規事業開発</option>
                                <option value='経営者・経営幹部・役員(CEO、COO、CFO、CIO、CTO)'>経営者・経営幹部・役員（CEO、COO、CFO、CIO、CTO）</option>

                                {/*管理職・エグゼクティブ*/}
                                <option disabled>管理職・エグゼクティブ</option>
                                <option value='経営企画・営業企画'>経営企画・営業企画</option>
                                <option value='事業企画・新規事業開発'>事業企画・新規事業開発</option>
                                <option value='事業統括マネージャー職（営業部門・管理部門）'>事業統括マネージャー職（営業部門・管理部門）</option>

                                {/*Webエンジニア*/}
                                <option disabled>エンジニア</option>
                                <option value='システムエンジニア（Web・オープン系・パッケージ開発）'>システムエンジニア（Web・オープン系・パッケージ開発）</option>
                                <option value='システムエンジニア（汎用機系）'>システムエンジニア（汎用機系）</option>
                                <option value='プロジェクトマネジャー（Web・オープン系・パッケージ開発）'>プロジェクトマネジャー（Web・オープン系・パッケージ開発）</option>
                                <option value='モバイルアプリ'>モバイルアプリ</option>
                                <option value='フロントエンド'>フロントエンド</option>
                                <option value='バックエンド'>バックエンド</option>
                                <option value='フルスタック'>フルスタック</option>

                                {/*インフラエンジニア*/}
                                <option disabled>インフラエンジニア</option>
                                <option value='インフラエンジニア'>インフラエンジニア</option>
                                <option value='サーバーエンジニア（設計構築）'>サーバーエンジニア（設計構築）</option>
                                <option value='ネットワークエンジニア（設計構築）'>ネットワークエンジニア（設計構築）</option>
                                <option value='データベースエンジニア'>データベースエンジニア</option>
                                <option value='プロジェクトマネージャー（インフラ）'>プロジェクトマネージャー（インフラ）</option>
                                <option value='Webサービスエンジニア（ネットワーク・サーバー・データベース）'>Webサービスエンジニア（ネットワーク・サーバー・データベース）</option>
                                <option value='運用・監視・保守（インフラ）'>運用・監視・保守（インフラ）</option>

                                {/*サポート・ヘルプデスク*/}
                                <option disabled>サポート・ヘルプデスク</option>
                                <option value='サポート・ヘルプデスク'>サポート・ヘルプデスク</option>

                                {/*社内SE（社内情報システム）*/}
                                <option disabled>社内SE（社内情報システム）</option>
                                <option value='社内SE（社内情報システム）'>社内SE（社内情報システム）</option>

                                {/* Creative */}
                                {/*Web・モバイル（制作・開発）*/}
                                <option disabled>Web・モバイル</option>
                                <option value='Webプロデューサー・Webディレクター・Webプランナー'>Webプロデューサー・Webディレクター・Webプランナー</option>
                                <option value='Webデザイナー'>Webデザイナー</option>
                                <option value='Webライター・Web編集（コンテンツ制作）'>Webライター・Web編集（コンテンツ制作）</option>
                                <option value='UI・UXデザイナー（Web・モバイル）'>UI・UXデザイナー（Web・モバイル）</option>
                                <option value='アシスタントディレクター・制作進行管理'>アシスタントディレクター・制作進行管理</option>

                                {/*広告・グラフィック*/}
                                <option disabled>広告・グラフィック</option>
                                <option value='フォトグラファー・カメラマン'>フォトグラファー・カメラマン</option>

                                {/*出版・広告・販促・印刷*/}
                                <option disabled>出版・広告・販促・印刷</option>
                                <option value='イラストレーター【広告・グラフィック関連】'>イラストレーター【広告・グラフィック関連】</option>
                                <option value='DTPオペレーター'>DTPオペレーター</option>
                                <option value='その他広告・グラフィック関連職'>その他広告・グラフィック関連職</option>
                                <option value='編集・記者・ライター'>編集、記者、ライター</option>
                                <option value='デザイナー（グラフィック・その他）'>デザイナー（グラフィック・その他）</option>

                                {/*店舗・販売*/}
                                <option disabled>店舗・販売</option>
                                <option value='店長'>店長</option>
                                <option value='ホールスタッフ'>ホールスタッフ</option>
                                <option value='調理スタッフ'>調理スタッフ</option>

                                {/*美容関連*/}
                                <option disabled>美容関連</option>
                                <option value='美容師・理容師・その他美容関連'>美容師・理容師・その他美容関連</option>
                                <option value='美容部員・エステティシャン・マッサージ・ビューティーアドバイザー'>美容部員・エステティシャン・マッサージ・ビューティーアドバイザー</option>

                                {/*ブライダル・葬祭*/}
                                <option disabled>ブライダル・葬祭</option>
                                <option value='ウェディングプランナー'>ウェディングプランナー</option>
                                <option value='葬祭ディレクター・プランナー'>葬祭ディレクター・プランナー</option>
                                <option value='その他ブライダル・葬祭関連'>その他ブライダル・葬祭関連</option>

                                {/*旅行関連*/}
                                <option disabled>旅行関連</option>
                                <option value='旅行手配員・添乗員・ツアーコンダクター'>旅行手配員・添乗員・ツアーコンダクター</option>

                                {/*宿泊施設・ホテル*/}
                                <option disabled>宿泊施設・ホテル</option>
                                <option value='施設管理・マネジメント'>施設管理・マネジメント</option>
                                <option value='フロント業務・予約受付'>フロント業務・予約受付</option>
                                <option value='その他宿泊施設・ホテル関連'>その他宿泊施設・ホテル関連</option>

                                {/*教育／スクール／研修／塾講師／専門学校／英会話学校*/}
                                <option disabled>教育／スクール／研修／塾講師／専門学校／英会話学校</option>
                                <option value='スクール長・マネジャー'>スクール長・マネジャー</option>
                                <option value='講師・指導員・インストラクター'>講師・指導員・インストラクター</option>

                                {/*建築設計・積算*/}
                                <option disabled>建築設計・積算</option>
                                <option value='意匠設計・構造設計'>意匠設計・構造設計</option>
                                <option value='製図・CADオペレーター'>製図・CADオペレーター</option>
                                <option value='内装設計・インテリア・空間デザイン'>内装設計・インテリア・空間デザイン</option>
                                <option value='積算（建築設計）'>積算（建築設計）</option>
                                <option value='内装設計（店舗・オフィス）'>内装設計（店舗・オフィス）</option>
                                <option value='内装設計・リフォーム・インテリア（住宅）'>内装設計・リフォーム・インテリア（住宅）</option>

                                {/*施工管理・職人・現場作業員*/}
                                <option disabled>施工管理・職人・現場作業員</option>
                                <option value='建築施工管理（RC造・S造・SRC造・木造）'>建築施工管理（RC造・S造・SRC造・木造）</option>
                                <option value='建築施工管理（店舗内装・オフィス内装）'>建築施工管理（店舗内装・オフィス内装） </option>
                                <option value='建築施工管理（住宅内装・リフォーム・インテリア'>建築施工管理（住宅内装・リフォーム・インテリア</option>
                                <option value='設備施工管理（電気・空調・衛生設備）'>設備施工管理（電気・空調・衛生設備） </option>
                                <option value='土木施工管理（トンネル・道路・造成・ダム・河川・港湾・造園など）'>土木施工管理（トンネル・道路・造成・ダム・河川・港湾・造園など）</option>

                                {/*その他建設・建築・不動産・プラント・工場関連職*/}
                                <option disabled>その他建設・建築・不動産・プラント・工場関連職</option>
                                <option value='その他建設・建築・不動産・プラント・工場関連職'>その他建設・建築・不動産・プラント・工場関連職</option>

                                {/*医療・看護*/}
                                <option disabled>医療・看護</option>
                                <option value='病院長・事務長'>病院長・事務長</option>
                                <option value='医師・看護師'>医師・看護師</option>
                                <option value='歯科医師・歯科衛生士・歯科技工士'>歯科医師・歯科衛生士・歯科技工士</option>
                                <option value='臨床心理士・カウンセラー'>臨床心理士・カウンセラー</option>
                                <option value='栄養士・管理栄養士'>栄養士・管理栄養士</option>
                                <option value='その他医療・看護'>その他医療・看護</option>

                                {/*福祉*/}
                                <option disabled>福祉</option>
                                <option value='院長・福祉施設長'>院長・福祉施設長</option>
                                <option value='介護福祉士・ケアマネジャー'>介護福祉士・ケアマネジャー</option>
                                <option value='ホームヘルパー・ケアワーカー'>ホームヘルパー・ケアワーカー</option>

                                {/*その他・専門職*/}
                                <option disabled>その他・専門職</option>
                                <option value='その他・専門職'>その他・専門職</option>
                            </select>
                            <input type='text' placeholder='働ける場所' onChange={e => setStatusInput({...statusInput, canWorkPlace: e.target.value})}  value={statusInput.canWorkPlace} required/>
                            <textarea rows='1' type='text' placeholder='簡単に経歴紹介' value={statusInput.career} onChange={e => setStatusInput({...statusInput, career: e.target.value})} required />
                            
                            <input type='text' placeholder='今 求めている仕事' value={statusInput.wantedJob} onChange={e => setStatusInput({...statusInput, wantedJob: e.target.value})}  required/>
                            <textarea rows='1' 
                                      placeholder="簡単な自己紹介"
                                      type='text' 
                                      value={statusInput.briefIntro}
                                      onChange={e => setStatusInput({...statusInput, briefIntro: e.target.value})} 
                                      required                     
                            >
                            </textarea>
                            {
                                src &&
                                    <div className='jobBox__input-image'>
                                        <CancelIcon className='cancelIcon' onClick={()=>setSrc(null)}/>
                                        <img src={src} alt="new test"/>               
                                        <Button className='editImage' onClick={()=>setIsOpenModal(true)}>編集する</Button>
                                    </div>                        
                            }

                            <div className='jobBox__input-actions'>
                                <div className='jobBox__input-icons'>
                                   <StatusInput Icon='fas fa-image'
                                                type="file"
                                                accept="image/*"
                                                name="image-upload"
                                                id="input-image"
                                                onChange={onSelectFile}
                                    />

                                </div>
                    
                                {
                                    isLoading ?
                                    <Button className='jobBox__button'><Spinner /></Button>
                                    :
                                    <Button type='submit'className='jobBox__button'>投稿する</Button>
                                }

                            </div>
                        </div>    
                    </div>
                </form>
            </div>
        </>
    )
}

export default UserPostingBox;
