import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close'
import './index.css';
import MessageModal from '../../elements/modal/MessageModal';
import logo2 from '../../assets/logo2.png';

const UnAuthTopNav = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const history = useHistory();

    const onAbout = () => {
        setIsOpenModal(false)
        history.push('/about-us')
    }
    const onHelp = () => {
        setIsOpenModal(false)
        history.push('/help-center')
    }
    const onUse = () => {
        setIsOpenModal(false)
        history.push('/use-and-terms')
    }
    const onPrivacy = () => {
        setIsOpenModal(false)
        history.push('/privacy-policy')
    }

    return(
        <>
            <MessageModal
               open={isOpenModal} 
               onClose={()=>setIsOpenModal(false)}
               title="Copipel情報"
               Icon = {CloseIcon}
               >
                <div className="unAuth__topMenu">
                    <div className="unAuth__topBar__content">
                        <button onClick={onAbout}>Copipelとは</button>
                        <button onClick={onHelp}>ヘルプセンター</button>
                        <button onClick={onUse}>利用規約</button>
                        <button onClick={onPrivacy}>プライバシーポリシー</button>
                    </div>
                </div>
            </MessageModal>  
        <div className='unAuth__topBar'>
            <div className='d-flex'>
                <button className='menuBar__btn' onClick={() => setIsOpenModal(true)}><i className='fas fa-bars topBar__menu'/></button>
                <div className='unAuth__topBar__img'>
                    <Link to='/' replace><img src={logo2} alt='Logo'/></Link>
                </div>
                <div className='unAuth__topBar__Auth'>
                    <Link to='/login' replace><button>ログイン</button></Link>
                </div>
            </div>
            <div className='unAuth__topBar__bottom'></div>
        </div>
        </>
    )
}

export default UnAuthTopNav;