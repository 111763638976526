import React from "react";
import { useStateValue } from "../../../contexts/StateContextProvider";
import NotFound from "../../../pages/error/NotFound";
import './index.css';

const ChartPage = () => {
    const [{user}] = useStateValue();
    return(
        <>
        {user.role === "ADMIN" ? 
        <div className="chart__page">
            Chart
        </div>
        :
         <NotFound/>}
        </>
    )
}


export default ChartPage;