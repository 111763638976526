import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import db from "../../firebase";
import { AuthCompanyProfile, AuthUserProfile, CompanyProfileTopBar, UserProfileTopBar } from "../../component";
import './index.css'
import NonUser from "../error/NonUser";
import { useStateValue } from "../../contexts/StateContextProvider";
import { ADMIN__DASHBOARD } from "../../constant/router";

const AuthUserProfilePage = () => {
    const {username} = useParams();
    const history = useHistory(); 
    const [profile, setProfile] = useState([])
    useEffect(() => {
        if(!profile.length){
            const getUser = db.collection('users').where('username', '==', username).onSnapshot(snapshot=>{
                setProfile(snapshot.docs.map(doc=>({
                  id: doc.id,
                  ...doc.data()
                }))[0])
              })
        return () => getUser();
        }
      }, [])

      const [copySuccess, setCopySuccess] = useState('');
      const onCopyBtn = (e) => {
        navigator.clipboard.writeText(window.location.href)
        setCopySuccess('copied');
      }
    const [{user}] = useStateValue()
    return(  
        <>
        {profile ? 
            <div className='feed'>
                <div className="profile__header d-flex">
                    <div className="profile__backArrow" onClick={()=>history.goBack()}>
                        <ArrowBackOutlinedIcon />
                    </div>
                    <div className='profile__title'>
                        <div className='profile__title_title'><h2>{profile && profile.displayName}</h2>{profile.role === "COMPANY" ? <VerifiedUserIcon className='post__badge'/>: <></>}</div>      
                    </div>
                    <div className="profile__top__link">
                        <button className="profile__top__link-pc" onClick={onCopyBtn}>
                            {copySuccess !== 'copied' ? <p>URLをコピーする<i className="fas fa-check-circle check-icon"/></p> : <p>URLをコピーしました<i className="fas fa-check-circle check-icon check-icon-success"/></p>}
                        </button>
                        <button className="profile__top__link-mobile" onClick={onCopyBtn}>
                            {copySuccess !== 'copied' ? <p>URLをコピー<i className="fas fa-check-circle check-icon"/></p> : <p>コピーしました<i className="fas fa-check-circle check-icon check-icon-success"/></p>}
                        </button>
                    </div>   
                </div>
                {profile && profile.role === "USER" ? <UserProfileTopBar/> : <CompanyProfileTopBar/>}

                {profile && profile.published ?
                 <>
                 {profile && profile.role === "USER" ? <AuthUserProfile/> : <AuthCompanyProfile/>}
                 </>
                :
                <>
                <h4>現在、このアカウントは非公開になっています</h4>
                </>
                }

                {user.role === 'ADMIN' ? 
               <Link to={ADMIN__DASHBOARD}><button>Admin Dashboard</button></Link>    
                : <></>}
                
            </div>
            :
        <NonUser/>
        }
        </>
    )
}

export default AuthUserProfilePage;

