import React from "react";
import CompanyDashboardNav from "../../component/mobileNav/CompanyDashboardNav";
import { useStateValue } from "../../contexts/StateContextProvider";
import { useDocumentTitle, useScrollTop } from "../../hooks";
import NotFound from "../error/NotFound";
import './index.css';


const CompanyDashboardContact = () => {
    useScrollTop();
    useDocumentTitle("お問い合わせ / Copipel");
    const [{user}] = useStateValue();
    return(
    <>
    <CompanyDashboardNav/>
    {user.role === 'COMPANY' ? 
        <div className="company__dashboard-contact">
            <div className="company__dashboard-contact-content">
                <h2><small>名前: </small><strong></strong></h2>
                <h2><small>メールアドレス: </small><strong></strong></h2>
                <h2><small>電話番号: </small><strong></strong></h2>
            </div>
        </div>
        : <NotFound/>
        }
    </>
    )
}

export default CompanyDashboardContact;