import React, {useState, useEffect} from 'react';
import { TextField } from '@material-ui/core';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';

import { getInfo } from '../../helpers/getImageDimension';
import CropPhotoB from '../editPhoto/CropPhotoB';
import StatusInput from '../statusInput/StatusInput';
import Modal from '../../elements/modal/Modal';
import TabbarMenu from '../../elements/tabbarMenu/TabbarMenu';
import './index.css';

const EditBasicProfile = ({profile, setUpdatedProfileState}) => {
    const {displayName, photoURL, twitterURL, instagramURL, facebookURL, lineURL, websiteURL1, websiteURL2, websiteURL3, youtubeURL, briefIntro} = profile
    const [profileState, setProfileState] = useState({
        displayName,
        twitterURL: twitterURL?twitterURL: '',
        instagramURL: instagramURL?instagramURL: '',
        facebookURL: facebookURL?facebookURL: '',
        lineURL: lineURL?lineURL: '',
        youtubeURL: youtubeURL?youtubeURL: '',
        websiteURL1: websiteURL1?websiteURL1: '',
        websiteURL2: websiteURL2?websiteURL2: '',
        websiteURL3: websiteURL3?websiteURL3: '',
        briefIntro: briefIntro?briefIntro: ''
    })
    const [src, setSrc] = useState({
        photoSrc: photoURL,
    })
    const [pictureToSend, setPictureToSend] = useState({
        photoToSend: photoURL,
    })
    const [croppedImageResult, setCroppedImageResult ] = useState(null)
    const [initialImageSize, setinitialImageSize] = useState({
        photo: {width:0, height:0},
    })
    const [initialAspectRatio, setinitialAspectRatio] = useState({
        photo: null,
    })

    const [isOpenModal, setIsOpenModal] = useState(false)    
    const [activeModal, setActiveModal] = useState('')

    const onSelectFile = (e, kind)=> {
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            if(kind === 'photo'){
                setSrc({ ...src, photoSrc: fileReader.result})
                setPictureToSend({ ...pictureToSend, photoToSend: fileReader.result})
                setActiveModal('photo')
            }
            setIsOpenModal(true)
        }   
        fileReader.readAsDataURL(e.target.files[0])

        getInfo(e).then(res=> {
            if(kind === 'photo'){
                setinitialImageSize({...initialImageSize, photo:{width: res.width, height: res.height}})
            }         
        })
    }   

    useEffect(() => {
        setinitialAspectRatio({
            ...initialAspectRatio,
            photo: initialImageSize.photo.width/initialImageSize.photo.height,

        })
    }, [initialImageSize])


    const changeSrc = () => {
        if(activeModal === 'photo'){
            setSrc({
                ...src,
                photoSrc: URL.createObjectURL(croppedImageResult)
            })
            setPictureToSend({...pictureToSend, photoToSend: croppedImageResult})
            
        }
    }

    const callbackforModal = () =>{
        changeSrc()
    }

    const closeModal = () => {
        setIsOpenModal(false)
        setActiveModal('')
    }

    const items = [
        {
            id: 0,
            title:'',
            item:  <CropPhotoB
                    image = {activeModal==='wallpaper'?src.wallpaperSrc:src.photoSrc}
                    setCroppedImageResult ={setCroppedImageResult} 
                    initialAspectRatio    = {activeModal==='wallpaper'?initialAspectRatio.wallpaper:initialAspectRatio.photo}
                />
        }
    ]

    useEffect(() => {
        setUpdatedProfileState({profileState, pictureToSend})
    }, [profileState, pictureToSend])

    return (
        <>
            <Modal  open={isOpenModal} 
                    onClose={closeModal}
                    title="画像編集"
                    callback = {callbackforModal}
                    Icon = {ArrowBackOutlinedIcon}
                    ButtonText='切り抜く'
                    >
                    <TabbarMenu items={items}/>
            </Modal>   

            <div className='editProfile'>
                <div className="editProfile__theme-photoWrapper">
                    {src.photoSrc && <img src={src.photoSrc} alt={`${displayName}`} />}
                    <div className="editProfile__btnWrapper">
                        <StatusInput Icon={AddAPhotoOutlinedIcon}
                                    type="file"
                                    accept="image/*"
                                    name="image-upload"
                                    id="input-wallpaper"
                                    onChange={(e)=>{onSelectFile(e, 'photo')
                                                    setActiveModal('photo')
                        }}
                        />
                    </div>
                </div>   
                <form noValidate autoComplete="off">
                    <TextField id="displayName" label="お名前" variant="filled" value={profileState.displayName} onChange={e=>setProfileState({...profileState, displayName:e.target.value})}/>
                    <TextField id="briefIntro" label="簡単な自身の説明" multiline rows={10} variant="filled" value={profileState.briefIntro} onChange={e=>setProfileState({...profileState, briefIntro:e.target.value})} className="text__box"/>
                    <TextField id="twitterURL" label="Twitter URL" variant="filled" value={profileState.twitterURL} onChange={e=>setProfileState({...profileState, twitterURL:e.target.value})}/>
                    <TextField id="instagramURL" label="Instagram URL" variant="filled" value={profileState.instagramURL} onChange={e=>setProfileState({...profileState, instagramURL:e.target.value})}/>
                    <TextField id="facebookURL" label="Facebook URL" variant="filled" value={profileState.facebookURL} onChange={e=>setProfileState({...profileState, facebookURL:e.target.value})}/>
                    <TextField id="lineURL" label="Line URL" variant="filled" value={profileState.lineURL} onChange={e=>setProfileState({...profileState, lineURL:e.target.value})}/>
                    <TextField id="youtubeURL" label="Yotube URL" variant="filled" value={profileState.youtubeURL} onChange={e=>setProfileState({...profileState, youtubeURL:e.target.value})}/>
                    <TextField id="websiteURL1" label="ウェブサイト 1" variant="filled" value={profileState.websiteURL1} onChange={e=>setProfileState({...profileState, websiteURL1:e.target.value})}/>
                    <TextField id="websiteURL2" label="ウェブサイト 2" variant="filled" value={profileState.websiteURL2} onChange={e=>setProfileState({...profileState, websiteURL2:e.target.value})}/>
                    <TextField id="websiteURL3" label="ウェブサイト 3" variant="filled" value={profileState.websiteURL3} onChange={e=>setProfileState({...profileState, websiteURL3:e.target.value})}/>
                </form>

            </div>

        </>
    )
}

export default EditBasicProfile

