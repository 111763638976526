import React from 'react';
import { NavLink } from 'react-router-dom';
import './index.css';

const SidebarOptions = ({active, text, Icon, onClick, url}) => {

    const isHome = text === 'ホーム' 
    return (
        <NavLink to={`${url}`} exact={isHome} className='sidebarOption cursor' activeClassName='sidebar__active' onClick={onClick} replace>
           <i className={`${Icon} sidebarOptionIcon`} />
           <h2>{text}</h2>
        </NavLink>
    )
}

export default SidebarOptions;