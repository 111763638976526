import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useStateValue } from '../../contexts/StateContextProvider';
import db from '../../firebase';
import './index.css';

const SidebarOptionNotification = ({active, text, Icon, onClick, url}) => {
    const [{user}] = useStateValue()

    const isHome = text === 'ホーム' 
    const [notification, setNotification] = useState([]);
    useEffect(() => {
        if(!notification.length){
            const getNoti = db.collection('notification').where('userId', '==', user.id).where('read', '==', 'false')
            .onSnapshot(snapshot => setNotification(snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))))
    
            return () => getNoti();
        }
    }, [])

    return (
        <div>
            <NavLink to={`${url}`} exact={isHome} className='sidebarOption cursor' activeClassName='sidebar__active' onClick={onClick} replace>
            <i className={`${Icon} sidebarOptionIcon`} />
            <h2>{text}</h2>{notification.length > 0 ? <div className='sidebar__unread'><h3>{notification.length}</h3></div> : <></>}
            </NavLink>
        </div>
    )
}

export default SidebarOptionNotification;