//User
export const LOGIN = '/login';
export const LOGIN__EMAIL = '/confirm';
export const EMAIL__CONFORMATION = '/code';
export const LOG__OUT = '/logout';
export const HOME = '/';
export const SEARCH = '/search';
export const ABOUT__US ='/about-us';
export const USE__AND__TERMS = '/use-and-terms';
export const PRIVACY__POLICY ='/privacy-policy';
export const HELP__CENTER ='/help-center';
export const HOW__TO__USE = '/how-to-use';
export const FORGET_PASSWORD = '/forget-password';
export const CHANGE__PASSWORD = '/change-password';
export const NOTIFICATION = '/notification';
export const SETTINGS = '/settings';
export const MESSAGE = '/messages';
export const USER__LIST ='/user-list';
export const DELETE__ACCOUNT = '/delete-account';

//Search
export const SEARCH__USER = '/search/users';
export const SEARCH__EVENT = '/search/events';
export const SEARCH__FREELANCE = '/search/freelances';
export const SEARCH__USER__POST = '/search/user-posts';
export const SEARCH__JOBS = '/search/jobs';
export const SEARCH__WORDS = '/search/words';

//TimeLine
// export const FREELANCE__TIMELINE = '/timeline/freelances';
export const USER__TIMELINE = '/timeline/user-post';
export const EVENT__TIMELINE = '/timeline/events';
export const FOLLOWING__TIMELINE = '/timeline/following-posts';
//Jobs
export const JOB__TIMELINE ='/timeline/jobs';
export const JOB__EMPLOYEE__TIMELINE ='/timeline/jobs/employee';
export const JOB__FREELANCE__TIMELINE ='/timeline/jobs/freelance';
export const JOB__INTERN__TIMELINE ='/timeline/jobs/intern';
export const JOB__OUTSOURSING__TIMELINE ='/timeline/jobs/outsoursing';
export const JOB__PARTTIME__TIMELINE ='/timeline/jobs/part-time';
export const JOB__NEWGRADUATION__TIMELINE = '/timeline/jobs/new-graduation';
export const JOB__CONTRACT__EMPLOYEE__TIMELINE = '/timeline/jobs/contract-employee';

//Office
export const JOB__CUSTOMER__OFFICE__TIMELINE ='/timeline/jobs/office/customer-support';
export const JOB__GENERAL__OFFICE__TIMELINE ='/timeline/jobs/office/general';
export const JOB__RECEIPT__OFFICE__TIMELINE ='/timeline/jobs/office/receipt';
export const JOB__SALES__OFFICE__TIMELINE ='/timeline/jobs/office/sales';
export const JOB__SECRETARY__OFFICE__TIMELINE ='/timeline/jobs/office/secretary';

//Engineer
export const JOB__WEB__ENGINEER__TIMELINE = '/timeline/jobs/engineer/web';
export const JOB__MOBILE__APP__ENGINEER__TIMELINE = '/timeline/jobs/engineer/mobile-app';
export const JOB__WEBFRONTEND__ENGINEER__TIMELINE = '/timeline/jobs/engineer/frontend';
export const JOB__WEBBACKEND__ENGINEER__TIMELINE = '/timeline/jobs/engineer/backend';
export const JOB__WEBFULLSTACK__ENGINEER__TIMELINE = '/timeline/jobs/engineer/fullstack';
export const JOB__WEBPM__ENGINEER__TIMELINE = '/timeline/jobs/engineer/web/pm';

//Infra
export const JOB__INFRA__ENGINEER__TIMELINE = '/timeline/jobs/engineer/infra';
export const JOB__INFRA__SERVER__ENGINEER__TIMELINE = '/timeline/jobs/engineer/infra/server';
export const JOB__INFRA__NETWORK__TIMELINE = '/timeline/jobs/engineer/infra/network';
export const JOB__INFRA__DATABASE__ENGINEER__TIMELINE = '/timeline/jobs/engineer/infra/database';
export const JOB__INFRA__PM__ENGINEER__TIMELINE = '/timeline/jobs/engineer/infra/pm';
export const JOB__INFRA__WEBSERVICE__ENGINEER__TIMELINE = '/timeline/jobs/engineer/infra/web-service';
export const JOB__INFRA__MAINTAIN__ENGINEER__TIMELINE = '/timeline/jobs/engineer/infra/maintain';

//Support - SE
export const JOB__SUPPORT__ENGINEER__TIMELINE = '/timeline/jobs/engineer/support';
export const JOB__INCOMPANY__SE__ENGINEER__TIMELINE = '/timeline/jobs/engineer/in-company-se';

//Creative
export const JOB__WEBDESIGNER__CREATIVE__TIMELINE = '/timeline/jobs/creative/web-desiner';
export const JOB__WEBDIRECTOR__CREATIVE__TIMELINE = '/timeline/jobs/creative/web-director';
export const JOB__WEBWRITER__CREATIVE__TIMELINE = '/timeline/jobs/creative/web-writer';
export const JOB__UIUX__CREATIVE__TIMELINE = '/timeline/jobs/creative/ui-ux';
export const JOB__ASSISTDIRECTOR__CREATIVE__TIMELINE = '/timeline/jobs/creative/assist-director';
export const JOB__PHOTOGRAPHER__CREATIVE__TIMELINE = '/timeline/jobs/creative/photographer';
export const JOB__ILLUSTRATOR__CREATIVE__TIMELINE = '/timeline/jobs/creative/illustrator';
export const JOB__DTP__OPERATOR__CREATIVE__TIMELINE = '/timeline/jobs/creative/dtp-operator';
export const JOB__WRITER__CREATIVE__TIMELINE = '/timeline/jobs/creative/writer';
export const JOB__DESIGNER__CREATIVE__TIMELINE = '/timeline/jobs/creative/designer';

//Sales
export const JOB__AGENCY__SALES__TIMELINE ='/timeline/jobs/sales/agency';
export const JOB__CORPORATE__SALES__TIMELINE ='/timeline/jobs/sales/corporate';
export const JOB__FINANCE__SALES__TIMELINE ='/timeline/jobs/sales/finance';
export const JOB__FOREIGN__SALES__TIMELINE ='/timeline/jobs/sales/foreign';
export const JOB__INHOUSE__SALES__TIMELINE ='/timeline/jobs/sales/in-house';
export const JOB__IT__SALES__TIMELINE ='/timeline/jobs/sales/it';
export const JOB__PERSONAL__SALES__TIMELINE ='/timeline/jobs/sales/personal';
export const JOB__REALSTATE__SALES__TIMELINE ='/timeline/jobs/sales/real-state';
export const JOB__TELEPHONE__SALES__TIMELINE ='/timeline/jobs/sales/telephone';

//Service
export const JOB__CEREMONIAL__SERVICE__TIMELINE ='/timeline/jobs/service/ceremonial';
export const JOB__COSMETIC__SERVICE__TIMELINE ='/timeline/jobs/service/cosmetic';
export const JOB__EDUCATION__SERVICE__TIMELINE ='/timeline/jobs/service/education';
export const JOB__HOTELS__SERVICE__TIMELINE ='/timeline/jobs/service/hotels';
export const JOB__STORE__SERVICE__TIMELINE ='/timeline/jobs/service/store';
export const JOB__TRAVEL__SERVICE__TIMELINE ='/timeline/jobs/service/travel';

//Other - Keyword
export const NEAR__STATION__TIMELINE = '/timeline/near-station';
export const TRANSPORTATION__PAID__TIMELINE = '/timeline/transportaion-fee-paid';
export const REMOTE__TIMELINE = '/timeline/remote';
export const TWO__DAYS__OFF__TIMELINE = '/timeline/two-days-off';
export const TWO__THREE__OK__TIMELINE = '/timeline/two-three-ok';
export const ONE__OK__TIMELINE = '/timeline/one-ok';
export const UNIVERSITY__STUDENT__OK = '/timeline/university-student-ok';
export const HIGHSCHOOL__STUDENT__OK = '/timeline/highschool-student-ok';
export const SIDE__BUSINESS__TIMELINE = '/timeline/side-business-ok';
export const NO__EXPERIENCE__TIMELINE = '/timeline/no-experience-ok';
export const WELCOME__EXPERIENCE__TIMELINE = '/timeline/welcome-experience';
export const EDUCATIONAL__BACKGOUND__NOT__TIMELINE = '/timeline/no-educational-background';
export const MATERNITY__CHILDCARE__LEAVE__TIMELINE = '/timeline/maternity-childcare-leave';
export const WITH__TRAINING__TIMELINE = '/timeline/with-training';
export const QUALIFICATION__ALLOWANCE__TIMELINE = '/timeline/qualification-allowance';
export const FAMILY__ALLOWANCE__TIMELINE = '/timeline/family-allowance';
export const INSENTIVE__TIMELINE = '/timeline/incentive';
export const SOCIAL__INSURANCE__TIMELINE = '/timeline/social-insurance';
export const CHILDCARE__SUPPORT__TIMELINE = '/timeline/childcare-support';
export const URGENT__RECRUITMENT__TIMELINE = '/timeline/urgent-recruitment';
export const WEB__INTERVIEW__OK__TIMELINE = '/timeline/web-interview-ok';
export const BENEFITS__TIMELINE = '/timeline/benefits';
export const SOME__REMOTE__TIMELINE = '/timeline/some-remote';
export const ANY__CLOTHES__TIMELINE = '/timeline/any-clothes';
export const DAILY__WEEKLY__OK__TIMELINE = '/timeline/daily-weekly-pay';
export const SHORT__TIME__TIMELINE = '/timeline/short-time';
export const DORM__HOUSINGALLOWANCE__TIMELINE = '/timeline/dorm-companyhousing-housingallowance';
export const PLOMOTION__TO__EMPLOYEE = '/timeline/promotion-to-employee';

//Prefecture
export const REMOTE__PREFECTURE__TIMELINE = '/timeline/prefecture/remote';
export const HOKKAIDO__PREFECTURE__TIMELINE = '/timeline/prefecture/hokkaido';
export const AOMORI__PREFECTURE__TIMELINE = '/timeline/prefecture/aomori';
export const IWATE__PREFECTURE__TIMELINE = '/timeline/prefecture/iwate';
export const MIYAGI__PREFECTURE__TIMELINE = '/timeline/prefecture/miyagi';
export const AKITA__PREFECTURE__TIMELINE = '/timeline/prefecture/akita';
export const YAMAGATA__PREFECTURE__TIMELINE = '/timeline/prefecture/yamagata';
export const HUKUSIMA__PREFECTURE__TIMELINE = '/timeline/prefecture/hukusima';
export const IBARAKI__PREFECTURE__TIMELINE = '/timeline/prefecture/ibaraki';
export const TOTIGI__PREFECTURE__TIMELINE = '/timeline/prefecture/totigi';
export const GUNMA__PREFECTURE__TIMELINE = '/timeline/prefecture/gunma';
export const SAITAMA__PREFECTURE__TIMELINE = '/timeline/prefecture/saitama';
export const TIBA__PREFECTURE__TIMELINE = '/timeline/prefecture/tiba';
export const TOKYO__PREFECTURE__TIMELINE = '/timeline/prefecture/tokyo';
export const KANAGAWA__PREFECTURE__TIMELINE = '/timeline/prefecture/kanagawa';
export const NIGATA__PREFECTURE__TIMELINE = '/timeline/prefecture/nigata';
export const TOYAMA__PREFECTURE__TIMELINE = '/timeline/prefecture/toyama';
export const ISHIKAWA__PREFECTURE__TIMELINE = '/timeline/prefecture/ishikawa';
export const HUKUI__PREFECTURE__TIMELINE = '/timeline/prefecture/hukui';
export const YAMANASHI__PREFECTURE__TIMELINE = '/timeline/prefecture/yamanashi';
export const NAGANO__PREFECTURE__TIMELINE = '/timeline/prefecture/nagano';
export const GIHU__PREFECTURE__TIMELINE = '/timeline/prefecture/gihu';
export const SHIZUOKA__PREFECTURE__TIMELINE = '/timeline/prefecture/shizuoka';
export const AITI__PREFECTURE__TIMELINE = '/timeline/prefecture/aiti';
export const MIE__PREFECTURE__TIMELINE = '/timeline/prefecture/mie';
export const SHIGA__PREFECTURE__TIMELINE = '/timeline/prefecture/shiga';
export const KYOTO__PREFECTURE__TIMELINE = '/timeline/prefecture/kyoto';
export const OSAKA__PREFECTURE__TIMELINE = '/timeline/prefecture/osaka';
export const HYOGO__PREFECTURE__TIMELINE = '/timeline/prefecture/hyogo';
export const NARA__PREFECTURE__TIMELINE = '/timeline/prefecture/nara';
export const WAKAYAMA__PREFECTURE__TIMELINE = '/timeline/prefecture/wakayama';
export const TOTTORI__PREFECTURE__TIMELINE = '/timeline/prefecture/tottori';
export const SHIMANE__PREFECTURE__TIMELINE = '/timeline/prefecture/shimane';
export const OKAYAMA__PREFECTURE__TIMELINE = '/timeline/prefecture/okayama';
export const HIROSHIMA__PREFECTURE__TIMELINE = '/timeline/prefecture/hiroshima';
export const YAMAGUTI__PREFECTURE__TIMELINE = '/timeline/prefecture/yamaguti';
export const TOKUSHIMA__PREFECTURE__TIMELINE = '/timeline/prefecture/tokushima';
export const KAGAWA__PREFECTURE__TIMELINE = '/timeline/prefecture/kagawa';
export const EHIME__PREFECTURE__TIMELINE = '/timeline/prefecture/ehime';
export const KOUTI__PREFECTURE__TIMELINE = '/timeline/prefecture/kouti';
export const HUKUOKA__PREFECTURE__TIMELINE = '/timeline/prefecture/hukuoka';
export const SAGA__PREFECTURE__TIMELINE = '/timeline/prefecture/saga';
export const NAGASAKI__PREFECTURE__TIMELINE = '/timeline/prefecture/nagasaki';
export const KUMAMOTO__PREFECTURE__TIMELINE = '/timeline/prefecture/kumamoto';
export const OITA__PREFECTURE__TIMELINE = '/timeline/prefecture/oita';
export const MIYAZAKI__PREFECTURE__TIMELINE = '/timeline/prefecture/miyazaki';
export const KAGOSHIMA__PREFECTURE__TIMELINE = '/timeline/prefecture/kagoshima';
export const OKINAWA__PREFECTURE__TIMELINE = '/timeline/prefecture/okinawa';


//Company
export const COMPANY__DASHBOARD = '/company/dashboard';
export const COMPANY__DASHBOARD__POST = '/company/post';
export const COMPANY__DASHBOARD__ALLPOST = '/company/all-post';
export const COMPANY__DASHBOARD__MANAGEPOST = '/company/manage-post';
export const COMPANY__DASHBOARD__CONTACT = '/company/contact';
export const COMPANY__DASHBOARD__SEARCH = '/company/search';
export const COMPANY__LOGIN = '/company/login';


//Admin
export const ADMIN__LOGIN = '/admin/login';
export const ADMIN__GET__USER = '/admin/user';
export const ADMIN__GET__COMPANY = '/admin/company';
export const ADMIN__GET__POST = '/admin/search-post';
export const ADMIN__DELETE__ACCOUNT = '/admin/delete-account';
export const ADMIN__DASHBOARD = '/admin/dashboard';
export const ADMIN__HOME = '/admin/home';
export const ADMIN__CHART = '/admin/chart';