import React from 'react';
import { youtubeShare } from '../../assets';


const YoputubeClickInfo = () => {
    return(
        <div className='youtube__click__info__page'>
            <div className='youtube__details'>
                <img src={youtubeShare} alt="Youtube Share"/>
                <ol>
                    <li>Youtubeの共有ボタンをクリックします。</li>
                    <li>次に、上記の<strong>赤い文字</strong>の箇所だけをコピーします。</li>
                    <li>Youtube URLのフィールドに貼り付けると完了です。</li>
                </ol>
            </div>
        </div>
    )
}

export default YoputubeClickInfo;