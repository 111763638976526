import React from "react";
import './index.css';


const CompanyList = () => {
    return(
        <div className="user__list">
            
        </div>
    )
}


export default CompanyList;