import React, {useState, useEffect} from 'react';
import { TextField } from '@material-ui/core';
import './index.css';

const EditCareerProfile = ({profile, setUpdatedProfileState}) => {
    const { careerYear1, careerYear2, careerYear3, careerYear4, careerYear5, careerYear6, careerYear7, careerYear8, careerYear9, careerYear10,
            careerMonth1, careerMonth2, careerMonth3, careerMonth4, careerMonth5, careerMonth6, careerMonth7, careerMonth8, careerMonth9, careerMonth10,
            careerContent1, careerContent2, careerContent3, careerContent4, careerContent5, careerContent6, careerContent7, careerContent8, careerContent9, careerContent10,
            careerTitle1, careerTitle2, careerTitle3, careerTitle4, careerTitle5, careerTitle6, careerTitle7, careerTitle8, careerTitle9, careerTitle10
            } = profile
    const [profileState, setProfileState] = useState({
        careerYear1: careerYear1?careerYear1: '',
        careerYear2: careerYear2?careerYear2: '',
        careerYear3: careerYear3?careerYear3: '',
        careerYear4: careerYear4?careerYear4: '',
        careerYear5: careerYear5?careerYear5: '',
        careerYear6: careerYear6?careerYear6: '',
        careerYear7: careerYear7?careerYear7: '',
        careerYear8: careerYear8?careerYear8: '',
        careerYear9: careerYear9?careerYear9: '',
        careerYear10: careerYear10?careerYear10: '',
        careerMonth1: careerMonth1?careerMonth1: '',
        careerMonth2: careerMonth2?careerMonth2: '',
        careerMonth3: careerMonth3?careerMonth3: '',
        careerMonth4: careerMonth4?careerMonth4: '',
        careerMonth5: careerMonth5?careerMonth5: '',
        careerMonth6: careerMonth6?careerMonth6: '',
        careerMonth7: careerMonth7?careerMonth7: '',
        careerMonth8: careerMonth8?careerMonth8: '',
        careerMonth9: careerMonth9?careerMonth9: '',
        careerMonth10: careerMonth10?careerMonth10: '',
        careerContent1: careerContent1?careerContent1: '',
        careerContent2: careerContent2?careerContent2: '',
        careerContent3: careerContent3?careerContent3: '',
        careerContent4: careerContent4?careerContent4: '',
        careerContent5: careerContent5?careerContent5: '',
        careerContent6: careerContent6?careerContent6: '',
        careerContent7: careerContent7?careerContent7: '',
        careerContent8: careerContent8?careerContent8: '',
        careerContent9: careerContent9?careerContent9: '',
        careerContent10: careerContent10?careerContent10: '',
        careerTitle1: careerTitle1?careerTitle1: '',
        careerTitle2: careerTitle2?careerTitle2: '',
        careerTitle3: careerTitle3?careerTitle3: '',
        careerTitle4: careerTitle4?careerTitle4: '',
        careerTitle5: careerTitle5?careerTitle5: '',
        careerTitle6: careerTitle6?careerTitle6: '',
        careerTitle7: careerTitle7?careerTitle7: '',
        careerTitle8: careerTitle8?careerTitle8: '',
        careerTitle9: careerTitle9?careerTitle9: '',
        careerTitle10: careerTitle10?careerTitle10: ''

    })



    useEffect(() => {
        setUpdatedProfileState({profileState})
    }, [profileState])

    return (
        <>

            <div className='editProfile'>
                <p>10個まで記入することができます</p>
                <form noValidate autoComplete="off">
                    <div className='d-flex'>
                        <TextField id="careerYear1" label="年(1)" variant="filled" value={profileState.careerYear1} onChange={e=>setProfileState({...profileState, careerYear1:e.target.value})}/>
                        <TextField id="careerMonth1" label="月(1)" variant="filled" value={profileState.careerMonth1} onChange={e=>setProfileState({...profileState, careerMonth1:e.target.value})}/>
                        <TextField id="careerTitle1" label="タイトル(1)" variant="filled" value={profileState.careerTitle1} onChange={e=>setProfileState({...profileState, careerTitle1:e.target.value})}/>
                    </div>
                    <TextField multiline rows={5} id="careerContent1" label="内容(1)" variant="filled" value={profileState.careerContent1} onChange={e=>setProfileState({...profileState, careerContent1:e.target.value})} className="text__box"/>
                    <div className='d-flex'>
                        <TextField id="careerYear2" label="年(2)" variant="filled" value={profileState.careerYear2} onChange={e=>setProfileState({...profileState, careerYear2:e.target.value})}/>
                        <TextField id="careerMonth2" label="月(2)" variant="filled" value={profileState.careerMonth2} onChange={e=>setProfileState({...profileState, careerMonth2:e.target.value})}/>
                        <TextField id="careerTitle2" label="タイトル(2)" variant="filled" value={profileState.careerTitle2} onChange={e=>setProfileState({...profileState, careerTitle2:e.target.value})}/>
                    </div>
                    <TextField multiline rows={5} id="careerContent2" label="内容(2)" variant="filled" value={profileState.careerContent2} onChange={e=>setProfileState({...profileState, careerContent2:e.target.value})} className="text__box"/>
                    <div className='d-flex'>
                        <TextField id="careerYear3" label="年(3)" variant="filled" value={profileState.careerYear3} onChange={e=>setProfileState({...profileState, careerYear3:e.target.value})}/>
                        <TextField id="careerMonth3" label="月(3)" variant="filled" value={profileState.careerMonth3} onChange={e=>setProfileState({...profileState, careerMonth3:e.target.value})}/>
                        <TextField id="careerTitle3" label="タイトル(3)" variant="filled" value={profileState.careerTitle3} onChange={e=>setProfileState({...profileState, careerTitle3:e.target.value})}/>
                    </div>
                    <TextField multiline rows={5} id="careerContent3" label="内容(3)" variant="filled" value={profileState.careerContent3} onChange={e=>setProfileState({...profileState, careerContent3:e.target.value})} className="text__box"/>
                    <div className='d-flex'>
                        <TextField id="careerYear4" label="年(4)" variant="filled" value={profileState.careerYear4} onChange={e=>setProfileState({...profileState, careerYear4:e.target.value})}/>
                        <TextField id="careerMonth4" label="月(4)" variant="filled" value={profileState.careerMonth4} onChange={e=>setProfileState({...profileState, careerMonth4:e.target.value})}/>
                        <TextField id="careerTitle4" label="タイトル(4)" variant="filled" value={profileState.careerTitle4} onChange={e=>setProfileState({...profileState, careerTitle4:e.target.value})}/>
                    </div>
                    <TextField multiline rows={5} id="careerContent4" label="内容(4)" variant="filled" value={profileState.careerContent4} onChange={e=>setProfileState({...profileState, careerContent4:e.target.value})} className="text__box"/>
                    <div className='d-flex'>
                        <TextField id="careerYear5" label="年(5)" variant="filled" value={profileState.careerYear5} onChange={e=>setProfileState({...profileState, careerYear5:e.target.value})}/>
                        <TextField id="careerMonth5" label="月(5)" variant="filled" value={profileState.careerMonth5} onChange={e=>setProfileState({...profileState, careerMonth5:e.target.value})}/>
                        <TextField id="careerTitle5" label="タイトル(5)" variant="filled" value={profileState.careerTitle5} onChange={e=>setProfileState({...profileState, careerTitle5:e.target.value})}/>
                    </div>
                    <TextField multiline rows={5} id="careerContent5" label="内容(5)" variant="filled" value={profileState.careerContent5} onChange={e=>setProfileState({...profileState, careerContent5:e.target.value})} className="text__box"/>
                    <div className='d-flex'>
                        <TextField id="careerYear6" label="年(6)" variant="filled" value={profileState.careerYear6} onChange={e=>setProfileState({...profileState, careerYear6:e.target.value})}/>
                        <TextField id="careerMonth6" label="月(6)" variant="filled" value={profileState.careerMonth6} onChange={e=>setProfileState({...profileState, careerMonth6:e.target.value})}/>
                        <TextField id="careerTitle6" label="タイトル(6)" variant="filled" value={profileState.careerTitle6} onChange={e=>setProfileState({...profileState, careerTitle6:e.target.value})}/>
                    </div>
                    <TextField multiline rows={5} id="careerContent6" label="内容(6)" variant="filled" value={profileState.careerContent6} onChange={e=>setProfileState({...profileState, careerContent6:e.target.value})} className="text__box"/>
                    <div className='d-flex'>
                        <TextField id="careerYear7" label="年(7)" variant="filled" value={profileState.careerYear7} onChange={e=>setProfileState({...profileState, careerYear7:e.target.value})}/>
                        <TextField id="careerMonth7" label="月(7)" variant="filled" value={profileState.careerMonth7} onChange={e=>setProfileState({...profileState, careerMonth7:e.target.value})}/>
                        <TextField id="careerTitle7" label="タイトル(7)" variant="filled" value={profileState.careerTitle7} onChange={e=>setProfileState({...profileState, careerTitle7:e.target.value})}/>
                    </div>
                    <TextField multiline rows={5} id="careerContent7" label="内容(7)" variant="filled" value={profileState.careerContent7} onChange={e=>setProfileState({...profileState, careerContent7:e.target.value})} className="text__box"/>
                    <div className='d-flex'>
                        <TextField id="careerYear8" label="年(8)" variant="filled" value={profileState.careerYear8} onChange={e=>setProfileState({...profileState, careerYear8:e.target.value})}/>
                        <TextField id="careerMonth8" label="月(8)" variant="filled" value={profileState.careerMonth8} onChange={e=>setProfileState({...profileState, careerMonth8:e.target.value})}/>
                        <TextField id="careerTitle8" label="タイトル(8)" variant="filled" value={profileState.careerTitle8} onChange={e=>setProfileState({...profileState, careerTitle8:e.target.value})}/>
                    </div>
                    <TextField multiline rows={5} id="careerContent8" label="内容(8)" variant="filled" value={profileState.careerContent8} onChange={e=>setProfileState({...profileState, careerContent8:e.target.value})} className="text__box"/>
                    <div className='d-flex'>
                        <TextField id="careerYear9" label="年(9)" variant="filled" value={profileState.careerYear9} onChange={e=>setProfileState({...profileState, careerYear9:e.target.value})}/>
                        <TextField id="careerMonth9" label="月(9)" variant="filled" value={profileState.careerMonth9} onChange={e=>setProfileState({...profileState, careerMonth9:e.target.value})}/>
                        <TextField id="careerTitle9" label="タイトル(9)" variant="filled" value={profileState.careerTitle9} onChange={e=>setProfileState({...profileState, careerTitle9:e.target.value})}/>
                    </div>
                    <TextField multiline rows={5} id="careerContent9" label="内容(9)" variant="filled" value={profileState.careerContent9} onChange={e=>setProfileState({...profileState, careerContent9:e.target.value})} className="text__box"/>
                    <div className='d-flex'>
                        <TextField id="careerYear10" label="年(10)" variant="filled" value={profileState.careerYear10} onChange={e=>setProfileState({...profileState, careerYear10:e.target.value})}/>
                        <TextField id="careerMonth10" label="月(10)" variant="filled" value={profileState.careerMonth10} onChange={e=>setProfileState({...profileState, careerMonth10:e.target.value})}/>
                        <TextField id="careerTitle10" label="タイトル(10)" variant="filled" value={profileState.careerTitle10} onChange={e=>setProfileState({...profileState, careerTitle10:e.target.value})}/>
                    </div>
                    <TextField multiline rows={5} id="careerContent10" label="内容(10)" variant="filled" value={profileState.careerContent10} onChange={e=>setProfileState({...profileState, careerContent10:e.target.value})} className="text__box"/>
                </form>

            </div>

        </>
    )
}

export default EditCareerProfile;

