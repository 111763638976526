import React, { useState } from "react";
import { Link } from "react-router-dom";
import db from "../../firebase";
import { useDocumentTitle, useScrollTop } from "../../hooks";
import './index.css';


const HelpCenter = () => {
    useDocumentTitle("ヘルプセンター / コピペル");
    useScrollTop();
    const init = {
        email: '',
        name: '',
        content: ''
    }
    const [contact, setContact] = useState(init);
    const [msg, setMeg] = useState(false)

    const onSubmit = () => {
        const {email, name, content} = contact
        db.collection('contact').add({
            email,
            name,
            content
        })
        setContact(init);
        setMeg(true)
    }

    return(
        <>
        <div className="help__center">
            <h3>お問い合わせ</h3>
            <div className="help__center__content">
                <div className='contact-content-input'>
                    <input type='text' name='name' placeholder='お名前' className='contact-content-input-input' value={contact.name} onChange={e => setContact({...contact, name: e.target.value})} required/>
                    <input type='email' name='email' placeholder='メールアドレス' className='contact-content-input-input' value={contact.email} onChange={e => setContact({...contact, email: e.target.value})} required/>
                    <textarea name="content" id="" cols="30" rows="10" placeholder='お問い合わせ内容' className='contact-content-input-input' value={contact.content} onChange={e => setContact({...contact, content: e.target.value})} required></textarea>
                    <button onClick={onSubmit}>送信する</button>
                    {msg ? <p>お問い合わせありがとうございます。</p> : <></>}
                </div>
                <div className="help__center-or">
                    <span>もしくは</span>
                </div>
                <div className="help__center__content-btn">
                    <Link to={`/profile/copipel01`}>
                    <button className="help__center-card">
                        <i className="fas fa-user help__center-icon"/>
                        <p>公式アカウントから</p>
                    </button>
                    </Link>
                    <button className="help__center-card">
                        <i className="fas fa-at help__center-icon"/>
                        <p><a href="mailto:info@copipel.com">メールアドレスから</a></p>
                    </button>
                </div>
            </div>
        </div>
        </>
    )
}

export default HelpCenter;