import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import firebase from 'firebase';
import { Avatar } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import { like, unlike, follow, unfollow, deletePost, tabSave, tabUnsave } from '../../../redux/actions/serverActions';
import { useStateValue } from '../../../contexts/StateContextProvider';
import db from '../../../firebase';
import Like from '../../like/Like';
import TagPost from '../../tagPost/tagPost';
import '../index.css';
import Popover from '../../../elements/popOver/PopOver';
import * as ROUTE from '../../../constant/router';

const AuthJobStatusPost = ({status}) => {
   const {postId} = useParams()
   const [{user}] = useStateValue()
   const [profile, setProfile] = useState({id:'',displayName:'', photoURL: '', username: '', followers: [], following: []})
   const {displayName, username, photoURL} = profile
   const {
      altText,
      jobTitle,
      briefIntro,
      hireSection,
      kindJob,
      offDay,
      reqSkill,
      timeWork,
      wanted,
      welfare,
      image,
      senderId,
      likes,
      bookMark,
      salary,
      youtubeLink,
      prefecture,
      otherAddress,
      website,
      canWorkPlace,
      wantedJob,
      briefIntroYour,
      eventDate, 
      eventDetails,
      eventTime,
      eventWhy,
      workDetails,
      skills,
      keywordsWord,
      salary2,
      salaryType,
      access,
      qualification
   } = status

   const history = useHistory()

   const [isFollowing, setIsFollowing] = useState(false)

   useEffect(() => {
      if(!profile.length){
         let mounted = true
         db.collection('users').doc(`${senderId}`).onSnapshot(snapshot=>{
            if(mounted) {
               setProfile(snapshot.data())
            }
         })
         return () => mounted = false
      }
   }, [])

   useEffect(() => {
      if(profile){
         setIsFollowing(profile.followers.includes(user.id))
      }
   }, [profile])

   const deleteMyPost = ()=> {
      deletePost(postId)
      history.replace('/')
   }

   const [copySuccess, setCopySuccess] = useState('');
   const onCopyBtn = (e) => {
      navigator.clipboard.writeText(`https://copipel.com/#/posts/${postId}`)
      setCopySuccess(true)
   }

   const [typeP, setTypeP] = useState([]);

   useEffect(() => {
      if(!typeP.length){
         db.collection('jobs').doc(postId).onSnapshot(snapshot => {
            setTypeP(snapshot.data())
         })
      }
   }, []);


   const [notification, setNotification] = useState([]);
   useEffect(() => {
      if(!notification.length){
         let mounted = true
         if(mounted){
             db.collection("notification").where('userId', '==', `${senderId}`).onSnapshot(snapshot => setNotification(snapshot.docs.map(doc => ({id: doc, ...doc.data()}))))
         }
         return () => mounted = false
      }
  }, [])

   const followNoti = () => {
      db.collection('notification').add({
         content: "にフォローされました",
         details: user.displayName,
         photoURL: user.photoURL,
         email: profile.email,
         subject: "新規フォロワー",
         timestamp: firebase.firestore.FieldValue.serverTimestamp(),
         url: `https://copipel.com/#/profile/${user.username}`,
         userId: senderId,
         read: false,
         emailSend: false
      })
   }

   const onFollowBtn = () => {
      follow(user.id, senderId);
      followNoti();

      if(profile.emailNotification === true){
         setTimeout(() => {
            if(notification.length > 0){
               db.collection('sendEmail').add({
                  content: "新しい通知が複数あります",
                  details: '',
                  url: `https://copipel.com/#/notification`,
                  email: profile.email,
                  subject: '新規通知',
               })
            } else{
               db.collection('sendEmail').add({
                  content: "にフォローされました",
                  details: user.displayName,
                  url: `https://copipel.com/#/profile/${user.username}`,
                  email: profile.email,
                  subject: '新規通知',
               })
            }
         }, 1000 * 60 * 60)
      }
   }

   const likeNoti = () => {
      db.collection('notification').add({
         content: "にお気に入りされました",
         details: user.displayName,
         photoURL: user.photoURL,
         timestamp: firebase.firestore.FieldValue.serverTimestamp(),
         url: `https://copipel.com/#/posts/${postId}`,
         email: profile.email,
         subject: '新規お気に入り',
         userId: senderId,
         read: false,
         emailSend: false
      })
   }

   const onLikeBtn = () => {
      like(postId, user.id);
      likeNoti();
      if(profile.emailNotification === true){
         setTimeout(() => {
            if(notification.length > 0){
               db.collection('sendEmail').add({
                  content: "新しい通知が複数あります",
                  details: '',
                  url: `https://copipel.com/#/notification`,
                  email: profile.email,
                  subject: '新規通知',
               })
            } else{
               db.collection('sendEmail').add({
                  content: "にお気に入りされました",
                  details: user.displayName,
                  url: `https://copipel.com/#/posts/${postId}`,
                  email: profile.email,
                  subject: '新規通知',
               })
            }
         }, 1000 * 60 * 60)
      }
   }

   return (
   <>
      <div className='statusPost'>
      <div className='post bottomWhited'>
         <div className="post__avatar">
            <Link to={`/profile/${username}`} replace><Avatar src={photoURL} /></Link>
         </div>
         <div className="post__body">
            <div className="post__header">
               <div className="post__headerText">
                  <div className="post__statusPostHeader">
                     <h3><Link to={`/profile/${username}`} replace>
                        {profile.anonymous === true ? <>{"匿名"}</> : <>{displayName}</>}
                     </Link>{profile.role === "COMPANY" ? <VerifiedUserIcon className='post__badge'/>: <div></div>} </h3>                    
                     <span className='post__headerSpecial'>
                        {username && `@${username} `} 
                     </span> 
                  </div>
                                          
                  <Popover
                        showArrow
                        triggerNode={
                        <div className='post__ExpandIcon'>
                           <button><i className='fas fa-angle-down expandIcon' /></button>
                        </div>
                        }
                        trigger='click'
                     >
                        <ul className="post__expandList">
                           <li>
                              {copySuccess ? <div><i className='fas fa-check post-success-icon post-icon'/>コピーしました</div>
                              :
                              <div className='' onClick={onCopyBtn}><i className='fas fa-paperclip post-clip-icon post-icon'/>URLをコピー</div>}
                           </li>
                           {senderId === user.id?
                           <>
                              <li onClick={() => deleteMyPost(postId)}>
                                 <div className='delete'><DeleteOutlineIcon /></div><h3 className="delete">削除する</h3>
                              </li>
                           </>
                           :
                           <>
                              {
                                 isFollowing?
                                    <li onClick={()=>unfollow(user.id, senderId)}>
                                       <div><PersonAddDisabledIcon /></div><h3>フォローをはずす {`@${username}`}</h3>
                                    </li>
                                 :  <li onClick={onFollowBtn}>
                                       <div><PersonAddIcon /></div><h3>フォローする {`@${username}`}</h3>
                                    </li>
                              }
                           </>
                        }
                        </ul>
                     </Popover>
               </div>
            </div>

         </div>
      </div>

      <div className='statusPost__body'>
         { typeP.typePost === "jobPosting"? 
         <div>
            <div className='d-flex'><p className='kindJob__text'>
               <span>
                  {kindJob === '法人営業' ||  kindJob === '個人営業' || kindJob === '内勤営業・カウンターセールス' || kindJob === 'ルートセールス・代理店営業' || 
                  kindJob === 'IT営業' || kindJob === '海外営業' || kindJob === '不動産営業' || kindJob === '金融営業' || kindJob === 'テレマーケティング' || kindJob === '一般事務' 
                  || kindJob === '営業事務' || kindJob === 'カスタマーサポート' || kindJob === '受付' || kindJob === '秘書' || kindJob === 'モバイルアプリ' || kindJob === 'フロントエンド' 
                  || kindJob === 'バックエンド' || kindJob === 'フルスタック' || kindJob === 'インフラエンジニア' || kindJob === 'サーバーエンジニア（設計構築）' || kindJob === 'ネットワークエンジニア（設計構築）' 
                  || kindJob === 'データベースエンジニア' || kindJob === 'プロジェクトマネージャー（インフラ）' || kindJob === '運用・監視・保守（インフラ' ||
                  kindJob === 'Webサービスエンジニア（ネットワーク・サーバー・データベース）' || kindJob === 'サポート・ヘルプデスク' || kindJob === '社内SE（社内情報システム）' 
                  || kindJob === 'Webプロデューサー・Webディレクター・Webプランナー' || kindJob === 'Webデザイナー' || kindJob === 'Webライター・Web編集（コンテンツ制作）' 
                  || kindJob === 'UI・UXデザイナー（Web・モバイル）' || kindJob === 'アシスタントディレクター・制作進行管理' || kindJob === 'フォトグラファー・カメラマン' 
                  || kindJob === 'イラストレーター【広告・グラフィック関連' || kindJob === '編集・記者・ライター'
                  ? 
                     <>
                     {kindJob === '法人営業' ? <Link className='color-white opacity' to={ROUTE.JOB__CORPORATE__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '個人営業' ? <Link className='color-white opacity' to={ROUTE.JOB__PERSONAL__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '内勤営業・カウンターセールス' ? <Link className='color-white opacity' to={ROUTE.JOB__INHOUSE__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'ルートセールス・代理店営業' ? <Link className='color-white opacity' to={ROUTE.JOB__AGENCY__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'IT営業' ? <Link className='color-white opacity' to={ROUTE.JOB__IT__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '海外営業' ? <Link className='color-white opacity' to={ROUTE.JOB__FOREIGN__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '不動産営業' ? <Link className='color-white opacity' to={ROUTE.JOB__REALSTATE__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '金融営業' ? <Link className='color-white opacity' to={ROUTE.JOB__FINANCE__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'テレマーケティング' ? <Link className='color-white opacity' to={ROUTE.JOB__TELEPHONE__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '一般事務' ? <Link className='color-white opacity' to={ROUTE.JOB__GENERAL__OFFICE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '営業事務' ? <Link className='color-white opacity' to={ROUTE.JOB__SALES__OFFICE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'カスタマーサポート' ? <Link className='color-white opacity' to={ROUTE.JOB__CUSTOMER__OFFICE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '受付' ? <Link className='color-white opacity' to={ROUTE.JOB__RECEIPT__OFFICE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '秘書' ? <Link className='color-white opacity' to={ROUTE.JOB__SECRETARY__OFFICE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'モバイルアプリ' ? <Link className='color-white opacity' to={ROUTE.JOB__MOBILE__APP__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'フロントエンド' ? <Link className='color-white opacity' to={ROUTE.JOB__WEBFRONTEND__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'バックエンド' ? <Link className='color-white opacity' to={ROUTE.JOB__WEBBACKEND__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'フルスタック' ? <Link className='color-white opacity' to={ROUTE.JOB__WEBFULLSTACK__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'インフラエンジニア' ? <Link className='color-white opacity' to={ROUTE.JOB__INFRA__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'サーバーエンジニア（設計構築）' ? <Link className='color-white opacity' to={ROUTE.JOB__INFRA__SERVER__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'ネットワークエンジニア（設計構築）' ? <Link className='color-white opacity' to={ROUTE.JOB__INFRA__NETWORK__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'データベースエンジニア' ? <Link className='color-white opacity' to={ROUTE.JOB__INFRA__DATABASE__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'プロジェクトマネージャー（インフラ）' ? <Link className='color-white opacity' to={ROUTE.JOB__INFRA__PM__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'Webサービスエンジニア（ネットワーク・サーバー・データベース）' ? <Link className='color-white opacity' to={ROUTE.JOB__INFRA__WEBSERVICE__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '運用・監視・保守（インフラ' ? <Link className='color-white opacity' to={ROUTE.JOB__INFRA__MAINTAIN__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'サポート・ヘルプデスク' ? <Link className='color-white opacity' to={ROUTE.JOB__SUPPORT__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '社内SE（社内情報システム）' ? <Link className='color-white opacity' to={ROUTE.JOB__RECEIPT__OFFICE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'Webプロデューサー・Webディレクター・Webプランナー' ? <Link className='color-white opacity' to={ROUTE.JOB__WEBPM__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'Webデザイナー' ? <Link className='color-white opacity' to={ROUTE.JOB__WEBDESIGNER__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'Webライター・Web編集（コンテンツ制作）' ? <Link className='color-white opacity' to={ROUTE.JOB__WEBWRITER__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'UI・UXデザイナー（Web・モバイル）' ? <Link className='color-white opacity' to={ROUTE.JOB__UIUX__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'アシスタントディレクター・制作進行管理' ? <Link className='color-white opacity' to={ROUTE.JOB__ASSISTDIRECTOR__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'フォトグラファー・カメラマン' ? <Link className='color-white opacity' to={ROUTE.JOB__PHOTOGRAPHER__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'イラストレーター【広告・グラフィック関連' ? <Link className='color-white opacity' to={ROUTE.JOB__ILLUSTRATOR__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'デザイナー（グラフィック・その他）' ? <Link className='color-white opacity' to={ROUTE.JOB__DESIGNER__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '編集・記者・ライター' ? <Link className='color-white opacity' to={ROUTE.JOB__WRITER__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     </>
                     : {kindJob}
                  }
               </span></p><p className='hire__section'><i className='fas fa-caret-down padding-right-3px'/>{hireSection}</p>
            </div>
            <p className='website__post'><i className='fas fa-globe'/> <a href={`${{website}}`}>{website}</a></p>
            <div className='statusPost__body--message'>
               <h1>{jobTitle}</h1>
               <p className='opacity-6'><i className='fas fa-map-marker-alt'/> {prefecture}</p>
            </div>
            <div className='d-display'>                
               <div>
                  { image.length>0 && <img src={image} alt={altText} className='job__post__img'/>}
               </div>
               <div className='job__header__body'>
                  <h2><i className='fas fa-square job__post__icon'/> 事業内容</h2>
                  <p className='job__post__text'>{briefIntro}</p>
               </div>
            </div>
            <div className='job__post__detail'>
               <div className=''>
                  <h2><i className='fas fa-square job__post__icon'/> 仕事内容</h2>
                  <p className='job__post__text'>{wanted}</p>
               </div>
               {/* <div className=''>
                  <h2><i className='fas fa-square job__post__icon'/> 必須のスキル・経験</h2>
                  <div className='post__reqSkill d-flex post__skills padding-left-5'>
                     {(reqSkill[0] && reqSkill[0]) && <p>{reqSkill[0]}</p>}
                     {(reqSkill[1] && reqSkill[1]) && <p>{reqSkill[1]}</p>}
                     {(reqSkill[2] && reqSkill[2]) && <p>{reqSkill[2]}</p>}
                     {(reqSkill[3] && reqSkill[3]) && <p>{reqSkill[3]}</p>}
                     {(reqSkill[4] && reqSkill[4]) && <p>{reqSkill[4]}</p>}
                  </div>
               </div>
               <div className=''>
                  <h2><i className='fas fa-square job__post__icon'/> 望ましいスキル・経験</h2>
                  <div className='post__reqSkill d-flex post__skills padding-left-5'>
                     {(opSkill[0] && opSkill[0]) && <p>{opSkill[0]}</p>}
                     {(opSkill[1] && opSkill[1]) && <p>{opSkill[1]}</p>}
                     {(opSkill[2] && opSkill[2]) && <p>{opSkill[2]}</p>}
                     {(opSkill[3] && opSkill[3]) && <p>{opSkill[3]}</p>}
                     {(opSkill[4] && opSkill[4]) && <p>{opSkill[4]}</p>}
                  </div>
               </div> */}
               <div className=''>
                  <h2><i className='fas fa-square job__post__icon'/> 応募資格・条件</h2>
                  <p className='job__post__text'>{qualification}</p>
               </div>
               <div className='job__post__table'>
                  <h2><i className='fas fa-square job__post__icon'/> 詳細</h2>
                  <table>
                     <thead>
                        <tr>
                           <th>給与</th>
                           <td>{salaryType}: {salary}円～{salary2}円</td>
                        </tr>
                        <tr>
                              <th>雇用区分</th>
                              <td>{hireSection}</td>
                        </tr>
                        <tr>
                              <th>勤務時間</th>
                              <td>{timeWork}</td>
                        </tr>
                        <tr>
                              <th>勤務地</th>
                              <td>{prefecture} {otherAddress}</td>
                        </tr>
                        <tr>
                              <th>アクセス</th>
                              <td>{access}</td>
                        </tr>
                        <tr>
                              <th>待遇・福利厚生</th>
                              <td>{welfare}</td>
                        </tr>
                        <tr>
                              <th>休日・休暇</th>
                              <td>{offDay}</td>
                        </tr>
                     </thead>
                  </table>
               </div>
            </div>
            {youtubeLink && youtubeLink ? 
               <div className="video-responsive">
                  <iframe
                     width="853"
                     height="480"
                     src={`https://www.youtube.com/embed/${youtubeLink}`}
                     frameBorder="0"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                     allowFullScreen
                     title="Embedded youtube"
                  />
               </div>
               : <></>
            }
         </div>
         :<></>   
         }
         {typeP.typePost === "userPosting" ? 
         <div>
            <div className='d-flex'>
               <p className='kindJob__text'><span>{kindJob}</span></p>
               </div>
            <div className='status__post__top'>
               <p><i className='fas fa-map-marker-alt'/> {canWorkPlace}</p>
            </div>
            <div>
               <div className='post__wanted__job d-flex'>
                  <div className="balloon3-right">
                  今、求めてます
                  </div>
                  <div className="status__post__watedJob">
                     <p>{wantedJob}</p>
                  </div>
               </div>
            </div>
            <div className='status__post__skills d-flex'>
                  <div className="balloon3-right">
                     スキル
                  </div>
                  <div className='d-flex post__skills'>
                     {(skills[0] && skills[0]) && <p>{skills[0]}</p>}
                     {(skills[1] && skills[1]) && <p>{skills[1]}</p>}
                     {(skills[2] && skills[2]) && <p>{skills[2]}</p>}
                     {(skills[3] && skills[3]) && <p>{skills[3]}</p>}
                  </div>
            </div>
            <div className='status__post__intro'>
               <h2>簡単な自己紹介</h2>
               <p>{briefIntroYour}</p>
            </div>
            
         </div>
         :<></>
         }
         {typeP.typePost === "eventPosting" ? 
         <div className='event__post__body'>
            <p className='kindJob__text'>
               <span>
               {kindJob === '法人営業' ||  kindJob === '個人営業' || kindJob === '内勤営業・カウンターセールス' || kindJob === 'ルートセールス・代理店営業' || 
                  kindJob === 'IT営業' || kindJob === '海外営業' || kindJob === '不動産営業' || kindJob === '金融営業' || kindJob === 'テレマーケティング' || kindJob === '一般事務' 
                  || kindJob === '営業事務' || kindJob === 'カスタマーサポート' || kindJob === '受付' || kindJob === '秘書' || kindJob === 'モバイルアプリ' || kindJob === 'フロントエンド' 
                  || kindJob === 'バックエンド' || kindJob === 'フルスタック' || kindJob === 'インフラエンジニア' || kindJob === 'サーバーエンジニア（設計構築）' || kindJob === 'ネットワークエンジニア（設計構築）' 
                  || kindJob === 'データベースエンジニア' || kindJob === 'プロジェクトマネージャー（インフラ）' || kindJob === '運用・監視・保守（インフラ' ||
                  kindJob === 'Webサービスエンジニア（ネットワーク・サーバー・データベース）' || kindJob === 'サポート・ヘルプデスク' || kindJob === '社内SE（社内情報システム）' 
                  || kindJob === 'Webプロデューサー・Webディレクター・Webプランナー' || kindJob === 'Webデザイナー' || kindJob === 'Webライター・Web編集（コンテンツ制作）' 
                  || kindJob === 'UI・UXデザイナー（Web・モバイル）' || kindJob === 'アシスタントディレクター・制作進行管理' || kindJob === 'フォトグラファー・カメラマン' 
                  || kindJob === 'イラストレーター【広告・グラフィック関連' || kindJob === '編集・記者・ライター'
                  ? 
                     <>
                     {kindJob === '法人営業' ? <Link className='color-white opacity' to={ROUTE.JOB__CORPORATE__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '個人営業' ? <Link className='color-white opacity' to={ROUTE.JOB__PERSONAL__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '内勤営業・カウンターセールス' ? <Link className='color-white opacity' to={ROUTE.JOB__INHOUSE__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'ルートセールス・代理店営業' ? <Link className='color-white opacity' to={ROUTE.JOB__AGENCY__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'IT営業' ? <Link className='color-white opacity' to={ROUTE.JOB__IT__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '海外営業' ? <Link className='color-white opacity' to={ROUTE.JOB__FOREIGN__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '不動産営業' ? <Link className='color-white opacity' to={ROUTE.JOB__REALSTATE__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '金融営業' ? <Link className='color-white opacity' to={ROUTE.JOB__FINANCE__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'テレマーケティング' ? <Link className='color-white opacity' to={ROUTE.JOB__TELEPHONE__SALES__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '一般事務' ? <Link className='color-white opacity' to={ROUTE.JOB__GENERAL__OFFICE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '営業事務' ? <Link className='color-white opacity' to={ROUTE.JOB__SALES__OFFICE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'カスタマーサポート' ? <Link className='color-white opacity' to={ROUTE.JOB__CUSTOMER__OFFICE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '受付' ? <Link className='color-white opacity' to={ROUTE.JOB__RECEIPT__OFFICE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '秘書' ? <Link className='color-white opacity' to={ROUTE.JOB__SECRETARY__OFFICE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'モバイルアプリ' ? <Link className='color-white opacity' to={ROUTE.JOB__MOBILE__APP__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'フロントエンド' ? <Link className='color-white opacity' to={ROUTE.JOB__WEBFRONTEND__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'バックエンド' ? <Link className='color-white opacity' to={ROUTE.JOB__WEBBACKEND__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'フルスタック' ? <Link className='color-white opacity' to={ROUTE.JOB__WEBFULLSTACK__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'インフラエンジニア' ? <Link className='color-white opacity' to={ROUTE.JOB__INFRA__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'サーバーエンジニア（設計構築）' ? <Link className='color-white opacity' to={ROUTE.JOB__INFRA__SERVER__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'ネットワークエンジニア（設計構築）' ? <Link className='color-white opacity' to={ROUTE.JOB__INFRA__NETWORK__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'データベースエンジニア' ? <Link className='color-white opacity' to={ROUTE.JOB__INFRA__DATABASE__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'プロジェクトマネージャー（インフラ）' ? <Link className='color-white opacity' to={ROUTE.JOB__INFRA__PM__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'Webサービスエンジニア（ネットワーク・サーバー・データベース）' ? <Link className='color-white opacity' to={ROUTE.JOB__INFRA__WEBSERVICE__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '運用・監視・保守（インフラ' ? <Link className='color-white opacity' to={ROUTE.JOB__INFRA__MAINTAIN__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'サポート・ヘルプデスク' ? <Link className='color-white opacity' to={ROUTE.JOB__SUPPORT__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '社内SE（社内情報システム）' ? <Link className='color-white opacity' to={ROUTE.JOB__RECEIPT__OFFICE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'Webプロデューサー・Webディレクター・Webプランナー' ? <Link className='color-white opacity' to={ROUTE.JOB__WEBPM__ENGINEER__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'Webデザイナー' ? <Link className='color-white opacity' to={ROUTE.JOB__WEBDESIGNER__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'Webライター・Web編集（コンテンツ制作）' ? <Link className='color-white opacity' to={ROUTE.JOB__WEBWRITER__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'UI・UXデザイナー（Web・モバイル）' ? <Link className='color-white opacity' to={ROUTE.JOB__UIUX__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'アシスタントディレクター・制作進行管理' ? <Link className='color-white opacity' to={ROUTE.JOB__ASSISTDIRECTOR__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'フォトグラファー・カメラマン' ? <Link className='color-white opacity' to={ROUTE.JOB__PHOTOGRAPHER__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'イラストレーター【広告・グラフィック関連' ? <Link className='color-white opacity' to={ROUTE.JOB__ILLUSTRATOR__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === 'デザイナー（グラフィック・その他）' ? <Link className='color-white opacity' to={ROUTE.JOB__DESIGNER__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     {kindJob === '編集・記者・ライター' ? <Link className='color-white opacity' to={ROUTE.JOB__WRITER__CREATIVE__TIMELINE}>{kindJob}</Link> : <></>}
                     </>
                     : {kindJob}
                  }
               </span></p>
            <h1>{jobTitle}</h1>
            <p className='website__post'><i className='fas fa-globe'/> <a href={`${{website}}`}>{website}</a></p>
            <div className='event__post__date d-flex'>
               <h2 className='event__date__head'>{eventDate}</h2>
               <div className='d-block event__time__place'>
                  <h2>{eventTime} ~ </h2>
                  <h2 className='event__place'><i className='fas fa-map-marked-alt'/> {prefecture} {otherAddress}</h2>
               </div>
            </div>
            <div className='event__details'>
               <h2>≪イベント内容≫</h2>
               <p>{eventDetails}</p>
            </div>
            <div className='event__details'>
               <h2>≪イベント開催の背景≫</h2>
               <p>{eventWhy}</p>
            </div>
            {youtubeLink && youtubeLink ? 
               <div className="video-responsive">
                  <iframe
                     width="853"
                     height="480"
                     src={`https://www.youtube.com/embed/${youtubeLink}`}
                     frameBorder="0"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                     allowFullScreen
                     title="Embedded youtube"
                  />
               </div>
               : <></>
            }
         </div>

         :<></>
         }

         {typeP.typePost === "freelancePosting" ? 
         <div className='freelance__post__body'>
            <div className='d-flex'><p className='kindJob__text'><span>{kindJob}</span></p><p className='hire__section'><i className='fas fa-caret-down padding-right-3px'/>{hireSection}</p></div>
            <h1>{jobTitle}</h1>
            <div className='freelance__money'><p><i className='fas fa-money-bill-wave'/> <strong>{salary}</strong>円</p></div>
            <div className='freelance__details'>
               <h2>≪仕事内容≫</h2>
               <p>{workDetails}</p>
            </div>
            <div className=''>
                  <h2><i className='fas fa-square job__post__icon'/> 必須のスキル・経験</h2>
                  <div className='post__reqSkill d-flex post__skills padding-left-5'>
                     {(reqSkill[0] && reqSkill[0]) && <p>{reqSkill[0]}</p>}
                     {(reqSkill[1] && reqSkill[1]) && <p>{reqSkill[1]}</p>}
                     {(reqSkill[2] && reqSkill[2]) && <p>{reqSkill[2]}</p>}
                     {(reqSkill[3] && reqSkill[3]) && <p>{reqSkill[3]}</p>}
                     {(reqSkill[4] && reqSkill[4]) && <p>{reqSkill[4]}</p>}
                  </div>
               </div>
         </div>
         :<></>
         }

      </div>

      <div className="statusPost__footer">
         {user.id === senderId ? 
            <></>
            :
            <div className="post__footer">
               <Like
                  likes={likes}
                  unlikeAction={()=> unlike(postId, user.id)}
                  likeAction={onLikeBtn}
                  postId={postId}
                  senderId={senderId}
                  typePost={typeP.typePost}
                  text1='申し込み済み'
                  text2='申し込む'
                  text3='お知らせ済み'
                  text4='興味を知らせる'
                  />
            </div>
         }
         {senderId === user.id ? <></>:
         < div className='tabPost__save__post-status'>
            <TagPost
                  likes={bookMark}
                  unlikeAction = {()=>tabUnsave(postId, user.id)}
                  likeAction = {()=>tabSave(postId, user.id)}
            />      
         </div> 
         }
      </div>
      {
         typeP.typePost === "jobPosting" || typeP.typePost === "eventPosting" ?
         <div className='post__keyword'>
            <ul className='post__keyword-status'>
               {/* 0-index */}
               {keywordsWord[0] === "駅近5分以内" || keywordsWord[0] === '交通費支給' || keywordsWord[0] === '在宅OK' || keywordsWord[0] === 'リモート' || keywordsWord[0] === '週2休み' || keywordsWord[0] === "週2・3からOK" || 
               keywordsWord[0] === "大学生OK" || keywordsWord[0] === '高校生OK' || keywordsWord[0] === '副業・WワークOK' || keywordsWord[0] === '未経験OK' || keywordsWord[0] === '経験者歓迎' || keywordsWord[0] === "学歴不問" ||
               keywordsWord[0] === "産休・育休" || keywordsWord[0] === '研修あり' || keywordsWord[0] === '資格手当' || keywordsWord[0] === '家族手当' || keywordsWord[0] === 'インセンティブ' || keywordsWord[0] === "社保完備" ||
               keywordsWord[0] === "育児支援" || keywordsWord[0] === '急募' || keywordsWord[0] === 'Web面接OK' || keywordsWord[0] === '福利厚生充実' ||
               keywordsWord[0] === '一部リモート' || keywordsWord[0] === '服装自由' || keywordsWord[0] === '日払い・週払い・即日払いOK' || keywordsWord[0] === '時短勤務制度あり' || keywordsWord[0] === '寮・社宅・住宅手当あり' ||
               keywordsWord[0] === '社員登用あり'
               ? 
               <>
               {keywordsWord[0] && keywordsWord[0] === "一部リモート" ? 
               <Link className="post__keyword-link" to={ROUTE.SOME__REMOTE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "服装自由" ? 
               <Link className="post__keyword-link" to={ROUTE.ANY__CLOTHES__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "日払い・週払い・即日払いOK" ? 
               <Link className="post__keyword-link" to={ROUTE.DAILY__WEEKLY__OK__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "時短勤務制度あり" ? 
               <Link className="post__keyword-link" to={ROUTE.SHORT__TIME__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "週1日からOK" ? 
               <Link className="post__keyword-link" to={ROUTE.ONE__OK__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "寮・社宅・住宅手当あり" ? 
               <Link className="post__keyword-link" to={ROUTE.DORM__HOUSINGALLOWANCE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "社員登用あり" ? 
               <Link className="post__keyword-link" to={ROUTE.PLOMOTION__TO__EMPLOYEE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "駅近5分以内" ? 
               <Link className="post__keyword-link" to={ROUTE.NEAR__STATION__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "交通費支給" ? 
               <Link className="post__keyword-link" to={ROUTE.TRANSPORTATION__PAID__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "在宅OK" ? 
               <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "リモート" ? 
               <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "週2休み" ? 
               <Link className="post__keyword-link" to={ROUTE.TWO__DAYS__OFF__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "週2・3からOK" ? 
               <Link className="post__keyword-link" to={ROUTE.TWO__THREE__OK__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "大学生OK" ? 
               <Link className="post__keyword-link" to={ROUTE.UNIVERSITY__STUDENT__OK}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "高校生OK" ? 
               <Link className="post__keyword-link" to={ROUTE.HIGHSCHOOL__STUDENT__OK}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "副業・WワークOK" ? 
               <Link className="post__keyword-link" to={ROUTE.SIDE__BUSINESS__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "未経験OK" ? 
               <Link className="post__keyword-link" to={ROUTE.NO__EXPERIENCE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "経験者歓迎" ? 
               <Link className="post__keyword-link" to={ROUTE.WELCOME__EXPERIENCE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "学歴不問" ? 
               <Link className="post__keyword-link" to={ROUTE.EDUCATIONAL__BACKGOUND__NOT__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "産休・育休" ? 
               <Link className="post__keyword-link" to={ROUTE.MATERNITY__CHILDCARE__LEAVE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "研修あり" ? 
               <Link className="post__keyword-link" to={ROUTE.WITH__TRAINING__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "資格手当" ? 
               <Link className="post__keyword-link" to={ROUTE.QUALIFICATION__ALLOWANCE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "家族手当" ? 
               <Link className="post__keyword-link" to={ROUTE.FAMILY__ALLOWANCE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "インセンティブ" ? 
               <Link className="post__keyword-link" to={ROUTE.INSENTIVE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "社保完備" ? 
               <Link className="post__keyword-link" to={ROUTE.SOCIAL__INSURANCE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "育児支援" ? 
               <Link className="post__keyword-link" to={ROUTE.CHILDCARE__SUPPORT__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "急募" ? 
               <Link className="post__keyword-link" to={ROUTE.URGENT__RECRUITMENT__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "Web面接OK" ? 
               <Link className="post__keyword-link" to={ROUTE.WEB__INTERVIEW__OK__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               {keywordsWord[0] && keywordsWord[0] === "福利厚生充実" ? 
               <Link className="post__keyword-link" to={ROUTE.BENEFITS__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
               }
               </>
               :
               <>
               {keywordsWord[0] && keywordsWord[0] && <li>{keywordsWord[0]}</li>}
               </>
               }

               {/* 1-index */}
               {keywordsWord[1] === "駅近5分以内" || keywordsWord[1] === '交通費支給' || keywordsWord[1] === '在宅OK' || keywordsWord[1] === 'リモート' || keywordsWord[1] === '週2休み' || keywordsWord[1] === "週2・3からOK" || 
               keywordsWord[1] === "大学生OK" || keywordsWord[1] === '高校生OK' || keywordsWord[1] === '副業・WワークOK' || keywordsWord[1] === '未経験OK' || keywordsWord[1] === '経験者歓迎' || keywordsWord[1] === "学歴不問" ||
               keywordsWord[1] === "産休・育休" || keywordsWord[1] === '研修あり' || keywordsWord[1] === '資格手当' || keywordsWord[1] === '家族手当' || keywordsWord[1] === 'インセンティブ' || keywordsWord[1] === "社保完備" ||
               keywordsWord[1] === "育児支援" || keywordsWord[1] === '急募' || keywordsWord[1] === 'Web面接OK' || keywordsWord[1] === '福利厚生充実' || 
               keywordsWord[1] === '一部リモート' || keywordsWord[1] === '服装自由' || keywordsWord[1] === '日払い・週払い・即日払いOK' || keywordsWord[1] === '時短勤務制度あり' || keywordsWord[1] === '寮・社宅・住宅手当あり' ||
               keywordsWord[1] === '社員登用あり'
               ? 
               <>
               {keywordsWord[1] && keywordsWord[1] === "一部リモート" ? 
               <Link className="post__keyword-link" to={ROUTE.SOME__REMOTE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "服装自由" ? 
               <Link className="post__keyword-link" to={ROUTE.ANY__CLOTHES__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "日払い・週払い・即日払いOK" ? 
               <Link className="post__keyword-link" to={ROUTE.DAILY__WEEKLY__OK__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "時短勤務制度あり" ? 
               <Link className="post__keyword-link" to={ROUTE.SHORT__TIME__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "週1日からOK" ? 
               <Link className="post__keyword-link" to={ROUTE.ONE__OK__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "寮・社宅・住宅手当あり" ? 
               <Link className="post__keyword-link" to={ROUTE.DORM__HOUSINGALLOWANCE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "社員登用あり" ? 
               <Link className="post__keyword-link" to={ROUTE.PLOMOTION__TO__EMPLOYEE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "駅近5分以内" ? 
               <Link className="post__keyword-link" to={ROUTE.NEAR__STATION__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "交通費支給" ? 
               <Link className="post__keyword-link" to={ROUTE.TRANSPORTATION__PAID__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "在宅OK" ? 
               <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "リモート" ? 
               <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "週2休み" ? 
               <Link className="post__keyword-link" to={ROUTE.TWO__DAYS__OFF__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "週2・3からOK" ? 
               <Link className="post__keyword-link" to={ROUTE.TWO__THREE__OK__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "大学生OK" ? 
               <Link className="post__keyword-link" to={ROUTE.UNIVERSITY__STUDENT__OK}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "高校生OK" ? 
               <Link className="post__keyword-link" to={ROUTE.HIGHSCHOOL__STUDENT__OK}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "副業・WワークOK" ? 
               <Link className="post__keyword-link" to={ROUTE.SIDE__BUSINESS__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "未経験OK" ? 
               <Link className="post__keyword-link" to={ROUTE.NO__EXPERIENCE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "経験者歓迎" ? 
               <Link className="post__keyword-link" to={ROUTE.WELCOME__EXPERIENCE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "学歴不問" ? 
               <Link className="post__keyword-link" to={ROUTE.EDUCATIONAL__BACKGOUND__NOT__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "産休・育休" ? 
               <Link className="post__keyword-link" to={ROUTE.MATERNITY__CHILDCARE__LEAVE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "研修あり" ? 
               <Link className="post__keyword-link" to={ROUTE.WITH__TRAINING__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "資格手当" ? 
               <Link className="post__keyword-link" to={ROUTE.QUALIFICATION__ALLOWANCE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "家族手当" ? 
               <Link className="post__keyword-link" to={ROUTE.FAMILY__ALLOWANCE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "インセンティブ" ? 
               <Link className="post__keyword-link" to={ROUTE.INSENTIVE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "社保完備" ? 
               <Link className="post__keyword-link" to={ROUTE.SOCIAL__INSURANCE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "育児支援" ? 
               <Link className="post__keyword-link" to={ROUTE.CHILDCARE__SUPPORT__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "急募" ? 
               <Link className="post__keyword-link" to={ROUTE.URGENT__RECRUITMENT__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "Web面接OK" ? 
               <Link className="post__keyword-link" to={ROUTE.WEB__INTERVIEW__OK__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               {keywordsWord[1] && keywordsWord[1] === "福利厚生充実" ? 
               <Link className="post__keyword-link" to={ROUTE.BENEFITS__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
               }
               </>
               :
               <>
               {keywordsWord[1] && keywordsWord[1] && <li>{keywordsWord[1]}</li>}
               </>
               }

               {/* 2-index */}
               {keywordsWord[2] === "駅近5分以内" || keywordsWord[2] === '交通費支給' || keywordsWord[2] === '在宅OK' || keywordsWord[2] === 'リモート' || keywordsWord[2] === '週2休み' || keywordsWord[2] === "週2・3からOK" || 
               keywordsWord[2] === "大学生OK" || keywordsWord[2] === '高校生OK' || keywordsWord[2] === '副業・WワークOK' || keywordsWord[2] === '未経験OK' || keywordsWord[2] === '経験者歓迎' || keywordsWord[2] === "学歴不問" ||
               keywordsWord[2] === "産休・育休" || keywordsWord[2] === '研修あり' || keywordsWord[2] === '資格手当' || keywordsWord[2] === '家族手当' || keywordsWord[2] === 'インセンティブ' || keywordsWord[2] === "社保完備" ||
               keywordsWord[2] === "育児支援" || keywordsWord[2] === '急募' || keywordsWord[2] === 'Web面接OK' || keywordsWord[2] === '福利厚生充実' ||
               keywordsWord[2] === '一部リモート' || keywordsWord[2] === '服装自由' || keywordsWord[2] === '日払い・週払い・即日払いOK' || keywordsWord[2] === '時短勤務制度あり' || keywordsWord[2] === '寮・社宅・住宅手当あり' ||
               keywordsWord[2] === '社員登用あり'
               ? 
               <>
               {keywordsWord[2] && keywordsWord[2] === "一部リモート" ? 
               <Link className="post__keyword-link" to={ROUTE.SOME__REMOTE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "服装自由" ? 
               <Link className="post__keyword-link" to={ROUTE.ANY__CLOTHES__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "日払い・週払い・即日払いOK" ? 
               <Link className="post__keyword-link" to={ROUTE.DAILY__WEEKLY__OK__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "時短勤務制度あり" ? 
               <Link className="post__keyword-link" to={ROUTE.SHORT__TIME__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "週1日からOK" ? 
               <Link className="post__keyword-link" to={ROUTE.ONE__OK__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "寮・社宅・住宅手当あり" ? 
               <Link className="post__keyword-link" to={ROUTE.DORM__HOUSINGALLOWANCE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "社員登用あり" ? 
               <Link className="post__keyword-link" to={ROUTE.PLOMOTION__TO__EMPLOYEE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "駅近5分以内" ? 
               <Link className="post__keyword-link" to={ROUTE.NEAR__STATION__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "交通費支給" ? 
               <Link className="post__keyword-link" to={ROUTE.TRANSPORTATION__PAID__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "在宅OK" ? 
               <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "リモート" ? 
               <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "週2休み" ? 
               <Link className="post__keyword-link" to={ROUTE.TWO__DAYS__OFF__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "週2・3からOK" ? 
               <Link className="post__keyword-link" to={ROUTE.TWO__THREE__OK__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "大学生OK" ? 
               <Link className="post__keyword-link" to={ROUTE.UNIVERSITY__STUDENT__OK}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "高校生OK" ? 
               <Link className="post__keyword-link" to={ROUTE.HIGHSCHOOL__STUDENT__OK}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "副業・WワークOK" ? 
               <Link className="post__keyword-link" to={ROUTE.SIDE__BUSINESS__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "未経験OK" ? 
               <Link className="post__keyword-link" to={ROUTE.NO__EXPERIENCE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "経験者歓迎" ? 
               <Link className="post__keyword-link" to={ROUTE.WELCOME__EXPERIENCE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "学歴不問" ? 
               <Link className="post__keyword-link" to={ROUTE.EDUCATIONAL__BACKGOUND__NOT__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "産休・育休" ? 
               <Link className="post__keyword-link" to={ROUTE.MATERNITY__CHILDCARE__LEAVE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "研修あり" ? 
               <Link className="post__keyword-link" to={ROUTE.WITH__TRAINING__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "資格手当" ? 
               <Link className="post__keyword-link" to={ROUTE.QUALIFICATION__ALLOWANCE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "家族手当" ? 
               <Link className="post__keyword-link" to={ROUTE.FAMILY__ALLOWANCE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "インセンティブ" ? 
               <Link className="post__keyword-link" to={ROUTE.INSENTIVE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "社保完備" ? 
               <Link className="post__keyword-link" to={ROUTE.SOCIAL__INSURANCE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "育児支援" ? 
               <Link className="post__keyword-link" to={ROUTE.CHILDCARE__SUPPORT__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "急募" ? 
               <Link className="post__keyword-link" to={ROUTE.URGENT__RECRUITMENT__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "Web面接OK" ? 
               <Link className="post__keyword-link" to={ROUTE.WEB__INTERVIEW__OK__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               {keywordsWord[2] && keywordsWord[2] === "福利厚生充実" ? 
               <Link className="post__keyword-link" to={ROUTE.BENEFITS__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
               }
               </>
               :
               <>
               {keywordsWord[2] && keywordsWord[2] && <li>{keywordsWord[2]}</li>}
               </>
               }


               {/* 3-index */}
               {keywordsWord[3] === "駅近5分以内" || keywordsWord[3] === '交通費支給' || keywordsWord[3] === '在宅OK' || keywordsWord[3] === 'リモート' || keywordsWord[3] === '週2休み' || keywordsWord[3] === "週2・3からOK" || 
               keywordsWord[3] === "大学生OK" || keywordsWord[3] === '高校生OK' || keywordsWord[3] === '副業・WワークOK' || keywordsWord[3] === '未経験OK' || keywordsWord[3] === '経験者歓迎' || keywordsWord[3] === "学歴不問" ||
               keywordsWord[3] === "産休・育休" || keywordsWord[3] === '研修あり' || keywordsWord[3] === '資格手当' || keywordsWord[3] === '家族手当' || keywordsWord[3] === 'インセンティブ' || keywordsWord[3] === "社保完備" ||
               keywordsWord[3] === "育児支援" || keywordsWord[3] === '急募' || keywordsWord[3] === 'Web面接OK' || keywordsWord[3] === '福利厚生充実' ||
               keywordsWord[3] === '一部リモート' || keywordsWord[3] === '服装自由' || keywordsWord[3] === '日払い・週払い・即日払いOK' || keywordsWord[3] === '時短勤務制度あり' || keywordsWord[3] === '寮・社宅・住宅手当あり' ||
               keywordsWord[3] === '社員登用あり'
               ? 
               <>
               {keywordsWord[3] && keywordsWord[3] === "一部リモート" ? 
               <Link className="post__keyword-link" to={ROUTE.SOME__REMOTE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "服装自由" ? 
               <Link className="post__keyword-link" to={ROUTE.ANY__CLOTHES__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "日払い・週払い・即日払いOK" ? 
               <Link className="post__keyword-link" to={ROUTE.DAILY__WEEKLY__OK__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "時短勤務制度あり" ? 
               <Link className="post__keyword-link" to={ROUTE.SHORT__TIME__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "週1日からOK" ? 
               <Link className="post__keyword-link" to={ROUTE.ONE__OK__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "寮・社宅・住宅手当あり" ? 
               <Link className="post__keyword-link" to={ROUTE.DORM__HOUSINGALLOWANCE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "社員登用あり" ? 
               <Link className="post__keyword-link" to={ROUTE.PLOMOTION__TO__EMPLOYEE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "駅近5分以内" ? 
               <Link className="post__keyword-link" to={ROUTE.NEAR__STATION__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "交通費支給" ? 
               <Link className="post__keyword-link" to={ROUTE.TRANSPORTATION__PAID__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "在宅OK" ? 
               <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "リモート" ? 
               <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "週2休み" ? 
               <Link className="post__keyword-link" to={ROUTE.TWO__DAYS__OFF__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "週2・3からOK" ? 
               <Link className="post__keyword-link" to={ROUTE.TWO__THREE__OK__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "大学生OK" ? 
               <Link className="post__keyword-link" to={ROUTE.UNIVERSITY__STUDENT__OK}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "高校生OK" ? 
               <Link className="post__keyword-link" to={ROUTE.HIGHSCHOOL__STUDENT__OK}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "副業・WワークOK" ? 
               <Link className="post__keyword-link" to={ROUTE.SIDE__BUSINESS__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "未経験OK" ? 
               <Link className="post__keyword-link" to={ROUTE.NO__EXPERIENCE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "経験者歓迎" ? 
               <Link className="post__keyword-link" to={ROUTE.WELCOME__EXPERIENCE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "学歴不問" ? 
               <Link className="post__keyword-link" to={ROUTE.EDUCATIONAL__BACKGOUND__NOT__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "産休・育休" ? 
               <Link className="post__keyword-link" to={ROUTE.MATERNITY__CHILDCARE__LEAVE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "研修あり" ? 
               <Link className="post__keyword-link" to={ROUTE.WITH__TRAINING__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "資格手当" ? 
               <Link className="post__keyword-link" to={ROUTE.QUALIFICATION__ALLOWANCE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "家族手当" ? 
               <Link className="post__keyword-link" to={ROUTE.FAMILY__ALLOWANCE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "インセンティブ" ? 
               <Link className="post__keyword-link" to={ROUTE.INSENTIVE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "社保完備" ? 
               <Link className="post__keyword-link" to={ROUTE.SOCIAL__INSURANCE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "育児支援" ? 
               <Link className="post__keyword-link" to={ROUTE.CHILDCARE__SUPPORT__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "急募" ? 
               <Link className="post__keyword-link" to={ROUTE.URGENT__RECRUITMENT__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "Web面接OK" ? 
               <Link className="post__keyword-link" to={ROUTE.WEB__INTERVIEW__OK__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               {keywordsWord[3] && keywordsWord[3] === "福利厚生充実" ? 
               <Link className="post__keyword-link" to={ROUTE.BENEFITS__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
               }
               </>
               :
               <>
               {keywordsWord[3] && keywordsWord[3] && <li>{keywordsWord[3]}</li>}
               </>
               }
            </ul>
         </div>
         :
         <></>
      }
      {typeP.typePost === "jobPosting" ? 
         <div>
            {typeP.underRecrute ? 
            <p className='under__recruite__mark recruite__mark'><i className='fas fa-check-circle recruite__mark-icon'/>採用中</p>
            :
            <p className='done__recruite__mark recruite__mark'><i className='fas fa-times-circle recruite__mark-icon'/>終了</p>
            }
         </div>
         : <></>
      }
      </div>
   </>
   )
}

export default AuthJobStatusPost;