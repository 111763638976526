import React, {useState, forwardRef, useEffect } from 'react';
import {Avatar} from '@material-ui/core';
import db from '../../../firebase';
import { Link, useHistory } from 'react-router-dom';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import '../index.css';
import Popover from '../../../elements/popOver/PopOver';
import * as ROUTE from '../../../constant/router';

const UnAuthJobPost = forwardRef(({
   altText,
   jobTitle,
   prefecture,
   kindJob,
   image,
   senderId,
   postId,
   salary,
   website,
   eventDate,
   eventTime,
   skills,
   wantedJob,
   canWorkPlace,
   hireSection,
   keywordsWord,
   salary2,
   salaryType,
   qualificationShort
   }, ref) => {

   const history = useHistory()

   const [profile, setProfile] = useState({id:'',displayName:'', photoURL: '', verified: false, username: '', followers:[], following:[]})
   const redirectToStatus = postId => history.push(`/posts/${postId}`)

   useEffect(() => {
      if(!profile.length){
         const getUser = db.collection('users').doc(senderId).onSnapshot(snapshot=>{
            setProfile(snapshot.data())
         })
   
         return () => getUser();
      }
   }, [])

   const onClickAlertBookmark = () => {
      window.alert(`ブックマークにはログインが必要です`);
   }
   const onClickAlertApply = () => {
      window.alert(`申し込みにはログインが必要です`);
   }
   const [copySuccess, setCopySuccess] = useState(false);
   const onCopyBtn = () => {
      navigator.clipboard.writeText(`https://copipel.com/#/posts/${postId}`)
      setCopySuccess(true)
   }

   const [typeP, setTypeP] = useState([]);

   useEffect(() => {
      if(!typeP.length){
         const getPost = db.collection('jobs').doc(`${postId}`).onSnapshot(snapshot => {
            setTypeP(snapshot.data())
         })
   
         return () => getPost();
      }
   }, [])


   return (
      <>

         <div className='post post-border' ref={ref}>
            <div className="post__body">
               <div className="post__header">
                  <div className="post__headerText">
                     <div className="post__avatar postStatus__avatar">
                        <Link to={`/profile/${profile.username}`}><Avatar src={profile.photoURL} /></Link>
                     </div>
                     <h3>
                        <Link to={`/profile/${profile.username}`}>
                        {profile.anonymous === true ? <>{"匿名"}</> : <>{profile.displayName}</>}
                        </Link> {' '}
                        <span className='post__headerSpecial'> 
                           {profile.role === "COMPANY" ? <VerifiedUserIcon className='post__badge'/>: <div></div>}
                              @{`${profile.username}`}
                        </span>
                     </h3>

                     <Popover
                        showArrow
                        triggerNode={
                        <div className='post__ExpandIcon'>
                           <button><i className='fas fa-angle-down expandIcon' /></button>
                        </div>
                        }
                        trigger='click'
                     >
                        <ul className="post__expandList">
                           <li>
                              {copySuccess ? <div><i className='fas fa-check post-success-icon post-icon'/>コピーしました</div>
                              :
                              <div className='' onClick={onCopyBtn}><i className='fas fa-paperclip post-clip-icon post-icon'/>URLをコピー</div>}
                           </li>
                           <li onClick={onClickAlertBookmark}><i className='far fa-bookmark post-icon'/>ブックマーク</li>
                        </ul>
                     </Popover>
                  </div>

                  <div className="post__headerDescription" onClick={()=>redirectToStatus(postId)}>
                  {typeP.typePost === 'jobPosting' && image.length>0?
                  <div className='d-flex mobile__img'>
                     <div>{ image.length>0 && <img src={image} alt={altText} onClick={()=>redirectToStatus(postId)} className='post__front__img'/>}</div>
                     <div>
                        <div className='d-flex'><p className='kindJob__text'><span>{kindJob}</span></p><p className='hire__section'><i className='fas fa-caret-down padding-right-3px'/>{hireSection}</p></div>
                        <p className='post__enter'> {jobTitle} </p>
                        <div className='post__enter__top d-flex'>
                           <p><i className='fas fa-map-marker-alt'/> {prefecture}</p>
                           <p className='padding-left-3'><i className='fas fa-money-bill-wave'/> {salaryType}: {salary}円～{salary2}円</p>
                        </div>
                        <div className='qualification__short'>
                           {qualificationShort && qualificationShort && <p><i className='fas fa-arrow-alt-circle-right'/>{qualificationShort}</p>}
                        </div>
                        <div className='post__reqSkill'>
                        {/* <div className='d-flex post__skills'>
                           {(reqSkill[0] && reqSkill[0]) && <p>{reqSkill[0]}</p>}
                           {(reqSkill[1] && reqSkill[1]) && <p>{reqSkill[1]}</p>}
                           {(reqSkill[2] && reqSkill[2]) && <p>{reqSkill[2]}</p>}
                           {(reqSkill[3] && reqSkill[3]) && <p>{reqSkill[3]}</p>}
                           {(reqSkill[4] && reqSkill[4]) && <p>{reqSkill[4]}</p>}
                        </div> */}
                        <p className='post__website'><i className='fas fa-globe'/> {website}</p>
                        </div>
                     </div>
                  </div>
                  :<></>
               
               }
                  {typeP.typePost === 'jobPosting' && image.length === 0?
                  <div>
                  <div className='d-flex'><p className='kindJob__text'><span>{kindJob}</span></p><p className='hire__section'><i className='fas fa-caret-down padding-right-3px'/>{hireSection}</p></div>
                  <p className='post__enter'> {jobTitle} </p>
                  <div className='post__enter__top d-flex'>
                     <p><i className='fas fa-map-marker-alt'/> {prefecture}</p>
                     <p className='padding-left-3'><i className='fas fa-money-bill-wave'/> {salaryType}: {salary}円～{salary2}円</p>
                  </div>
                  <div className='qualification__short'>
                     {qualificationShort && qualificationShort && <p><i className='fas fa-arrow-alt-circle-right'/>{qualificationShort}</p>}
                  </div>
                  <div className='post__reqSkill'>
                     {/* <div className='d-flex post__skills'>
                        {(reqSkill[0] && reqSkill[0]) && <p>{reqSkill[0]}</p>}
                        {(reqSkill[1] && reqSkill[1]) && <p>{reqSkill[1]}</p>}
                        {(reqSkill[2] && reqSkill[2]) && <p>{reqSkill[2]}</p>}
                        {(reqSkill[3] && reqSkill[3]) && <p>{reqSkill[3]}</p>}
                        {(reqSkill[4] && reqSkill[4]) && <p>{reqSkill[4]}</p>}
                     </div> */}
                     <p className='post__website'><i className='fas fa-globe'/> {website}</p>
                  </div>
                  { image.length>0 && <img src={image} alt={altText} onClick={()=>redirectToStatus(postId)} className='post__front__img'/>}
                  </div>
                  :<></>
               
               }
               {typeP.typePost === 'eventPosting' && image.length > 0?
               <div className='d-flex mobile__img'>
                  <div>{ image.length>0 && <img src={image} alt={altText} onClick={()=>redirectToStatus(postId)} className='post__front__img'/>}</div>
                  <div className='width-100'>
                     <p className='kindJob__text'><span>{kindJob}</span></p>
                     <p className='post__enter'> {jobTitle} </p>
                     <div className='post__enter__top d-flex'>
                        <p><i className='fas fa-map-marker-alt'/> {prefecture}</p>
                        <p className='padding-left-3'><i className='fas fa-calendar-day'/> {eventDate} / {eventTime}</p>
                     </div>
                     {website.length>0 && <p className='post__website'><i className='fas fa-globe'/> {website}</p>}
                  </div>
               </div>
                  :<></>
               }
               {typeP.typePost === 'eventPosting' && image.length === 0?
               <div>
                  <div>{ image.length>0 && <img src={image} alt={altText} onClick={()=>redirectToStatus(postId)} className='post__front__img'/>}</div>
                  <div>
                     <p className='kindJob__text'><span>{kindJob}</span></p>
                     <p className='post__enter'> {jobTitle} </p>
                     <div className='post__enter__top d-flex'>
                        <p><i className='fas fa-map-marker-alt'/> {prefecture}</p>
                        <p className='padding-left-3'><i className='fas fa-calendar-day'/> {eventDate} / {eventTime}</p>
                     </div>
                     {website.length>0 && <p className='post__website'><i className='fas fa-globe'/> {website}</p>}
                  </div>
               </div>
                  :<></>
               }
               {typeP.typePost === 'freelancePosting' && image.length === 0?
                  <div>
                  <p className='kindJob__text'><span>{kindJob}</span></p>
                  <p className='post__enter'> {jobTitle} </p>
                  <div className='post__enter__top d-flex'>
                     <p className='padding-left-3'><i className='fas fa-money-bill-wave'/> {salaryType}: {salary}円～{salary2}円</p>           
                  </div>

                  {/* <div className='post__reqSkill post__skills'>
                     <div className='d-flex'>
                        {(reqSkill[0] && reqSkill[0]) && <p>{reqSkill[0]}</p>}
                        {(reqSkill[1] && reqSkill[1]) && <p>{reqSkill[1]}</p>}
                        {(reqSkill[2] && reqSkill[2]) && <p>{reqSkill[2]}</p>}
                        {(reqSkill[3] && reqSkill[3]) && <p>{reqSkill[3]}</p>}
                        {(reqSkill[4] && reqSkill[4]) && <p>{reqSkill[4]}</p>}
                     </div>
                  </div> */}
                  { image.length>0 && <img src={image} alt={altText} onClick={()=>redirectToStatus(postId)} className='post__front__img'/>}
                  </div>
                  :<></>
               }
               {typeP.typePost === 'freelancePosting' && image.length > 0?
               <div className='d-flex mobile__img'>
                  <div>{ image.length>0 && <img src={image} alt={altText} onClick={()=>redirectToStatus(postId)} className='post__front__img'/>}</div>
                  <div>
                     <p className='kindJob__text'><span>{kindJob}</span></p>
                     <p className='post__enter'> {jobTitle} </p>
                     <div className='post__enter__top d-flex'>
                     <p className='padding-left-3'><i className='fas fa-money-bill-wave'/> {salaryType}: {salary}円～{salary2}円</p>           
                     </div>
                     {/* <div className='post__reqSkill post__skills'>
                     <div className='d-flex'>
                        {(reqSkill[0] && reqSkill[0]) && <p>{reqSkill[0]}</p>}
                        {(reqSkill[1] && reqSkill[1]) && <p>{reqSkill[1]}</p>}
                        {(reqSkill[2] && reqSkill[2]) && <p>{reqSkill[2]}</p>}
                        {(reqSkill[3] && reqSkill[3]) && <p>{reqSkill[3]}</p>}
                        {(reqSkill[4] && reqSkill[4]) && <p>{reqSkill[4]}</p>}
                     </div>
                  </div> */}
                  </div>
               </div>
                  :<></>
               }
               {typeP.typePost === 'userPosting'?
                  <div>
                     <div className='d-flex'>
                     <p className='kindJob__text'><span>{kindJob}</span></p>
                     </div>
                  <div className='post__enter__top d-flex'>
                     <p><i className='fas fa-map-marker-alt'/> {canWorkPlace}</p>
                  </div>
                  <div>
                     <div className='post__wanted__job d-flex'>
                        <div className="balloon3-right">
                        今、求めてます
                        </div>
                        <div>
                           <p>{wantedJob}</p>
                        </div>
                     </div>
                  </div>
                  <div className='post__reqSkill d-flex post__skills'>
                     {(skills[0] && skills[0]) && <p>{skills[0]}</p>}
                     {(skills[1] && skills[1]) && <p>{skills[1]}</p>}
                     {(skills[2] && skills[2]) && <p>{skills[2]}</p>}
                     {(skills[3] && skills[3]) && <p>{skills[3]}</p>}
                     {(skills[4] && skills[4]) && <p>{skills[4]}</p>}
                     {(skills[5] && skills[5]) && <p>{skills[5]}</p>}
                     {(skills[6] && skills[6]) && <p>{skills[6]}</p>}
                     {(skills[7] && skills[7]) && <p>{skills[7]}</p>}
                     {(skills[8] && skills[8]) && <p>{skills[8]}</p>}
                     {(skills[9] && skills[9]) && <p>{skills[9]}</p>}
                     {(skills[10] && skills[10]) && <p>{skills[10]}</p>}
                     {(skills[11] && skills[11]) && <p>{skills[11]}</p>}
                  </div>
                  { image.length>0 && <img src={image} alt={altText} onClick={()=>redirectToStatus(postId)} className='post__front__img'/>}
                  </div>
                  :<></>
               }
               </div>
               </div>
               <div className='apply-btn-border footerIcon_wrapper '>
                  <span className='post__apply-btn' onClick={onClickAlertApply}>申し込む</span>
               </div>
               <div className='tabPost__save__post-front'>
                  <button onClick={onClickAlertBookmark}><i className='far fa-bookmark bookmark-icon'/></button>
               </div>
               {
                  typeP.typePost === "jobPosting" || typeP.typePost === "eventPosting" ?
                  <div className='post__keyword'>
                     <ul className='post__keyword-status'>
                        {/* 0-index */}
                        {keywordsWord[0] === "駅近5分以内" || keywordsWord[0] === '交通費支給' || keywordsWord[0] === '在宅OK' || keywordsWord[0] === 'リモート' || keywordsWord[0] === '週2休み' || keywordsWord[0] === "週2・3からOK" || 
                        keywordsWord[0] === "大学生OK" || keywordsWord[0] === '高校生OK' || keywordsWord[0] === '副業・WワークOK' || keywordsWord[0] === '未経験OK' || keywordsWord[0] === '経験者歓迎' || keywordsWord[0] === "学歴不問" ||
                        keywordsWord[0] === "産休・育休" || keywordsWord[0] === '研修あり' || keywordsWord[0] === '資格手当' || keywordsWord[0] === '家族手当' || keywordsWord[0] === 'インセンティブ' || keywordsWord[0] === "社保完備" ||
                        keywordsWord[0] === "育児支援" || keywordsWord[0] === '急募' || keywordsWord[0] === 'Web面接OK' || keywordsWord[0] === '福利厚生充実' ||
                        keywordsWord[0] === '一部リモート' || keywordsWord[0] === '服装自由' || keywordsWord[0] === '日払い・週払い・即日払いOK' || keywordsWord[0] === '時短勤務制度あり' || keywordsWord[0] === '寮・社宅・住宅手当あり' ||
                        keywordsWord[0] === '社員登用あり'
                        ? 
                        <>
                        {keywordsWord[0] && keywordsWord[0] === "一部リモート" ? 
                        <Link className="post__keyword-link" to={ROUTE.SOME__REMOTE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "服装自由" ? 
                        <Link className="post__keyword-link" to={ROUTE.ANY__CLOTHES__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "日払い・週払い・即日払いOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.DAILY__WEEKLY__OK__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "時短勤務制度あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.SHORT__TIME__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "週1日からOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.ONE__OK__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "寮・社宅・住宅手当あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.DORM__HOUSINGALLOWANCE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "社員登用あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.PLOMOTION__TO__EMPLOYEE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "駅近5分以内" ? 
                        <Link className="post__keyword-link" to={ROUTE.NEAR__STATION__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "交通費支給" ? 
                        <Link className="post__keyword-link" to={ROUTE.TRANSPORTATION__PAID__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "在宅OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "リモート" ? 
                        <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "週2休み" ? 
                        <Link className="post__keyword-link" to={ROUTE.TWO__DAYS__OFF__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "週2・3からOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.TWO__THREE__OK__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "大学生OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.UNIVERSITY__STUDENT__OK}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "高校生OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.HIGHSCHOOL__STUDENT__OK}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "副業・WワークOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.SIDE__BUSINESS__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "未経験OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.NO__EXPERIENCE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "経験者歓迎" ? 
                        <Link className="post__keyword-link" to={ROUTE.WELCOME__EXPERIENCE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "学歴不問" ? 
                        <Link className="post__keyword-link" to={ROUTE.EDUCATIONAL__BACKGOUND__NOT__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "産休・育休" ? 
                        <Link className="post__keyword-link" to={ROUTE.MATERNITY__CHILDCARE__LEAVE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "研修あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.WITH__TRAINING__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "資格手当" ? 
                        <Link className="post__keyword-link" to={ROUTE.QUALIFICATION__ALLOWANCE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "家族手当" ? 
                        <Link className="post__keyword-link" to={ROUTE.FAMILY__ALLOWANCE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "インセンティブ" ? 
                        <Link className="post__keyword-link" to={ROUTE.INSENTIVE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "社保完備" ? 
                        <Link className="post__keyword-link" to={ROUTE.SOCIAL__INSURANCE__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "育児支援" ? 
                        <Link className="post__keyword-link" to={ROUTE.CHILDCARE__SUPPORT__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "急募" ? 
                        <Link className="post__keyword-link" to={ROUTE.URGENT__RECRUITMENT__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "Web面接OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.WEB__INTERVIEW__OK__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        {keywordsWord[0] && keywordsWord[0] === "福利厚生充実" ? 
                        <Link className="post__keyword-link" to={ROUTE.BENEFITS__TIMELINE}><li>{keywordsWord[0]}</li></Link> : <></>
                        }
                        </>
                        :
                        <>
                        {keywordsWord[0] && keywordsWord[0] && <li>{keywordsWord[0]}</li>}
                        </>
                        }

                        {/* 1-index */}
                        {keywordsWord[1] === "駅近5分以内" || keywordsWord[1] === '交通費支給' || keywordsWord[1] === '在宅OK' || keywordsWord[1] === 'リモート' || keywordsWord[1] === '週2休み' || keywordsWord[1] === "週2・3からOK" || 
                        keywordsWord[1] === "大学生OK" || keywordsWord[1] === '高校生OK' || keywordsWord[1] === '副業・WワークOK' || keywordsWord[1] === '未経験OK' || keywordsWord[1] === '経験者歓迎' || keywordsWord[1] === "学歴不問" ||
                        keywordsWord[1] === "産休・育休" || keywordsWord[1] === '研修あり' || keywordsWord[1] === '資格手当' || keywordsWord[1] === '家族手当' || keywordsWord[1] === 'インセンティブ' || keywordsWord[1] === "社保完備" ||
                        keywordsWord[1] === "育児支援" || keywordsWord[1] === '急募' || keywordsWord[1] === 'Web面接OK' || keywordsWord[1] === '福利厚生充実' || 
                        keywordsWord[1] === '一部リモート' || keywordsWord[1] === '服装自由' || keywordsWord[1] === '日払い・週払い・即日払いOK' || keywordsWord[1] === '時短勤務制度あり' || keywordsWord[1] === '寮・社宅・住宅手当あり' ||
                        keywordsWord[1] === '社員登用あり'
                        ? 
                        <>
                        {keywordsWord[1] && keywordsWord[1] === "一部リモート" ? 
                        <Link className="post__keyword-link" to={ROUTE.SOME__REMOTE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "服装自由" ? 
                        <Link className="post__keyword-link" to={ROUTE.ANY__CLOTHES__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "日払い・週払い・即日払いOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.DAILY__WEEKLY__OK__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "時短勤務制度あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.SHORT__TIME__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "週1日からOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.ONE__OK__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "寮・社宅・住宅手当あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.DORM__HOUSINGALLOWANCE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "社員登用あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.PLOMOTION__TO__EMPLOYEE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "駅近5分以内" ? 
                        <Link className="post__keyword-link" to={ROUTE.NEAR__STATION__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "交通費支給" ? 
                        <Link className="post__keyword-link" to={ROUTE.TRANSPORTATION__PAID__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "在宅OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "リモート" ? 
                        <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "週2休み" ? 
                        <Link className="post__keyword-link" to={ROUTE.TWO__DAYS__OFF__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "週2・3からOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.TWO__THREE__OK__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "大学生OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.UNIVERSITY__STUDENT__OK}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "高校生OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.HIGHSCHOOL__STUDENT__OK}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "副業・WワークOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.SIDE__BUSINESS__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "未経験OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.NO__EXPERIENCE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "経験者歓迎" ? 
                        <Link className="post__keyword-link" to={ROUTE.WELCOME__EXPERIENCE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "学歴不問" ? 
                        <Link className="post__keyword-link" to={ROUTE.EDUCATIONAL__BACKGOUND__NOT__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "産休・育休" ? 
                        <Link className="post__keyword-link" to={ROUTE.MATERNITY__CHILDCARE__LEAVE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "研修あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.WITH__TRAINING__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "資格手当" ? 
                        <Link className="post__keyword-link" to={ROUTE.QUALIFICATION__ALLOWANCE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "家族手当" ? 
                        <Link className="post__keyword-link" to={ROUTE.FAMILY__ALLOWANCE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "インセンティブ" ? 
                        <Link className="post__keyword-link" to={ROUTE.INSENTIVE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "社保完備" ? 
                        <Link className="post__keyword-link" to={ROUTE.SOCIAL__INSURANCE__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "育児支援" ? 
                        <Link className="post__keyword-link" to={ROUTE.CHILDCARE__SUPPORT__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "急募" ? 
                        <Link className="post__keyword-link" to={ROUTE.URGENT__RECRUITMENT__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "Web面接OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.WEB__INTERVIEW__OK__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        {keywordsWord[1] && keywordsWord[1] === "福利厚生充実" ? 
                        <Link className="post__keyword-link" to={ROUTE.BENEFITS__TIMELINE}><li>{keywordsWord[1]}</li></Link> : <></>
                        }
                        </>
                        :
                        <>
                        {keywordsWord[1] && keywordsWord[1] && <li>{keywordsWord[1]}</li>}
                        </>
                        }

                        {/* 2-index */}
                        {keywordsWord[2] === "駅近5分以内" || keywordsWord[2] === '交通費支給' || keywordsWord[2] === '在宅OK' || keywordsWord[2] === 'リモート' || keywordsWord[2] === '週2休み' || keywordsWord[2] === "週2・3からOK" || 
                        keywordsWord[2] === "大学生OK" || keywordsWord[2] === '高校生OK' || keywordsWord[2] === '副業・WワークOK' || keywordsWord[2] === '未経験OK' || keywordsWord[2] === '経験者歓迎' || keywordsWord[2] === "学歴不問" ||
                        keywordsWord[2] === "産休・育休" || keywordsWord[2] === '研修あり' || keywordsWord[2] === '資格手当' || keywordsWord[2] === '家族手当' || keywordsWord[2] === 'インセンティブ' || keywordsWord[2] === "社保完備" ||
                        keywordsWord[2] === "育児支援" || keywordsWord[2] === '急募' || keywordsWord[2] === 'Web面接OK' || keywordsWord[2] === '福利厚生充実' ||
                        keywordsWord[2] === '一部リモート' || keywordsWord[2] === '服装自由' || keywordsWord[2] === '日払い・週払い・即日払いOK' || keywordsWord[2] === '時短勤務制度あり' || keywordsWord[2] === '寮・社宅・住宅手当あり' ||
                        keywordsWord[2] === '社員登用あり'
                        ? 
                        <>
                        {keywordsWord[2] && keywordsWord[2] === "一部リモート" ? 
                        <Link className="post__keyword-link" to={ROUTE.SOME__REMOTE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "服装自由" ? 
                        <Link className="post__keyword-link" to={ROUTE.ANY__CLOTHES__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "日払い・週払い・即日払いOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.DAILY__WEEKLY__OK__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "時短勤務制度あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.SHORT__TIME__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "週1日からOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.ONE__OK__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "寮・社宅・住宅手当あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.DORM__HOUSINGALLOWANCE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "社員登用あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.PLOMOTION__TO__EMPLOYEE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "駅近5分以内" ? 
                        <Link className="post__keyword-link" to={ROUTE.NEAR__STATION__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "交通費支給" ? 
                        <Link className="post__keyword-link" to={ROUTE.TRANSPORTATION__PAID__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "在宅OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "リモート" ? 
                        <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "週2休み" ? 
                        <Link className="post__keyword-link" to={ROUTE.TWO__DAYS__OFF__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "週2・3からOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.TWO__THREE__OK__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "大学生OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.UNIVERSITY__STUDENT__OK}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "高校生OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.HIGHSCHOOL__STUDENT__OK}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "副業・WワークOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.SIDE__BUSINESS__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "未経験OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.NO__EXPERIENCE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "経験者歓迎" ? 
                        <Link className="post__keyword-link" to={ROUTE.WELCOME__EXPERIENCE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "学歴不問" ? 
                        <Link className="post__keyword-link" to={ROUTE.EDUCATIONAL__BACKGOUND__NOT__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "産休・育休" ? 
                        <Link className="post__keyword-link" to={ROUTE.MATERNITY__CHILDCARE__LEAVE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "研修あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.WITH__TRAINING__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "資格手当" ? 
                        <Link className="post__keyword-link" to={ROUTE.QUALIFICATION__ALLOWANCE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "家族手当" ? 
                        <Link className="post__keyword-link" to={ROUTE.FAMILY__ALLOWANCE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "インセンティブ" ? 
                        <Link className="post__keyword-link" to={ROUTE.INSENTIVE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "社保完備" ? 
                        <Link className="post__keyword-link" to={ROUTE.SOCIAL__INSURANCE__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "育児支援" ? 
                        <Link className="post__keyword-link" to={ROUTE.CHILDCARE__SUPPORT__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "急募" ? 
                        <Link className="post__keyword-link" to={ROUTE.URGENT__RECRUITMENT__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "Web面接OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.WEB__INTERVIEW__OK__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        {keywordsWord[2] && keywordsWord[2] === "福利厚生充実" ? 
                        <Link className="post__keyword-link" to={ROUTE.BENEFITS__TIMELINE}><li>{keywordsWord[2]}</li></Link> : <></>
                        }
                        </>
                        :
                        <>
                        {keywordsWord[2] && keywordsWord[2] && <li>{keywordsWord[2]}</li>}
                        </>
                        }


                        {/* 3-index */}
                        {keywordsWord[3] === "駅近5分以内" || keywordsWord[3] === '交通費支給' || keywordsWord[3] === '在宅OK' || keywordsWord[3] === 'リモート' || keywordsWord[3] === '週2休み' || keywordsWord[3] === "週2・3からOK" || 
                        keywordsWord[3] === "大学生OK" || keywordsWord[3] === '高校生OK' || keywordsWord[3] === '副業・WワークOK' || keywordsWord[3] === '未経験OK' || keywordsWord[3] === '経験者歓迎' || keywordsWord[3] === "学歴不問" ||
                        keywordsWord[3] === "産休・育休" || keywordsWord[3] === '研修あり' || keywordsWord[3] === '資格手当' || keywordsWord[3] === '家族手当' || keywordsWord[3] === 'インセンティブ' || keywordsWord[3] === "社保完備" ||
                        keywordsWord[3] === "育児支援" || keywordsWord[3] === '急募' || keywordsWord[3] === 'Web面接OK' || keywordsWord[3] === '福利厚生充実' ||
                        keywordsWord[3] === '一部リモート' || keywordsWord[3] === '服装自由' || keywordsWord[3] === '日払い・週払い・即日払いOK' || keywordsWord[3] === '時短勤務制度あり' || keywordsWord[3] === '寮・社宅・住宅手当あり' ||
                        keywordsWord[3] === '社員登用あり'
                        ? 
                        <>
                        {keywordsWord[3] && keywordsWord[3] === "一部リモート" ? 
                        <Link className="post__keyword-link" to={ROUTE.SOME__REMOTE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "服装自由" ? 
                        <Link className="post__keyword-link" to={ROUTE.ANY__CLOTHES__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "日払い・週払い・即日払いOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.DAILY__WEEKLY__OK__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "時短勤務制度あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.SHORT__TIME__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "週1日からOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.ONE__OK__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "寮・社宅・住宅手当あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.DORM__HOUSINGALLOWANCE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "社員登用あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.PLOMOTION__TO__EMPLOYEE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "駅近5分以内" ? 
                        <Link className="post__keyword-link" to={ROUTE.NEAR__STATION__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "交通費支給" ? 
                        <Link className="post__keyword-link" to={ROUTE.TRANSPORTATION__PAID__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "在宅OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "リモート" ? 
                        <Link className="post__keyword-link" to={ROUTE.REMOTE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "週2休み" ? 
                        <Link className="post__keyword-link" to={ROUTE.TWO__DAYS__OFF__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "週2・3からOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.TWO__THREE__OK__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "大学生OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.UNIVERSITY__STUDENT__OK}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "高校生OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.HIGHSCHOOL__STUDENT__OK}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "副業・WワークOK" ? 
                        <Link className="post__keyword-link" to={ROUTE.SIDE__BUSINESS__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "未経験OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.NO__EXPERIENCE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "経験者歓迎" ? 
                        <Link className="post__keyword-link" to={ROUTE.WELCOME__EXPERIENCE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "学歴不問" ? 
                        <Link className="post__keyword-link" to={ROUTE.EDUCATIONAL__BACKGOUND__NOT__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "産休・育休" ? 
                        <Link className="post__keyword-link" to={ROUTE.MATERNITY__CHILDCARE__LEAVE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "研修あり" ? 
                        <Link className="post__keyword-link" to={ROUTE.WITH__TRAINING__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "資格手当" ? 
                        <Link className="post__keyword-link" to={ROUTE.QUALIFICATION__ALLOWANCE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "家族手当" ? 
                        <Link className="post__keyword-link" to={ROUTE.FAMILY__ALLOWANCE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "インセンティブ" ? 
                        <Link className="post__keyword-link" to={ROUTE.INSENTIVE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "社保完備" ? 
                        <Link className="post__keyword-link" to={ROUTE.SOCIAL__INSURANCE__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "育児支援" ? 
                        <Link className="post__keyword-link" to={ROUTE.CHILDCARE__SUPPORT__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "急募" ? 
                        <Link className="post__keyword-link" to={ROUTE.URGENT__RECRUITMENT__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "Web面接OK" ? 
                        <Link className="post__keyword-link" to={ROUTE.WEB__INTERVIEW__OK__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        {keywordsWord[3] && keywordsWord[3] === "福利厚生充実" ? 
                        <Link className="post__keyword-link" to={ROUTE.BENEFITS__TIMELINE}><li>{keywordsWord[3]}</li></Link> : <></>
                        }
                        </>
                        :
                        <>
                        {keywordsWord[3] && keywordsWord[3] && <li>{keywordsWord[3]}</li>}
                        </>
                        }
                     </ul>
                  </div>
                  :
                  <></>
               }
               {typeP.typePost === "jobPosting" ? 
               <div>
                  {typeP.underRecrute ? 
                  <p className='under__recruite__mark recruite__mark'><i className='fas fa-check-circle recruite__mark-icon'/>採用中</p>
                  :
                  <p className='done__recruite__mark recruite__mark'><i className='fas fa-times-circle recruite__mark-icon'/>終了</p>
                  }
               </div>
               : <></>
               }
            </div>
         </div>
      </>
   )
  }
)

export default UnAuthJobPost