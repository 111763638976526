import { Switch } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useStateValue } from "../../contexts/StateContextProvider";
import db from "../../firebase";


const SidebarNotificationModal = () => {
    const [{user}] = useStateValue();
    useEffect(() => {
        if(checked === true){
            db.collection('users').doc(user.id).update({
                published: true
            })
        } else if (checked === false){
            db.collection('users').doc(user.id).update({
                published: false
            })
        }
    })

    const [checked, setChecked] = useState(user.published);
    const handleChange = (event) => {
        setChecked(event.target.checked)
    }

    return(
        <div className="sidebar__notification__modal">
            <h3>通知設定</h3>
            <div className="sidebar__account__modal-notification d-flex">
                <p>メール通知設定</p>
                <div className="switch-btn d-flex">
                    <Switch
                       checked={checked}
                       onChange={handleChange}
                       color="primary"
                       name="checked"
                       inputProps={{ 'aria-label': 'primary checkbox' }}
                   />
                   <p>{checked ? '受け取る' : '受け取らない'}</p>
                </div>
            </div>
        </div>
    )
}

export default SidebarNotificationModal;