import React, {useState, useEffect} from 'react';
import HomeBack from '../../../component/homeBack/HomeBack';
import ActiveNavigationofTimeline from '../../../component/navigation/ActiveNavigationofTimeline';
import JobPostingFront from '../../../component/postFront/unAuth/UnAuthJobPostingFront';
import db from '../../../firebase';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useScrollTop from '../../../hooks/useScrollTop';
import '../index.css';
import Loader from '../../../elements/loader/index';
import { Link } from 'react-router-dom';
import { ABOUT__US, HELP__CENTER, PRIVACY__POLICY, USE__AND__TERMS } from '../../../constant/router';
import { CopyrightFooter, PrefectureModal } from '../../../component';
import { TabbarMenu } from '../../../elements';

const UnAuthAllTimeLine = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
       if(!posts.length){
         let mounted = true;
         setLoading(true)
         db.collection('jobs')
         .orderBy('timestamp', 'desc').limit(5)
         .onSnapshot(snapshot=>{
            if(mounted){
               if(snapshot.empty){
                  setLoading(false)
                  return
               }
                  setPosts(snapshot.docs.map(doc=> ({id:doc.id, ...doc.data()})))
                  setLoading(false)
            }
         })
      return () => mounted = false;
       }
   }, []);

   const onRefreshBtn = () => {
      window.location.reload();
   }

   useScrollTop();
   useDocumentTitle("Copipel / コピペル");

   const item = [
      {
         id: 0,
         title: '場所から',
         item: <PrefectureModal/>

      },
      // {
      //    id:1,
      //    title: '職種から',
      //    item: <TimeLineOccupationModal/>
      // },
      // {
      //    id:2,
      //    title: 'アカウント',
      //    item: <TimeLineFindAccountModal/>
      // }
   ]
   
    return (
       <>
         <div className='feed'>
           <HomeBack/>
           <ActiveNavigationofTimeline/>
           <div className="feed__header">
              <h2>タイムライン</h2>
              <button onClick={onRefreshBtn} className='timeLine__load__btn'><i className='fas fa-redo-alt refresh__btn'/></button>        
           </div>

           { loading && <div className="feed__loader"><Loader/></div> }

            <JobPostingFront posts={posts} />
            <div className='timeline__content__box'>
               <h2 className='header-text'>さがす</h2>
               <TabbarMenu items={item}/>
            </div>

            <div className='timeline__info timeline__content__box'>
               <h2 className='header-text'>コピペル情報</h2>
               <div className='timeline__info-flex'>
                  <div className='timeline__info-card  timeline__info-card-margin info-card'>
                     <Link to={ABOUT__US}><h3>コピペルとは</h3></Link>
                  </div>
                  {/* <div className='timeline__info-card  timeline__info-card-margin info-card'>
                     <Link to={HOW__TO__USE}><h3>コピペルの使い方</h3></Link>
                  </div> */}
                  <div className='timeline__info-card  timeline__info-card-margin info-card'>
                     <Link to={USE__AND__TERMS}><h3>利用規約</h3></Link>
                  </div>
               </div>
               <div className='timeline__info-flex'>
                  <div className='timeline__info-card  timeline__info-card-margin info-card'>
                     <Link to={PRIVACY__POLICY}><h3>プライバシーポリシー</h3></Link>
                  </div>
                  <div className='timeline__info-card  timeline__info-card-margin info-card'>
                        <Link to={HELP__CENTER}><h3>お問い合わせ</h3></Link>
                  </div>
               </div>
               <div className='timeline__info-flex'>
               </div>
            </div>
            <CopyrightFooter/>
        </div>
       </>
    )
}

export default UnAuthAllTimeLine;