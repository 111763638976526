import React, { forwardRef, useState } from "react";
import db from "../../../firebase";
import './index.css';

const CompanyPostList = forwardRef(({
    jobTitle,
    senderId,
    postId,
    kindJob,
    briefIntro,
    eventDate,
    eventDetails,
    eventPlace,
    eventTime,
    eventWhy,
    grade,
    hireSection,
    offDay,
    otherAddress,
    prefecture,
    salary,
    styleWork,
    timeWork,
    wanted,
    underRecrute,
    website,
    welfare,
    youtubeLink,
    typePost
}, ref) => {

    const [openEdit, setopenEdit] = useState(false);
    
    const [kindJobInput, setKindJopInput] = useState(`${kindJob}`)
    const [briefIntroInput, setBriefIntroInput] = useState(`${briefIntro}`);
    const [eventDateInput, setEventDataInput] = useState(`${eventDate}`);
    const [eventDetailsInput, setEventDetailsInput] = useState(`${eventDetails}`);
    const [eventTimeInput, setEventTimeInput] = useState(`${eventTime}`);
    const [eventWhyInput, setEventWhyInput] = useState(`${eventWhy}`);
    const [gradeInput, setGradeInput] = useState(`${grade}`);
    const [hireSectionInput, setHireSectionInput] = useState(`${hireSection}`);
    const [offDayInput, setOffDayInput] = useState(`${offDay}`);
    const [otherAddressInput, setOtherAddressInput] = useState(`${otherAddress}`);
    const [prefectureInput, setPrefectureInput] = useState(`${prefecture}`);
    const [salaryInput, setSalaryInput] = useState(`${salary}`);
    const [styleWorkInput, setStyleWorkInput] = useState(`${styleWork}`);
    const [timeWorkInput, setTimeWorkInput] = useState(`${timeWork}`);
    const [wantedInput, setWantedInput] = useState(`${wanted}`);
    const [websiteInput, setWebsiteInput] = useState(`${website}`);
    const [welfareInput, setWelfareInput] = useState(`${welfare}`);
    const [youtubeLinkInput, setYoutubeLinkInput] = useState(`${youtubeLink}`);

    const onUpdateJobPost = () => {
        db.collection('jobs').doc(postId).update({
            kindJob: kindJobInput,
            briefIntro: briefIntroInput,
            eventDate: eventDateInput,
            eventDetails: eventDetailsInput,
            eventTime: eventTimeInput,
            eventWhy: eventWhyInput,
            grade: gradeInput,
            hireSection: hireSectionInput,
            offDay: offDayInput,
            otherAddress: otherAddressInput,
            prefecture: prefectureInput,
            salary: salaryInput,
            styleWork: styleWorkInput,
            timeWork: timeWorkInput,
            wanted: wantedInput,
            website: websiteInput,
            welfare: welfareInput,
            youtubeLink: youtubeLinkInput
        })
    }

    const onDeletePost = () => {
        const confirm = window.confirm('本当に削除しますか？');
        if (confirm === true){
            db.collection('jobs').doc(postId).delete()
        }
        else {
            return
        }
    }

    const onDoneRecruit = () => {
        const confirm = window.confirm("本当に終了しますか？");
        if (confirm === true){
            db.collection('jobs').doc(postId).update({
                underRecrute: false
            })
        }
        else{
            return
        }
    }
 
    return(
        <>
        <div className="company__post-list" ref={ref}>
            <div className="company__post-list-content">
                <h2><small>タイトル: </small><strong>{jobTitle}</strong></h2>
                <p><small>職種: </small><strong>{kindJob}</strong></p>
                {typePost === 'eventPosting' ? <p><small>タイプ: </small><strong>イベント</strong></p>
                :
                <>
                {hireSection === 'フリーランス' ? <p><small>タイプ: </small><strong>フリーランス</strong></p> : <></>}
                {hireSection === 'アルバイト' ? <p><small>タイプ: </small><strong>アルバイト</strong></p> : <></>}
                {hireSection === '正社員' ? <p><small>タイプ: </small><strong>正社員</strong></p> : <></>}
                {hireSection === '業務委託' ? <p><small>タイプ: </small><strong>業務委託</strong></p> : <></>}
                {hireSection === 'インターン' ? <p><small>タイプ: </small><strong>インターン</strong></p> : <></>}
                </>
                }
                {typePost === 'jobPosting' ? 
                            <>
                            {hireSection === 'フリーランス' ? <p><small>タイプ</small><strong>フリーランス</strong></p> : <></>}
                            {hireSection === 'アルバイト' ? <p><small>タイプ</small><strong>アルバイト</strong></p> : <></>}
                            {hireSection === '正社員' ? <p><small>タイプ</small><strong>正社員</strong></p> : <></>}
                            {hireSection === '業務委託' ? <p><small>タイプ</small><strong>業務委託</strong></p> : <></>}
                            {hireSection === 'インターン' ? <p><small>タイプ</small><strong>インターン</strong></p> : <></>}
                            </>
                            :
                            <></>    
                }
                <div className="company__post-list-content-under">
                    {openEdit === false ? 
                        <button onClick={() => setopenEdit(true)}>編集する</button>
                        :
                        <button onClick={() => setopenEdit(false)}>閉じる</button> 
                    }
                    <button onClick={() => onDeletePost()}>削除する</button>
                    {underRecrute === true ? 
                        <button onClick={() => onDoneRecruit()}>募集を終了にする</button> : <></>
                    }
                </div>
            </div>
            <div className="company__post-list-edit">
                {typePost === 'jobPosting' && openEdit === true ? 
                <div className="company__post-list-edit-back">
                    <div className="company__post-list-edit-input company__post-list-flex">
                        <select onChange={e => setKindJopInput(e.target.value)} value={kindJobInput}>
                            <option disabled selected>職種の選択</option>
                            {/*営業*/}
                            <option disabled>営業</option>
                            <option value='法人営業'>法人営業</option>
                            <option value='個人営業'>個人営業</option>
                            <option value='内勤営業・カウンターセールス'>内勤営業・カウンターセールス</option>
                            <option value='ルートセールス・代理店営業'>ルートセールス・代理店営業</option>
                            <option value='IT営業'>IT営業</option>
                            <option value='海外営業'>海外営業</option>
                            <option value='不動産営業'>不動産営業</option>
                            <option value='金融営業'>金融営業</option>
                            <option value='テレマーケティング'>テレマーケティング</option>

                            {/*事務・アシスタント*/}
                            <option disabled>事務・アシスタント</option>
                            <option value='一般事務'>一般事務</option>
                            <option value='営業事務'>営業事務</option>
                            <option value='カスタマーサポート'>カスタマーサポート</option>
                            <option value='受付'>受付</option>
                            <option value='秘書'>秘書</option>

                            {/*財務・会計・経理*/}
                            <option value='経理'>経理</option>
                            <option value='財務'>財務</option>
                            <option value='管理会計'>管理会計</option>

                            {/*総務・人事・法務・知財・広報・IR*/}
                            <option disabled>総務・人事・法務・知財・広報・IR</option>
                            <option value='人事（採用）'>人事（採用）</option>
                            <option value='人事（労務）'>人事（労務）</option>
                            <option value='総務'>総務</option>
                            <option value='広報'>広報</option>
                            <option value='法務'>法務</option>

                            {/*商品企画・営業企画・マーケティング・宣伝*/}
                            <option disabled>商品企画・営業企画・マーケティング・宣伝</option>
                            <option value='商品企画、サービス企画'>商品企画、サービス企画</option>
                            <option value='販売促進・PR・広告宣伝'>販売促進・PR・広告宣伝</option>
                            <option value='Webマーケティング'>Webマーケティング</option>

                            {/*経営企画・事業統括・新規事業開発*/}
                            <option disabled>経営企画・事業統括・新規事業開発</option>
                            <option value='経営者・経営幹部・役員(CEO、COO、CFO、CIO、CTO)'>経営者・経営幹部・役員（CEO、COO、CFO、CIO、CTO）</option>

                            {/*管理職・エグゼクティブ*/}
                            <option disabled>管理職・エグゼクティブ</option>
                            <option value='経営企画・営業企画'>経営企画・営業企画</option>
                            <option value='事業企画・新規事業開発'>事業企画・新規事業開発</option>
                            <option value='事業統括マネージャー職（営業部門・管理部門）'>事業統括マネージャー職（営業部門・管理部門）</option>

                            {/*Webエンジニア*/}
                            <option disabled>Webエンジニア</option>
                            <option value='システムエンジニア（Web・オープン系・パッケージ開発）'>システムエンジニア（Web・オープン系・パッケージ開発）</option>
                            <option value='システムエンジニア（汎用機系）'>システムエンジニア（汎用機系）</option>
                            <option value='プロジェクトマネジャー（Web・オープン系・パッケージ開発）'>プロジェクトマネジャー（Web・オープン系・パッケージ開発）</option>
                            <option value='フロントエンド'>フロントエンド</option>
                            <option value='バックエンド'>バックエンド</option>
                            <option value='フルスタック'>フルスタック</option>

                            {/*インフラエンジニア*/}
                            <option disabled>インフラエンジニア</option>
                            <option value='サーバーエンジニア（設計構築）'>サーバーエンジニア（設計構築）</option>
                            <option value='ネットワークエンジニア（設計構築）'>ネットワークエンジニア（設計構築）</option>
                            <option value='データベースエンジニア'>データベースエンジニア</option>
                            <option value='プロジェクトマネージャー（インフラ）'>プロジェクトマネージャー（インフラ）</option>
                            <option value='Webサービスエンジニア（ネットワーク・サーバー・データベース）'>Webサービスエンジニア（ネットワーク・サーバー・データベース）</option>
                            <option value='運用・監視・保守（インフラ）'>運用・監視・保守（インフラ）</option>

                            {/*サポート・ヘルプデスク*/}
                            <option disabled>サポート・ヘルプデスク</option>
                            <option value='テクニカルサポート・カスタマーサポート'>テクニカルサポート・カスタマーサポート</option>
                            <option value='ヘルプデスク'>ヘルプデスク</option>

                            {/*社内SE（社内情報システム）*/}
                            <option disabled>社内SE（社内情報システム）</option>
                            <option value='システム開発・運用（アプリ担当）'>システム開発・運用（アプリ担当）</option>
                            <option value='システム構築・運用（インフラ担当）'>システム構築・運用（インフラ担当）</option>
                            <option value='IT戦略・システム企画担当'>IT戦略・システム企画担当</option>

                            {/*Web・モバイル（制作・開発）*/}
                            <option disabled>Web・モバイル（制作・開発）</option>
                            <option value='Webプロデューサー・Webディレクター・Webプランナー'>Webプロデューサー・Webディレクター・Webプランナー</option>
                            <option value='Webデザイナー'>Webデザイナー</option>
                            <option value='マークアップエンジニア・コーダー・フロントエンドエンジニア'>マークアップエンジニア・コーダー・フロントエンドエンジニア</option>
                            <option value='Webライター・Web編集（コンテンツ制作）'>Webライター・Web編集（コンテンツ制作）</option>
                            <option value='UI・UXデザイナー（Web・モバイル）'>UI・UXデザイナー（Web・モバイル）</option>
                            <option value='アシスタントディレクター・制作進行管理'>アシスタントディレクター・制作進行管理</option>

                            {/*広告・グラフィック*/}
                            <option disabled>広告・グラフィック</option>
                            <option value='フォトグラファー、カメラマン'>フォトグラファー、カメラマン</option>

                            {/*出版・広告・販促・印刷*/}
                            <option disabled>出版・広告・販促・印刷</option>
                            <option value='イラストレーター【広告・グラフィック関連】'>イラストレーター【広告・グラフィック関連】</option>
                            <option value='DTPオペレーター'>DTPオペレーター</option>
                            <option value='その他広告・グラフィック関連職'>その他広告・グラフィック関連職</option>
                            <option value='編集、記者、ライター'>編集、記者、ライター</option>
                            <option value='デザイナー（グラフィック・その他）'>デザイナー（グラフィック・その他）</option>

                            {/*店舗・販売*/}
                            <option disabled>店舗・販売</option>
                            <option value='店長'>店長</option>
                            <option value='ホールスタッフ'>ホールスタッフ</option>
                            <option value='調理スタッフ'>調理スタッフ</option>

                            {/*美容関連*/}
                            <option disabled>美容関連</option>
                            <option value='美容師・理容師・その他美容関連'>美容師・理容師・その他美容関連</option>
                            <option value='美容部員・エステティシャン・マッサージ・ビューティーアドバイザー'>美容部員・エステティシャン・マッサージ・ビューティーアドバイザー</option>

                            {/*ブライダル・葬祭*/}
                            <option disabled>ブライダル・葬祭</option>
                            <option value='ウェディングプランナー'>ウェディングプランナー</option>
                            <option value='葬祭ディレクター・プランナー'>葬祭ディレクター・プランナー</option>
                            <option value='その他ブライダル・葬祭関連'>その他ブライダル・葬祭関連</option>

                            {/*旅行関連*/}
                            <option disabled>旅行関連</option>
                            <option value='旅行手配員・添乗員・ツアーコンダクター'>旅行手配員・添乗員・ツアーコンダクター</option>

                            {/*宿泊施設・ホテル*/}
                            <option disabled>宿泊施設・ホテル</option>
                            <option value='施設管理・マネジメント'>施設管理・マネジメント</option>
                            <option value='フロント業務・予約受付'>フロント業務・予約受付</option>
                            <option value='その他宿泊施設・ホテル関連'>その他宿泊施設・ホテル関連</option>

                            {/*教育／スクール／研修／塾講師／専門学校／英会話学校*/}
                            <option disabled>教育／スクール／研修／塾講師／専門学校／英会話学校</option>
                            <option value='スクール長・マネジャー'>スクール長・マネジャー</option>
                            <option value='講師・指導員・インストラクター'>講師・指導員・インストラクター</option>

                            {/*建築設計・積算*/}
                            <option disabled>建築設計・積算</option>
                            <option value='意匠設計・構造設計'>意匠設計・構造設計</option>
                            <option value='製図・CADオペレーター'>製図・CADオペレーター</option>
                            <option value='内装設計・インテリア・空間デザイン'>内装設計・インテリア・空間デザイン</option>
                            <option value='積算（建築設計）'>積算（建築設計）</option>
                            <option value='内装設計（店舗・オフィス）'>内装設計（店舗・オフィス）</option>
                            <option value='内装設計・リフォーム・インテリア（住宅）'>内装設計・リフォーム・インテリア（住宅）</option>

                            {/*施工管理・職人・現場作業員*/}
                            <option disabled>施工管理・職人・現場作業員</option>
                            <option value='建築施工管理（RC造・S造・SRC造・木造）'>建築施工管理（RC造・S造・SRC造・木造）</option>
                            <option value='建築施工管理（店舗内装・オフィス内装）'>建築施工管理（店舗内装・オフィス内装） </option>
                            <option value='建築施工管理（住宅内装・リフォーム・インテリア'>建築施工管理（住宅内装・リフォーム・インテリア</option>
                            <option value='設備施工管理（電気・空調・衛生設備）'>設備施工管理（電気・空調・衛生設備） </option>
                            <option value='土木施工管理（トンネル・道路・造成・ダム・河川・港湾・造園など）'>土木施工管理（トンネル・道路・造成・ダム・河川・港湾・造園など）</option>

                            {/*その他建設・建築・不動産・プラント・工場関連職*/}
                            <option disabled>その他建設・建築・不動産・プラント・工場関連職</option>
                            <option value='その他建設・建築・不動産・プラント・工場関連職'>その他建設・建築・不動産・プラント・工場関連職</option>

                            {/*医療・看護*/}
                            <option disabled>医療・看護</option>
                            <option value='病院長・事務長'>病院長・事務長</option>
                            <option value='医師・看護師'>医師・看護師</option>
                            <option value='歯科医師・歯科衛生士・歯科技工士'>歯科医師・歯科衛生士・歯科技工士</option>
                            <option value='臨床心理士・カウンセラー'>臨床心理士・カウンセラー</option>
                            <option value='栄養士・管理栄養士'>栄養士・管理栄養士</option>
                            <option value='その他医療・看護'>その他医療・看護</option>

                            {/*福祉*/}
                            <option disabled>福祉</option>
                            <option value='院長・福祉施設長'>院長・福祉施設長</option>
                            <option value='介護福祉士・ケアマネジャー'>介護福祉士・ケアマネジャー</option>
                            <option value='ホームヘルパー・ケアワーカー'>ホームヘルパー・ケアワーカー</option>

                            {/*その他・専門職*/}
                            <option disabled>その他・専門職</option>
                            <option value='その他・専門職'>その他・専門職</option>
                        </select>

                        <select onChange={e => setHireSectionInput(e.target.value)} value={hireSectionInput}>
                            <option disabled selected>雇用区分を選択する</option>
                            <option value='正社員'>正社員</option>
                            <option value='アルバイト'>アルバイト</option>
                            <option value='業務委託'>業務委託</option>
                            <option value='フリーランス'>フリーランス</option>
                            <option value='インターン'>インターン</option>
                        </select>
                    </div>
                    <div className="company__post-list-edit-input company__post-list-flex">
                        <select onChange={e => setGradeInput(e.target.value)} value={gradeInput}>
                            <option disabled selected>新卒 / 中途</option>
                            <option value='新卒'>新卒</option>
                            <option value='中途'>中途</option>
                            <option value='どちらでも構わない'>どちらでも構わない</option>
                        </select>
                        <select onChange={(e) => setStyleWorkInput(e.target.value)} value={styleWorkInput}>
                            <option disabled selected>勤務スタイルを選択する</option>
                            <option value="完全リモート">完全リモート</option>
                            <option value='一部リモート'>一部リモート</option>
                            <option value='出勤のみ'>出勤のみ</option>
                        </select>
                    </div>
                    <div className="company__post-list-edit-input company__post-list-flex">
                        <select onChange={e => setPrefectureInput(e.target.value)} value={prefectureInput}>
                            <option disabled selected>県を選択する</option>
                            <option value="リモート">リモート</option>
                            <option value="北海道">北海道</option>
                            <option value="青森">青森</option>
                            <option value="岩手">岩手</option>
                            <option value="宮城">宮城</option>
                            <option value="秋田">秋田</option>
                            <option value="山形">山形</option>
                            <option value="福島">福島</option>
                            <option value="茨城">茨城</option>
                            <option value="栃木">栃木</option>
                            <option value="群馬">群馬</option>
                            <option value="埼玉">埼玉</option>
                            <option value="千葉">千葉</option>
                            <option value="東京">東京</option>
                            <option value="神奈川">神奈川</option>
                            <option value="新潟">新潟</option>
                            <option value="富山">富山</option>
                            <option value="石川">石川</option>
                            <option value="福井">福井</option>
                            <option value="山梨">山梨</option>
                            <option value="長野">長野</option>
                            <option value="岐阜">岐阜</option>
                            <option value="静岡">静岡</option>
                            <option value="愛知">愛知</option>
                            <option value="三重">三重</option>
                            <option value="滋賀">滋賀</option>
                            <option value="京都">京都</option>
                            <option value="大阪">大阪</option>
                            <option value="兵庫">兵庫</option>
                            <option value="奈良">奈良</option>
                            <option value="和歌山">和歌山</option>
                            <option value="鳥取">鳥取</option>
                            <option value="島根">島根</option>
                            <option value="岡山">岡山</option>
                            <option value="広島">広島</option>
                            <option value="山口">山口</option>
                            <option value="徳島">徳島</option>
                            <option value="香川">香川</option>
                            <option value="愛媛">愛媛</option>
                            <option value="高知">高知</option>
                            <option value="福岡">福岡</option>
                            <option value="佐賀">佐賀</option>
                            <option value="長崎">長崎</option>
                            <option value="熊本">熊本</option>
                            <option value="大分">大分</option>
                            <option value="宮崎">宮崎</option>
                            <option value="鹿児島">鹿児島</option>
                            <option value="沖縄">沖縄</option>
                        </select>
                        <input type='text' placeholder='勤務地の場所(残りの住所)' value={otherAddressInput} onChange={e => setOtherAddressInput(e.target.value)}/>
                    </div>
                    <div className="company__post-list-edit-input company__post-list-flex">
                        <select onChange={e=> setSalaryInput(e.target.value)} value={salaryInput}>
                            <option disabled selected>給与を選択する</option>
                            <option value="300万円以上">300万円以上</option>
                            <option value="400万円以上">400万円以上</option>
                            <option value="500万円以上">500万円以上</option>
                            <option value="600万円以上">600万円以上</option>
                            <option value="700万円以上">700万円以上</option>
                            <option value="800万円以上">800万円以上</option>
                            <option value="900万円以上">900万円以上</option>
                            <option value="1000万円以上">1000万円以上</option>
                        </select>
                        <input type='text' placeholder='ウェブサイトURL(申し込み用のURLなど)' value={websiteInput} onChange={e => setWebsiteInput(e.target.value)}/>
                    </div>
                    <div className="company__post-list-edit-input company__post-list-flex">
                        <input type='text' placeholder='Youtube URL(会社の紹介動画など)' value={youtubeLinkInput} onChange={e => setYoutubeLinkInput(e.target.value)}/>
                        <input type='text' placeholder='勤務制度' value={timeWorkInput} onChange={e => setTimeWorkInput(e.target.value)}/>
                    </div>
                    <textarea rows='3' 
                        placeholder="簡単な紹介...(例: どんな会社か、どういうことを行っているか)"
                        type='text' 
                        value={briefIntroInput}
                        onChange={e=> setBriefIntroInput(e.target.value)}
                    >
                    </textarea>
                    <textarea rows='3' 
                        placeholder="任せたい仕事内容..."
                        type='text' 
                        value={wantedInput}
                        onChange={e=> setWantedInput(e.target.value)}            
                    >
                    </textarea>
                    <textarea rows='3' 
                        placeholder="福利厚生"
                        type='text' 
                        value={welfareInput}
                        onChange={e=> setWelfareInput(e.target.value)}       
                        >
                    </textarea>
                    <textarea rows='3' 
                        placeholder="休日・休暇"
                        type='text' 
                        value={offDayInput}
                        onChange={e=> setOffDayInput(e.target.value)}           
                    >
                    </textarea>

                    <button className="company__post-list-submit" onClick={() => onUpdateJobPost()}>変更する</button>
                </div>
                :<></>
                }
                {typePost === 'eventPosting' && openEdit === true ? 
                <div className="company__post-list-edit-back">
                    <div className="company__post-list-edit-input company__post-list-flex">
                        <input type='date' placeholder='開催日' value={eventDateInput} onChange={e => setEventDataInput(e.target.value)}/>
                        <input type='time' placeholder='開催時間' value={eventTimeInput} onChange={e => setEventTimeInput(e.target.value)}/>
                    </div>
                    <div className="company__post-list-edit-input company__post-list-flex">
                        <select onChange={e => setPrefectureInput(e.target.value)} value={prefectureInput}>
                            <option disabled selected>県を選択する</option>
                            <option value="リモート">リモート</option>
                            <option value="北海道">北海道</option>
                            <option value="青森">青森</option>
                            <option value="岩手" >岩手</option>
                            <option value="宮城" >宮城</option>
                            <option value="秋田" >秋田</option>
                            <option value="山形" >山形</option>
                            <option value="福島" >福島</option>
                            <option value="茨城" >茨城</option>
                            <option value="栃木" >栃木</option>
                            <option value="群馬" >群馬</option>
                            <option value="埼玉" >埼玉</option>
                            <option value="千葉" >千葉</option>
                            <option value="東京" >東京</option>
                            <option value="神奈川" >神奈川</option>
                            <option value="新潟" >新潟</option>
                            <option value="富山" >富山</option>
                            <option value="石川" >石川</option>
                            <option value="福井" >福井</option>
                            <option value="山梨" >山梨</option>
                            <option value="長野" >長野</option>
                            <option value="岐阜" >岐阜</option>
                            <option value="静岡" >静岡</option>
                            <option value="愛知" >愛知</option>
                            <option value="三重" >三重</option>
                            <option value="滋賀" >滋賀</option>
                            <option value="京都" >京都</option>
                            <option value="大阪" >大阪</option>
                            <option value="兵庫" >兵庫</option>
                            <option value="奈良" >奈良</option>
                            <option value="和歌山" >和歌山</option>
                            <option value="鳥取" >鳥取</option>
                            <option value="島根" >島根</option>
                            <option value="岡山" >岡山</option>
                            <option value="広島" >広島</option>
                            <option value="山口" >山口</option>
                            <option value="徳島" >徳島</option>
                            <option value="香川" >香川</option>
                            <option value="愛媛" >愛媛</option>
                            <option value="高知" >高知</option>
                            <option value="福岡" >福岡</option>
                            <option value="佐賀" >佐賀</option>
                            <option value="長崎" >長崎</option>
                            <option value="熊本" >熊本</option>
                            <option value="大分" >大分</option>
                            <option value="宮崎" >宮崎</option>
                            <option value="鹿児島" >鹿児島</option>
                            <option value="沖縄" >沖縄</option>
                        </select>
                        <input type='text' placeholder='残りの住所' value={otherAddressInput} onChange={e => setOtherAddressInput(e.target.value)}/>
                    </div>
                    <div className="company__post-list-edit-input company__post-list-flex">
                        <input type='text' placeholder='ウェブサイトURL(申し込み用のURLなど)' value={websiteInput} onChange={e => setWebsiteInput(e.target.value)} />
                        <input type='text' placeholder='Youtube URL(会社の紹介動画など)' value={youtubeLinkInput} onChange={e => setYoutubeLinkInput(e.target.value)}/>
                    </div>
                    <textarea 
                        rows='3' 
                        placeholder="なぜ開催するのか"
                        type='text' 
                        value={eventWhyInput}
                        onChange={e=> setEventWhyInput(e.target.value)}
                        required                     
                    >
                    </textarea>
                    <textarea 
                        rows='3' 
                        placeholder="イベント内容"
                        type='text' 
                        value={eventDetailsInput}
                        onChange={e=> setEventDetailsInput(e.target.value)}               
                    >
                    </textarea>
                    <button className="company__post-list-submit" onClick={() => onUpdateJobPost()}>変更する</button>
                </div>
                : <></>
                }
            </div>
        </div>
        </>
    )
})
export default CompanyPostList;