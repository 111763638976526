import React, {useState, useEffect} from 'react';
import { useMediaQuery } from 'react-responsive';
import { useStateValue } from '../../contexts/StateContextProvider';
import { useRoomState } from '../../contexts/IsRoomOpenedContextProvider';
import db from '../../firebase';
import noMessage from '../../assets/noMessage.png';
import SidebarChatOption from '../sidebarChatoption/SidebarChatOption';
import './index.css';

const SidebarChat = () => {
    const [{user}] = useStateValue()
    const [{isRoomOpened}] = useRoomState()
    const [existingRooms, setExistingRooms] = useState([])
    const isMobile = useMediaQuery({ maxWidth: 914 })

    useEffect(() => {
        if(!existingRooms.length){
            const unsubscribe = db.collection('rooms').where('members', 'array-contains', user.id).onSnapshot(snapshot => 
                setExistingRooms(snapshot.docs.map((doc)=>{
                    return {
                        id: doc.id,
                        ...doc.data()}}))
                )
            return ()=>unsubscribe()
        }
    }, [])


    const dynamicSearch = () => existingRooms.filter(room=>room.alias.toLowerCase())
    return (
            <>
                <div className={`sidebarChat ${(isRoomOpened && isMobile) && 'noned'}`}>
                    <div className="sidebarChat__header">
                        <h2>メッセージ</h2>       
                    </div>      

                    <div className="sidebarChat__chats">
                    {existingRooms.length > 0 ? <></> : 
                    <div className='sidebarChat__noExist'>
                        <h2>メッセージを始めよう</h2>
                        <img src={noMessage} alt=''/>
                        <div className='how__toStart d-flex'>
                            <button><i className='far fa-question-circle chat__question-icon'/></button>
                            <p>メッセージの始め方</p>
                        </div>
                    </div>
                    
                    }
                    {
                        dynamicSearch().map(room=>(
                          <SidebarChatOption key={room.id}
                            members = {room.members}
                            roomId = {room.id}
                          />
                        ))
                    }
                    </div>            

                </div>
            </>
    )
}

export default SidebarChat
