import React, { useState, useEffect } from "react";
import logo2 from '../../../assets/logo2.png';
import db from "../../../firebase";
import { useHistory } from "react-router-dom";
import './index.css';
import { useDocumentTitle, useScrollTop } from "../../../hooks";
import { LOGIN } from "../../../constant/router";
import passwordsValidate from "../../../helpers/passwordInpurValidate";

const ForgetPasswordContent = ({email}) => {
    const initValues = {
        password: '',
        confirmPassword: ''
    }

    const [passwordState, setPasswordState] = useState(initValues);
    const [error, setError] = useState(false);
    const [profile, setProfile] = useState([]);
    const [getCode, setGetCode] = useState('');
    const [isMatchCode, setIsMatchCode] = useState(false);
    const [code, setCode] = useState('');
    const history = useHistory();
    const [msg, setMeg] = useState(false);
    useEffect(() => {
        const getUser = db.collection('users').where('email', '==', `${email}`).onSnapshot(snapshot=>{
            setProfile(snapshot.docs.map(doc=>({
                id: doc.id,
                ...doc.data()
            }))[0])
            })

        return () => getUser();
      }, [])

      useEffect(() => {
        const getCodes = db.collection('confirmEmail').where('email', '==', `${email}`).onSnapshot(snapshot=>{
            setGetCode(snapshot.docs.map(doc=>({
                  id: doc.id,
                  ...doc.data()
                }))[0])
              })
            return () => getCodes();
      }, [])

    const onSubmitCode = () => {
        if (email){
            if(`${getCode.code}` === `${code}`){
                db.collection('users').doc(`${profile.id}`).update({
                    emailConformation: true
                })
                setMeg(true)
                setTimeout(() => {
                    db.collection('confirmEmail').doc(`${getCode.id}`).delete();
                }, 5000)
                setIsMatchCode(true)
            } else {
                setError('コードをもう一度お確かめください')
            }
        }

    }
    const onSignoutBtn = () => {
        localStorage.clear()
        history.push(LOGIN)
        window.location.reload()
    }

    const [message, setMessage] = useState(false);
    const onChangePassword = (e) => {
        const {password, confirmPassword} = passwordState
        e.preventDefault();
        if(!error.length){
            if(password === confirmPassword){
                db.collection('users').doc(profile.id).update({
                    password: password
                })
                setMessage(true);
                setTimeout(() => {
                    onSignoutBtn()
                }, 5000)
            } else {
                setError(passwordsValidate(passwordState))
            }
        }
    }

    let min = 11111111
    let max = 99999999
    const randomNum = Math.floor(Math.random() * (max-min)) + min;

    const onResubmitCode = () => {
        db.collection('confirmEmail').doc(getCode.id).delete();
        db.collection('confirmEmail').add({
            email: email,
            code: randomNum
        })
    }

    useScrollTop();
    useDocumentTitle("パスワード変更 / コピペル")

    return(
        <>
            {isMatchCode ? 
            <>
            <div className="forget__password">
                <div className="card login-form">
                    <div className="card-body">
                        <img src={logo2} alt="" className="logo__form__page"/>
                        <h3 className="card-title">パスワード変更</h3>
                        <div>
                            <div className="form-group">
                                <label>新しいパスワードを入力してください</label>
                                <input type="password" className="form-control form-control-sm" value={passwordState.password} placeholder="パスワード" onChange={(e) => setPasswordState({...passwordState, password: e.target.value})}/>
                                <input type="password" className="form-control form-control-sm" value={passwordState.confirmPassword} placeholder="もう一度パスワードを入力してください" onChange={(e) => setPasswordState({...passwordState, confirmPassword: e.target.value})}/>
                                {error.length>0 && <div className='error'>{error}</div>}
                                {message ? <p>パスワードを変更しました。ログアウトします...</p> : <></>}
                            </div>
                            <button className="btn btn-primary btn-block" onClick={onChangePassword}>パスワード変更する</button>
                        </div>
                        
                    </div>
                </div>
            </div>
            </>
            :
            <>
            <div className="forget__password">
                <div className="card login-form">
                    <div className="card-body width-50rem">
                        <img src={logo2} alt="" className="logo__form__page logo__form__code"/>
                        <h3 className="card-title text-center">メールアドレス認証</h3>
                        <div className="card-text">
                            <div className="form-group">
                                <div>
                                    <label>認証コードを入力してください</label>
                                    <input type="text" maxLength='8' name="code" className="form-control form-control-sm" value={code} placeholder="例)12345678" onChange={(e) => setCode(e.target.value)}/>
                                </div>
                            </div>
                            {msg ? <p>認証コードがマッチしました...</p> : <></>}
                            <div>
                                <button className="btn btn-primary btn-block code-btn" onClick={onSubmitCode}><span>コードを認証する</span></button>
                                {error.length>0 && <div className='error'>{error}</div>}
                            </div> 
                        </div>
                        <div className="resend__code opacity-hover">
                            <button onClick={onResubmitCode}>コードを再送信する</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
            } 
        </>
    );
};

export default ForgetPasswordContent;
