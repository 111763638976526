import React, {useEffect, useState} from "react";
import { useStateValue } from "../../../contexts/StateContextProvider";
import db from "../../../firebase";
import AdminDashboardMini from "../../components/card/AdminDashboardMini";
import Chart from "../../components/chart/Chart";
import GetCompany from "../../components/getUser/GetCompany";
import GetUser from "../../components/getUser/GetUser";
import './index.css';

const Dashboard = () => {
    const [users, setUsers] = useState([]);
    const [companys, setCompanys] = useState([]);
    const [jobPost, setJobPost] = useState([]);
    const [eventPost, setEventPost] = useState([]);
    const [userPost, setUserPost] = useState([]);
    const [{user}] = useStateValue()
    useEffect(() => {
        if(!users.length){
            const getUser = db.collection('users').where("role", '==', "USER").onSnapshot(snapshot=>{
                setUsers(snapshot.docs.map(user=>({
                   id:user.id,
                   ...user.data()
                })))
          })
 
         return () => getUser();
        }
        if(!companys.length){
            const getUser = db.collection('users').where("role", '==', "COMPANY").onSnapshot(snapshot=>{
                setCompanys(snapshot.docs.map(user=>({
                   id:user.id,
                   ...user.data()
                })))
          })
 
         return () => getUser();
        }

        if(!jobPost.length){
            const getPost = db.collection('jobs').where("typePost", '==', "jobPosting").onSnapshot(snapshot=>{
                setJobPost(snapshot.docs.map(user=>({
                   id:user.id,
                   ...user.data()
                })))
          })
 
         return () => getPost();
        }


        if(!eventPost.length){
            const getPost = db.collection('jobs').where("typePost", '==', "eventPosting").onSnapshot(snapshot=>{
                setEventPost(snapshot.docs.map(user=>({
                   id:user.id,
                   ...user.data()
                })))
          })
 
         return () => getPost();
        }

        if(!userPost.length){
            const getPost = db.collection('jobs').where("typePost", '==', "userPosting").onSnapshot(snapshot=>{
                setUserPost(snapshot.docs.map(user=>({
                   id:user.id,
                   ...user.data()
                })))
          })
 
         return () => getPost();
        }
    }, []);

    return(
        <>
        {user.role === "ADMIN" ? 
        <div className="admin__dashboard">
            <div className="admin__dashboard-1">
                <div className="d-flex admin__dashboard-1-content">
                    <AdminDashboardMini icon='fas fa-user' title='ユーザー' unit={users.length} unitTitle='人'/>
                    <AdminDashboardMini icon='fas fa-building' title='企業' unit={companys.length} unitTitle='社'/>
                    <AdminDashboardMini icon='fas fa-paste' title='求人投稿' unit={jobPost.length} unitTitle='本'/>
                    <AdminDashboardMini icon='fas fa-paste' title='イベント投稿' unit={eventPost.length} unitTitle='本'/>
                    <AdminDashboardMini icon='fas fa-paste' title='ユーザー投稿' unit={userPost.length} unitTitle='本'/>
                </div>
            </div>
            <div className="admin__dashboard-2">
                <div className="admin__dashboard-2-flex">
                    <div className="admin__dashboard-2-chart">
                        <Chart users={users}/>
                    </div>
                    <div className="admin__dashboard-2-chart">
                        <Chart/>
                    </div>
                </div>
            </div>
            <div className="admin__dashboard-3">
                <div className="admin__dashboard-3-content">
                    <div className="admin__dashboard-3-card">
                        <GetUser users={users}/>
                    </div>
                    <div className="admin__dashboard-3-card">
                        <GetCompany users={companys}/>
                    </div>
                </div>
            </div>
        </div>
        : <></>
        }
        </>
    )
}

export default Dashboard;