import React from "react";
import { NavLink, useParams } from "react-router-dom";
import '../index.css';

const CompanyProfileTopbar = () => {
    const {username} = useParams();
    return(
        <div className="active__navigation">
        <ul>
            <li><NavLink exact to={`/profile/${username}`}  activeClassName="active__user__profile" replace><span>プロフィール</span></NavLink></li>
            <li><NavLink to={`/profile/${username}/job`}  activeClassName="active__user__profile" replace><span>求人投稿</span></NavLink></li>
            <li><NavLink to={`/profile/${username}/freelance`}  activeClassName="active__user__profile" replace><span>フリーランス投稿</span></NavLink></li>
            <li><NavLink to={`/profile/${username}/event`}  activeClassName="active__user__profile" replace><span>イベント投稿</span></NavLink></li>
        </ul>
    </div>
    )
}

export default CompanyProfileTopbar;