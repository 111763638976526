import React from "react";
import { Link, NavLink } from "react-router-dom";
import { COMPANY__DASHBOARD, COMPANY__DASHBOARD__ALLPOST, COMPANY__DASHBOARD__MANAGEPOST, COMPANY__DASHBOARD__POST, COMPANY__DASHBOARD__SEARCH, HOME } from "../../constant/router";

const CompanyDashboardNav = () => {
    return(
        <div className="company__dashboard-nav-mobile">
            <div className="company__dashboard-nav-mobile-item">
                <NavLink
                    activeClassName="sidenavigation-menu-active-mobile"
                    className="sidenavigation-menu-mobile"
                    to={COMPANY__DASHBOARD}
                    >
                    <i className="fab fa-delicious company__dashboard-icon"/>
                </NavLink>
                <NavLink
                    activeClassName="sidenavigation-menu-active-mobile"
                    className="sidenavigation-menu-mobile"
                    to={COMPANY__DASHBOARD__POST}
                    >
                    <i className="fas fa-plus company__dashboard-icon"/>
                </NavLink>
                <NavLink
                    activeClassName="sidenavigation-menu-active-mobile"
                    className="sidenavigation-menu-mobile"
                    to={COMPANY__DASHBOARD__ALLPOST}
                    >
                    <i className="far fa-list-alt company__dashboard-icon"/>
                </NavLink>
                <NavLink
                    activeClassName="sidenavigation-menu-active-mobile"
                    className="sidenavigation-menu-mobile"
                    to={COMPANY__DASHBOARD__MANAGEPOST}
                    >
                    <i className="fas fa-edit company__dashboard-icon"/>
                </NavLink>
                <NavLink
                    activeClassName="sidenavigation-menu-active-mobile"
                    className="sidenavigation-menu-mobile"
                    to={COMPANY__DASHBOARD__SEARCH}
                    >
                    <i className="fas fa-search company__dashboard-icon"/>
                </NavLink>
                <Link
                    className="sidenavigation-menu-mobile"
                    to={HOME}
                    >
                    <i className="fas fa-home company__dashboard-icon"/>
                    </Link>
            </div>
        </div>
    )
}

export default CompanyDashboardNav;