import React, { useState } from "react";
import logo2 from '../../assets/logo2.png';
import { Link, useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close'
import './index.css';
import MessageModal from "../../elements/modal/MessageModal";
import { ABOUT__US, COMPANY__DASHBOARD, HELP__CENTER, HOME, PRIVACY__POLICY, USE__AND__TERMS } from "../../constant/router";


const CompanyMobileTopNav = () => {
    const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);
    const history = useHistory();

    const onAbout = () => {
        setIsOpenInfoModal(false)
        history.push(ABOUT__US)
    }
    const onHelp = () => {
        setIsOpenInfoModal(false)
        history.push(HELP__CENTER)
    }
    const onUse = () => {
        setIsOpenInfoModal(false)
        history.push(USE__AND__TERMS)
    }
    const onPrivacy = () => {
        setIsOpenInfoModal(false)
        history.push(PRIVACY__POLICY)
    }
    return(
        <>
        
        <MessageModal
               open={isOpenInfoModal} 
               onClose={()=>setIsOpenInfoModal(false)}
               Icon = {CloseIcon}
               >
                <div className="unAuth__topMenu">
                    <div className="unAuth__topBar__content">
                        <button onClick={onAbout}>Copipelとは</button>
                        {/* <button onClick={onHow}>Copipelの使い方</button> */}
                        <button onClick={onHelp}>ヘルプセンター</button>
                        <button onClick={onUse}>利用規約</button>
                        <button onClick={onPrivacy}>プライバシーポリシー</button>
                    </div>
                </div>
            </MessageModal>  
        <div className="mobile__top__nav">
            <div className="d-flex">
                <button className='menuBarInfo__btn' onClick={() => setIsOpenInfoModal(true)}><i className='fas fa-bars topBar__menu'/></button>
                <div className="mobile__top__nav__img">
                    <Link to={HOME}><img src={logo2} alt="Logo"/></Link>
                </div>
                <div className="mobile__btn">
                    <Link to={COMPANY__DASHBOARD}><button>ダッシュボード</button></Link>
                </div>
            </div>
        </div>
        </>
    )
}


export default CompanyMobileTopNav;