import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import profileDefault from '../../../../assets/profileDefault.png';
import db from '../../../../firebase';
import '../../index.css';

const UnAuthCompanyProfile = () => {
    const {username} = useParams();
    const [profile, setProfile] = useState({bio:'', displayName:'', followers: [], following:[], location:'', photoURL:'', websiteURL1:'', websiteURL2: '', websiteURL3: '', twitterURL: '', lineURL: '', facebookURL: '', instagramURL: '', profileBrief: '' , currentDoing: '' , careerYear1: '' })

    useEffect(() => {
        if(!profile.length){
            const getData = db.collection('users').where('username', '==', username).onSnapshot(snapshot=>{
                setProfile(snapshot.docs.map(doc=>({
                    id: doc.id,
                    ...doc.data()
                }))[0])
                })
            return () => getData();
        }
      }, [])


    return(
        <div className='user__profile'>
            <div className='company__profile__column1 user__profile__column1 profile__card'>
                <div className="d-flex">
                    
                    {profile && profile.photoURL? 
                    <div>{(profile && profile.photoURL) && <img src={profile.photoURL} alt={`${profile && profile.displayName}`} />}</div>
                    :
                    <div><img src={profileDefault} alt={`${profile && profile.displayName}`}/></div>
                }
                        <div className="user__profile__basic">
                            <h1>{profile && profile.displayName}</h1>
                            <div className="infoWrapper">
                            <div className="userProfile__actions">
                        
                            </div>
                            <div className="countInfo">
                                <Link to={`/profile/${username}/following`} replace>
                                    <span>{profile && profile.following.length} <p>フォロー中</p></span>
                                </Link>
                                <Link to={`/profile/${username}/followed`} replace> 
                                    <span>{profile!==undefined && profile.followers.length} <p>フォロワー</p></span>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile__website">
                    <ul>
                        {(profile && profile.websiteURL1) && <li><i className='fas fa-link link__icon'/><a href={`${profile.websiteURL1}`}>{profile.websiteURL1}</a></li>}
                        {(profile && profile.websiteURL2) && <li><i className='fas fa-link link__icon'/><a href={`${profile.websiteURL2}`}>{profile.websiteURL2}</a></li>}
                        {(profile && profile.websiteURL3) && <li><i className='fas fa-link link__icon'/><a href={`${profile.websiteURL3}`}>{profile.websiteURL3}</a></li>}
                    </ul>
                </div>
            </div>
            <div className='company__profile__column2 user__profile__column2 profile__card'>
                <ul className="snsbtniti d-flex padding-left-35">
                    {(profile && profile.youtubeURL) || (profile && profile.twitterURL) || (profile && profile.instagramURL) || (profile && profile.facebookURL) || (profile && profile.lineURL) ? <div></div>: <p className="unwrite__text">SNSのURLがまだ記入されていません</p>}
                    {(profile && profile.youtubeURL) && <li><a href={`${profile && profile.youtubeURL}`} className="flowbtn8 fl_yu2"><i className="fab fa-youtube"/></a></li>}
                    {(profile && profile.twitterURL) && <li><a href={`${profile && profile.twitterURL}`} className="flowbtn8 fl_tw2"><i className="fab fa-twitter"/></a></li>}
                    {(profile && profile.instagramURL) && <li><a href={`${profile && profile.instagramURL}`} className="flowbtn8 insta_btn2"><i className="fab fa-instagram"/></a></li>}
                    {(profile && profile.facebookURL) && <li><a href={`${profile && profile.facebookURL}`} className="flowbtn8 fl_fb2"><i className="fab fa-facebook-f"/></a></li>}
                    {(profile && profile.lineURL) &&  <li><a href={`${profile && profile.lineURL}`} className="flowbtn8 fl_li2"><i className="fab fa-line"/></a></li>}
                </ul>
            </div>
            <div className='company__profile__column3 user__profile__column3 profile__card'>
                <div className="text__back__box">
                    <h2>{profile && profile.displayName}について</h2>
                    <div className="back__text">
                        About Us
                    </div>
                </div>
                {profile && profile.briefIntro ? <p>{profile.briefIntro}</p>:<p className="unwrite__text">簡単な自己紹介がまだ記入されていません</p>}
            </div>

            <div className='company__profile__column4 user__profile__column3 profile__card'>
                <div className="text__back__box">
                    <h2>何をしているか？</h2>
                    <div className="back__text">
                        What we doing?
                    </div>
                </div>
                {profile && profile.doing ? <p>{profile.doing}</p>:<p className="unwrite__text">何をしているかはまだ記入されていません</p>}
            </div>

            <div className='company__profile__column5 user__profile__column3 profile__card'>
                <div className="text__back__box">
                    <h2>特徴</h2>
                    <div className="back__text">
                        Features
                    </div>
                </div>
                {profile && profile.features ? <p>{profile.features}</p>:<p className="unwrite__text">特徴がまだ記入されていません</p>}
            </div>

            <div className='company__profile__column5 profile__card'>
                <div className="text__back__box">
                    <h2>会社情報</h2>
                    <div className="back__text">
                        Company Info
                    </div>
                </div>
                <div className='company__info'>
                    <ul>
                        {(profile && profile.prefecture) || (profile && profile.founder) || (profile && profile.founder) || (profile && profile.whenFound) || (profile && profile.peopleWorking) ? <div></div> : <p className='unwrite__text'>会社情報はまだ記入されていません</p>}
                        {(profile && profile.prefecture) && <li><i className='fas fa-map-marker-alt company__info__icon'/><strong>{profile.prefecture} {profile.otherAddress}</strong></li>}
                        {(profile && profile.founder) && <li><i className='fas fa-user-tie company__info__icon'/><strong>{profile.founder}</strong> / <small>創設者</small></li>}
                        {(profile && profile.whenFound) && <li><i className='far fa-calendar-alt company__info__icon'/><strong>{profile.whenFound}</strong> / <small>創設年</small></li>}
                        {(profile && profile.peopleWorking) && <li><i className='fas fa-users company__info__icon'/><strong>{profile.peopleWorking}</strong>人 / <small>従業員数</small></li>}
                    </ul>
                </div>
            </div>

            <div className='company__profile__column6 profile__card user__profile__column3'>
                <div className="text__back__box">
                    <h2>企業からのメッセージ</h2>
                    <div className="back__text">
                        Message
                    </div>
                </div>
                {(profile && profile.message) && <p>{profile.message}</p>}
            </div>
        </div>
    )
}

export default UnAuthCompanyProfile;
