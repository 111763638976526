import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import db from "../../firebase";
import AuthJobPost from "../postFront/auth/AuthJobPost";
import './index.css';

const AuthShowTabJobPost = () => {
    const {username} = useParams()
    const [profile, setProfile] = useState({});
    const [tabSave, setTabSave] = useState([]);

    useEffect(() => {
       if(username){
          db.collection('users').where('username', '==', username).onSnapshot(snapshot=>{
          setProfile(snapshot.docs.map(doc=>({
             id: doc.id,
             ...doc.data()
          }))[0])
          })         
       }
    }, [])

   useEffect(() => {
    if(profile.id){
       db.collection('jobs').where('bookMark', 'array-contains', profile.id)
       .onSnapshot(snapshot=> {
       setTabSave(snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()})))
       }) 
    }

 }, [profile])

    return(
        <div>
           {tabSave.length > 0 ? <></> : <p className="profile__no__post">現在、ブックマークはありません</p>}
            {tabSave && tabSave.map(post => (
               <AuthJobPost
               key={post.id}
               postId = {post.id}
               altText = {post.altText}
               senderId = {post.senderId}
               username = {post.username}
               kindJob = {post.kindJob}
               prefecture = {post.prefecture}
               jobTitle = {post.jobTitle}
               avatar = {post.avatar}
               image = {post.image}
               timestamp = {post.timestamp}
               likes = {post.likes}
               bookMark = {post.bookMark}
               styleWork = {post.styleWork}
               salary = {post.salary}
               reqSkill = {post.reqSkill}
               website = {post.website}
               eventPlace = {post.eventPlace}
               eventDate = {post.eventDate}
               eventTime = {post.eventTime}
               skills = {post.skills}
               wantedJob = {post.wantedJob}
               canWorkPlace = {post.canWorkPlace}
                  />
            ))}
        </div>
    )
}

export default AuthShowTabJobPost;