import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { logo2 } from "../../../assets";
import { LOGIN } from "../../../constant/router";
import db from "../../../firebase";
import '../index.css';

const SignupConfirmEmail = ({email}) => {
    const [code, setCode] = useState('');
    const [getCode, setGetCode] = useState('');

    const [msg, setMeg] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();
    const [profile, setProfile] = useState([]);

    useEffect(() => {
        const getUser = db.collection('users').where('email', '==', `${email}`).onSnapshot(snapshot=>{
            setProfile(snapshot.docs.map(doc=>({
                id: doc.id,
                ...doc.data()
            }))[0])
            })

        return () => getUser();
      }, [])

      useEffect(() => {
        const getCode = db.collection('confirmEmail').where('email', '==', `${email}`).onSnapshot(snapshot=>{
            setGetCode(snapshot.docs.map(doc=>({
                  id: doc.id,
                  ...doc.data()
                }))[0])
              })
            return () => getCode();
      }, [])

    const onSubmitCode = () => {
        if (email){
            if(`${getCode.code}` === code){
                db.collection('users').doc(profile.id).update({
                    emailConformation: true
                })
                setMeg(true)
                setTimeout(() => {
                    db.collection('confirmEmail').doc(getCode.id).delete();
                    history.push(LOGIN);
                    window.location.reload();
                }, 5000)
            } else {
                setError('コードをもう一度お確かめください')
            }
        }

    }

    let min = 11111111
    let max = 99999999
    const randomNum = Math.floor(Math.random() * (max-min)) + min;

    const onResubmitCode = () => {
        db.collection('confirmEmail').doc(getCode.id).delete();
        db.collection('confirmEmail').add({
            email: email,
            code: randomNum
        })
    }

    return(
        <>
        <div className="forget__password">
            <div className="card login-form">
                <div className="card-body width-50rem">
                    <img src={logo2} alt="" className="logo__form__page logo__form__code"/>
                    <h3 className="card-title text-center">メールアドレス認証</h3>
                    <div className="card-text">
                        <div className="form-group">
                            <div>
                                <label>認証コードを入力してください</label>
                                <input type="text" maxLength='8' name="code" className="form-control form-control-sm" value={code} placeholder="例)12345678" onChange={(e) => setCode(e.target.value)}/>
                            </div>
                        </div>
                        {msg ? <p>メール認証を完了しました。5秒後に自動的に戻ります...</p> : <></>}
                        <div>
                            <button className="btn btn-primary btn-block code-btn" onClick={onSubmitCode}><span>コードを認証する</span></button>
                            {error.length>0 && <div className='error'>{error}</div>}
                        </div> 
                    </div>
                    <div className="resend__code opacity-hover">
                        <button onClick={onResubmitCode}>コードを再送信する</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}


export default SignupConfirmEmail;