import React, { useState, useEffect } from 'react';
import db from '../../../firebase';
import { Loader, MessageModal } from '../../../elements';
import { ActiveNavigationofTimeline, AuthJobPostingFront, CompanyMobileTopNav, UserMobileTopNav } from '../../../component';
import { useStateValue } from '../../../contexts/StateContextProvider';
import { useDocumentTitle, useScrollTop } from '../../../hooks';
import '../index.css';
import { Link } from 'react-router-dom';

const AuthAllTimeLine = () => {
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(false)
    const [{user}] = useStateValue();
    const [isOpenModal, setIsOpenModal] = useState(true);

    useEffect(() => {
       if(!posts.length){
         let mounted = true;
         setLoading(true)
         db.collection('jobs')
         .orderBy('timestamp', 'desc')
         .onSnapshot(snapshot=>{
            if(mounted){
               if(snapshot.empty){
                  setLoading(false)
                  return
               }
                  setPosts(snapshot.docs.map(doc=> ({id:doc.id, ...doc.data()})))
                  setLoading(false)
            }
         }) 
      return () => mounted = false;
       }

   }, []);

   const [profile, setProfile] = useState([])
   useEffect(() => {
       if(!profile.length){
           const getUser = db.collection('users').where('username', '==', user.username).onSnapshot(snapshot=>{
               setProfile(snapshot.docs.map(doc=>({
                 id: doc.id,
                 ...doc.data()
               }))[0])
             })
       return () => getUser();
       }
     }, [])

    const onRefreshBtn = () => {
       window.location.reload();
    }
    useScrollTop();
    useDocumentTitle("Copipel / コピペル");

    const onUpdate = () => {
      return db.collection('users').doc(`${user.id}`).update({
         firstTime: false
      })
    }

   const onCloseMessage = () => {
      setIsOpenModal(false)
      onUpdate()
   }
    return (
       <>
       {profile && profile.firstTime === true ?
      <MessageModal
      open={isOpenModal}
      onClose={() => onCloseMessage()}
      >
        <div className="first__message">
            <h2><span>Copipelにご登録いただき<br/>ありがとうございます。</span></h2>
            <div className="first__message-content">
                <h3>ご利用前に</h3>
                1. プロフィールに情報を入力ください。<Link to={`/profile/${profile.username}`}><button className='opacity' onClick={() => onUpdate()}>プロフィールへ</button></Link>
                <br/>
                <br/>
                2. 興味を知らせるボタンをクリック
                <br/>
                <br/>
                3. 相手がチャットして来るまで待ちましょう!
            </div>
        </div>
      </MessageModal>
      :
      <div className='feed'>
         {user.role === "USER" ? <UserMobileTopNav/> : <CompanyMobileTopNav/>}
         <ActiveNavigationofTimeline/>
         <div className="feed__header">
            <h2>タイムライン</h2>
            <button onClick={onRefreshBtn} className='timeLine__load__btn'><i className='fas fa-redo-alt refresh__btn'/></button>
         </div>
         { loading && <div className="feed__loader"><Loader/></div> }
         <AuthJobPostingFront posts={posts} />
      </div> 
      }
       </>
    )
}

export default AuthAllTimeLine;