import { CLOSING_ROOM, OPENING_ROOM } from "../../constant/constant"


export const initialState = {
    isRoomOpened: null
}


const isRoomOpenededucer = (state, action) => {
    switch (action.type) {
        case OPENING_ROOM:
            return {
                isRoomOpened: true
            }
        
        case CLOSING_ROOM:
            return {
                isRoomOpened: false
            }

        default:
            return state
    }
}

export default isRoomOpenededucer