import React, {useState, useEffect, useContext} from 'react';
import db from '../../firebase';
import {useStateValue} from '../../contexts/StateContextProvider';
import { useParams, Redirect, Link } from 'react-router-dom';
import { ChatContext } from '../../contexts/ChatContextProvider';
import './index.css';
import { REMOVE_ROOM } from '../../constant/constant';

const CreateChatBtn= () => {
    const [{user}] = useStateValue();

    const [check, setCheck] = useState([])
    const [check2, setCheck2] = useState([])
    const {username} = useParams();
    const initProfile={
        bio:'',
        displayName:'', 
        followers:[], 
        following:[], 
        id: '', 
        location: '', 
        photoURL: '', 
        username: '', 
        wallpaper: '', 
        website: '', 
        careerYear1: '',
    }
    const [profile, setProfile] = useState(initProfile)
    const {dispatch} = useContext(ChatContext);

    useEffect(() => {
            const getUser = db.collection('users').where('username', '==', username).onSnapshot(snapshot=>{
                setProfile(snapshot.docs.map(doc=>({
                  id: doc.id,
                  ...doc.data()
                }))[0])
              })
            return () => getUser();
      }, []);

    useEffect(() => {
        const getCheck = db.collection('rooms').where('members', 'array-contains', user.id).onSnapshot(snapshot => 
            setCheck(snapshot.docs.map((doc) => {
                return{
                    id: doc.id,
                    ...doc.data(),
                }
            }))
        );
        return () => getCheck();
    }, [])

    useEffect(() => {
        const getCheck = db.collection('rooms').where('members', 'array-contains', profile.id).onSnapshot(snapshot => 
            setCheck2(snapshot.docs.map((doc) => {
                return{
                    id: doc.id,
                    ...doc.data(),
                }
            }))
        )
        return () => getCheck();
    }, [])

    const createChat = () => {
        if(matchId.length !== 0){
            return <Redirect to={`/messages/${matchId}`}/>
        } else {
            db.collection('rooms').add({
                alias: `${user.displayName} ${user.username} ${profile.displayName} ${profile.username}`,
                members: [user.id, profile.id]
            })
            dispatch({
                type: REMOVE_ROOM,
                id: profile.id
            })
        }
    }


    const onCreateChat = () => {
        if(createChat()){
            return <Redirect from={`/profile/${user.username}`} to={`/messages/${matchId}`}/>
        }
    }
    let list1 = [];
    let list2 = [];
    check && check.map(i => {
        return list1.push(`${i.id}`)
    })

    check2 && check2.map(j => {
        return list2.push(`${j.id}`)
    })

    const matchId = list1.filter(el => list2.includes(el));

    return (
        <>
        <div className='mail__btn'>
            <Link to={`/messages/${matchId}`} onClick={onCreateChat} className="mail__btn__link">
                <i className='far fa-envelope'/>メッセージ
            </Link>
        </div>
        </>
    )
}

export default CreateChatBtn;