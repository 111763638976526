import React, {useEffect, useState} from 'react'
import { useStateValue } from '../../contexts/StateContextProvider';


const TagPost = ({likes, likeAction, unlikeAction}) => {
    const [{user}] = useStateValue()
    const [isLiked, setisLiked] = useState(false)

    useEffect(() => {
        if(user.id && likes){
            if(likes.includes(user.id)){
                setisLiked(true)
            } else {
                setisLiked(false)
            }
        }
    }, [likes])

    return (
        <div className="">
            { isLiked?
                <span className='' onClick={unlikeAction}><i className='fas fa-bookmark bookmark-icon'/></span>
            :
                <span className='' onClick={likeAction}><i className='far fa-bookmark bookmark-icon'/></span>
            }

        </div>
    );
};

export default TagPost;