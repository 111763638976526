import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';

import { useStateValue } from '../../contexts/StateContextProvider';
import { useRoomState } from '../../contexts/IsRoomOpenedContextProvider';
import db from '../../firebase';
import { OPENING_ROOM } from '../../constant/constant';
import './index.css';
import { UserItemFollow } from '..';

const ConversationInfo = () => {
    const history = useHistory()
    const {roomId} = useParams()
    const [{user}] = useStateValue()
    const [{isRoomOpened}, dispatch] = useRoomState()

    const [members, setMembers] = useState('')
    const [display, setDisplay] = useState({});

    useEffect(() => {
      if (roomId){
         db.collection('rooms').doc(roomId).onSnapshot(snapshot=>{
            setMembers(snapshot.data() && snapshot.data().members.filter(userId=>userId!==user.id)[0])
         })

         dispatch({
            type: OPENING_ROOM,  
         })

         if(isRoomOpened){}
      }
    }, [roomId])

    useEffect(() => {
      if(members){
         db.collection('users')
         .doc(members)
         .onSnapshot(snapshot=>{
            setDisplay(snapshot.data())
         })
      }
    }, [members])


    return (
        <div className="conversationInfo">
           <div className="conversationInfo__header">
             <div className="conversationInfo__header-back">
                <ArrowBackOutlinedIcon onClick={()=>history.goBack()}/>
             </div>
              <h2>ユーザー</h2>     
           </div>

           <UserItemFollow display={display}/>

        </div>
    )
}

export default ConversationInfo;
