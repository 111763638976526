import React, { useEffect, useState } from 'react';
import { useStateValue } from '../../contexts/StateContextProvider';
import db from '../../firebase';
import UserItemFollowing from '../userItemFollow/UserItemFollowing';
import './index.css';

const AuthWidgetFollow = () => {
   const [{user}] = useStateValue()
   const [users, setUsers] = useState([])

   useEffect(() => {
      if(!users.length){
         let mounted = true
         db.collection('users').orderBy('joined', 'desc').limit(12).onSnapshot(snapshot=>{
            if(mounted){
               setUsers(snapshot.docs.map(user=>({
                  id:user.id,
                  ...user.data()
               })))
            }
         })
         return () => mounted = false
      }
   }, [])

    return (
           <div className="widgets__widgetContainer">
              <h2>新しい企業</h2>

              <ul className='widgets__trendsContainer'>

              {
                 users && users.filter(u=> u.id!==user.id && u.role==="COMPANY" && u.displayName !== 'Copipel').map(user=> {
                    return <li key={user.id}><UserItemFollowing display={user}/></li>
                 })
              }
              </ul>
           </div>
    )
}

export default AuthWidgetFollow
