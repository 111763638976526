import React from "react";
import { useDocumentTitle, useScrollTop } from "../../hooks";
import './index.css';


const UseAndTerms = () => {
    useScrollTop();
    useDocumentTitle('利用規約 / コピペル');
    
    return(
        <>
        <div className="use__and__terms">Use</div>
        </>
    )
}

export default UseAndTerms;