import React from 'react';
import './index.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
    
  } from 'chart.js';
import {Bar} from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const Chart = ({users}) => {
    const ageList = []
    const ageList2 = {}
    users && users.map(i => {
        return ageList.push(i.age)
    })

    for (let l of ageList){
        if (l in ageList2){
            ageList2[l] += 1
        } else{
            ageList2[l] = 1
        }
    }

    const ageTitle = []
    const ageLabel = []
    for(const [key, val] of Object.entries(ageList2)) {
        ageTitle.push(key)
        ageLabel.push(val)
      }

    const state = {
        labels: ageTitle,
        datasets: [
          {
            backgroundColor: '#7b68ee',
            borderColor: '#7b68ee',
            borderWidth: 1,
            data: ageLabel
          }
        ]
    }
    return(
        <div className='chart__box'>
        <Bar
          data={state}
          options={{
            title:{
              display:true,
              text:'Average Rainfall per month',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        />
      </div>
    )
}


export default Chart;