import React from "react";
import { useDocumentTitle, useScrollTop } from "../../hooks";

const NonUser = () => {
    useDocumentTitle("Copipel / 404");
    useScrollTop();
    return(
    <section className="page_404">
        <div className="container">
            <div className="row"> 
                <div className="col-sm-12 ">
                    <div className="col-sm-10 col-sm-offset-1  text-center">
                        <div className="four_zero_four_bg">
                        <h1 className="text-center ">404</h1>
                        </div>
                    <div className="contant_box_404">
                        <h3 className="h2">
                        ユーザーが存在しません。もう一度お確かめください。
                        </h3>
                        <a href="https://copipel.com/#/" className="link_404">ホームに戻る</a>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}


export default NonUser;