import React from "react";
import { aboutUs } from "../../assets";
import { useDocumentTitle, useScrollTop } from "../../hooks";
import './index.css';

const AboutUs = () => {
    useDocumentTitle("コピペルとは");
    useScrollTop();

    return(
        <>
        <div className="about-us">
        <div className="about__us__img addImg">
                <img src={aboutUs} alt=""/>
            </div>
            <div className="about__us__meg">
                <h2>What is "copipel"</h2>
                <p>copipelは、仕事探しやキャリアの発信、ユーザー同士でのやりとりができる仕事に特化したSNSです。<br/><br/>
                ここでは、あなたが欲しいノウハウを持つ人や企業の採用担当に直接聞いたり、<br/>
                あなたのことが気になるユーザーからの質問に答えたり、<br/>
                気になる企業に求職者として応募したり、
                新たな外注先を発掘したり、<br/>
                気になる分野の情報を集めるために自由にコミュニケーションができます。<br/><br/>
                さらに、経験・スキル・体験などを一つのキャリアシートにまとめURL化できるため<br/>
                見てもらいたい相手に送ることや企業への直接エントリーが可能です。<br/><br/>
                求人サイトや人材紹介会社ごとの煩わしい登録手続きからあなたを解放し、<br/>
                応募から採用までにかかる時間を短縮します。<br/><br/>
                キャリアシートを全体公開に変更すれば、<br/>
                企業の採用担当やあなたのことが気になる人から<br/>
                積極的にメッセージを受け取ることもできます。<br/><br/>
                copipelは、あなたの仕事探しから仕事情報の収集・発信までを一元管理し、<br/>
                理想のキャリア形成の実現をサポートします。<br/>
                </p>
            </div>
        </div>
        </>
    );
};


export default AboutUs;