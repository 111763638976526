import { Link, NavLink } from 'react-router-dom';
import React from "react";
import './index.css';
import { COMPANY__DASHBOARD, COMPANY__DASHBOARD__ALLPOST, COMPANY__DASHBOARD__CONTACT, COMPANY__DASHBOARD__MANAGEPOST, COMPANY__DASHBOARD__POST, COMPANY__DASHBOARD__SEARCH, HOME } from '../../constant/router';

const CompanyDashboardNav = () => {
    return(
            <aside className="sidenavigation">
                <h3>COMPANY</h3>
                <div className="sidenavigation-wrapper">
                    <div className="sidenavigation-item">
                        <NavLink
                    activeClassName="sidenavigation-menu-active"
                    className="sidenavigation-menu"
                    to={COMPANY__DASHBOARD}
                    >
                        <div className='d-flex'>
                            <i className="fab fa-delicious company__dashboard-icon"/>
                            <p>ダッシュボード</p>
                        </div>
                    </NavLink>
                    <NavLink
                    activeClassName="sidenavigation-menu-active"
                    className="sidenavigation-menu"
                    to={COMPANY__DASHBOARD__POST}
                    >
                        <div className='d-flex'>
                            <i className="fas fa-plus company__dashboard-icon"/>
                            <p>投稿する</p>
                        </div>
                    </NavLink>
                    <NavLink
                    activeClassName="sidenavigation-menu-active"
                    className="sidenavigation-menu"
                    to={COMPANY__DASHBOARD__ALLPOST}
                    >
                        <div className='d-flex'>
                            <i className="far fa-list-alt company__dashboard-icon"/>
                            <p>投稿一覧</p>
                        </div>
                    </NavLink>
                    <NavLink
                    activeClassName="sidenavigation-menu-active"
                    className="sidenavigation-menu"
                    to={COMPANY__DASHBOARD__MANAGEPOST}
                    >
                        <div className='d-flex'>
                            <i className="fas fa-edit company__dashboard-icon"/>
                            <p>投稿管理</p>
                        </div>
                    </NavLink>
                    <NavLink
                    activeClassName="sidenavigation-menu-active"
                    className="sidenavigation-menu"
                    to={COMPANY__DASHBOARD__SEARCH}
                    >
                        <div className='d-flex'>
                            <i className="fas fa-search company__dashboard-icon"/>
                            <p>ユーザーを探す</p>
                        </div>
                    </NavLink>
                    <NavLink
                    activeClassName="sidenavigation-menu-active"
                    className="sidenavigation-menu"
                    to={COMPANY__DASHBOARD__CONTACT}
                    >
                        <div className='d-flex'>
                            <i className="far fa-comments company__dashboard-icon"/>
                            <p>お問い合わせ</p>
                        </div>
                    </NavLink>
                    <Link
                    className="sidenavigation-menu"
                    to={HOME}
                    >
                        <div className='d-flex'>
                            <i className="fas fa-home company__dashboard-icon"/>
                            <p>ホームへ</p>
                        </div>
                    </Link>
                    </div>
                </div>
          </aside>
    )
}


export default CompanyDashboardNav;