import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import firebase from 'firebase';

import { useStateValue } from "../../../../contexts/StateContextProvider";
import db from "../../../../firebase";
import postToCloudinary from "../../../../helpers/postToCloudinary";
import { follow, unfollow } from "../../../../redux/actions/serverActions";
import profileDefault from '../../../../assets/profileDefault.png';
import '../../index.css';
import EditBasicProfile from "../../../editProfile/EditBasicProfile";
import EditCareerProfile from "../../../editProfile/EditCareerProfile";
import EditQualiProfile from "../../../editProfile/EditQualiProfile";
import MessageModal from "../../../../elements/modal/MessageModal";
import ProfileTabbarMenu from '../../../../elements/tabbarMenu/ProfileTabbarMenu';
import Spinner from "../../../../elements/spinner/Spinner";
import CreateChatBtn from "../../../createChatBtn/CreateChatBtn";

const AuthUserProfile = () => {
    const {username} = useParams();
    const [profile, setProfile] = useState({bio:'', displayName:'', followers: [], following:[], location:'', photoURL:'', websiteURL1:'', websiteURL2: '', websiteURL3: '', twitterURL: '', lineURL: '', facebookURL: '', instagramURL: '', profileBrief: '' , currentDoing: '' , careerYear1: '' })
    const [{user}] = useStateValue()
    let isMe = (profile && profile.id) === user.id?true: false
    const [isFollowing, setIsFollowing] = useState(false)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)

    useEffect(() => {
        if(username){
            const getData = db.collection('users').where('username', '==', username).onSnapshot(snapshot=>{
                setProfile(snapshot.docs.map(doc=>({
                    id: doc.id,
                    ...doc.data()
                }))[0])
                })

            return () => getData();
        }
      }, [])

      const history = useHistory();
  
      useEffect(() => {
          if(profile){
              if (!isMe){
                  setIsFollowing(profile.followers.includes(user.id))
              }
          }
      }, [profile])

      const signOut = () => {
        localStorage.clear()
        history.push('/')
        window.location.reload()
        
      }
      const [updatedProfileState, setUpdatedProfileState] = useState({})
      const [isPhotoReady, setIsPhotoReady] = useState(false)
      const [finalPhoto, setFinalPhoto] = useState(null)

      const onCallBasic = () => {
        const {photoToSend} = updatedProfileState.pictureToSend
        setIsUpdating(true)
        if(photoToSend === profile.photoURL){
                setFinalPhoto(profile.photoURL)
                setIsPhotoReady(true)
        }

        if (photoToSend !== profile.photoURL){
            const doFetch = () => postToCloudinary(photoToSend).then(res=> {
                setFinalPhoto(res)
                setIsPhotoReady(true)
            })
            .catch(error=>{ doFetch() } ) 

            doFetch()           
        }
        if(isPhotoReady){
            const {displayName, websiteURL1, websiteURL2, websiteURL3, instagramURL, facebookURL, lineURL, twitterURL, briefIntro, youtubeURL} = updatedProfileState.profileState
            const doUpdate = () => {
                db.collection('users').doc(user.id).update({
                    displayName,
                    briefIntro,
                    websiteURL1,
                    websiteURL2,
                    websiteURL3,
                    instagramURL, 
                    facebookURL,
                    twitterURL,
                    lineURL,
                    youtubeURL,
                    photoURL: finalPhoto,
                }).then(res => {
                    setIsUpdating(false)
                })
                .catch(error => { doUpdate()})               
            }
            doUpdate()
            setIsOpenModal(false)
        }
      }

      const onCallCareer = () => {
        const {careerYear1, careerMonth1, careerContent1, careerTitle1, careerYear2, careerMonth2, careerContent2, careerTitle2, careerYear3, careerMonth3, careerContent3, careerTitle3, careerYear4, careerMonth4, careerContent4, careerTitle4, careerYear5, careerMonth5, careerContent5, careerTitle5,
            careerYear6, careerMonth6, careerContent6, careerTitle6, careerYear7, careerMonth7, careerContent7, careerTitle7, careerYear8, careerMonth8, careerContent8, careerTitle8, careerYear9, careerMonth9, careerContent9, careerTitle9, careerYear10, careerMonth10, careerContent10, careerTitle10
        } = updatedProfileState.profileState
        const doUpdate = () => {
            db.collection('users').where("username", "==", user).doc(user.id).update({
                careerYear1, careerMonth1, careerContent1, careerTitle1,
                careerYear2, careerMonth2, careerContent2, careerTitle2,
                careerYear3, careerMonth3, careerContent3, careerTitle3,
                careerYear4, careerMonth4, careerContent4, careerTitle4,
                careerYear5, careerMonth5, careerContent5, careerTitle5,
                careerYear6, careerMonth6, careerContent6, careerTitle6,
                careerYear7, careerMonth7, careerContent7, careerTitle7,
                careerYear8, careerMonth8, careerContent8, careerTitle8,
                careerYear9, careerMonth9, careerContent9, careerTitle9, 
                careerYear10, careerMonth10, careerContent10, careerTitle10
            }).then(res => {
                setIsUpdating(false)
            })
            .catch(error => { doUpdate()})               
        }
        doUpdate()
        setIsOpenModal(false)
    }

    const onCallSkill = () => {
        const {skills1, skills2, skills3, skills4, skills5, skills6, qualiYear1, qualiMonth1, qualiContent1, qualiYear2, qualiMonth2, qualiContent2, qualiYear3, qualiMonth3, qualiContent3, qualiYear4, qualiMonth4, qualiContent4,
            qualiYear5, qualiMonth5, qualiContent5, qualiYear6, qualiMonth6, qualiContent6, qualiYear7, qualiMonth7, qualiContent7, qualiYear8, qualiMonth8, qualiContent8,
        } = updatedProfileState.profileState
        const doUpdate = () => {
            db.collection('users').where("username", "==", user).doc(user.id).update({
                skills1,
                skills2,
                skills3,
                skills4,
                skills5,
                skills6,
                skillsKeyword: [
                    skills1(0, 1), skills1(0, 2), skills1(0, 3), skills1(0, 4, skills1(0, 5), skills1(0, 6), skills1(0, 7), skills1(0, 8), skills1(0, 9), skills1(0, 10)),
                    skills2(0, 1), skills2(0, 2), skills2(0, 3), skills2(0, 4, skills2(0, 5), skills2(0, 6), skills2(0, 7), skills2(0, 8), skills2(0, 9), skills2(0, 10)),
                    skills3(0, 1), skills3(0, 2), skills3(0, 3), skills3(0, 4, skills3(0, 5), skills3(0, 6), skills3(0, 7), skills3(0, 8), skills3(0, 9), skills3(0, 10)),
                    skills4(0, 1), skills4(0, 2), skills4(0, 3), skills4(0, 4, skills4(0, 5), skills4(0, 6), skills4(0, 7), skills4(0, 8), skills4(0, 9), skills4(0, 10)),
                    skills5(0, 1), skills5(0, 2), skills5(0, 3), skills5(0, 4, skills5(0, 5), skills5(0, 6), skills5(0, 7), skills5(0, 8), skills5(0, 9), skills5(0, 10)),
                    skills6(0, 1), skills6(0, 2), skills6(0, 3), skills6(0, 4, skills6(0, 5), skills6(0, 6), skills6(0, 7), skills6(0, 8), skills6(0, 9), skills6(0, 10))
                ],
                qualiYear1, qualiMonth1, qualiContent1,
                qualiYear2, qualiMonth2, qualiContent2,
                qualiYear3, qualiMonth3, qualiContent3,
                qualiYear4, qualiMonth4, qualiContent4,
                qualiYear5, qualiMonth5, qualiContent5,
                qualiYear6, qualiMonth6, qualiContent6,
                qualiYear7, qualiMonth7, qualiContent7,
                qualiYear8, qualiMonth8, qualiContent8,
            }).then(res => {
                setIsUpdating(false)
            })
            .catch(error => { doUpdate()})               
        }
        doUpdate()
        setIsOpenModal(false)
    }



      const items = [
        {
            id: 0,
            title: '基本情報',
            item: <EditBasicProfile profile={profile} setUpdatedProfileState={setUpdatedProfileState} />  ,
            func: onCallBasic      
        },
        {
            id: 1,
            title: "キャリア",
            item: <EditCareerProfile profile={profile} setUpdatedProfileState={setUpdatedProfileState} />,
            func: onCallCareer
        },
        {
            id: 2,
            title: "資格・スキル",
            item: <EditQualiProfile profile={profile} setUpdatedProfileState={setUpdatedProfileState}/>,
            func: onCallSkill
        },
      ];

      const [notification, setNotification] = useState([]);
      useEffect(() => {
          if(!notification.length){
            const getData = db.collection("notification").where('userId', '==', `${profile.id}`).onSnapshot(snapshot => setNotification(snapshot.docs.map(doc => ({id: doc, ...doc.data()}))))
            return () => getData();
          }
      })

     const followNoti = () => {
        db.collection('notification').add({
           content: "にフォローされました",
           details: profile.displayName,
           photoURL: profile.photoURL,
           email: profile.email,
           subject: "新規フォロワー",
           timestamp: firebase.firestore.FieldValue.serverTimestamp(),
           url: `https://copipel.com/#/profile/${profile.username}`,
           userId: profile.id,
           read: 'false'
        })
     }
  
     const onFollowBtn = () => {
        follow(user.id, profile.id);
        followNoti();

        if(profile.emailNotification === true){
            setTimeout(() => {
               if(notification.length > 0){
                  db.collection('sendEmail').add({
                     content: "新しい通知が複数あります",
                     details: '',
                     url: `https://thestarclient.com/notification`,
                     email: profile.email,
                     subject: '新規通知',
                  })
               } else{
                  db.collection('sendEmail').add({
                     content: "にお気に入りされました",
                     details: user.displayName,
                     url: `https://thestarclient.com/profile/${user.username}`,
                     email: profile.email,
                     subject: '新規通知',
                  })
               }
            }, 1000 * 60 * 60)
        }
     }
    return(
        <>
        <MessageModal
            open={isOpenModal} 
            title="プロフィール編集"
            onClose={()=>setIsOpenModal(false)}
            >
                <ProfileTabbarMenu items={items} ButtonText="保存する" onClose={() => setIsOpenModal(false)}/>
        </MessageModal> 
        {profile.published === true ? 
                  <div className="user__profile">
                  <div className="user__profile__column1 profile__card">
                      <div className="d-flex profile__basic__info">
                          {profile && profile.photoURL? 
                          <>
                          {(profile && profile.photoURL) && <img src={profile.photoURL} alt={`${profile.displayName}`} />}
                          </>
                          :
                          <>
                          <img src={profileDefault} alt={`${profile.displayName}`}/>
                          </>
                          }
                              <div className="user__profile__basic">
                                  <h1>{profile.anonymous === true ? <>{"匿名"}</> : <>{profile.displayName}</>}</h1>
                                  <div className="infoWrapper">
                                  <div className="userProfile__actions">
                              
                                      { !isMe && <div className="mailWrapper">
                                              <CreateChatBtn/>
                                      </div> }                     
                                      <div className="profile__actionBtn">
                                      { isMe? 
                                      <div className="followWrapper" onClick={()=>setIsOpenModal(true)}>
                                          プロフィール編集
                                      </div>
                                      : 
                                          (
                                          isFollowing?
                                              <div className="followWrapper" onClick={() => unfollow(user.id, profile.id)}>
                                                  フォロー中
                                              </div>
                                          :
                                              <div className="followWrapper" onClick={onFollowBtn}>
                                                  フォローする
                                              </div>                        
                                          )              
                                      }
                                      </div>
                                  </div>
                                  <div className="countInfo">
                                      <Link to={`/profile/${username}/following`}>
                                          <span>{profile && profile.following.length} <p>フォロー中</p></span>
                                      </Link>
                                      <Link to={`/profile/${username}/followed`}>
                                          <span>{profile!==undefined && profile.followers.length} <p>フォロワー</p></span>
                                      </Link>
      
                                      { (isMe && isUpdating) && <div className='themeSpinner'> <Spinner /> </div>  }   
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="profile__website">
                          <ul>
                              {(profile && profile.websiteURL1) && <li><i className='fas fa-link link__icon'/><a href={`${profile.websiteURL1}`}>{profile.websiteURL1}</a></li>}
                              {(profile && profile.websiteURL2) && <li><i className='fas fa-link link__icon'/><a href={`${profile.websiteURL2}`}>{profile.websiteURL2}</a></li>}
                              {(profile && profile.websiteURL3) && <li><i className='fas fa-link link__icon'/><a href={`${profile.websiteURL3}`}>{profile.websiteURL3}</a></li>}
                          </ul>
                      </div>
                  </div>
      
                  <div className="user__profile__column2 profile__card">
                      <ul className="snsbtniti d-flex padding-left-35">
                          {(profile && profile.youtubeURL) || (profile && profile.twitterURL) || (profile && profile.instagramURL) || (profile && profile.facebookURL) || (profile && profile.lineURL) ? <div></div>: <p className="unwrite__text">SNSのURLがまだ記入されていません</p>}
                          {(profile && profile.youtubeURL) && <li><a href={`${profile && profile.youtubeURL}`} className="flowbtn8 fl_yu2"><i className="fab fa-youtube"/></a></li>}
                          {(profile && profile.twitterURL) && <li><a href={`${profile && profile.twitterURL}`} className="flowbtn8 fl_tw2"><i className="fab fa-twitter"/></a></li>}
                          {(profile && profile.instagramURL) && <li><a href={`${profile && profile.instagramURL}`} className="flowbtn8 insta_btn2"><i className="fab fa-instagram"/></a></li>}
                          {(profile && profile.facebookURL) && <li><a href={`${profile && profile.facebookURL}`} className="flowbtn8 fl_fb2"><i className="fab fa-facebook-f"/></a></li>}
                          {(profile && profile.lineURL) &&  <li><a href={`${profile && profile.lineURL}`} className="flowbtn8 fl_li2"><i className="fab fa-line"/></a></li>}
                      </ul>
                  </div>
      
                  <div className="user__profile__column3 profile__card">
                  <div className="text__back__box">
                          <h2>私について</h2>
                          <div className="back__text">
                              About Me
                          </div>
                      </div>
                      {profile && profile.briefIntro ? <p>{profile.briefIntro}</p>:<p className="unwrite__text">簡単な自己紹介がまだ記入されていません</p>}
                  </div>
      
                  <div className="user__profile__column4 profile__card">
                      <div className="text__back__box">
                          <h2>学歴・職歴</h2>
                          <div className="back__text">
                              Education / Career
                          </div>
                      </div>
                      <div className="career__box__all">
                          <div className="career__box">
                              {(profile && profile.careerTitle1) || (profile && profile.careerTitle2) || (profile && profile.careerTitle3) || (profile && profile.careerTitle4) || (profile && profile.careerTitle5) || (profile && profile.careerTitle6) || (profile && profile.careerTitle7) || (profile && profile.careerTitle8) || (profile && profile.careerTitle9) || (profile && profile.careerTitle10)?<div></div>:<p className="unwrite__text">学歴・職歴がまだ記入されていません</p>}
                              {(profile && profile.careerTitle10) && <h3><small>{profile.careerYear10}年{profile.careerMonth10}月 ~ </small> {profile.careerTitle10}</h3>}
                              <div className="d-flex">
                                  {(profile && profile.careerTitle10) && <div className="vertical__line"></div>}
                                  {(profile && profile.careerContent10) && <p>{profile.careerContent10}</p>}
                              </div>
                          </div>
                          <div className="career__box">
                              {(profile && profile.careerTitle7) && <h3><small>{profile.careerYear7}年{profile.careerMonth7}月 ~ </small> {profile.careerTitle7}</h3>}
                              <div className="d-flex">
                                  {(profile && profile.careerTitle7) && <div className="vertical__line"></div>}
                                  {(profile && profile.careerContent7) && <p>{profile.careerContent7}</p>}
                              </div>
                          </div>
                          <div className="career__box">
                              {(profile && profile.careerTitle8) && <h3><small>{profile.careerYear8}年{profile.careerMonth8}月 ~ </small> {profile.careerTitle8}</h3>}
                              <div className="d-flex">
                                  {(profile && profile.careerTitle8) && <div className="vertical__line"></div>}
                                  {(profile && profile.careerContent8) && <p>{profile.careerContent8}</p>}
                              </div>
                          </div>
                          <div className="career__box">
                              {(profile && profile.careerTitle7) && <h3><small>{profile.careerYear7}年{profile.careerMonth7}月 ~ </small> {profile.careerTitle7}</h3>}
                              <div className="d-flex">
                                  {(profile && profile.careerTitle7) && <div className="vertical__line"></div>}
                                  {(profile && profile.careerContent7) && <p>{profile.careerContent7}</p>}
                              </div>
                          </div>
                          <div className="career__box">
                              {(profile && profile.careerTitle6) && <h3><small>{profile.careerYear6}年{profile.careerMonth6}月 ~ </small> {profile.careerTitle6}</h3>}
                              <div className="d-flex">
                                  {(profile && profile.careerTitle6) && <div className="vertical__line"></div>}
                                  {(profile && profile.careerContent6) && <p>{profile.careerContent6}</p>}
                              </div>
                          </div>
                          <div className="career__box">
                              {(profile && profile.careerTitle5) && <h3><small>{profile.careerYear5}年{profile.careerMonth5}月 ~ </small> {profile.careerTitle5}</h3>}
                              <div className="d-flex">
                                  {(profile && profile.careerTitle5) && <div className="vertical__line"></div>}
                                  {(profile && profile.careerContent5) && <p>{profile.careerContent5}</p>}
                              </div>
                          </div>
                          <div className="career__box">
                              {(profile && profile.careerTitle4) && <h3><small>{profile.careerYear4}年{profile.careerMonth4}月 ~ </small> {profile.careerTitle4}</h3>}
                              <div className="d-flex">
                                  {(profile && profile.careerTitle4) && <div className="vertical__line"></div>}
                                  {(profile && profile.careerContent4) && <p>{profile.careerContent4}</p>}
                              </div>
                          </div>
                          <div className="career__box">
                              {(profile && profile.careerTitle3) && <h3><small>{profile.careerYear3}年{profile.careerMonth3}月 ~ </small> {profile.careerTitle3}</h3>}
                              <div className="d-flex">
                                  {(profile && profile.careerTitle3) && <div className="vertical__line"></div>}
                                  {(profile && profile.careerContent3) && <p>{profile.careerContent3}</p>}
                              </div>
                          </div>
                          <div className="career__box">
                              {(profile && profile.careerTitle2) && <h3><small>{profile.careerYear2}年{profile.careerMonth2}月 ~ </small> {profile.careerTitle2}</h3>}
                              <div className="d-flex">
                                  {(profile && profile.careerTitle2) && <div className="vertical__line"></div>}
                                  {(profile && profile.careerContent2) && <p>{profile.careerContent2}</p>}
                              </div>
                          </div>
                          <div className="career__box">
                              {(profile && profile.careerTitle1) && <h3><small>{profile.careerYear1}年{profile.careerMonth1}月 ~ </small> {profile.careerTitle1}</h3>}
                              <div className="d-flex">
                                  {(profile && profile.careerTitle1) && <div className="vertical__line"></div>}
                                  {(profile && profile.careerContent1) && <p>{profile.careerContent1}</p>}
                              </div>
                          </div>
                      </div>
                  </div>
      
                  <div className="user__profile__column5 profile__card">
                      <div className="text__back__box">
                          <h2>スキル</h2>
                          <div className="back__text">
                              Skills
                          </div>
                      </div>
                      {(profile && profile.skills1) || (profile && profile.skills2) || (profile && profile.skills3) || (profile && profile.skills4) || (profile && profile.skills5) || (profile && profile.skills6)? <div></div>: <p className="unwrite__text">スキルがまだ記入されていません</p>}
                      <div className="d-flex">
                          <ul>
                              {(profile && profile.skills1) && <li>{profile.skills1}</li>}
                              {(profile && profile.skills3) && <li>{profile.skills3}</li>}
                              {(profile && profile.skills5) && <li>{profile.skills5}</li>}
                          </ul>
                          <ul>
                              {(profile && profile.skills2) && <li>{profile.skills2}</li>}
                              {(profile && profile.skills5) && <li>{profile.skills5}</li>}
                              {(profile && profile.skills6) && <li>{profile.skills6}</li>}
                          </ul>
                      </div>
                  </div>
      
                  
                  <div className="user__profile__column6 profile__card">
                      <div className="text__back__box">
                          <h2>資格</h2>
                          <div className="back__text">
                              Qualifications
                          </div>
                      </div>
                      {(profile && profile.qualiContent1) || (profile && profile.qualiContent2) || (profile && profile.qualiContent3) || (profile && profile.qualiContent4) || (profile && profile.qualiContent5) || (profile && profile.qualiContent6)?<div></div>: <p className="unwrite__text">資格がまだ記入されていません</p>}
                      <div className="d-flex">
                          <ul>
                              {(profile.qualiContent1) && <li><small>{profile && profile.qualiYear1}年{profile && profile.qualiMonth1}月</small> - <strong>{profile && profile.qualiContent1}</strong></li>}
                              {(profile.qualiContent3) && <li><small>{profile && profile.qualiYear3}年{profile && profile.qualiMonth3}月</small> - <strong>{profile && profile.qualiContent3}</strong></li>}
                              {(profile.qualiContent5) && <li><small>{profile && profile.qualiYear5}年{profile && profile.qualiMonth5}月</small> - <strong>{profile && profile.qualiContent5}</strong></li>}
                          </ul>
                          <ul>
                              {(profile.qualiContent2) && <li><small>{profile && profile.qualiYear2}年{profile && profile.qualiMonth2}月</small> - <strong>{profile && profile.qualiContent2}</strong></li>}
                              {(profile.qualiContent4) && <li><small>{profile && profile.qualiYear4}年{profile && profile.qualiMonth4}月</small> - <strong>{profile && profile.qualiContent4}</strong></li>}
                              {(profile.qualiContent6) && <li><small>{profile && profile.qualiYear6}年{profile && profile.qualiMonth6}月</small> - <strong>{profile && profile.qualiContent6}</strong></li>}
                          </ul>
                      </div>
                  </div>
                  {isMe ? 
                  <div className="user__profile__column7 profile__card">
                      <button onClick={signOut}>ログアウト</button>
                  </div>
                  :<></>
                  }
              </div>
              : <p>現在、非表示になっています</p>
        }
        </>
    )
}

export default AuthUserProfile;