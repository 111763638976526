import React from "react";
import { useHistory } from "react-router-dom";
import { ABOUT__US } from "../../constant/router";

const SidebarCopipel = () => {
    const history = useHistory();
    const onAboutUs = () => {
        history.push(ABOUT__US)
        window.location.reload()
    }
    // const onHowToUse = () => {
    //     history.push(HOW__TO__USE)
    //     window.location.reload()
    // }
    return(
        <div className="sidebar__copipel">
            <div className="unAuth__topBar__content">
                <button onClick={onAboutUs}>Copipel / コピペルとは</button>
                {/* <button onClick={onHowToUse}>Copipel / コピペルの使い方</button> */}
            </div>
        </div>
    )
}

export default SidebarCopipel;