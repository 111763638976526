import React from "react";
import { Link } from "react-router-dom";
import { HomeBack } from "../../component";
import { LOGIN } from "../../constant/router";

import { useDocumentTitle, useScrollTop } from "../../hooks";
import './index.css';


const HowtoUse = () => {
    useScrollTop();
    useDocumentTitle('コピペルの使い方');

    return(
        <div className="how__to__use">
            <HomeBack/>
            <div className="how__to__use-1">
                <div className="how__to__use-1-flex">
                    <div className="how__to__use__content">
                        <img src='' alt="login"/>
                        <h3>アカウント作成<Link to={LOGIN} className="opacity">( ログインページ )</Link></h3>
                    </div>
                    <div className="how__to__use__content">
                        <img src='' alt="profile"/>
                        <h3>プロフィールページから情報を入力</h3>
                    </div>
                    <div className="how__to__use__content">
                        <img src='' alt="start"/>
                        <h3>仕事探しスタート</h3>
                    </div>
                </div>
                <button className="details-btn" onClick={() => document.getElementById('details').scrollIntoView()}><div>詳細を確認する</div><div><i className="fas fa-angle-double-down"/></div></button>
            </div>
            <div className="how__to__use-2">
                <div className="d-flex">
                    <div className="how__to__use-2-img">
                        <img src="" alt="profile"/>
                    </div>
                    <div className="how__to__use-2-content">
                        <h2>ボタンをクリックするだけでURLがコピーされ、簡単に履歴書を送信</h2>
                    </div>
                </div>
            </div>

            <div className="how__to__use-3">
                <div className="how__to__use__content-box" id="details">
                    <div className="d-flex">
                        <div className="how__to__use__content-box-tab">
                            <div className="d-flex">
                                <button className="how__to__use__content-box-tab-btn">
                                    <i className="fas fa-user-cog tabs-icon"/>
                                </button>
                            </div>
                            
                            <div className="d-flex">
                                <button className="how__to__use__content-box-tab-btn">
                                    <i className="fas fa-user-cog tabs-icon"/>
                                </button>
                            </div>
                            <div className="d-flex">
                                <button className="how__to__use__content-box-tab-btn">
                                    <i className="fas fa-user-cog tabs-icon"/>
                                </button>
                            </div>
                            <div className="d-flex">
                                <button className="how__to__use__content-box-tab-btn">
                                    <i className="fas fa-user-cog tabs-icon"/>
                                </button>
                            </div>
                            <div className="d-flex">
                                <button className="how__to__use__content-box-tab-btn">
                                    <i className="fas fa-user-cog tabs-icon"/>
                                </button>
                            </div>
                            <div className="d-flex">
                                <button className="how__to__use__content-box-tab-btn">
                                    <i className="fas fa-user-cog tabs-icon"/>
                                </button>
                            </div>
                            <div className="d-flex">
                                <button className="how__to__use__content-box-tab-btn">
                                    <i className="fas fa-user-cog tabs-icon"/>
                                </button>
                            </div>
                        </div>
                        <div className="how__to__use__content-box-details">
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HowtoUse;