import React from "react";
import './index.css';


const UserList = () => {
    return(
        <div className="user__list">
        </div>
    )
}


export default UserList;