
export default function authValidate(values, users) {
    let errors = { }
    const { name, username, email, password, confirmPassword } = values
    const emailRegex = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/                                                                                                 //eslint-disable-line                                                        
  
    if(username.length === 0){
      errors.erUsername = 'ユーザー名を入力してください' 
    }

    if(username){
      users && users.map(i => {
        if(i.username === username){
          errors.erUsername = 'このユーザー名はすでに使われています'
        }
        return errors.erUsername = ''
      })
    }
  
    if(name.length === 0){
      errors.erName = 'お名前を入力してください' 
    }
  
  
    if (!email) {
      errors.erEmail = 'メールアドレスを入力してください'
    } else if (!emailRegex.test(email)) {   
      errors.erEmail = 'このメールアドレスは有効ではありません'
    }

    if(!email){
      users && users.map(i => {
        if(i.email === email){
         errors.erEmail = 'このメールアドレスはすでに使われています'
        }
        return errors.erEmail = ''
      })
    }

  
    if (!password) {
      errors.erPassword = 'パスワードを入力してください'
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d@$.!%*#?&]/.test(password)) {
      errors.erPassword = 'パスワードは８文字以上、１文字以上大文字、小文字、記号、数字が必要です'
    }
  
  
    if(password !== confirmPassword){
        errors.erPasswords = 'パスワードがマッチしていません'
    }
  
    let {erName, erUsername, erEmail, erPassword, erPasswords} = errors
  
    if(erName){
      return erName
    } else {
      if (erUsername){
        return erUsername
      } else {
        if (erEmail){
          return erEmail
        } else {
          if (erPassword){
            return erPassword
          } else {
            if (erPasswords){
              return erPasswords
            } else {
              return ''
            }
          }
        }
      }
    }
      
  }