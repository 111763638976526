import React from 'react';
import './index.css';


const CopyrightFooter = () => {
    return(
        <div className='copyRight__footer'>
            <p>©2022 copipel.com</p>
        </div>
    )
}

export default CopyrightFooter;