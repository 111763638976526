import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import db from "../../../firebase";
import AuthWidgetPost from "./AuthWidgetPost";


const AuthWidgetPostSidebar = () => {
    const [status, setStatus] = useState(null)
    const {postId} = useParams()
    useEffect(() => {
        let mounted = true
        if(postId){
        db.collection('jobs').doc(postId)
           .onSnapshot(snapshot=>{
              if(mounted){
                 setStatus({...snapshot.data()})
              }
        })
        }
  
        return () => mounted = false
  
      }, [postId])
  
    return(
        <>
        {status && <AuthWidgetPost statusJob={status}/>}
        </>
    )
}

export default AuthWidgetPostSidebar;