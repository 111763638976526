import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import profileDefault from '../../../assets/profileDefault.png';
import db from "../../../firebase";
import '../index.css';

const UnAuthWidgetPost = ({statusJob}) => {
    const [profile, setProfile] = useState({id:'',displayName:'', photoURL: '', verified: false, username: '', followers: [], following: [], twitterURL: '', instagramURL: '', lineURL: '', youtubeURL: '', facebookURL: '', websiteURL1: '', websiteURL2: '', websiteURL3: ''})
    const {senderId} = statusJob;


    useEffect(() => {
      let mounted = true
      db.collection('users').doc(senderId).onSnapshot(snapshot=>{
         if(mounted) {
            setProfile(snapshot.data())
         }
      })

      return () => mounted = false
   }, [])

    const {postId} = useParams()

    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);
    const onCopyBtn = (e) => {
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        setCopySuccess('コピーしました!');
    }

    useEffect(() => {
      let mounted = true
      if(postId){
      db.collection('jobs').doc(postId)
         .onSnapshot(snapshot=>{
            if(mounted){
            }
      })
      }
      return () => mounted = false

    }, [postId])
    return(
        <div className="widget__post">
            <div className="widget__card wiget__copy__btn">
                <p><input value={`http://localhost:3000/posts/${postId}`} readOnly ref={textAreaRef}/>{copySuccess ? <div className='post__success'><button><i className='fas fa-check success__icon'/>コピーしました</button></div>:<div className='post__success'><button onClick={onCopyBtn}><i className='fas fa-paperclip icon__paperclip__post'/>URLをコピー</button></div>}</p>
            </div>
           <div className="widget__post__side widget__card">
                    {profile && profile.photoURL? 
                    <div>{(profile && profile.photoURL) && <img src={profile.photoURL} alt={`${profile.displayName}`} />}</div>
                    :
                    <div><img src={profileDefault} alt={`${profile.displayName}`}/></div>
                    }
                        <div className="user__profile__basic">
                            <h1>{profile.displayName}</h1>
                            <div className="infoWrapper">
                            <div className="countInfo d-block widget__follow">
                                <Link to={`/profile/${profile.username}/following`}>
                                    <span>{profile && profile.following.length} <p>フォロー中</p></span>
                                </Link>
                                <Link to={`/profile/${profile.username}/followed`}>
                                    <span>{profile!==undefined && profile.followers.length} <p>フォロワー</p></span>
                                </Link>
                            </div>
                        </div>
                    </div>
           </div>
           <div className="widget__card">
                <ul className="snsbtniti d-flex widget__sns">
                    {(profile && profile.youtubeURL) || (profile && profile.twitterURL) || (profile && profile.instagramURL) || (profile && profile.facebookURL) || (profile && profile.lineURL) ? <div></div>: <p className="unwrite__text">SNSのURLがまだ記入されていません</p>}
                    {(profile && profile.youtubeURL) && <li><a href={`${profile && profile.youtubeURL}`} className="flowbtn8 fl_yu2"><i className="fab fa-youtube"/></a></li>}
                    {(profile && profile.twitterURL) && <li><a href={`${profile && profile.twitterURL}`} className="flowbtn8 fl_tw2"><i className="fab fa-twitter"/></a></li>}
                    {(profile && profile.instagramURL) && <li><a href={`${profile && profile.instagramURL}`} className="flowbtn8 insta_btn2"><i className="fab fa-instagram"/></a></li>}
                    {(profile && profile.facebookURL) && <li><a href={`${profile && profile.facebookURL}`} className="flowbtn8 fl_fb2"><i className="fab fa-facebook-f"/></a></li>}
                    {(profile && profile.lineURL) &&  <li><a href={`${profile && profile.lineURL}`} className="flowbtn8 fl_li2"><i className="fab fa-line"/></a></li>}
                </ul>
           </div>
        </div>
    )
}

export default UnAuthWidgetPost;