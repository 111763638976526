import React, {useState } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import { SearchTabbar, UserItemFollow } from "../../../component";
import db from "../../../firebase";
import '../index.css';

const UserSearchHome = () => {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [users, setUsers] = useState([]);

   const onClickSearchUser = () => {
    db.collection('users').onSnapshot(snapshot => {
        if(snapshot.empty){
            setLoading(false)
            return
        }
        setUsers(snapshot.docs.map(doc => ({id:doc.id, ...doc.data()})))
        setLoading(false)
    })
   }
    return(
        <>
        <div className="search__box search__user">
            <SearchTabbar/>
            <div className="search__box-content search__box-content-user">
                <div className="d-flex">
                    <input type="text" placeholder="ユーザー名..." value={search} onChange={(e) => setSearch(e.target.value)}/>
                    <div className="search__box-content-btn">
                        <button onClick={() => onClickSearchUser()}>さがす</button>
                    </div>
                </div>
            </div>
                { loading &&  <LinearProgress/> }
                <ul className='widgets__trendsContainer search__user-result'>
                    {search ? 
                        <>
                            {
                            users && users.filter(u=> 
                                u.nameKeywords[0] === search || u.nameKeywords[1] === search || u.nameKeywords[2] === search || u.nameKeywords[3] === search || u.nameKeywords[4] === search || u.nameKeywords[5] === search 
                                || u.nameKeywords[6] === search || u.nameKeywords[7] === search || u.nameKeywords[8] === search || u.nameKeywords[9] === search || u.nameKeywords[10] === search 
                                || u.nameKeywords[11] === search || u.nameKeywords[12] === search || u.nameKeywords[13] === search || u.nameKeywords[14] === search || u.nameKeywords[15] === search 
                                || u.nameKeywords[16] === search || u.nameKeywords[17] === search || u.nameKeywords[18] === search || u.nameKeywords[19] === search || u.nameKeywords[20] === search
                                || u.nameKeywords[21] === search || u.nameKeywords[22] === search || u.nameKeywords[23] === search || u.nameKeywords[24] === search || u.nameKeywords[25] === search || u.nameKeywords[26] === search 
                                || u.nameKeywords[27] === search || u.nameKeywords[28] === search || u.nameKeywords[29] === search || u.nameKeywords[30] === search || u.nameKeywords[31] === search 
                                || u.nameKeywords[32] === search || u.nameKeywords[33] === search || u.nameKeywords[34] === search || u.nameKeywords[35] === search || u.nameKeywords[36] === search 
                                || u.nameKeywords[37] === search || u.nameKeywords[38] === search || u.nameKeywords[39] === search || u.nameKeywords[40] === search
                                ).map(user=> {
                                return <li key={user.id}><UserItemFollow display={user}/></li>
                            })
                            }
                        </>
                    : <></>}
                </ul>
        </div>
        </>
    )
}

export default UserSearchHome;