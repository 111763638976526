import React, { useState } from "react";
import { Link } from "react-router-dom";
import './index.css';
import * as ROUTE from '../../constant/router';

const PrefectureModal = () => {
    const [isOpenHokkaido, setIsOpenHokkaido] = useState(false);
    const [isOpenTohoku, setIsOpenTohoku] = useState(false);
    const [isOpenKanto, setIsOpenKanto] = useState(false);
    const [isOpenTyubu, setIsOpenTyubu] = useState(false);
    const [isOpenKansai, setIsOpenKansai] = useState(false);
    const [isOpenTyugoku, setIsOpenTyugoku] = useState(false);
    const [isOpenSikoku, setIsOpenSikoku] = useState(false);
    const [isOpenKyusyu, setIsOpenKyusyu] = useState(false);

    const onOpenHokaido = () => {
        setIsOpenHokkaido(true);
        setIsOpenTohoku(false);
        setIsOpenKanto(false);
        setIsOpenTyubu(false);
        setIsOpenKansai(false);
        setIsOpenTyugoku(false);
        setIsOpenSikoku(false);
        setIsOpenKyusyu(false);
    }
    const onOpenTohoku = () => {
        setIsOpenHokkaido(false);
        setIsOpenTohoku(true);
        setIsOpenKanto(false);
        setIsOpenTyubu(false);
        setIsOpenKansai(false);
        setIsOpenTyugoku(false);
        setIsOpenSikoku(false);
        setIsOpenKyusyu(false);
    }

    const onOpenKanto = () => {
        setIsOpenHokkaido(false);
        setIsOpenTohoku(false);
        setIsOpenKanto(true);
        setIsOpenTyubu(false);
        setIsOpenKansai(false);
        setIsOpenTyugoku(false);
        setIsOpenSikoku(false);
        setIsOpenKyusyu(false);
    }

    const onOpenTyubu = () => {
        setIsOpenHokkaido(false);
        setIsOpenTohoku(false);
        setIsOpenKanto(false);
        setIsOpenTyubu(true);
        setIsOpenKansai(false);
        setIsOpenTyugoku(false);
        setIsOpenSikoku(false);
        setIsOpenKyusyu(false);
    }

    const onOpenKansai = () => {
        setIsOpenHokkaido(false);
        setIsOpenTohoku(false);
        setIsOpenKanto(false);
        setIsOpenTyubu(false);
        setIsOpenKansai(true);
        setIsOpenTyugoku(false);
        setIsOpenSikoku(false);
        setIsOpenKyusyu(false);
    }

    const onOpenTyugoku = () => {
        setIsOpenHokkaido(false);
        setIsOpenTohoku(false);
        setIsOpenKanto(false);
        setIsOpenTyubu(false);
        setIsOpenKansai(false);
        setIsOpenTyugoku(true);
        setIsOpenSikoku(false);
        setIsOpenKyusyu(false);
    }
    const onOpenSikoku = () => {
        setIsOpenHokkaido(false);
        setIsOpenTohoku(false);
        setIsOpenKanto(false);
        setIsOpenTyubu(false);
        setIsOpenKansai(false);
        setIsOpenTyugoku(false);
        setIsOpenSikoku(true);
        setIsOpenKyusyu(false);
    }

    const onOpenKyusyu = () => {
        setIsOpenHokkaido(false);
        setIsOpenTohoku(false);
        setIsOpenKanto(false);
        setIsOpenTyubu(false);
        setIsOpenKansai(false);
        setIsOpenTyugoku(false);
        setIsOpenSikoku(false);
        setIsOpenKyusyu(true);
    }

    return(
        <>
        <div className="prefecture__modal modal-box">
            <div className="d-flex">
                <div className="modal-flex-block">
                    <div className="timeline__info-card modal-box-card">
                        {isOpenHokkaido ? <p className="cursor" onClick={() => setIsOpenHokkaido(false)}>北海道<i className="fas fa-times modal-icon"/></p> : <p className="cursor" onClick={() => onOpenHokaido()}>北海道<i className="fas fa-arrow-right modal-icon"/></p>}
                    </div>
                    <div className="timeline__info-card modal-box-card">
                        {isOpenTohoku ? <p className="cursor" onClick={() => setIsOpenTohoku(false)}>東北<i className="fas fa-times modal-icon"/></p> : <p className="cursor" onClick={() => onOpenTohoku()}>東北<i className="fas fa-arrow-right modal-icon"/></p>}
                    </div>
                </div>
                <div className="modal-flex-block">
                    <div className="timeline__info-card modal-box-card">
                        {isOpenKanto ? <p className="cursor" onClick={() => setIsOpenKanto(false)}>関東<i className="fas fa-times modal-icon"/></p> : <p className="cursor" onClick={() => onOpenKanto()}>関東<i className="fas fa-arrow-right modal-icon"/></p>}
                    </div>
                    <div className="timeline__info-card modal-box-card">
                        {isOpenTyubu ? <p className="cursor" onClick={() => setIsOpenTyubu(false)}>中部<i className="fas fa-times modal-icon"/></p> : <p className="cursor" onClick={() => onOpenTyubu()}>中部<i className="fas fa-arrow-right modal-icon"/></p>}
                    </div>
                </div>
            </div>
            <div>
                {isOpenHokkaido ?                     
                    <div className="modal__under-card-btn">
                        <Link to={ROUTE.HOKKAIDO__PREFECTURE__TIMELINE}><button>北海道</button></Link>
                    </div>
                    :<></>}
                {isOpenTohoku ?                     
                    <div className="modal__under-card-btn">
                        <Link to={ROUTE.AOMORI__PREFECTURE__TIMELINE}><button>青森県</button></Link>
                        <Link to={ROUTE.IWATE__PREFECTURE__TIMELINE}><button>岩手県</button></Link>
                        <Link to={ROUTE.MIYAGI__PREFECTURE__TIMELINE}><button>宮城県</button></Link>
                        <Link to={ROUTE.AKITA__PREFECTURE__TIMELINE}><button>秋田県</button></Link>
                        <Link to={ROUTE.YAMAGATA__PREFECTURE__TIMELINE}><button>山形県</button></Link>
                        <Link to={ROUTE.HUKUSIMA__PREFECTURE__TIMELINE}><button>福島県</button></Link>
                    </div>
                    :<></>}
                {isOpenKanto ?                     
                    <div className="modal__under-card-btn">
                        <Link to={ROUTE.IBARAKI__PREFECTURE__TIMELINE}><button>茨城県</button></Link>
                        <Link to={ROUTE.TOTIGI__PREFECTURE__TIMELINE}><button>栃木県</button></Link>
                        <Link to={ROUTE.GUNMA__PREFECTURE__TIMELINE}><button>群馬県</button></Link>
                        <Link to={ROUTE.SAITAMA__PREFECTURE__TIMELINE}><button>埼玉県</button></Link>
                        <Link to={ROUTE.TIBA__PREFECTURE__TIMELINE}><button>千葉県</button></Link>
                        <Link to={ROUTE.TOKYO__PREFECTURE__TIMELINE}><button>東京都</button></Link>
                        <Link to={ROUTE.KANAGAWA__PREFECTURE__TIMELINE}><button>神奈川県</button></Link>
                    </div>
                    :<></>}
                {isOpenTyubu ?                     
                <div className="modal__under-card-btn">
                    <Link to={ROUTE.NIGATA__PREFECTURE__TIMELINE}><button>新潟県</button></Link>
                    <Link to={ROUTE.TOYAMA__PREFECTURE__TIMELINE}><button>富山県</button></Link>
                    <Link to={ROUTE.ISHIKAWA__PREFECTURE__TIMELINE}><button>石川県</button></Link>
                    <Link to={ROUTE.HUKUI__PREFECTURE__TIMELINE}><button>福井県</button></Link>
                    <Link to={ROUTE.YAMANASHI__PREFECTURE__TIMELINE}><button>山梨県</button></Link>
                    <Link to={ROUTE.NAGANO__PREFECTURE__TIMELINE}><button>長野県</button></Link>
                    <Link to={ROUTE.GIHU__PREFECTURE__TIMELINE}><button>岐阜県</button></Link>
                    <Link to={ROUTE.SHIZUOKA__PREFECTURE__TIMELINE}><button>静岡県</button></Link>
                    <Link to={ROUTE.AITI__PREFECTURE__TIMELINE}><button>愛知県</button></Link>
                </div>
                :<></>}
            </div>
            <div className="d-flex">
                <div className="modal-flex-block">
                    <div className="timeline__info-card modal-box-card">
                        {isOpenKansai ? <p className="cursor" onClick={() => setIsOpenKansai(false)}>関西<i className="fas fa-times modal-icon"/></p> : <p className="cursor" onClick={() => onOpenKansai()}>関西<i className="fas fa-arrow-right modal-icon"/></p>}
                    </div>
                    <div className="timeline__info-card modal-box-card">
                        {isOpenTyugoku ? <p className="cursor" onClick={() => setIsOpenTyugoku(false)}>中国<i className="fas fa-times modal-icon"/></p> : <p className="cursor" onClick={() => onOpenTyugoku()}>中国<i className="fas fa-arrow-right modal-icon"/></p>}
                    </div>
                </div>
                <div className="modal-flex-block">
                    <div className="timeline__info-card modal-box-card">
                        {isOpenSikoku ? <p className="cursor" onClick={() => setIsOpenSikoku(false)}>四国<i className="fas fa-times modal-icon"/></p> : <p className="cursor" onClick={() => onOpenSikoku()}>四国<i className="fas fa-arrow-right modal-icon"/></p>}
                    </div>
                    <div className="timeline__info-card modal-box-card">
                        {isOpenKyusyu ? <p className="cursor" onClick={() => setIsOpenKyusyu(false)}>九州・沖縄<i className="fas fa-times modal-icon"/></p> : <p className="cursor" onClick={() => onOpenKyusyu()}>九州・沖縄<i className="fas fa-arrow-right modal-icon"/></p>}
                    </div>
                </div>
            </div>
            <div>
                {isOpenKyusyu ?                     
                    <div className="modal__under-card-btn">
                        <Link to={ROUTE.HUKUOKA__PREFECTURE__TIMELINE}><button>福岡県</button></Link>
                        <Link to={ROUTE.SAGA__PREFECTURE__TIMELINE}><button>佐賀県</button></Link>
                        <Link to={ROUTE.NAGASAKI__PREFECTURE__TIMELINE}><button>長崎県</button></Link>
                        <Link to={ROUTE.KUMAMOTO__PREFECTURE__TIMELINE}><button>熊本県</button></Link>
                        <Link to={ROUTE.OITA__PREFECTURE__TIMELINE}><button>大分県</button></Link>
                        <Link to={ROUTE.MIYAZAKI__PREFECTURE__TIMELINE}><button>宮崎県</button></Link>
                        <Link to={ROUTE.KAGOSHIMA__PREFECTURE__TIMELINE}><button>鹿児島県</button></Link>
                        <Link to={ROUTE.OKINAWA__PREFECTURE__TIMELINE}><button>沖縄県</button></Link>
                    </div>
                :<></>}
                {isOpenSikoku ?                     
                    <div className="modal__under-card-btn">
                        <Link to={ROUTE.TOKUSHIMA__PREFECTURE__TIMELINE}><button>徳島県</button></Link>
                        <Link to={ROUTE.KAGAWA__PREFECTURE__TIMELINE}><button>香川県</button></Link>
                        <Link to={ROUTE.EHIME__PREFECTURE__TIMELINE}><button>愛媛県</button></Link>
                        <Link to={ROUTE.KOUTI__PREFECTURE__TIMELINE}><button>高知県</button></Link>
                    </div>
                :<></>}
                {isOpenTyugoku ?                     
                    <div className="modal__under-card-btn">
                        <Link to={ROUTE.TOTTORI__PREFECTURE__TIMELINE}><button>鳥取県</button></Link>
                        <Link to={ROUTE.SHIMANE__PREFECTURE__TIMELINE}><button>島根県</button></Link>
                        <Link to={ROUTE.OKAYAMA__PREFECTURE__TIMELINE}><button>岡山県</button></Link>
                        <Link to={ROUTE.HIROSHIMA__PREFECTURE__TIMELINE}><button>広島県</button></Link>
                        <Link to={ROUTE.YAMAGUTI__PREFECTURE__TIMELINE}><button>山口県</button></Link>
                    </div>
                :<></>}
                {isOpenKansai ?                     
                    <div className="modal__under-card-btn">
                        <Link to={ROUTE.MIE__PREFECTURE__TIMELINE}><button>三重県</button></Link>
                        <Link to={ROUTE.SHIGA__PREFECTURE__TIMELINE}><button>滋賀県</button></Link>
                        <Link to={ROUTE.KYOTO__PREFECTURE__TIMELINE}><button>京都府</button></Link>
                        <Link to={ROUTE.OSAKA__PREFECTURE__TIMELINE}><button>大阪府</button></Link>
                        <Link to={ROUTE.HYOGO__PREFECTURE__TIMELINE}><button>兵庫県</button></Link>
                        <Link to={ROUTE.NARA__PREFECTURE__TIMELINE}><button>奈良県</button></Link>
                        <Link to={ROUTE.WAKAYAMA__PREFECTURE__TIMELINE}><button>和歌山県</button></Link>
                    </div>
                :<></>}
            </div>

        </div>
        </>
    )
}

export default PrefectureModal;