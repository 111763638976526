import React, {useEffect, useState} from 'react'
import { useStateValue } from '../../contexts/StateContextProvider';
import ShowWhoLikes from './ShowWhoLikesPost';

const Like = ({likes, likeAction, unlikeAction, postId, senderId, text1, text2, text3, text4, typePost}) => {
    const [{user}] = useStateValue()
    const [isLiked, setisLiked] = useState(false)

    useEffect(() => {
        if(user.id && likes){
            if(likes.includes(user.id)){
                setisLiked(true)
            } else {
                setisLiked(false)
            }
        }
    }, [likes])

    return (
        <>
        {typePost === 'jobPosting' ? 
            <div className="footerIcon_wrapper  apply-btn-border">
                { isLiked?
                    <span className='liked post__apply-btn' onClick={unlikeAction}>{text1}</span>
                :
                    <span onClick={likeAction} className='post__apply-btn'>{text2}</span>
                }
                
                {senderId === user.id ? <div className="footerIcon__counter">{(likes && likes.length>0 ) ? likes.length : '0'}</div> : <></>}
                <ShowWhoLikes senderId={senderId} postId={postId}/>
            </div>
        :
        <div className="footerIcon_wrapper  apply-btn-border">
            { isLiked?
                <span className='liked post__apply-btn' onClick={unlikeAction}>{text3}</span>
            :
                <span onClick={likeAction} className='post__apply-btn'>{text4}</span>
            }
            
            {senderId === user.id ? <div className="footerIcon__counter">{(likes && likes.length>0 ) ? likes.length : '0'}</div> : <></>}
            <ShowWhoLikes senderId={senderId} postId={postId}/>
        </div>    
        }
        </>
    );
};

export default Like;