import React from "react";
import { NavLink } from "react-router-dom";
import { SEARCH__EVENT, SEARCH__JOBS, SEARCH__USER, SEARCH__USER__POST, SEARCH__WORDS } from "../../constant/router";

const SearchTabbar = () => {
    return(
        <div className="active__navigation active__navigation__search">
            <ul>
                <li><NavLink exact to={SEARCH__WORDS} className="gray" activeClassName="active" ><span>ワード</span></NavLink></li>
                <li><NavLink exact to={SEARCH__USER} className="gray" activeClassName="active" ><span>ユーザー</span></NavLink></li>
                <li><NavLink to={SEARCH__JOBS} className="gray" activeClassName="active"><span>求人投稿</span></NavLink></li>
                <li><NavLink to={SEARCH__EVENT} className="gray" activeClassName="active"><span>イベント</span></NavLink></li>
                <li><NavLink to={SEARCH__USER__POST} className="gray" activeClassName="active"><span>ユーザー投稿</span></NavLink></li>
            </ul>
        </div>
    )
}

export default SearchTabbar;