import React from 'react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Search from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';

import { useStateValue } from '../../contexts/StateContextProvider';
import './index.css';
import { HOME, MESSAGE, NOTIFICATION, SEARCH__WORDS } from '../../constant/router';

const AuthBottomNav = () => {
  const [{user}] = useStateValue()

  return (
    <div className="bottomNav">
       <nav>
          <NavLink exact to={HOME} activeClassName='bottomNav__active' replace><HomeIcon /></NavLink>
          <NavLink to={SEARCH__WORDS} activeClassName='bottomNav__active' replace><Search /></NavLink>
          <NavLink to={`/profile/${user.username}`} activeClassName='bottomNav__active' replace><PersonIcon /></NavLink>
          <NavLink to={NOTIFICATION} activeClassName='bottomNav__active' replace><NotificationsNoneIcon /></NavLink>
          <NavLink to={MESSAGE} activeClassName='bottomNav__active' replace><MailOutlineIcon /></NavLink>
       </nav>
    </div>
  )
}

export default AuthBottomNav