import React, { useEffect, useState } from "react";
import { AuthJobPost } from "../../../component";
import { useStateValue } from "../../../contexts/StateContextProvider";
import db from "../../../firebase";
import FlipMove from 'react-flip-move';

const CompanyDashboardEventPost = () => {
    const [jobPost, setJobPost] = useState([]);
    const [{user}] = useStateValue();
    useEffect(() => {
      let mounted = true
      db.collection('jobs').orderBy('timestamp', 'desc')
      .onSnapshot(snapshot=>{
         if(mounted){
            if(snapshot.empty){
               return
            }
              setJobPost(snapshot.docs.map(doc=> ({id:doc.id, ...doc.data()})))
         }
      }) 
   
   return () => mounted = false

 }, [])
    return(
        <div className='feed'>
            <>
            <FlipMove>
            {jobPost.filter(post => post.typePost === 'eventPosting' && post.senderId === user.id).map(post => (
                <AuthJobPost 
                key={post.id}
                postId = {post.id}
                altText = {post.altText}
                senderId = {post.senderId}
                username = {post.username}
                kindJob = {post.kindJob}
                prefecture = {post.prefecture}
                jobTitle = {post.jobTitle}
                avatar = {post.avatar}
                image = {post.image}
                timestamp = {post.timestamp}
                likes = {post.likes}
                bookMark = {post.bookMark}
                styleWork = {post.styleWork}
                salary = {post.salary}
                reqSkill = {post.reqSkill}
                website = {post.website}
                eventPlace = {post.eventPlace}
                eventDate = {post.eventDate}
                eventTime = {post.eventTime}
                skills = {post.skills}
                wantedJob = {post.wantedJob}
                canWorkPlace = {post.canWorkPlace}
                hireSection = {post.hireSection}
                keywordsWord = {post.keywordsWord}
          />
                ))
            }           
            </FlipMove>
            </>
        </div>
    )
}

export default CompanyDashboardEventPost;