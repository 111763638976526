import React, {useState, useEffect} from 'react';
import db from '../../../firebase';
import { useStateValue } from '../../../contexts/StateContextProvider';
import { useDocumentTitle, useScrollTop } from '../../../hooks';
import { ActiveNavigationofTimeline, AuthJobPostingFront, CompanyMobileTopNav, UserMobileTopNav } from '../../../component';
import { Loader } from '../../../elements';
import '../index.css';

const AuthEventsTimeLine = () => {
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
       if(!posts.length){
         let mounted = true
         setLoading(true)
         db.collection('jobs')
         .where('typePost', '==', 'eventPosting')
         .orderBy('timestamp', 'desc')
         .onSnapshot(snapshot=>{
            if(mounted){
               if(snapshot.empty){
                  setLoading(false)
                  return
               }
                  setPosts(snapshot.docs.map(doc=> ({id:doc.id, ...doc.data()})))
                  setLoading(false)
            }
         }) 
      return () => mounted = false
       }
    }, [])
    
    const onRefreshBtn = () => {
      window.location.reload();
   }

   const [{user}] = useStateValue();

   useScrollTop();
   useDocumentTitle("イベント / コピペル");

    return (
        <div className='feed'>
         {user.role === "USER" ? <UserMobileTopNav/> : <CompanyMobileTopNav/>}
           <ActiveNavigationofTimeline/>
           <div className="feed__header">
              <h2>イベント</h2>
              <button onClick={onRefreshBtn} className='timeLine__load__btn'><i className='fas fa-redo-alt refresh__btn'/></button>
           </div>

           { loading && <div className="feed__loader"><Loader/></div> }

           <AuthJobPostingFront posts={posts} />

        </div>
    )
}

export default AuthEventsTimeLine;