import React, {useState, useEffect} from 'react';
import { Button } from '@material-ui/core';
import firebase from 'firebase';
import CancelIcon from '@material-ui/icons/Cancel';
import CropIcon from '@material-ui/icons/Crop';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import { useHistory } from 'react-router-dom';

import db from '../../firebase';
import postToCloudinary from '../../helpers/postToCloudinary';
import { getInfo } from '../../helpers/getImageDimension';
import { generateAltText } from '../../helpers/generateAltText';
import { MessageModal, Modal, Spinner, TabbarMenu } from '../../elements';
import YoputubeClickInfo from './YoutubeClickInfo';
import { useStateValue } from '../../contexts/StateContextProvider';
import { useDocumentTitle, useScrollTop } from '../../hooks';
import { AddALT, CropPhotoB, StatusInput } from '../../component';
import './index.css';
import { HOME } from '../../constant/router';

const EventPostingBox = () => {
    const [{user}] = useStateValue();
    const history = useHistory();
    const [altText, setAltText] = useState(generateAltText(user.displayName))
    const [src, setSrc] = useState(null)
    const [imageToSend, setImageToSend] = useState(null)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [initialImageSize, setinitialImageSize] = useState({width: 0, height: 0})
    const [initialAspectRatio, setinitialAspectRatio] = useState(null)
    const [croppedImageResult, setCroppedImageResult ] = useState(null)
    const [isLoading, setIsloading] = useState(false);
    const [isOpenYoutubeModal, setIsOpenYoutubeModal] = useState(false)
    const initInput = {
        eventTitle: "",
        eventDate: "",
        eventWhy: "",
        eventDetails: "",
        eventTime: "",
        kindJob: "",
        website: "",
        youtubeLink: "",
        prefecture: "",
        otherAddress: "",
        keywords1: "",
        keywords2: "",
        keywords3: "",
        keywords4: ""
    }
    const [statusInput, setStatusInput] = useState(initInput);

    useScrollTop();
    useDocumentTitle("イベント投稿 / コピペル");

    const sendPost = (e) => {
        e.preventDefault()
        setIsloading(true)
        const {eventTitle, eventDate, eventWhy, eventDetails, eventTime, kindJob, website, youtubeLink, prefecture, otherAddress, keywords1, keywords2, keywords3, keywords4} = statusInput
        if(imageToSend){
            postToCloudinary(imageToSend)
            .then( res=>{

                db.collection('jobs').add({
                    displayName: user.displayName,
                    briefIntroYour: '',
                    skills: '',
                    wantedJob: '',
                    career: '',
                    youtubeLink: youtubeLink,
                    companyName: user.displayName,
                    salary: '',
                    altText: altText,
                    jobTitle: eventTitle,
                    isDone: false,
                    kindJob: kindJob,
                    workDetails: '',
                    image: res,
                    styleWork: '',
                    prefecture: prefecture,
                    otherAddress: otherAddress,
                    briefIntro: '',
                    wanted: '',
                    reqSkill: '',
                    opSkill: '',
                    hireSection: '',
                    grade: '',
                    timeWork: '',
                    welfare: '',
                    different: '',
                    testPeriod: '',
                    offDay: '',
                    titleKeyWords: [eventTitle.substring(0, 1), eventTitle.substring(0, 2), eventTitle.substring(0, 3), eventTitle.substring(0, 4), eventTitle.substring(0, 5), eventTitle.substring(0, 6), eventTitle.substring(0, 7), eventTitle.substring(0, 8), eventTitle.substring(0, 9), eventTitle.substring(0, 10),
                        eventTitle.substring(0, 11), eventTitle.substring(0, 12), eventTitle.substring(0, 13), eventTitle.substring(0, 14), eventTitle.substring(0, 15), eventTitle.substring(0, 16), eventTitle.substring(0, 17), eventTitle.substring(0, 18), eventTitle.substring(0, 19), eventTitle.substring(0, 20),
                        eventTitle],
                    keywords: [eventTitle.substring(0, 1), eventTitle.substring(0, 2), eventTitle.substring(0, 3), eventTitle.substring(0, 4), eventTitle.substring(0, 5),
                            eventTitle.substring(0, 6), eventTitle.substring(0, 7), eventTitle.substring(0, 8), eventTitle.substring(0, 9), eventTitle.substring(0, 10),
                            eventTitle.substring(0, 11), eventTitle.substring(0, 12), eventTitle.substring(0, 13), eventTitle.substring(0, 14), eventTitle.substring(0, 15),
                            eventTitle.substring(0, 16), eventTitle.substring(0, 17), eventTitle.substring(0, 18), eventTitle.substring(0, 19), eventTitle.substring(0, 20),
                            keywords1.substring(0, 1), keywords1.substring(0, 2), keywords1.substring(0, 3), keywords1.substring(0, 4), keywords1.substring(0, 5),
                            keywords1.substring(0, 6), keywords1.substring(0, 7), keywords1.substring(0, 8), keywords1.substring(0, 9), keywords1.substring(0, 10),
                            keywords2.substring(0, 1), keywords2.substring(0, 2), keywords2.substring(0, 3), keywords2.substring(0, 4), keywords2.substring(0, 5),
                            keywords2.substring(0, 6), keywords2.substring(0, 7), keywords2.substring(0, 8), keywords2.substring(0, 9), keywords2.substring(0, 10),
                            keywords3.substring(0, 1), keywords3.substring(0, 2), keywords3.substring(0, 3), keywords3.substring(0, 4), keywords3.substring(0, 5),
                            keywords3.substring(0, 6), keywords3.substring(0, 7), keywords3.substring(0, 8), keywords3.substring(0, 9), keywords3.substring(0, 10),
                            keywords4.substring(0, 1), keywords4.substring(0, 2), keywords4.substring(0, 3), keywords4.substring(0, 4), keywords4.substring(0, 5),
                            keywords4.substring(0, 6), keywords4.substring(0, 7), keywords4.substring(0, 8), keywords4.substring(0, 9), keywords4.substring(0, 10),
        
                            // Extra
                            keywords4.substring(0, 7), keywords4.substring(0, 8), keywords4.substring(0, 9), keywords4.substring(0, 10),
                    ],
                    keywordsWord: [keywords1, keywords2, keywords3, keywords4],
                    eventDate: eventDate,
                    eventWhy: eventWhy,
                    eventDetails: eventDetails,
                    eventTime: eventTime,
                    website: website,
                    bookMark: [],
                    likes: [],
                    typePost: "eventPosting",
                    senderId: user.id,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp()
                })
                setStatusInput(initInput);
                setAltText('');
                setSrc(null);
                setIsloading(false);
                history.push(HOME)

            })
            .catch( err=> {
                setIsloading(false)
                return
            })

        } else {

        db.collection('jobs').add({
            displayName: user.displayName,
            briefIntroYour: '',
            skills: '',
            wantedJob: '',
            career: '',
            companyName: user.displayName,
            youtubeLink: youtubeLink,
            salary: '',
            altText: altText,
            jobTitle: eventTitle,
            isDone: false,
            kindJob: kindJob,
            workDetails: '',
            styleWork: '',
            prefecture: prefecture,
            otherAddress: otherAddress,
            image: '',
            briefIntro: '',
            wanted: '',
            reqSkill: '',
            opSkill: '',
            hireSection: '',
            grade: '',
            timeWork: '',
            welfare: '',
            different: '',
            testPeriod: '',
            offDay: '',
            titleKeyWords: [eventTitle.substring(0, 1), eventTitle.substring(0, 2), eventTitle.substring(0, 3), eventTitle.substring(0, 4), eventTitle.substring(0, 5), eventTitle.substring(0, 6), eventTitle],
            eventDate: eventDate,
            eventWhy: eventWhy,
            eventDetails: eventDetails,
            eventTime: eventTime,
            website: website,
            keywords: [eventTitle.substring(0, 1), eventTitle.substring(0, 2), eventTitle.substring(0, 3), eventTitle.substring(0, 4), eventTitle.substring(0, 5),
                    eventTitle.substring(0, 6), eventTitle.substring(0, 7), eventTitle.substring(0, 8), eventTitle.substring(0, 9), eventTitle.substring(0, 10),
                    eventTitle.substring(0, 11), eventTitle.substring(0, 12), eventTitle.substring(0, 13), eventTitle.substring(0, 14), eventTitle.substring(0, 15),
                    eventTitle.substring(0, 16), eventTitle.substring(0, 17), eventTitle.substring(0, 18), eventTitle.substring(0, 19), eventTitle.substring(0, 20),
                    keywords1.substring(0, 1), keywords1.substring(0, 2), keywords1.substring(0, 3), keywords1.substring(0, 4), keywords1.substring(0, 5),
                    keywords1.substring(0, 6), keywords1.substring(0, 7), keywords1.substring(0, 8), keywords1.substring(0, 9), keywords1.substring(0, 10),
                    keywords2.substring(0, 1), keywords2.substring(0, 2), keywords2.substring(0, 3), keywords2.substring(0, 4), keywords2.substring(0, 5),
                    keywords2.substring(0, 6), keywords2.substring(0, 7), keywords2.substring(0, 8), keywords2.substring(0, 9), keywords2.substring(0, 10),
                    keywords3.substring(0, 1), keywords3.substring(0, 2), keywords3.substring(0, 3), keywords3.substring(0, 4), keywords3.substring(0, 5),
                    keywords3.substring(0, 6), keywords3.substring(0, 7), keywords3.substring(0, 8), keywords3.substring(0, 9), keywords3.substring(0, 10),
                    keywords4.substring(0, 1), keywords4.substring(0, 2), keywords4.substring(0, 3), keywords4.substring(0, 4), keywords4.substring(0, 5),
                    keywords4.substring(0, 6), keywords4.substring(0, 7), keywords4.substring(0, 8), keywords4.substring(0, 9), keywords4.substring(0, 10),

                    // Extra
                    keywords4.substring(0, 7), keywords4.substring(0, 8), keywords4.substring(0, 9), keywords4.substring(0, 10),
            ],
            keywordsWord: [keywords1, keywords2, keywords3, keywords4],
            bookMark: [],
            likes: [],
            typePost: "eventPosting",
            senderId: user.id,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
        })
        setStatusInput(initInput);
        setAltText('');
        setSrc(null);
        setIsloading(false);
        history.push(HOME)
        }
    }

    const onSelectFile = e => {
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            setSrc(fileReader.result)
            setImageToSend(fileReader.result)
        }   
        fileReader.readAsDataURL(e.target.files[0])

        getInfo(e).then(res=> {
            setinitialImageSize({width: res.width, height: res.height})
        })
    }

    useEffect(() => {
        setinitialAspectRatio(initialImageSize.width/initialImageSize.height)
    }, [initialImageSize])

    const changeSrc = () => {
        setSrc(URL.createObjectURL(croppedImageResult))
        setImageToSend(croppedImageResult)
    }

    const callbackforModal = () =>{
        changeSrc()
        if (altText.length === 0){
            setAltText(generateAltText(user.displayName))
        }
    }

    const items = [
        {
            id: 0,
            title:'',
            icon: <CropIcon />,
            item: <CropPhotoB
                    image={src} 
                    setCroppedImageResult ={setCroppedImageResult} 
                    initialAspectRatio    = {initialAspectRatio}
            />
        },
        {
            id: 1,
            title: 'ALT',
            icon: '',
            item: <AddALT image={croppedImageResult} altText={altText} setAltText={setAltText}/>
        }
    ]



    useEffect(() => {
        var textarea = document.querySelector('textarea')
        textarea.addEventListener('keydown', autosize)
                    
        function autosize(){
        var el = this
        setTimeout(function(){
            el.style.cssText = 'height:auto padding:0'
            el.style.cssText = 'height:' + el.scrollHeight + 'px'
        },0)
        }
    }, [])

    return (
        <>
            <MessageModal
            open={isOpenYoutubeModal} 
            onClose={()=>setIsOpenYoutubeModal(false)}
            Icon = {ArrowBackOutlinedIcon}
            title="Youtubeのリンクの貼り方"
            >
                <YoputubeClickInfo/>
            </MessageModal>

            <Modal  open={isOpenModal} 
                    onClose={()=>setIsOpenModal(false)}
                    title="画像を編集"
                    callback = {callbackforModal}
                    Icon = {ArrowBackOutlinedIcon}
                    ButtonText='保存する'
                    >
                    <TabbarMenu items={items}/>
            </Modal>

            <div className='jobBox'>
                <div className='youtube__info__div'>
                    <button className='youtube__info' onClick={()=>setIsOpenYoutubeModal(true)}><i className='fas fa-info-circle youtube__click__info'/></button>
                </div>
                <form onSubmit={sendPost}>
                    <div className='jobBox__wrapperInput' >
                        <div className='jobBox__input'>
                            <input type='text' placeholder='イベントタイトル' value={statusInput.eventTitle} onChange={e => setStatusInput({...statusInput, eventTitle: e.target.value})} required maxLength='20'/>
                            <select className="jobPost__select" onChange={e => setStatusInput({...statusInput, kindJob: e.target.value})} required>
                            <option selected disabled>職種を選択する</option>
                                {/*営業*/}
                                <option disabled>営業</option>
                                <option value='法人営業'>法人営業</option>
                                <option value='個人営業'>個人営業</option>
                                <option value='内勤営業・カウンターセールス'>内勤営業・カウンターセールス</option>
                                <option value='ルートセールス・代理店営業'>ルートセールス・代理店営業</option>
                                <option value='IT営業'>IT営業</option>
                                <option value='海外営業'>海外営業</option>
                                <option value='不動産営業'>不動産営業</option>
                                <option value='金融営業'>金融営業</option>
                                <option value='テレマーケティング'>テレマーケティング</option>

                                {/*事務・アシスタント*/}
                                <option disabled>事務・アシスタント</option>
                                <option value='一般事務'>一般事務</option>
                                <option value='営業事務'>営業事務</option>
                                <option value='カスタマーサポート'>カスタマーサポート</option>
                                <option value='受付'>受付</option>
                                <option value='秘書'>秘書</option>

                                {/*財務・会計・経理*/}
                                <option value='経理'>経理</option>
                                <option value='財務'>財務</option>
                                <option value='管理会計'>管理会計</option>

                                {/*総務・人事・法務・知財・広報・IR*/}
                                <option disabled>総務・人事・法務・知財・広報・IR</option>
                                <option value='人事（採用）'>人事（採用）</option>
                                <option value='人事（労務）'>人事（労務）</option>
                                <option value='総務'>総務</option>
                                <option value='広報'>広報</option>
                                <option value='法務'>法務</option>

                                {/*商品企画・営業企画・マーケティング・宣伝*/}
                                <option disabled>商品企画・営業企画・マーケティング・宣伝</option>
                                <option value='商品企画、サービス企画'>商品企画、サービス企画</option>
                                <option value='販売促進・PR・広告宣伝'>販売促進・PR・広告宣伝</option>
                                <option value='Webマーケティング'>Webマーケティング</option>

                                {/*経営企画・事業統括・新規事業開発*/}
                                <option disabled>経営企画・事業統括・新規事業開発</option>
                                <option value='経営者・経営幹部・役員(CEO、COO、CFO、CIO、CTO)'>経営者・経営幹部・役員（CEO、COO、CFO、CIO、CTO）</option>

                                {/*管理職・エグゼクティブ*/}
                                <option disabled>管理職・エグゼクティブ</option>
                                <option value='経営企画・営業企画'>経営企画・営業企画</option>
                                <option value='事業企画・新規事業開発'>事業企画・新規事業開発</option>
                                <option value='事業統括マネージャー職（営業部門・管理部門）'>事業統括マネージャー職（営業部門・管理部門）</option>

                                {/*Webエンジニア*/}
                                <option disabled>エンジニア</option>
                                <option value='システムエンジニア（Web・オープン系・パッケージ開発）'>システムエンジニア（Web・オープン系・パッケージ開発）</option>
                                <option value='システムエンジニア（汎用機系）'>システムエンジニア（汎用機系）</option>
                                <option value='プロジェクトマネジャー（Web・オープン系・パッケージ開発）'>プロジェクトマネジャー（Web・オープン系・パッケージ開発）</option>
                                <option value='モバイルアプリ'>モバイルアプリ</option>
                                <option value='フロントエンド'>フロントエンド</option>
                                <option value='バックエンド'>バックエンド</option>
                                <option value='フルスタック'>フルスタック</option>

                                {/*インフラエンジニア*/}
                                <option disabled>インフラエンジニア</option>
                                <option value='インフラエンジニア'>インフラエンジニア</option>
                                <option value='サーバーエンジニア（設計構築）'>サーバーエンジニア（設計構築）</option>
                                <option value='ネットワークエンジニア（設計構築）'>ネットワークエンジニア（設計構築）</option>
                                <option value='データベースエンジニア'>データベースエンジニア</option>
                                <option value='プロジェクトマネージャー（インフラ）'>プロジェクトマネージャー（インフラ）</option>
                                <option value='Webサービスエンジニア（ネットワーク・サーバー・データベース）'>Webサービスエンジニア（ネットワーク・サーバー・データベース）</option>
                                <option value='運用・監視・保守（インフラ）'>運用・監視・保守（インフラ）</option>

                                {/*サポート・ヘルプデスク*/}
                                <option disabled>サポート・ヘルプデスク</option>
                                <option value='サポート・ヘルプデスク'>サポート・ヘルプデスク</option>

                                {/*社内SE（社内情報システム）*/}
                                <option disabled>社内SE（社内情報システム）</option>
                                <option value='社内SE（社内情報システム）'>社内SE（社内情報システム）</option>

                                {/* Creative */}
                                {/*Web・モバイル（制作・開発）*/}
                                <option disabled>Web・モバイル</option>
                                <option value='Webプロデューサー・Webディレクター・Webプランナー'>Webプロデューサー・Webディレクター・Webプランナー</option>
                                <option value='Webデザイナー'>Webデザイナー</option>
                                <option value='Webライター・Web編集（コンテンツ制作）'>Webライター・Web編集（コンテンツ制作）</option>
                                <option value='UI・UXデザイナー（Web・モバイル）'>UI・UXデザイナー（Web・モバイル）</option>
                                <option value='アシスタントディレクター・制作進行管理'>アシスタントディレクター・制作進行管理</option>

                                {/*広告・グラフィック*/}
                                <option disabled>広告・グラフィック</option>
                                <option value='フォトグラファー・カメラマン'>フォトグラファー・カメラマン</option>

                                {/*出版・広告・販促・印刷*/}
                                <option disabled>出版・広告・販促・印刷</option>
                                <option value='イラストレーター【広告・グラフィック関連】'>イラストレーター【広告・グラフィック関連】</option>
                                <option value='DTPオペレーター'>DTPオペレーター</option>
                                <option value='その他広告・グラフィック関連職'>その他広告・グラフィック関連職</option>
                                <option value='編集・記者・ライター'>編集、記者、ライター</option>
                                <option value='デザイナー（グラフィック・その他）'>デザイナー（グラフィック・その他）</option>

                                {/*店舗・販売*/}
                                <option disabled>店舗・販売</option>
                                <option value='店長'>店長</option>
                                <option value='ホールスタッフ'>ホールスタッフ</option>
                                <option value='調理スタッフ'>調理スタッフ</option>

                                {/*美容関連*/}
                                <option disabled>美容関連</option>
                                <option value='美容師・理容師・その他美容関連'>美容師・理容師・その他美容関連</option>
                                <option value='美容部員・エステティシャン・マッサージ・ビューティーアドバイザー'>美容部員・エステティシャン・マッサージ・ビューティーアドバイザー</option>

                                {/*ブライダル・葬祭*/}
                                <option disabled>ブライダル・葬祭</option>
                                <option value='ウェディングプランナー'>ウェディングプランナー</option>
                                <option value='葬祭ディレクター・プランナー'>葬祭ディレクター・プランナー</option>
                                <option value='その他ブライダル・葬祭関連'>その他ブライダル・葬祭関連</option>

                                {/*旅行関連*/}
                                <option disabled>旅行関連</option>
                                <option value='旅行手配員・添乗員・ツアーコンダクター'>旅行手配員・添乗員・ツアーコンダクター</option>

                                {/*宿泊施設・ホテル*/}
                                <option disabled>宿泊施設・ホテル</option>
                                <option value='施設管理・マネジメント'>施設管理・マネジメント</option>
                                <option value='フロント業務・予約受付'>フロント業務・予約受付</option>
                                <option value='その他宿泊施設・ホテル関連'>その他宿泊施設・ホテル関連</option>

                                {/*教育／スクール／研修／塾講師／専門学校／英会話学校*/}
                                <option disabled>教育／スクール／研修／塾講師／専門学校／英会話学校</option>
                                <option value='スクール長・マネジャー'>スクール長・マネジャー</option>
                                <option value='講師・指導員・インストラクター'>講師・指導員・インストラクター</option>

                                {/*建築設計・積算*/}
                                <option disabled>建築設計・積算</option>
                                <option value='意匠設計・構造設計'>意匠設計・構造設計</option>
                                <option value='製図・CADオペレーター'>製図・CADオペレーター</option>
                                <option value='内装設計・インテリア・空間デザイン'>内装設計・インテリア・空間デザイン</option>
                                <option value='積算（建築設計）'>積算（建築設計）</option>
                                <option value='内装設計（店舗・オフィス）'>内装設計（店舗・オフィス）</option>
                                <option value='内装設計・リフォーム・インテリア（住宅）'>内装設計・リフォーム・インテリア（住宅）</option>

                                {/*施工管理・職人・現場作業員*/}
                                <option disabled>施工管理・職人・現場作業員</option>
                                <option value='建築施工管理（RC造・S造・SRC造・木造）'>建築施工管理（RC造・S造・SRC造・木造）</option>
                                <option value='建築施工管理（店舗内装・オフィス内装）'>建築施工管理（店舗内装・オフィス内装） </option>
                                <option value='建築施工管理（住宅内装・リフォーム・インテリア'>建築施工管理（住宅内装・リフォーム・インテリア</option>
                                <option value='設備施工管理（電気・空調・衛生設備）'>設備施工管理（電気・空調・衛生設備） </option>
                                <option value='土木施工管理（トンネル・道路・造成・ダム・河川・港湾・造園など）'>土木施工管理（トンネル・道路・造成・ダム・河川・港湾・造園など）</option>

                                {/*その他建設・建築・不動産・プラント・工場関連職*/}
                                <option disabled>その他建設・建築・不動産・プラント・工場関連職</option>
                                <option value='その他建設・建築・不動産・プラント・工場関連職'>その他建設・建築・不動産・プラント・工場関連職</option>

                                {/*医療・看護*/}
                                <option disabled>医療・看護</option>
                                <option value='病院長・事務長'>病院長・事務長</option>
                                <option value='医師・看護師'>医師・看護師</option>
                                <option value='歯科医師・歯科衛生士・歯科技工士'>歯科医師・歯科衛生士・歯科技工士</option>
                                <option value='臨床心理士・カウンセラー'>臨床心理士・カウンセラー</option>
                                <option value='栄養士・管理栄養士'>栄養士・管理栄養士</option>
                                <option value='その他医療・看護'>その他医療・看護</option>

                                {/*福祉*/}
                                <option disabled>福祉</option>
                                <option value='院長・福祉施設長'>院長・福祉施設長</option>
                                <option value='介護福祉士・ケアマネジャー'>介護福祉士・ケアマネジャー</option>
                                <option value='ホームヘルパー・ケアワーカー'>ホームヘルパー・ケアワーカー</option>

                                {/*その他・専門職*/}
                                <option disabled>その他・専門職</option>
                                <option value='その他・専門職'>その他・専門職</option>
                            </select>
                            <input type='date' placeholder='開催日' value={statusInput.eventDate} onChange={e => setStatusInput({...statusInput, eventDate: e.target.value})} required/>
                            <select className="jobPost__select" value={statusInput.eventTime} onChange={e => setStatusInput({...statusInput, eventTime: e.target.value})} required>
                                <option disabled selected>時間を選択する</option>
                                <option value='8:00'>8:00</option>
                                <option value='8:30'>8:30</option>
                                <option value='9:00'>9:00</option>
                                <option value='9:30'>9:30</option>
                                <option value='10:00'>10:00</option>
                                <option value='10:30'>10:30</option>
                                <option value='11:00'>11:00</option>
                                <option value='11:30'>11:30</option>
                                <option value='12:00'>12:00</option>
                                <option value='12:30'>12:30</option>
                                <option value='13:00'>13:00</option>
                                <option value='13:30'>13:30</option>
                                <option value='14:00'>14:00</option>
                                <option value='14:30'>14:30</option>
                                <option value='15:00'>15:00</option>
                                <option value='15:30'>15:30</option>
                                <option value='16:00'>16:00</option>
                                <option value='16:30'>16:30</option>
                                <option value='17:00'>17:00</option>
                                <option value='17:30'>17:30</option>
                                <option value='18:00'>18:00</option>
                                <option value='18:30'>18:30</option>
                                <option value='19:00'>19:00</option>
                                <option value='19:30'>19:30</option>
                                <option value='20:00'>20:00</option>
                            </select>
                            <select className='jobPost__select' onChange={e => setStatusInput({...statusInput, prefecture: e.target.value})} required>
                                <option disabled selected>県を選択する</option>
                                <option value="リモート">リモート</option>
                                <option value="北海道">北海道</option>
                                <option value="青森">青森</option>
                                <option value="岩手" >岩手</option>
                                <option value="宮城" >宮城</option>
                                <option value="秋田" >秋田</option>
                                <option value="山形" >山形</option>
                                <option value="福島" >福島</option>
                                <option value="茨城" >茨城</option>
                                <option value="栃木" >栃木</option>
                                <option value="群馬" >群馬</option>
                                <option value="埼玉" >埼玉</option>
                                <option value="千葉" >千葉</option>
                                <option value="東京" >東京</option>
                                <option value="神奈川" >神奈川</option>
                                <option value="新潟" >新潟</option>
                                <option value="富山" >富山</option>
                                <option value="石川" >石川</option>
                                <option value="福井" >福井</option>
                                <option value="山梨" >山梨</option>
                                <option value="長野" >長野</option>
                                <option value="岐阜" >岐阜</option>
                                <option value="静岡" >静岡</option>
                                <option value="愛知" >愛知</option>
                                <option value="三重" >三重</option>
                                <option value="滋賀" >滋賀</option>
                                <option value="京都" >京都</option>
                                <option value="大阪" >大阪</option>
                                <option value="兵庫" >兵庫</option>
                                <option value="奈良" >奈良</option>
                                <option value="和歌山" >和歌山</option>
                                <option value="鳥取" >鳥取</option>
                                <option value="島根" >島根</option>
                                <option value="岡山" >岡山</option>
                                <option value="広島" >広島</option>
                                <option value="山口" >山口</option>
                                <option value="徳島" >徳島</option>
                                <option value="香川" >香川</option>
                                <option value="愛媛" >愛媛</option>
                                <option value="高知" >高知</option>
                                <option value="福岡" >福岡</option>
                                <option value="佐賀" >佐賀</option>
                                <option value="長崎" >長崎</option>
                                <option value="熊本" >熊本</option>
                                <option value="大分" >大分</option>
                                <option value="宮崎" >宮崎</option>
                                <option value="鹿児島" >鹿児島</option>
                                <option value="沖縄" >沖縄</option>
                            </select>
                            <input type='text' placeholder='残りの住所' value={statusInput.otherAddress} onChange={e => setStatusInput({...statusInput, otherAddress: e.target.value})}/>
                            <input type='text' placeholder='ウェブサイトURL(申し込み用のURLなど)' value={statusInput.website} onChange={e => setStatusInput({...statusInput, website: e.target.value})}/>
                            <input type='text' placeholder='Youtube URL(会社の紹介動画など)、リンクの貼り方は上記のボタンより確認できます' value={statusInput.youtubeLink} onChange={e => setStatusInput({...statusInput, youtubeLink: e.target.value})}/>
                            <textarea rows='1' 
                                      placeholder="なぜ開催するのか"
                                      type='text' 
                                      value={statusInput.eventWhy} 
                                      onChange={e => setStatusInput({...statusInput, eventWhy: e.target.value})}
                                      required                     
                            >
                            </textarea>
                            <textarea rows='1' 
                                      placeholder="イベント内容"
                                      type='text' 
                                      value={statusInput.eventDetails} 
                                      onChange={e => setStatusInput({...statusInput, eventDetails: e.target.value})}
                                      required                     
                            >
                            </textarea>
                            <div className='d-flex'>
                                    <div>
                                        <input type='text' list='keyword' maxLength='10' placeholder='キーワード1' value={statusInput.keywords1} onChange={e => setStatusInput({...statusInput, keywords1: e.target.value})}/>
                                        <datalist id='keyword'>
                                            <option value='駅近'>駅近</option>
                                            <option value='交通費支給'>交通費支給</option>
                                            <option value='リモート'>リモート</option>
                                            <option value='週休2日'>週休2日</option>
                                            <option value='週2・3からOK'>週2・3からOK</option>
                                            <option value='大学生OK'>大学生OK</option>
                                            <option value='高校生OK'>高校生OK</option>
                                            <option value='副業・WワークOK'>副業・WワークOK</option>
                                            <option value='未経験OK'>未経験OK</option>
                                            <option value='経験者歓迎'>経験者歓迎</option>
                                            <option value='学歴不問'>学歴不問</option>
                                            <option value='産休・育休'>産休・育休</option>
                                            <option value='研修あり'>研修あり</option>
                                            <option value='資格手当'>資格手当</option>
                                            <option value='家族手当'>家族手当</option>
                                            <option value='インセンティブ'>インセンティブ</option>
                                            <option value='社保完備'>社保完備</option>
                                            <option value='育児支援'>育児支援</option>
                                            <option value='急募'>急募</option>
                                            <option value='Web面接OK'>Web面接OK</option>
                                            <option value='福利厚生充実'>福利厚生充実</option>
                                        </datalist>
                                    </div>
                                    <div>
                                        <input type='text' list='keyword' maxLength='10' placeholder='キーワード2' value={statusInput.keywords2} onChange={e => setStatusInput({...statusInput, keywords2: e.target.value})}/>
                                        <datalist id='keyword'>
                                            <option value='駅近'>駅近</option>
                                            <option value='交通費支給'>交通費支給</option>
                                            <option value='リモート'>リモート</option>
                                            <option value='週休2日'>週休2日</option>
                                            <option value='週2・3からOK'>週2・3からOK</option>
                                            <option value='大学生OK'>大学生OK</option>
                                            <option value='高校生OK'>高校生OK</option>
                                            <option value='副業・WワークOK'>副業・WワークOK</option>
                                            <option value='未経験OK'>未経験OK</option>
                                            <option value='経験者歓迎'>経験者歓迎</option>
                                            <option value='学歴不問'>学歴不問</option>
                                            <option value='産休・育休'>産休・育休</option>
                                            <option value='研修あり'>研修あり</option>
                                            <option value='資格手当'>資格手当</option>
                                            <option value='家族手当'>家族手当</option>
                                            <option value='インセンティブ'>インセンティブ</option>
                                            <option value='社保完備'>社保完備</option>
                                            <option value='育児支援'>育児支援</option>
                                            <option value='急募'>急募</option>
                                            <option value='Web面接OK'>Web面接OK</option>
                                            <option value='福利厚生充実'>福利厚生充実</option>
                                        </datalist>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div>
                                        <input type='text' list='keyword' maxLength='10' placeholder='キーワード3' value={statusInput.keywords3} onChange={e => setStatusInput({...statusInput, keywords3: e.target.value})}/>
                                        <datalist id='keyword'>
                                            <option value='駅近'>駅近</option>
                                            <option value='交通費支給'>交通費支給</option>
                                            <option value='リモート'>リモート</option>
                                            <option value='週休2日'>週休2日</option>
                                            <option value='週2・3からOK'>週2・3からOK</option>
                                            <option value='大学生OK'>大学生OK</option>
                                            <option value='高校生OK'>高校生OK</option>
                                            <option value='副業・WワークOK'>副業・WワークOK</option>
                                            <option value='未経験OK'>未経験OK</option>
                                            <option value='経験者歓迎'>経験者歓迎</option>
                                            <option value='学歴不問'>学歴不問</option>
                                            <option value='産休・育休'>産休・育休</option>
                                            <option value='研修あり'>研修あり</option>
                                            <option value='資格手当'>資格手当</option>
                                            <option value='家族手当'>家族手当</option>
                                            <option value='インセンティブ'>インセンティブ</option>
                                            <option value='社保完備'>社保完備</option>
                                            <option value='育児支援'>育児支援</option>
                                            <option value='急募'>急募</option>
                                            <option value='Web面接OK'>Web面接OK</option>
                                            <option value='福利厚生充実'>福利厚生充実</option>
                                        </datalist>
                                    </div>
                                    <div>
                                        <input type='text' list='keyword' maxLength='10' placeholder='キーワード4' value={statusInput.keywords4} onChange={e => setStatusInput({...statusInput, keywords4: e.target.value})}/>
                                        <datalist id='keyword'>
                                            <option value='駅近'>駅近</option>
                                            <option value='交通費支給'>交通費支給</option>
                                            <option value='リモート'>リモート</option>
                                            <option value='週休2日'>週休2日</option>
                                            <option value='週2・3からOK'>週2・3からOK</option>
                                            <option value='大学生OK'>大学生OK</option>
                                            <option value='高校生OK'>高校生OK</option>
                                            <option value='副業・WワークOK'>副業・WワークOK</option>
                                            <option value='未経験OK'>未経験OK</option>
                                            <option value='経験者歓迎'>経験者歓迎</option>
                                            <option value='学歴不問'>学歴不問</option>
                                            <option value='産休・育休'>産休・育休</option>
                                            <option value='研修あり'>研修あり</option>
                                            <option value='資格手当'>資格手当</option>
                                            <option value='家族手当'>家族手当</option>
                                            <option value='インセンティブ'>インセンティブ</option>
                                            <option value='社保完備'>社保完備</option>
                                            <option value='育児支援'>育児支援</option>
                                            <option value='急募'>急募</option>
                                            <option value='Web面接OK'>Web面接OK</option>
                                            <option value='福利厚生充実'>福利厚生充実</option>
                                        </datalist>
                                    </div>
                                </div>
                            {
                                src &&
                                    <div className='jobBox__input-image'>
                                        <CancelIcon className='cancelIcon' onClick={()=>setSrc(null)}/>
                                        <img src={src} alt="new test"/>               
                                        <Button className='editImage' onClick={()=>setIsOpenModal(true)}>編集する</Button>
                                    </div>                        
                            }

                            <div className='jobBox__input-actions'>
                                <div className='jobBox__input-icons'>
                                   <StatusInput Icon='fas fa-image'
                                                type="file"
                                                accept="image/*"
                                                name="image-upload"
                                                id="input-image"
                                                onChange={onSelectFile}
                                                required
                                    />

                                </div>
                    
                                {
                                    isLoading ?
                                    <Button className='jobBox__button'><Spinner /></Button>
                                    :
                                    <Button type='submit'className='jobBox__button'>投稿する</Button>
                                }

                            </div>
                        </div>    
                    </div>
                </form>
            </div>
        </>
    )
}

export default EventPostingBox;
