
import React, { useEffect, useState } from "react";
import CompanyDashboardNav from "../../component/mobileNav/CompanyDashboardNav";
import { useStateValue } from "../../contexts/StateContextProvider";
import { TabbarMenu } from "../../elements";
import db from "../../firebase";
import { useDocumentTitle, useScrollTop } from "../../hooks";
import NotFound from "../error/NotFound";
import CompanyDashboardCard from "./card/CompanyDashboardCard";
import './index.css';
import CompanyDashboardEventPost from "./post/CompanyDashboardEvent";
import CompanyDashboardJobPost from "./post/CompanyDashboardJobPost";

const CompanyDashboard = () => {
    useScrollTop();
    useDocumentTitle("ダッシュボード / Copipel");
    const [{user}] = useStateValue();

    const [posts, setPosts] = useState([]);
    useEffect(() => {
        let mounted = true
        db.collection('jobs').orderBy('timestamp', 'desc').where('displayName', '==', user.displayName)
        .onSnapshot(snapshot=>{
           if(mounted){
              if(snapshot.empty){
                 return
              }
                setPosts(snapshot.docs.map(doc=> ({id:doc.id, ...doc.data()})))
           }
        }) 
     return () => mounted = false
   }, []);

   const items = [
    {
        id:0,
        title: "求人投稿",
        item: <CompanyDashboardJobPost/>
    },
    {
        id:1,
        title: "イベント投稿",
        item: <CompanyDashboardEventPost/>
    }
];


    return(
        <>
        <CompanyDashboardNav/>
        {user.role === 'COMPANY' ? 
            <div className="company__dashboard">
                <div className="company__dashboard-1">
                    <div className="d-flex">
                        <CompanyDashboardCard icon='far fa-list-alt' title='合計投稿' unit={`${posts.length}`} unitTitle='数'/>
                    </div>
                </div>
                <div className="company__dashboard-2">
                </div>
                <div className="company__dashboard-3">
                    <TabbarMenu items={items}/>
                </div>
            </div>
            : <NotFound/>
        }
        </>
    )
}


export default CompanyDashboard;