import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import db from "../../../../firebase";
import '../../index.css';
import UnAuthJobPostingFront from '../../../postFront/unAuth/UnAuthJobPostingFront';
import UserProfileTopbar from "../UserProfileTopbar";

const UnAuthUserFollowingPostPage = () => {
    const {username} = useParams();
    const history = useHistory(); 
    const [posts, setPosts] = useState([]);
    const [profile, setProfile] = useState([])
    useEffect(() => {
       if(!profile.length){
         const getData = db.collection('users').where('username', '==', username).onSnapshot(snapshot=>{
            setProfile(snapshot.docs.map(doc=>({
              id: doc.id,
              ...doc.data()
            }))[0])
          })
   
          return () => getData();
       }
      }, [])

    const [following, setFollowing] = useState([])
 
    useEffect(() => {
       if(!following.length){
         let mounted = true
         db.collection('users').doc(`${profile.id}`).onSnapshot(snapshot=>{
            if (mounted) {
               setFollowing(snapshot.data() && snapshot.data().following)
            }
         })
         return () => mounted = false
       }
   }, [])
 
   useEffect(() => {
      if(!posts.length){
         let mounted = true
         if(following){
            db.collection('jobs')
            .where('senderId', 'in', ['', ...following])
            .orderBy('timestamp', 'desc')
            .onSnapshot(snapshot=>{
               if(mounted){
                  if(snapshot.empty){
                     return
                  }
                     setPosts(snapshot.docs.map(doc=> ({id:doc.id, ...doc.data()})))
               }
            })
         }
      return () => mounted = false
      }
   }, [following]);

   const [copySuccess, setCopySuccess] = useState('');
   const onCopyBtn = (e) => {
      navigator.clipboard.writeText(window.location.href)
      setCopySuccess('copied');
   }
  
  
    return(
        <div className='feed'>
            <div className="profile__header">
               <div className="profile__backArrow" onClick={()=>history.goBack()}>
                  <ArrowBackOutlinedIcon />
               </div>
               <div className='profile__title'>
                  <div className='profile__title_title'><h2>{profile.anonymous === true ? <>{"匿名"}</> : <>{profile.displayName}</>}</h2>{profile && profile.role === "COMPANY" ? <VerifiedUserIcon className='post__badge'/>: <></>}</div>           
               </div>
               <div className="profile__top__link">
                  <button className="profile__top__link-pc" onClick={onCopyBtn}>{copySuccess !== 'copied' ? <p>URLをコピーする<i className="fas fa-check-circle check-icon"/></p> : <p>URLをコピーしました<i className="fas fa-check-circle check-icon check-icon-success"/></p>}</button>
               </div>   
            </div>
            <UserProfileTopbar/>
            {profile && profile.published ? 
            <>
               {posts.length > 0 ? <UnAuthJobPostingFront posts={posts} /> : <p className="profile__no__post">現在、フォローしているユーザーによる投稿はありません</p>}
            </>
            :
            <h4>現在、このアカウントは非公開になっています</h4>
            }
        </div>
    )
}

export default UnAuthUserFollowingPostPage;