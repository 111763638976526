import React from "react";
import { NavLink, useParams } from "react-router-dom";
import '../index.css';

const UserProfileTopbar = () => {
    const {username} = useParams();
    return(
        <div className="active__navigation">
            <ul>
                <li><NavLink exact to={`/profile/${username}`}  activeClassName="active__user__profile" replace><span>プロフィール</span></NavLink></li>
                <li><NavLink to={`/profile/${username}/bookmark`}  activeClassName="active__user__profile" replace><span>ブックマーク</span></NavLink></li>
                <li><NavLink to={`/profile/${username}/favorite`}  activeClassName="active__user__profile" replace><span>気になる</span></NavLink></li>
                <li><NavLink to={`/profile/${username}/post`}  activeClassName="active__user__profile" replace><span>投稿</span></NavLink></li>
                <li><NavLink to={`/profile/${username}/following-post`} activeClassName="active__user__profile" replace><span>フォロー / 投稿</span></NavLink></li>
            </ul>
        </div>
    )
}

export default UserProfileTopbar;