import React from "react";
import './index.css';

const AdminDashboardMini = ({icon, title, unit, unitTitle}) => {
    return(
        <div className="admin__dashboard__mini">
            <div className="d-flex">
                <div>
                    <i className={icon}/>
                </div>
                <div className="admin__dashboard__mini-content">
                    <h3>{title}</h3>
                    <h5><strong>{unit}</strong>{unitTitle}</h5>
                </div>
            </div>
        </div>
    )
}

export default AdminDashboardMini;