import React, {useEffect} from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import ChatContextProvider from './contexts/ChatContextProvider';
import { useStateValue } from './contexts/StateContextProvider';
import * as COMPONENT from './component';
import * as ROUTE from './constant/router';
import * as PAGES from './pages/index';
import * as ADMINPAGES from './admin/pages/index';
import { SET_USER } from './constant/constant';
import { AuthBottomNav, UnAuthBottomNav } from './elements';
import './App.css';
import { Redirect } from 'react-router-dom';
import AdminSidebar from './admin/components/sidebar/AdminSidebar';


const AppRouter = () => {
  const [{user}, dispatch] = useStateValue();
  useEffect(() => {
      dispatch({
        type: SET_USER,
        user: JSON.parse(localStorage.getItem('copipel_user'))
      })    
  }, [])

  return(
    <div className="app">
            <Router>
            {user ? <></> : <COMPONENT.UnAuthTopNav/>}
            <div className="app__mainContent">
              <Switch>
                <Route path={ROUTE.LOGIN}>
                  {user ? <COMPONENT.AuthSidebar/>: <></>}
                  <div className='app__main'>
                    {user ? <Redirect to={ROUTE.HOME}/> : <PAGES.Login/>}
                  </div>
                </Route>

                <Route path={ROUTE.LOGIN__EMAIL}>
                  <PAGES.LoginConfirmEmail/>
                </Route>

                <Route path={ROUTE.DELETE__ACCOUNT}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                  <div className='app__main'>
                    {user ? <PAGES.AuthDeleteAccount/> : <PAGES.UnAuthDeleteAccount/>}
                  </div>
                </Route>

                <Route path={ROUTE.EMAIL__CONFORMATION}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                  <div className='app__main'>
                    <PAGES.ConfirmEmail/>
                  </div>
                </Route>
                
                <Route path={ROUTE.FORGET_PASSWORD}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                  <div className='app__main'>
                    {user ? <PAGES.ChangePassword/> : <PAGES.ForgetPassword/>}
                  </div>
                </Route>

                {/*INFO*/}
                <Route path={ROUTE.ABOUT__US}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                  <div className='app__main'>
                    <PAGES.AboutUs/>
                  </div>
                </Route>

                <Route path={ROUTE.USE__AND__TERMS}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                  <div className='app__main'>
                    <PAGES.UseAndTerms/>
                  </div>
                </Route>

                <Route path={ROUTE.PRIVACY__POLICY}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                  <div className='app__main'>
                    <PAGES.PrivacyPolicy/>
                  </div>
                </Route>
                <Route path={ROUTE.HELP__CENTER}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                  <div className='app__main'>
                    <PAGES.HelpCenter/>
                  </div>
                </Route>
                <Route path={ROUTE.HOW__TO__USE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                  <div className='app__main'>
                    <PAGES.HowtoUse/>
                  </div>
                </Route>

                <Route exact path={ROUTE.HOME}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthAllTimeLine/> :<PAGES.UnAuthAllTimeLine/>}
                    </div>
                {user ? <COMPONENT.AuthWidgetsFollow/> : <COMPONENT.UnAuthWidgetsFollow/>}
                </Route>

                <Route path={ROUTE.USER__LIST}>
                  {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                  <div className='app__main'>
                  </div>
                  <div className='widget__empty'></div>
                </Route>

                <Route exact path={ROUTE.JOB__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthJobTimeLine/> :<PAGES.UnAuthJobTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>

                {/*Job Type*/}
                <Route exact path={ROUTE.JOB__EMPLOYEE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthEmployeeJobsTimeLine/> :<PAGES.UnAuthEmployeeJobsTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__FREELANCE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthFreelanceJobsTimeLine/> :<PAGES.UnAuthFreelanceJobsTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__INTERN__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthInternJobsTimeLine/> :<PAGES.UnAuthInternJobsTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__OUTSOURSING__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthOutsoursingJobsTimeLine/> :<PAGES.UnAuthOutsoursingJobsTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__PARTTIME__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthParttimeJobsTimeLine/> :<PAGES.UnAuthParttimeJobsTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                
                {/*Office*/}
                <Route exact path={ROUTE.JOB__CUSTOMER__OFFICE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthCustomerOfficeTimeLine/> :<PAGES.UnAuthCustomerOfficeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__GENERAL__OFFICE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthGeneralOfficeTimeLine/> :<PAGES.UnAuthGeneralOfficeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__RECEIPT__OFFICE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthReceiptOfficeTimeLine/> :<PAGES.UnAuthReceiptOfficeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__SALES__OFFICE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthSalesOfficeTimeLine/> :<PAGES.UnAuthSalesOfficeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__SECRETARY__OFFICE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthSecretryOfficeTimeLine/> :<PAGES.UnAuthSecretryOfficeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>

                {/*Sales*/}
                <Route exact path={ROUTE.JOB__AGENCY__SALES__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthAgencySalesTimeLine/> :<PAGES.UnAuthAgencySalesTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__CORPORATE__SALES__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthCorporateSalesTimeLine/> :<PAGES.UnAuthCorporateSalesTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__FINANCE__SALES__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthFinanceSalesTimeLine/> :<PAGES.UnAuthFinanceSalesTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__FOREIGN__SALES__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthForeignSalesTimeLine/> :<PAGES.UnAuthForeignSalesTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__INHOUSE__SALES__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthInHouseSalesTimeLine/> :<PAGES.UnAuthInHouseSalesTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__IT__SALES__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthITSalesTimeLine/> :<PAGES.UnAuthITSalesTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__PERSONAL__SALES__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthPersonalSalesTimeLine/> :<PAGES.UnAuthPersonalSalesTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__REALSTATE__SALES__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthRealStateSalesTimeLine/> :<PAGES.UnAuthRealStateSalesTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__TELEPHONE__SALES__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthTelephoneSalesTimeLine/> :<PAGES.UnAuthTelephoneSalesTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>

                {/*Creative*/}
                <Route exact path={ROUTE.JOB__ASSISTDIRECTOR__CREATIVE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthAssistDirectorCreativeTimeLine/> :<PAGES.UnAuthAssistDirectorCreativeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__DESIGNER__CREATIVE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthDesignerCreativeTimeLine/> :<PAGES.UnAuthDesignerCreativeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__DTP__OPERATOR__CREATIVE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthDTPOperatorCreativeTimeLine/> :<PAGES.UnAuthDTPOperatorCreativeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__ILLUSTRATOR__CREATIVE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthIllustratorCreativeTimeLine/> :<PAGES.UnAuthIllustratorCreativeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__PHOTOGRAPHER__CREATIVE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthPhotographerCreativeTimeLine/> :<PAGES.UnAuthPhotographerCreativeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__UIUX__CREATIVE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthUIUXCreativeTimeLine/> :<PAGES.UnAuthUIUXCreativeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__WEBDESIGNER__CREATIVE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthWebDesignerCreativeTimeLine/> :<PAGES.UnAuthWebDesignerCreativeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__WEBDIRECTOR__CREATIVE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthWebDirectorCreativeTimeLine/> :<PAGES.UnAuthWebDirectorCreativeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__WEBWRITER__CREATIVE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthWebWriterCreativeTimeLine/> :<PAGES.UnAuthWebWriterCreativeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__WRITER__CREATIVE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthWriterCreativeTimeLine/> :<PAGES.UnAuthWriterCreativeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>

                {/* Engineer */}
                <Route exact path={ROUTE.JOB__WEBBACKEND__ENGINEER__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthBackendEngineerTimeLine/> :<PAGES.UnAuthBackendEngineerTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__WEBFRONTEND__ENGINEER__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthFrontendEngineerTimeLine/> :<PAGES.UnAuthFrontendEngineerTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__WEBFULLSTACK__ENGINEER__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthFullstackEngineerTimeLine/> :<PAGES.UnAuthFullstackEngineerTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__INCOMPANY__SE__ENGINEER__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthInCompanySEEngineerTimeLine/> :<PAGES.UnAuthInCompanySEEngineerTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__INFRA__ENGINEER__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthInfraEngineerTimeLine/> :<PAGES.UnAuthInfraEngineerTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__INFRA__DATABASE__ENGINEER__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthInfraDatabaseEngineerTimeLine/> :<PAGES.UnAuthInfraDatabaseEngineerTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__INFRA__MAINTAIN__ENGINEER__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthInfraMaintainEngineerTimeLine/> :<PAGES.UnAuthInfraMaintainEngineerTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__INFRA__NETWORK__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthInfraNetworkEngineerTimeLine/> :<PAGES.UnAuthInfraNetworkEngineerTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__INFRA__PM__ENGINEER__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthInfraPMEngineerTimeLine/> :<PAGES.UnAuthInfraPMEngineerTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__INFRA__SERVER__ENGINEER__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthInfraServerEngineerTimeLine/> :<PAGES.UnAuthInfraServerEngineerTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__INFRA__WEBSERVICE__ENGINEER__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthInfraWebServiceEngineerTimeLine/> :<PAGES.UnAuthInfraWebServiceEngineerTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__MOBILE__APP__ENGINEER__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthMobileAppEngineerTimeLine/> :<PAGES.UnAuthMobileAppEngineerTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__SUPPORT__ENGINEER__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthSupportEngineerTimeLine/> :<PAGES.UnAuthSupportEngineerTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__WEB__ENGINEER__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthWebEngineerTimeLine/> :<PAGES.UnAuthWebEngineerTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.JOB__WEBPM__ENGINEER__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthWebPMEngineerTimeLine/> :<PAGES.UnAuthWebPMEngineerTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>

                {/*Other - Keyword*/}
                <Route exact path={ROUTE.ANY__CLOTHES__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthAnyClothesimeLine/> :<PAGES.UnAuthAnyClothesimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.BENEFITS__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthBenefitsTimeLine/> :<PAGES.UnAuthBenefitsTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.CHILDCARE__SUPPORT__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthChildSupportTimeLine/> :<PAGES.UnAuthChildSupportTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.DAILY__WEEKLY__OK__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthDailyWeeklyPayTimeLine/> :<PAGES.UnAuthDailyWeeklyPayTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>                
                <Route exact path={ROUTE.DORM__HOUSINGALLOWANCE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthDormCompanyHousingTimeLine/> :<PAGES.UnAuthDormCompanyHousingTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.FAMILY__ALLOWANCE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthFamilyAllowanceTimeLine/> :<PAGES.UnAuthFamilyAllowanceTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.HIGHSCHOOL__STUDENT__OK}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthHighschoolStudentTimeLine/> :<PAGES.UnAuthHighschoolStudentTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.INSENTIVE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthInsentiveTimeLine/> :<PAGES.UnAuthInsentiveTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.MATERNITY__CHILDCARE__LEAVE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthMaternityChildTimeLine/> :<PAGES.UnAuthMaternityChildTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.NEAR__STATION__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthNearStationTimeLine/> :<PAGES.UnAuthNearStationTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.NO__EXPERIENCE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthNoExperienceTimeLine/> :<PAGES.UnAuthNoExperienceTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.EDUCATIONAL__BACKGOUND__NOT__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthNoEducationalTimeLine/> :<PAGES.UnAuthNoEducationalTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.PLOMOTION__TO__EMPLOYEE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthPromotionTimeLine/> :<PAGES.UnAuthPromotionTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.QUALIFICATION__ALLOWANCE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthQualificationAllowanceTimeLine/> :<PAGES.UnAuthQualificationAllowanceTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.REMOTE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthRemoteTimeLine/> :<PAGES.UnAuthRemoteTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.SHORT__TIME__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthShortTimeTimeLine/> :<PAGES.UnAuthShortTimeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.SIDE__BUSINESS__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthSideBusinessTimeLine/> :<PAGES.UnAuthSideBusinessTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.SOCIAL__INSURANCE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthSocialInsuranceTimeLine/> :<PAGES.UnAuthSocialInsuranceTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.SOME__REMOTE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthSomeRemoteTimeLine/> :<PAGES.UnAuthSomeRemoteTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.TRANSPORTATION__PAID__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthTransportationPaidTimeLine/> :<PAGES.UnAuthTransportationPaidTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.TWO__DAYS__OFF__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthTwoDaysOffTimeLine/> :<PAGES.UnAuthTwoDaysOffTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.TWO__THREE__OK__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthTwoThreeTimeLine/> :<PAGES.UnAuthTwoThreeTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.UNIVERSITY__STUDENT__OK}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthUniversityStudentTimeLine/> :<PAGES.UnAuthUniversityStudentTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.URGENT__RECRUITMENT__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthUrgentRecruitTimeLine/> :<PAGES.UnAuthUrgentRecruitTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.WEB__INTERVIEW__OK__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthWebInterviewTimeLine/> :<PAGES.UnAuthWebInterviewTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.WELCOME__EXPERIENCE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthWelcomeExperienceTimeLine/> :<PAGES.UnAuthWelcomeExperienceTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.WITH__TRAINING__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthWithTrainingTimeLine/> :<PAGES.UnAuthWithTrainingTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>

                {/* Prefecture */}
                <Route exact path={ROUTE.REMOTE__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthRemotePrefectureTimeLine/> :<PAGES.UnAuthRemotePrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.HOKKAIDO__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthHokkaidoPrefectureTimeLine/> :<PAGES.UnAuthHokkaidoPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.AOMORI__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthAomoriPrefectureTimeLine/> :<PAGES.UnAuthAomoriPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.IWATE__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthIwatePrefectureTimeLine/> :<PAGES.UnAuthIwatePrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.MIYAGI__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthMiyagiPrefectureTimeLine/> :<PAGES.UnAuthMiyagiPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.AKITA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthAkitaPrefectureTimeLine/> :<PAGES.UnAuthAkitaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.YAMAGATA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthYamagataPrefectureTimeLine/> :<PAGES.UnAuthYamagataPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.HUKUSIMA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthHukushimaPrefectureTimeLine/> :<PAGES.UnAuthHukushimaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.IBARAKI__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthIbarakiPrefectureTimeLine/> :<PAGES.UnAuthIbarakiPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.TOTIGI__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthTotigiPrefectureTimeLine/> :<PAGES.UnAuthTotigiPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.GUNMA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthGunmaPrefectureTimeLine/> :<PAGES.UnAuthGunmaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.SAITAMA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthSaitamaPrefectureTimeLine/> :<PAGES.UnAuthSaitamaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.TIBA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthTibaPrefectureTimeLine/> :<PAGES.UnAuthTibaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.TOKYO__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthTokyoPrefectureTimeLine/> :<PAGES.UnAuthTokyoPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.KANAGAWA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthKanagawaPrefectureTimeLine/> :<PAGES.UnAuthKanagawaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.NIGATA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthNigataPrefectureTimeLine/> :<PAGES.UnAuthNigataPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.TOYAMA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthToyamaPrefectureTimeLine/> :<PAGES.UnAuthToyamaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.ISHIKAWA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthIshikawaPrefectureTimeLine/> :<PAGES.UnAuthIshikawaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.HUKUI__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthHukuiPrefectureTimeLine/> :<PAGES.UnAuthHukuiPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.YAMANASHI__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthYamanashiPrefectureTimeLine/> :<PAGES.UnAuthYamanashiPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.NAGANO__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthNaganoPrefectureTimeLine/> :<PAGES.UnAuthNaganoPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.GIHU__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthGihuPrefectureTimeLine/> :<PAGES.UnAuthGihuPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.SHIZUOKA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthShizuokaPrefectureTimeLine/> :<PAGES.UnAuthShizuokaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.AITI__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthAitiPrefectureTimeLine/> :<PAGES.UnAuthAitiPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.MIE__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthMiePrefectureTimeLine/> :<PAGES.UnAuthMiePrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.SHIGA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthShigaPrefectureTimeLine/> :<PAGES.UnAuthShigaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.KYOTO__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthKyotoPrefectureTimeLine/> :<PAGES.UnAuthKyotoPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.OSAKA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthOsakaPrefectureTimeLine/> :<PAGES.UnAuthOsakaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.HYOGO__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthHyogoPrefectureTimeLine/> :<PAGES.UnAuthHyogoPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.NARA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthNaraPrefectureTimeLine/> :<PAGES.UnAuthNaraPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.WAKAYAMA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthWakayamaPrefectureTimeLine/> :<PAGES.UnAuthWakayamaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.TOTTORI__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthTottoriPrefectureTimeLine/> :<PAGES.UnAuthTottoriPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.SHIMANE__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthShimanePrefectureTimeLine/> :<PAGES.UnAuthShimanePrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.OKAYAMA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthOkayamaPrefectureTimeLine/> :<PAGES.UnAuthOkayamaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.HIROSHIMA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthHiroshimaPrefectureTimeLine/> :<PAGES.UnAuthHiroshimaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.YAMAGUTI__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthYamagutiPrefectureTimeLine/> :<PAGES.UnAuthYamagutiPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.TOKUSHIMA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthTokushimaPrefectureTimeLine/> :<PAGES.UnAuthTokushimaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.KAGAWA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthKagawaPrefectureTimeLine/> :<PAGES.UnAuthKagawaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.EHIME__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthEhimePrefectureTimeLine/> :<PAGES.UnAuthEhimePrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.KOUTI__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthKoutiPrefectureTimeLine/> :<PAGES.UnAuthKoutiPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.HUKUOKA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthHukuokaPrefectureTimeLine/> :<PAGES.UnAuthHukuokaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.SAGA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthSagaPrefectureTimeLine/> :<PAGES.UnAuthSagaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.NAGASAKI__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthNagasakiPrefectureTimeLine/> :<PAGES.UnAuthNagasakiPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.KUMAMOTO__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthKumamotoPrefectureTimeLine/> :<PAGES.UnAuthKumamotoPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.OITA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthOitaPrefectureTimeLine/> :<PAGES.UnAuthOitaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.MIYAZAKI__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthMiyazakiPrefectureTimeLine/> :<PAGES.UnAuthMiyazakiPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.KAGOSHIMA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthKagoshimaPrefectureTimeLine/> :<PAGES.UnAuthKagoshimaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                <Route exact path={ROUTE.OKINAWA__PREFECTURE__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthOkinawaPrefectureTimeLine/> :<PAGES.UnAuthOkinawaPrefectureTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>


                <Route exact path={ROUTE.USER__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthUserTimeLine/> :<PAGES.UnAuthUserTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>               

                <Route exact path={ROUTE.EVENT__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      {user ? <PAGES.AuthEventsTimeLine/> :<PAGES.UnAuthEventsTimeLine/>}
                    </div>
                    <div className='widget__empty'></div>
                </Route>
                
                {/*Company Dashboard*/}
                <Route path={ROUTE.COMPANY__DASHBOARD__POST}>
                  <div className='app__admin'>
                  {user? <PAGES.CompanyDashboardNav/> : <></>}
                    <div className='app__admin__wrapper'>
                    {user? <PAGES.CompanyDashboardPost/> : <></>}
                    </div>
                  </div>
                </Route>
                <Route path={ROUTE.COMPANY__DASHBOARD__ALLPOST}>
                  <div className='app__admin'>
                  {user? <PAGES.CompanyDashboardNav/> : <></>}
                    <div className='app__admin__wrapper'>
                      {user ? <PAGES.CompanyDashboardAllPost/> : <></>}
                    </div>
                  </div>
                </Route>
                <Route path={ROUTE.COMPANY__DASHBOARD}>
                  <div className='app__admin'>
                  {user? <PAGES.CompanyDashboardNav/> : <></>}
                    <div className='app__admin__wrapper'>
                      {user ? <PAGES.CompanyDashboard/> : <></>}
                    </div>
                  </div>
                </Route>
                <Route path={ROUTE.COMPANY__DASHBOARD__MANAGEPOST}>
                  <div className='app__admin'>
                  {user? <PAGES.CompanyDashboardNav/> : <></>}
                    <div className='app__admin__wrapper'>
                      {user ? <PAGES.CompanyDashboardPostManage/> : <></>}
                    </div>
                  </div>
                </Route>
                <Route path={ROUTE.COMPANY__DASHBOARD__CONTACT}>
                  <div className='app__admin'>
                  {user? <PAGES.CompanyDashboardNav/> : <></>}
                    <div className='app__admin__wrapper'>
                      {user ? <PAGES.CompanyDashboardContact/> : <></>}
                    </div>
                  </div>
                </Route>
                <Route path={ROUTE.COMPANY__DASHBOARD__SEARCH}>
                  <div className='app__admin'>
                    {user? <PAGES.CompanyDashboardNav/> : <></>}
                    <div className='app__admin__wrapper'>
                      {user ? <PAGES.CompanyDashboardSearch/> : <></>}
                    </div>
                  </div>
                </Route>

                {/* <Route path={ROUTE.FOLLOWING__TIMELINE}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                  <div className='app__main'>
                    {user ? <PAGES.FollowingTimeLine/> : <Redirect to={ROUTE.HOME}/>}
                  </div>
                </Route>  */}

                <Route path='/profile/:username'>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                      <Switch>
                        <Route path='/profile/:username' exact>
                          {user ? <PAGES.AuthUserProfilePage/>:<PAGES.UnAuthUserProfilePage/> }
                        </Route>
                        <Route path='/profile/:username/bookmark'>{user ? <COMPONENT.AuthUserBookMarkPage/> : <COMPONENT.UnAuthUserBookMarkPage/>}</Route>
                        <Route path='/profile/:username/favorite'>{user ? <COMPONENT.AuthUserLikesPage/> : <COMPONENT.UnAuthUserLikesPage/>}</Route>
                        <Route path='/profile/:username/post'>{user ? <COMPONENT.AuthUserPostPage/> : <COMPONENT.UnAuthUserLikesPage/>}</Route>
                        <Route path='/profile/:username/job'>{user ? <COMPONENT.AuthCompanyJobPost/> : <COMPONENT.UnAuthCompanyJobPost/>}</Route>
                        <Route path='/profile/:username/freelance'>{user ? <COMPONENT.AuthCompanyFreelancePost/> : <COMPONENT.UnAuthCompanyFreelancePost/>}</Route>
                        <Route path='/profile/:username/event'>{user ? <COMPONENT.AuthCompanyEventPost/> : <COMPONENT.UnAuthCompanyEventPost/>}</Route>
                        <Route path='/profile/:username/following-post'>{user ? <COMPONENT.AuthUserFollowingPostPage/> : <COMPONENT.UnAuthUserFollowingPostPage/>}</Route>
                        <Route path='/profile/:username/followed'><COMPONENT.ProfileFollowed/></Route>
                        <Route path='/profile/:username/following'><COMPONENT.ProfileFollowing/></Route>

                      </Switch>
                    </div>
                    <div className='widget__empty'></div>   
                </Route>
                <Route path={ROUTE.NOTIFICATION}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                  <div className='app__main'>
                    {user ? <PAGES.Notification/> : <></>}
                  </div>
                </Route>

                <Route path={ROUTE.COMPANY__DASHBOARD}>
                {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                  <div className='app__main'>
                    {user ? <PAGES.CompanyDashboard/> : <></>}
                  </div>
                </Route>

                {/*Search*/}
                <Route path={ROUTE.SEARCH__JOBS}>
                  <div className='app__main'>
                    {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    {user ? <PAGES.AuthJobSeachHome/> : <PAGES.UnAuthJobSearchHome/>}
                  </div>
                  <div className='widget__empty'></div>
                </Route>
                <Route path={ROUTE.SEARCH__USER}>
                  <div className='app__main'>
                    {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <PAGES.UserSearchHome/>
                  </div>
                  <div className='widget__empty'></div>
                </Route>
                <Route path={ROUTE.SEARCH__EVENT}>
                  <div className='app__main'>
                    {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    {user ? <PAGES.AuthEventSearchHome/> : <PAGES.UnAuthEventSearchHome/> }
                  </div>
                  <div className='widget__empty'></div>
                </Route>
                <Route path={ROUTE.SEARCH__FREELANCE}>
                  <div className='app__main'>
                    {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    {user ? <PAGES.AuthFreelanceSearchHome/> : <PAGES.UnAuthFreelanceSearchHome/>}
                  </div>
                  <div className='widget__empty'></div>
                </Route>
                <Route path={ROUTE.SEARCH__USER__POST }>
                  <div className='app__main'>
                    {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    {user ? <PAGES.AuthUserPostSearchHome/> : <PAGES.UnAuthUserPostSearchHome/>}
                  </div>
                  <div className='widget__empty'></div>
                </Route>
                <Route path={ROUTE.SEARCH__WORDS}>
                  <div className='app__main'>
                    {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <PAGES.WordsSearch/>
                  </div>
                  <div className='widget__empty'></div>
                </Route>


                <Route path={ROUTE.MESSAGE}>
                  {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <div className="app__main">
                    {user ? <COMPONENT.SidebarChat/>: <></>}
                      <Switch>
                        <Route path='/messages/:roomId' exact>
                          {user ? <PAGES.Chat/>: <></>}
                        </Route>
                        <Route path='/messages/:roomId/info'>
                          {user ? <COMPONENT.ConversationInfo />: <></>}
                        </Route>
                      </Switch>
                    </div>            
                </Route> 

                <Route path="/posts/:postId">
                  <div className='app__main'>
                  {user ? <COMPONENT.AuthSidebar/>: <COMPONENT.UnAuthSidebar/>}
                    <Switch>
                      <Route path='/posts/:postId' exact>
                      {user ? <COMPONENT.AuthJobStatus/>: <COMPONENT.UnAuthJobStatus/>}
                      </Route>
                    </Switch>
                  </div>
                  {user ? <COMPONENT.AuthWidgetPostSidebar/> : <COMPONENT.UnAuthWidgetPostSidebar/>}
                </Route>

                <Route path={ROUTE.ADMIN__DASHBOARD}>
                  <div className='app__admin'>
                    <AdminSidebar/>
                    <div className='app__admin__wrapper'>
                      {user ? <ADMINPAGES.Dashboard/> : <></>}
                    </div>
                  </div>
                </Route>
                <Route path={ROUTE.ADMIN__CHART}>
                  <div className='app__admin'>
                    <AdminSidebar/>
                    <div className='app__admin__wrapper'>
                      {user ? <ADMINPAGES.ChartPage/> : <></>}
                    </div>
                  </div>
                </Route>
                <Route path={ROUTE.ADMIN__GET__USER}>
                  <div className='app__admin'>
                    <AdminSidebar/>
                    <div className='app__admin__wrapper'>
                      {user ? <ADMINPAGES.UserList/> : <></>}
                    </div>
                  </div>
                </Route>
                <Route path={ROUTE.ADMIN__GET__COMPANY}>
                  <div className='app__admin'>
                    <AdminSidebar/>
                    <div className='app__admin__wrapper'>
                      {user ? <ADMINPAGES.CompanyList/> : <></>}
                    </div>
                  </div>
                </Route>

                <Route><PAGES.NotFound/></Route>
              </Switch>
              </div>
            {user ? <AuthBottomNav/> : <UnAuthBottomNav/>}
          </Router>
  </div>  
  )
}

const App = () => {

  return (
    <ChatContextProvider>
      <AppRouter/>
    </ChatContextProvider>

  )
}

export default COMPONENT.NetworkDetector(App);