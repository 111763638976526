import React, {useEffect, useState} from 'react';
import { useHistory, useParams, Link} from 'react-router-dom';
import firebase from 'firebase';
import {Avatar} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import SendIcon from '@material-ui/icons/Send';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';

import { useStateValue } from '../../contexts/StateContextProvider';
import { useRoomState } from '../../contexts/IsRoomOpenedContextProvider';
import db from '../../firebase';
import { CLOSING_ROOM, OPENING_ROOM } from '../../constant/constant';
import postToCloudinary from '../../helpers/postToCloudinary';
import { Spinner } from '../../elements';
import { MessageItem } from '../../component';
import './index.css';

const Chat = () => {
   const [messages, setMessages] = useState([])
   const [text, setText] = useState('')
   const [members, setMembers] = useState('')
   const [display, setDisplay] = useState({})
   const [{user}] = useStateValue()
   const [{isRoomOpened}, dispatch] = useRoomState()
   const history = useHistory()

   const [isLoading, setIsloading] = useState(false)
   const {roomId} = useParams();
   const {username} = useParams();

   const [src, setSrc] = useState(null)
   const [imageToSend, setImageToSend] = useState(null)


   useEffect(() => {
      if (roomId){
         db.collection('rooms').doc(roomId).onSnapshot(snapshot=>{
            setMembers(snapshot.data() && snapshot.data().members.filter(userId=>userId!==user.id)[0])
         })
      }

      db.collection('rooms')
        .doc(roomId)
        .collection('messages')
        .orderBy('timestamp', 'asc')
        .onSnapshot(snapshot=>{
           setMessages(snapshot.docs.map(doc=>({id: doc.id, data: doc.data()})))
      })

      dispatch({
         type: OPENING_ROOM,
         isRoomOpened: true    
      })      

      if(isRoomOpened){}

   }, [roomId])

   useEffect(() => {
      if(members){
         db.collection('users')
         .doc(members)
         .onSnapshot(snapshot=>{
            setDisplay(snapshot.data())
         })
      }
   }, [members])

   const [profile, setProfile] = useState([]);
   useEffect(() => {
      const getUser = db.collection('users').where('username', '==', username).onSnapshot(snapshot=>{
          setProfile(snapshot.docs.map(doc=>({
            id: doc.id,
            ...doc.data()
          }))[0])
        })
      return () => getUser();
}, []);
   const sendMessage = (e) => {

      setIsloading(true)

        if(imageToSend){
            postToCloudinary(imageToSend)
            .then( res=>{

               db.collection('rooms')
                .doc(roomId)
                .collection('messages')
                .add({
                     senderId: user.id,
                     message: text,
                     image : res,
                     timestamp: firebase.firestore.FieldValue.serverTimestamp()
               })
               setText('')
               setSrc(null)
               setImageToSend(null)
               setIsloading(false)
            })
            .catch( err=> {
               setIsloading(false)
                return
            })
        } else {
            db.collection('rooms')
             .doc(roomId)
             .collection('messages')
             .add({
               senderId: user.id,
               message: text,
               timestamp: firebase.firestore.FieldValue.serverTimestamp()
              })
            setText('')
            setSrc(null)
            setIsloading(false)
         }
      }
    const closeRoom = () => {
         dispatch({
            type: CLOSING_ROOM,
         })  

         history.push('/messages')     
    }

    const onSendMessage = () => {
       sendMessage();
       db.collection('notification').add({
          content: 'から新着メッセージが届きました',
          details: user.displayName,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          photoURL: user.photoURL,
          url: `https://copipel.com/#/messages/${roomId}`,
          userId: profile.id
       })
    }


    return (
      <>
        <div className="chat">
           <div className="chat__header">
              <div className="chat__backArrow">
                 <ArrowBackOutlinedIcon  onClick={closeRoom}/>
              </div>
              <div className="chat__header-ava">
                 <Avatar src={display && display.photoURL}/>
               </div>
              <h2>{display && display.displayName}</h2>
              <Link to={`/messages/${roomId}/info`}><InfoIcon member={display}/></Link>      
           </div>

            <div className="chat__body">
               {
                  messages.map(msg=>{
                      return <MessageItem key={msg.id} msg={msg} />
                  })
               }
            </div>

            <div className="chat__footer">
               <form>
                  <textarea type="text"
                     placeholder='メッセージを入力...'
                     value={text}
                     onChange ={(e)=>setText(e.target.value)}
                     required
                  />
                  {
                     (src || text.length>0)?
                     isLoading?
                     <span className='spinnerSpan'><Spinner /></span>
                     :<SendIcon type='submit' className='readyBtn' onClick={onSendMessage}/>
                     :<SendIcon /> 
                  }
               </form>

            </div>

        </div>
      </>
    )
}

export default Chat