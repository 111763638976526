import React, { useState, useEffect } from "react";
import { LinearProgress } from "@material-ui/core";
import { SearchTabbar, UnAuthJobPost } from "../../../component";
import db from "../../../firebase";

const UnAuthFreelanceSearchHome = () => {
    const [searchFreelanceTitle, setSearchFreelanceTitle] = useState('');
    const [searchFreelanceSkill, setSearchFreelanceSkill] = useState('');

    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if(!posts.length){
            let mounted = true
            db.collection('jobs').where('typePost', '==', 'freelancePosting')
            .orderBy('timestamp', 'desc')
            .onSnapshot(snapshot=>{
               if(mounted){
                  if(snapshot.empty){
                     setLoading(false)
                     return
                  }
                     setPosts(snapshot.docs.map(doc=> ({id:doc.id, ...doc.data()})))
                     setLoading(false)
               }
            }) 
         return () => mounted = false
        }
   }, [])

    return(
        <div className="search__box">
            <SearchTabbar/>
            <div class="search__box-content">
                <div class="inner-form">
                    <div class="left">
                        <div className="d-flex">
                            <div class="input-wrap second">
                                <div class="input-field second">
                                    <label>職種</label>
                                    <div class="input-select">
                                    <select data-trigger="" name="choices-single-defaul">
                                        <option placeholder="">1 adult</option>
                                        <option>2 adults</option>
                                        <option>3 adults</option>
                                        <option>4 adults</option>
                                    </select>
                                    </div>
                                </div>
                            </div>
                            <div class="input-wrap first">
                                <div class="input-field first">
                                    <label>スキル</label>
                                    <input type="text" placeholder="スキルを入力してください" value={searchFreelanceSkill} onChange={(e) => setSearchFreelanceSkill(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="search__border">
                            <div class="input-wrap first">
                                <div class="input-field first">
                                    <label>タイトル</label>
                                    <input type="text" placeholder="タイトルを入力してください" value={searchFreelanceTitle} onChange={(e) => setSearchFreelanceTitle(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                { loading &&  <LinearProgress /> }
                {posts && posts.filter(i => i.titleKeyWords[0] === searchFreelanceTitle || i.titleKeyWords[1] === searchFreelanceTitle || i.titleKeyWords[2] === searchFreelanceTitle || i.titleKeyWords[3] === searchFreelanceTitle || i.reqSkill[0] === searchFreelanceSkill || i.reqSkill[1] === searchFreelanceSkill || i.reqSkill[2] === searchFreelanceSkill || i.reqSkill[3] === searchFreelanceSkill).map(post => (
                    <UnAuthJobPost
                    key={post.id}
                        postId = {post.id}
                        altText = {post.altText}
                        senderId = {post.senderId}
                        username = {post.username}
                        kindJob = {post.kindJob}
                        prefecture = {post.prefecture}
                        jobTitle = {post.jobTitle}
                        avatar = {post.avatar}
                        image = {post.image}
                        timestamp = {post.timestamp}
                        likes = {post.likes}
                        bookMark = {post.bookMark}
                        styleWork = {post.styleWork}
                        salary = {post.salary}
                        reqSkill = {post.reqSkill}
                        website = {post.website}
                        eventPlace = {post.eventPlace}
                        eventDate = {post.eventDate}
                        eventTime = {post.eventTime}
                        skills = {post.skills}
                        wantedJob = {post.wantedJob}
                        canWorkPlace = {post.canWorkPlace}
                        />
                ))}
        </div>
    )
}

export default UnAuthFreelanceSearchHome;