
import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyBrW4wnTz5Ic4ezEszaoEwfFKjrcLhSjFA",
  authDomain: "copipel.firebaseapp.com",
  projectId: "copipel",
  storageBucket: "copipel.appspot.com",
  messagingSenderId: "550090941825",
  appId: "1:550090941825:web:7fb539faabb3be74caf480",
  measurementId: "G-RPTT8N6SNV"
};
const firebaseApp = firebase.initializeApp(firebaseConfig)

export const db = firebaseApp.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.functions();


export default db;