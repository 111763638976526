import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';

import profileDefault from '../../../../assets/profileDefault.png';
import { useStateValue } from '../../../../contexts/StateContextProvider';
import MessageModal from '../../../../elements/modal/MessageModal';
import Spinner from '../../../../elements/spinner/Spinner';
import db from '../../../../firebase';
import { follow, unfollow } from '../../../../redux/actions/serverActions';
import CompanyEdit from '../../../editProfile/CompanyEdit';
import ProfileTabbarMenu from '../../../../elements/tabbarMenu/ProfileTabbarMenu';
import '../../index.css';
import CreateChatBtn from '../../../createChatBtn/CreateChatBtn';

const AuthCompanyProfile = () => {
    const {username} = useParams();
    const [profile, setProfile] = useState({bio:'', displayName:'', followers: [], following:[], location:'', photoURL:'', websiteURL1:'', websiteURL2: '', websiteURL3: '', twitterURL: '', lineURL: '', facebookURL: '', instagramURL: '', profileBrief: '' , currentDoing: '' , careerYear1: '' })
    const [{user}] = useStateValue()
    let isMe = (profile && profile.id) === user.id?true: false
    const [isFollowing, setIsFollowing] = useState(false)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)

    useEffect(() => {
        if(!profile.length){
            const getData = db.collection('users').where('username', '==', username).onSnapshot(snapshot=>{
                setProfile(snapshot.docs.map(doc=>({
                  id: doc.id,
                  ...doc.data()
                }))[0])
              })
    
              return () => getData();
        }
      }, [])

    const history = useHistory();

    useEffect(() => {
        if(profile){
            if (!isMe){
                setIsFollowing(profile.followers.includes(user.id))
            }
        }
    }, [profile])

    const signOut = () => {
        localStorage.clear()
        history.push('/')
        window.location.reload()
    
    }
    const [updatedProfileState, setUpdatedProfileState] = useState({})
    const onCallBasic = () => {
        const {briefIntro, displayName, doing, facebookURL, features, founder, instagramURL, lineURL, message, otherAddress, peopleWorking, prefecture, twitterURL, websiteURL1, websiteURL2, websiteURL3, whenFound, youtubeURL} = updatedProfileState.profileState
        const doUpdate = () => {
            db.collection('users').doc(user.id).update({
                briefIntro, displayName, doing, features, facebookURL, founder, instagramURL, lineURL, message, otherAddress, peopleWorking, prefecture, twitterURL, websiteURL1, websiteURL2, websiteURL3, whenFound, youtubeURL
            }).then(res => {
                setIsUpdating(false)
            })
            .catch(error => { doUpdate()})               
        }
        doUpdate()
        setIsOpenModal(false)
    }
    const items = [
        {
            id: 0,
            title: "基本情報",
            item: <CompanyEdit profile={profile} setUpdatedProfileState={setUpdatedProfileState}/>,
            func: onCallBasic
        }
    ]
    const [notification, setNotification] = useState([]);
    useEffect(() => {
        if(!notification.length){
            const getData = db.collection("notification").where('userId', '==', `${profile.id}`).onSnapshot(snapshot => setNotification(snapshot.docs.map(doc => ({id: doc, ...doc.data()}))))
            return () => getData();
        }
    })
    const followNoti = () => {
        db.collection('notification').add({
           content: "にフォローされました",
           details: profile.displayName,
           photoURL: profile.photoURL,
           email: profile.email,
           subject: "新規フォロワー",
           timestamp: firebase.firestore.FieldValue.serverTimestamp(),
           url: `https://copipel.com/#/profile/${profile.username}`,
           userId: profile.id,
           read: 'false'
        })
     }
  
     const onFollowBtn = () => {
        follow(user.id, profile.id);
        followNoti();
        
      if(profile.emailNotification === true){
        setTimeout(() => {
           if(notification.length > 0){
              db.collection('sendEmail').add({
                 content: "新しい通知が複数あります",
                 details: '',
                 url: `https://copipel.com/#/notification`,
                 email: profile.email,
                 subject: '新規通知',
              })
           } else{
              db.collection('sendEmail').add({
                 content: "にお気に入りされました",
                 details: user.displayName,
                 url: `https://copipel.com/#/profile/${user.username}`,
                 email: profile.email,
                 subject: '新規通知',
              })
           }
        }, 1000 * 60 * 60)
     }
     }
    return(
        <>
        <MessageModal
            open={isOpenModal} 
            title="プロフィール編集"
            onClose={()=>setIsOpenModal(false)}
            >
                <ProfileTabbarMenu items={items} ButtonText="保存する" onClose={() => setIsOpenModal(false)}/>
        </MessageModal> 
        <div className='user__profile'>
            <div className='company__profile__column1 user__profile__column1 profile__card'>
                <div className="d-flex profile__basic__info">
                    
                    {profile && profile.photoURL? 
                    <div>{(profile && profile.photoURL) && <img src={profile.photoURL} alt={`${profile && profile.displayName}`} />}</div>
                    :
                    <div><img src={profileDefault} alt={`${profile && profile.displayName}`}/></div>
                }
                        <div className="user__profile__basic">
                            <h1>{profile && profile.displayName}</h1>
                            <div className="infoWrapper">
                            <div className="userProfile__actions">
                            
                                {isMe && profile.role === "USER" &&
                                <div className='resumeIconWrapper'>
                                    <Link to={`/${user.username}/profile/view`}>
                                        <i className='fas fa-clipboard-list clipIcon' />
                                    </Link>
                                </div>
                                }
                                { !isMe && <div className="mailWrapper">
                                    <CreateChatBtn/>
                                </div> }
                                <div className='profile__actionBtn'>
                                { isMe? 
                                <div className="followWrapper" onClick={()=>setIsOpenModal(true)}>
                                    プロフィール編集
                                </div>
                                : 
                                    (
                                    isFollowing?
                                        <div className="followWrapper" onClick={()=>unfollow(user.id, profile.id)}>
                                            フォロー中
                                        </div>
                                    :
                                        <div className="followWrapper" onClick={onFollowBtn}>
                                            フォローする
                                        </div>                        
                                    )              
                                }
                                </div>
                            </div>
                            <div className="countInfo">
                                <Link to={`/profile/${username}/following`}>
                                    <span>{profile && profile.following.length} <p>フォロー中</p></span>
                                </Link>
                                <Link to={`/profile/${username}/followed`}>
                                    <span>{profile!==undefined && profile.followers.length} <p>フォロワー</p></span>
                                </Link>

                                { (isMe && isUpdating) && <div className='themeSpinner'> <Spinner /> </div>  }   
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile__website">
                    <ul>
                        {(profile && profile.websiteURL1) && <li><i className='fas fa-link link__icon'/><a href={`${profile.websiteURL1}`}>{profile.websiteURL1}</a></li>}
                        {(profile && profile.websiteURL2) && <li><i className='fas fa-link link__icon'/><a href={`${profile.websiteURL2}`}>{profile.websiteURL2}</a></li>}
                        {(profile && profile.websiteURL3) && <li><i className='fas fa-link link__icon'/><a href={`${profile.websiteURL3}`}>{profile.websiteURL3}</a></li>}
                    </ul>
                </div>
            </div>
            <div className='company__profile__column2 user__profile__column2 profile__card'>
                <ul className="snsbtniti d-flex padding-left-35">
                    {(profile && profile.youtubeURL) || (profile && profile.twitterURL) || (profile && profile.instagramURL) || (profile && profile.facebookURL) || (profile && profile.lineURL) ? <div></div>: <p className="unwrite__text">SNSのURLがまだ記入されていません</p>}
                    {(profile && profile.youtubeURL) && <li><a href={`${profile && profile.youtubeURL}`} className="flowbtn8 fl_yu2"><i className="fab fa-youtube"/></a></li>}
                    {(profile && profile.twitterURL) && <li><a href={`${profile && profile.twitterURL}`} className="flowbtn8 fl_tw2"><i className="fab fa-twitter"/></a></li>}
                    {(profile && profile.instagramURL) && <li><a href={`${profile && profile.instagramURL}`} className="flowbtn8 insta_btn2"><i className="fab fa-instagram"/></a></li>}
                    {(profile && profile.facebookURL) && <li><a href={`${profile && profile.facebookURL}`} className="flowbtn8 fl_fb2"><i className="fab fa-facebook-f"/></a></li>}
                    {(profile && profile.lineURL) &&  <li><a href={`${profile && profile.lineURL}`} className="flowbtn8 fl_li2"><i className="fab fa-line"/></a></li>}
                </ul>
            </div>
            <div className='company__profile__column3 user__profile__column3 profile__card'>
                <div className="text__back__box">
                    <h2>{profile && profile.displayName}について</h2>
                    <div className="back__text">
                        About Us
                    </div>
                </div>
                {profile && profile.briefIntro ? <p>{profile.briefIntro}</p>:<p className="unwrite__text">簡単な自己紹介がまだ記入されていません</p>}
            </div>

            <div className='company__profile__column4 user__profile__column3 profile__card'>
                <div className="text__back__box">
                    <h2>何をしているか？</h2>
                    <div className="back__text">
                        What we doing?
                    </div>
                </div>
                {profile && profile.doing ? <p>{profile.doing}</p>:<p className="unwrite__text">何をしているかはまだ記入されていません</p>}
            </div>

            <div className='company__profile__column5 user__profile__column3 profile__card'>
                <div className="text__back__box">
                    <h2>特徴</h2>
                    <div className="back__text">
                        Features
                    </div>
                </div>
                {profile && profile.features ? <p>{profile.features}</p>:<p className="unwrite__text">特徴がまだ記入されていません</p>}
            </div>

            <div className='company__profile__column6 profile__card'>
                <div className="text__back__box">
                    <h2>会社情報</h2>
                    <div className="back__text">
                        Company Info
                    </div>
                </div>
                <div className='company__info'>
                    <ul>
                        {(profile && profile.prefecture) || (profile && profile.founder) || (profile && profile.founder) || (profile && profile.whenFound) || (profile && profile.peopleWorking) ? <div></div> : <p className='unwrite__text'>会社情報はまだ記入されていません</p>}
                        {(profile && profile.prefecture) && <li><i className='fas fa-map-marker-alt company__info__icon'/><strong>{profile.prefecture} {profile.otherAddress}</strong></li>}
                        {(profile && profile.founder) && <li><i className='fas fa-user-tie company__info__icon'/><strong>{profile.founder}</strong> / <small>創設者</small></li>}
                        {(profile && profile.whenFound) && <li><i className='far fa-calendar-alt company__info__icon'/><strong>{profile.whenFound}</strong> / <small>創設年</small></li>}
                        {(profile && profile.peopleWorking) && <li><i className='fas fa-users company__info__icon'/><strong>{profile.peopleWorking}</strong>人 / <small>従業員数</small></li>}
                    </ul>
                </div>
            </div>

            <div className='company__profile__column7 profile__card user__profile__column3'>
                <div className="text__back__box">
                    <h2>企業からのメッセージ</h2>
                    <div className="back__text">
                        Message
                    </div>
                </div>
                {(profile && profile.message) && <p>{profile.message}</p>}
            </div>
            {isMe ? 
            <div className="user__profile__column7 profile__card">
                <button onClick={signOut}>ログアウト</button>
            </div>
            :<div></div>
            }
        </div>
        </>
    )
}

export default AuthCompanyProfile;