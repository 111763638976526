import React, { useEffect, useState } from "react";
import { useStateValue } from "../../contexts/StateContextProvider";
import db from "../../firebase";
import './index.css';
import CompanyPostList from "./post-list/CompanyPostList";
import FlipMove from 'react-flip-move'
import { useDocumentTitle, useScrollTop } from "../../hooks";
import NotFound from "../error/NotFound";
import CompanyDashboardNav from "../../component/mobileNav/CompanyDashboardNav";

const CompanyDashboardPostManage = () => {
    const [posts, setPosts] = useState([])
    const [{user}] = useStateValue();

    useEffect(() => {
        let mounted = true
        db.collection('jobs').orderBy('timestamp', 'desc')
        .onSnapshot(snapshot=>{
           if(mounted){
              if(snapshot.empty){
                 return
              }
                setPosts(snapshot.docs.map(doc=> ({id:doc.id, ...doc.data()})))
           }
        }) 
     
     return () => mounted = false

   }, [])

   useScrollTop();
   useDocumentTitle("管理 / Copipel")

    return(
        <>
        <CompanyDashboardNav/>
        {user.role === "COMPANY" ? 
            <div className="company__dashboard_postmanage">
                <div className="company__dashboard_postmanage-list">
                    {[posts.length === 0 ? <p>現在、投稿はありません。</p>
                    :
                    <FlipMove>
                    {posts.filter(post => post.senderId === `${user.id}`).map(post => (
                        <CompanyPostList key={post.id}
                        postId={post.id}
                        senderId = {post.senderId}
                        jobTitle = {post.jobTitle}
                        kindJob = {post.kindJob}
                        briefIntro = {post.briefIntro}
                        eventDate = {post.eventDate}
                        eventDetails = {post.eventDetails}
                        eventPlace = {post.eventPlace}
                        eventTime = {post.eventTime}
                        eventWhy = {post.eventWhy}
                        grade = {post.grade}
                        hireSection = {post.hireSection}
                        offDay = {post.offDay}
                        otherAddress = {post.otherAddress}
                        prefecture = {post.prefecture}
                        salary = {post.salary}
                        styleWork = {post.styleWork}
                        timeWork = {post.timeWork}
                        wanted = {post.wanted}
                        website = {post.website}
                        welfare = {post.welfare}
                        youtubeLink = {post.youtubeLink}
                        typePost = {post.typePost}
                        underRecrute = {post.underRecrute}
                    />
                    ))}
                    </FlipMove>
                    ]}
                </div>
            </div>
            : <NotFound/>
        }
        </>
    )
}


export default CompanyDashboardPostManage;