import firebase from "firebase";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import profileDefault from '../../../assets/profileDefault.png';
import { useStateValue } from "../../../contexts/StateContextProvider";
import db from "../../../firebase";
import { follow, unfollow } from "../../../redux/actions/serverActions";
import '../index.css';
import CreateChatWidget from '../../createChatBtn/CreateChatWidget'

const AuthWidgetPost = ({statusJob}) => {
    const [{user}] = useStateValue()
    const [profile, setProfile] = useState({displayName:'', photoURL: '', username: '', followers: [], following: [], twitterURL: '', instagramURL: '', lineURL: '', youtubeURL: '', facebookURL: '' })
    const {senderId} = statusJob;

    const [isFollowing, setIsFollowing] = useState(false)
    useEffect(() => {
      const getUser = db.collection('users').doc(`${senderId}`).onSnapshot(snapshot=> setProfile(snapshot.data()))
      return () => getUser();
   }, [])
 
    useEffect(() => {
       if(profile){
          setIsFollowing(profile.followers.includes(user.id))
       }
    }, [profile])

    const {postId} = useParams()

    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);
    const onCopyBtn = (e) => {
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        setCopySuccess('コピーしました!');
    }
    let isMe = senderId === user.id?true: false

    const followNoti = () => {
        db.collection('notification').add({
           content: "にフォローされました",
           details: profile.displayName,
           photoURL: profile.photoURL,
           email: profile.email,
           subject: "新規フォロワー",
           timestamp: firebase.firestore.FieldValue.serverTimestamp(),
           url: `https://thestarclient.com/profile/${profile.username}`,
           userId: senderId,
           read: 'false'
        })
     }

     const [notification, setNotification] = useState([]);
     useEffect(() => {
        const getData = db.collection("notification").where('userId', '==', senderId).onSnapshot(snapshot => setNotification(snapshot.docs.map(doc => ({id: doc, ...doc.data()}))))
        return () => getData();
     }, [])
  
     const onFollowBtn = () => {
        follow(user.id, senderId);
        followNoti();

        if(profile.emailNotification === true){
            setTimeout(() => {
               if(notification.length > 0){
                  db.collection('sendEmail').add({
                     content: "新しい通知が複数あります",
                     details: '',
                     url: `https://thestarclient.com/notification`,
                     email: profile.email,
                     subject: '新規通知',
                  })
               } else{
                  db.collection('sendEmail').add({
                     content: "にフォローされました",
                     details: user.displayName,
                     url: `https://thestarclient.com/profile/${user.username}`,
                     email: profile.email,
                     subject: '新規通知',
                  })
               }
            }, 1000 * 60 * 60)
         }
     }
    return(
        <div className="widget__post">
            <div className="widget__card wiget__copy__btn">
                <input value={`http://localhost:3000/posts/${postId}`} readOnly ref={textAreaRef}/>{copySuccess ? <div className='post__success'><button><i className='fas fa-check success__icon'/>コピーしました</button></div>:<div className='post__success'><button onClick={onCopyBtn}><i className='fas fa-paperclip icon__paperclip__post'/>URLをコピー</button></div>}
            </div>
           <div className="widget__post__side widget__card">
                    {profile && profile.photoURL? 
                    <div>{(profile && profile.photoURL) && <img src={profile.photoURL} alt={`${profile.displayName}`} />}</div>
                    :
                    <div><img src={profileDefault} alt={`${profile.displayName}`}/></div>
                    }
                        <div className="user__profile__basic">
                            <h1>{profile.displayName}</h1>
                            <div className="infoWrapper">
                                <div className="userProfile__actions d-block">
                        
                                { !isMe && <div className="mailWrapper mail__widget__wrapper">
                                    <CreateChatWidget profileId={senderId} profileDisplayName={profile.displayName} profileUserName={profile.username}/>
                                </div> }                     
                                </div>
                                <div className="followWrapper follow__widget__wrapper">
                                { isMe? 
                                <div><Link className="profile__jump__wrapper" to={`/profile/${user.username}`}>プロフィール</Link></div>
                                :
                                    (
                                    isFollowing?
                                        <div className="followWrapper followed__wrapper" onClick={()=>unfollow(user.id, senderId)}>
                                            フォロー中
                                        </div>
                                    :
                                        <div className="following__widget follow__wrapper" onClick={onFollowBtn}>
                                            フォローする
                                        </div>
                                    )         
                                }
                                </div>
                            <div className="countInfo d-block widget__follow">
                                <Link to={`/profile/${profile.username}/following`}>
                                    <span>{profile && profile.following.length} <p>フォロー中</p></span>
                                </Link>
                                <Link to={`/profile/${profile.username}/followed`}>
                                    <span>{profile!==undefined && profile.followers.length} <p>フォロワー</p></span>
                                </Link>

                            </div>
                        </div>
                    </div>
           </div>
           <div className="widget__card">
                <ul className="snsbtniti d-flex widget__sns">
                    {(profile && profile.youtubeURL) || (profile && profile.twitterURL) || (profile && profile.instagramURL) || (profile && profile.facebookURL) || (profile && profile.lineURL) ? <div></div>: <p className="unwrite__text">SNSのURLがまだ記入されていません</p>}
                    {(profile && profile.youtubeURL) && <li><a href={`${profile && profile.youtubeURL}`} className="flowbtn8 fl_yu2"><i className="fab fa-youtube"/></a></li>}
                    {(profile && profile.twitterURL) && <li><a href={`${profile && profile.twitterURL}`} className="flowbtn8 fl_tw2"><i className="fab fa-twitter"/></a></li>}
                    {(profile && profile.instagramURL) && <li><a href={`${profile && profile.instagramURL}`} className="flowbtn8 insta_btn2"><i className="fab fa-instagram"/></a></li>}
                    {(profile && profile.facebookURL) && <li><a href={`${profile && profile.facebookURL}`} className="flowbtn8 fl_fb2"><i className="fab fa-facebook-f"/></a></li>}
                    {(profile && profile.lineURL) &&  <li><a href={`${profile && profile.lineURL}`} className="flowbtn8 fl_li2"><i className="fab fa-line"/></a></li>}
                </ul>
           </div>
        </div>
    )
}

export default AuthWidgetPost;