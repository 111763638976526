import React from "react";
import { useDocumentTitle, useScrollTop } from "../../hooks";
import './index.css';


const PrivacyPolicy = () => {
    useDocumentTitle("プライバシーポリシー / コピペル");
    useScrollTop();
    return(
        <div className="privacy__policy">
            Pri
        </div>
    );
};

export default PrivacyPolicy;